import React from 'react';

import { Edit, TrashCan, AddAlt, Flash } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useTerritoryGroupDialog } from 'app/contexts/territoryGroupDialogProvider';
import { useTerritoryOptimization } from 'app/contexts/territoryOptimizationProvider';

import { SplitFeatures } from 'app/global/features';

import usePhase from 'app/hooks/usePhase';
import useTreatment from 'app/hooks/useTreatment';

import { DeploymentModelPhase, PillLevel, TerritoryGroupDialogModes, TerritoryOptimizationStep } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './TerritoryHoverToolbar.module.pcss';

const b = block(style);

interface TerritoryHoverToolbarProps {
  pillLevel: PillLevel;
  territoryGroupId: string;
  shouldShowOptimize?: boolean;
  effectiveDate?: string;
  endDate?: string;
}

const TerritoryHoverToolbar: React.FC<TerritoryHoverToolbarProps> = ({
  pillLevel,
  territoryGroupId,
  shouldShowOptimize,
  effectiveDate,
  endDate
}: TerritoryHoverToolbarProps) => {
  const { selectedBattleCardId: battleCardId } = useBattleCard();
  const { openTerritoryGroupDialog } = useTerritoryGroupDialog();
  const deploymentModelPhase = usePhase();

  const [isTerritoryOptimizationOn] = useTreatment(SplitFeatures.TERRITORY_OPTIMIZATION);
  const { setOptimizationTargetTgId, setTerritoryOptimizationDrawerState } = useTerritoryOptimization();

  const handleClick = (event) => {
    event.stopPropagation();
  };
  return (
    <div className={b()} onClick={handleClick}>
      {pillLevel === PillLevel.TERRITORY && (
        <>
          <div>
            <IconButton
              type="button"
              icon={<Edit />}
              testId="territoryHoverToolbar-edit"
              title={formatMessage('EDIT_TERRITORY_GROUP')}
              onClick={() =>
                openTerritoryGroupDialog({
                  territoryGroupId,
                  mode: TerritoryGroupDialogModes.EDIT_GROUP,
                  battleCardId,
                  effectiveDate,
                  endDate
                })
              }
            />
          </div>
          {deploymentModelPhase === DeploymentModelPhase.plan && (
            <IconButton
              type="button"
              icon={<TrashCan />}
              testId="territoryHoverToolbar-delete"
              title={formatMessage('DELETE_TERRITORY_GROUP')}
              onClick={() =>
                openTerritoryGroupDialog({
                  territoryGroupId,
                  mode: TerritoryGroupDialogModes.DELETE_GROUP,
                  battleCardId
                })
              }
            />
          )}
        </>
      )}
      <IconButton
        type="button"
        icon={<AddAlt />}
        testId="territoryHoverToolbar-add"
        title={formatMessage('ADD_TERRITORY_GROUP')}
        onClick={() =>
          openTerritoryGroupDialog({
            territoryGroupId,
            mode: TerritoryGroupDialogModes.CREATE_GROUP,
            battleCardId,
            effectiveDate,
            endDate
          })
        }
      />
      {isTerritoryOptimizationOn &&
        pillLevel === PillLevel.TERRITORY &&
        shouldShowOptimize &&
        deploymentModelPhase === DeploymentModelPhase.plan && (
          <IconButton
            type="button"
            icon={<Flash />}
            testId="territoryHoverToolbar-territory-optimization"
            title={formatMessage('TERRITORY_OPTIMIZATION')}
            onClick={() => {
              setOptimizationTargetTgId(territoryGroupId);
              setTerritoryOptimizationDrawerState(TerritoryOptimizationStep.START);
            }}
          />
        )}
    </div>
  );
};

export default TerritoryHoverToolbar;
