import React from 'react';

import { Menu } from '@blueprintjs/core';
import { ChevronDown, Network_1 as Network1 } from '@carbon/icons-react';
import { useHistory } from 'react-router-dom';

import { MenuItem } from 'components/menu/MenuItem';
import Popover from 'components/Popover/Popover';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useCommandCenter } from 'app/contexts/commandCenterProvider';
import { useContributor } from 'app/contexts/contributorProvider';
import { useCurrency } from 'app/contexts/currencyProvider';
import { useDataTray } from 'app/contexts/dataTrayProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { useLocalization } from 'app/contexts/localizationProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useQuotaApprovalWorkflow } from 'app/contexts/quotaApprovalWorkflowProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { MembershipSpec, UserRoleType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './TenantMenu.module.pcss';

const b = block(style);

const modifiers = {
  arrow: {
    enabled: false
  }
};

interface TenantMenuProps {
  tenants: MembershipSpec[];
}

const TenantMenu: React.FC<TenantMenuProps> = ({ tenants }: TenantMenuProps) => {
  const { resetValues: resetBattleCardProviderValues } = useBattleCard();
  const { resetValues: resetContributorProviderValues } = useContributor();
  const { resetValues: resetDataTrayProviderValues } = useDataTray();
  const { resetValues: resetGridProviderValues } = useGrid();
  const { resetValues: resetPlanTargetsProviderValues } = usePlanTargets();
  const { resetValues: resetQuotaApprovalWorkflowProviderValues } = useQuotaApprovalWorkflow();
  const { selectedTenant, resetValues: resetScopeProviderValues } = useScope();
  const { resetValues: resetTerritoryDefineAndRefineProviderValues } = useTerritoryDefineAndRefine();
  const { userProfile, setShouldFetchUserSession, resetValues: resetUserProviderValues } = useUser();
  const { resetValues: resetCurrencyProviderValues } = useCurrency();
  const { resetValues: resetLocalizationProviderValues } = useLocalization();
  const { resetValues: resetCommandCenterProviderValues } = useCommandCenter();
  const history = useHistory();

  const tenantMenuContent = (
    <Menu className={b('menu')} data-testid="multi-tenant-menu">
      {tenants
        // eslint-disable-next-line no-restricted-syntax
        ?.filter((tenant) => tenant.systemRoleName !== UserRoleType.NONE && tenant.tenantId !== selectedTenant?.id)
        ?.map((tenant) => {
          return (
            <MenuItem
              text={`${tenant.tenantName}`}
              key={tenant.tenantGlobalId}
              onClick={() => setTenant(tenant)}
              icon={<Network1 />}
              className={b('menuItem')}
              data-testid={`multi-tenant-menu-item-${tenant?.tenantName}`}
            />
          );
        })}
    </Menu>
  );

  const setTenant = (tenant: MembershipSpec) => {
    resetBattleCardProviderValues();
    resetContributorProviderValues();
    resetDataTrayProviderValues();
    resetGridProviderValues();
    resetPlanTargetsProviderValues();
    resetQuotaApprovalWorkflowProviderValues();
    resetScopeProviderValues();
    resetTerritoryDefineAndRefineProviderValues();
    resetUserProviderValues();
    resetCurrencyProviderValues();
    resetLocalizationProviderValues();
    resetCommandCenterProviderValues();
    localStorage.setItem(
      userProfile.subjectId,
      JSON.stringify({
        tenantId: tenant.tenantId,
        tenantSlug: tenant.tenantSlug,
        tenantGlobalId: tenant.tenantGlobalId
      })
    );
    history.push(`/${tenant.tenantSlug}`);
    setShouldFetchUserSession(true);
  };

  const tenantName =
    tenants?.find((tenant) => tenant.tenantId === selectedTenant?.id)?.tenantName ?? formatMessage('SELECT_TENANT');

  return (
    <>
      {tenants?.length > 1 && tenantName && (
        <Popover content={tenantMenuContent} placement={'bottom-start'} modifiers={modifiers}>
          <div className={b()} data-testid="multi-tenant-dropdown">
            <div className={b('menuIcon')}>
              <Network1 size={32} />
            </div>
            <span className={b('menuText')}>{`${tenantName}`}</span>
            <ChevronDown size={24} />
          </div>
        </Popover>
      )}
      {tenants?.length === 1 && tenantName && (
        <div className={b('singleTenant')} data-testid="single-tenant-text">
          <div className={b('menuIcon')}>
            <Network1 size={32} />
          </div>
          <span className={b('menuText')}>{`${tenantName}`}</span>
        </div>
      )}
    </>
  );
};

export default TenantMenu;
