// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9h6N7 {\n    margin-top: var(--l);\n  }\n  ._3UK63 {\n    display: flex;\n    flex-direction: column;\n  }\n  /* postcss-bem-linter: ignore */\n  ._3UK63 label {\n      font-weight: normal;\n      width: 100%;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryGroupDialogForm_startDateField": "_9h6N7",
	"TerritoryGroupDialogForm_form": "_3UK63"
};
export default ___CSS_LOADER_EXPORT___;
