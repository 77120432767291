import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

import { DeploymentModelPhase } from 'app/models';

import usePhase from './usePhase';

export const useIsTerritoryGroupEffectiveDatesEnabled = () => {
  const deploymentModelPhase = usePhase();

  const [isTerritoryGroupEffectiveDatesEnabled] = useTreatment(SplitFeatures.TERRITORY_GROUP_EFFECTIVE_DATES);

  return isTerritoryGroupEffectiveDatesEnabled && deploymentModelPhase === DeploymentModelPhase.manage;
};
