import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import {
  ServerError,
  // eslint-disable-next-line no-restricted-imports
  useLazyQuery,
  // eslint-disable-next-line no-restricted-imports
  useMutation,
  // eslint-disable-next-line no-restricted-imports
  useQuery
} from '@apollo/client';
import { S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import { Classes } from '@blueprintjs/core';
import { ArrowRight, CheckmarkOutline, Document, Time, WarningAlt } from '@carbon/icons-react';
import { HTMLLink } from '@varicent/components';

import TextButton from 'components/Buttons/TextButton/TextButton';
import Icon from 'components/Icon/Icon';
import StatusToast from 'components/StatusToast/StatusToast';

import AccountInformationCallout from 'app/components/TerritoryMap/AccountInformationCallout';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { getFileUploadHeaders, getFileUploadHeadersErrorMessage } from 'app/core/fileUpload/fileUploadHeaderUtils';
import { FileHeader } from 'app/core/fileUpload/fileUploadProvider';

import { SplitFeatures } from 'app/global/features';

import {
  WriteFileToDB,
  WriteFileToDBVariables,
  WriteLocationFileToDB,
  WriteLocationFileToDBVariables
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { ADD_FILE_METADATA } from 'app/graphql/mutations/addFileMetadata';
import { WRITE_FILE_TO_DB } from 'app/graphql/mutations/writeFileToDB';
import { WRITE_LOCATION_FILE_TO_DB } from 'app/graphql/mutations/writeLocationFileToDb';
import { GET_FILE_HEADERS } from 'app/graphql/queries/getFileHeaders';
import { GET_FILE_UPLOAD_PROGRESS } from 'app/graphql/queries/getFileUploadProgress';

import usePhase from 'app/hooks/usePhase';
import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import { ColumnHeadersListedMap, DeploymentModelPhase, FileType } from 'app/models';

import block from 'utils/bem-css-modules';
import { config } from 'utils/config';
import { validateFileHeader, validateFile } from 'utils/helpers/fileUploadUtils';
import { FILE_UPLOAD_MISSING_INPUT } from 'utils/helpers/graphQLErrorMessages';
import { formatMessage } from 'utils/messages/utils';

import beginFileProcessing from 'assets/pngs/begin_file_upload.png';
import fileProcessingError from 'assets/pngs/file_processing_error.png';
import fileProcessingSuccess from 'assets/pngs/file_processing_success.png';

import AccountLocationUploadInstructions from './AccountLocationUploadInstructions';
import style from './FileUploadSequence.module.pcss';

const b = block(style);

enum SequenceStep {
  UPLOAD = 'Upload',
  PROCESSING = 'Processing'
}

enum ProcessingStatus {
  PENDING = 'Pending',
  COMPLETED = 'Completed',
  FAILED = 'Failed'
}

const eventNoOp = () => {
  // Any optional event handlers
};

export type FileUploadSequenceProps = {
  fileUploadType: FileType;
  showFileHeaders?: boolean;
  setDisableDialogCancel?: Dispatch<SetStateAction<boolean>>;
  setDisableDialogComplete?: Dispatch<SetStateAction<boolean>>;
  triggerAlignmentUpload?: () => void;
  onError?: () => void;
  onComplete?: () => void;
  onStart?: () => void;
  hierarchyId?: number;
  locationGroupName?: string;
  showImage?: boolean;
  disabled?: boolean;
};

const CONSECUTIVE_POLLING_ERROR_LIMIT = 30;

const FileUploadSequence: React.FC<FileUploadSequenceProps> = ({
  fileUploadType,
  hierarchyId,
  locationGroupName,
  setDisableDialogCancel = eventNoOp,
  setDisableDialogComplete = eventNoOp,
  onError = eventNoOp,
  onComplete = eventNoOp,
  onStart = eventNoOp,
  triggerAlignmentUpload,
  showImage = true,
  showFileHeaders = true,
  disabled = false
}) => {
  const [fileHeaders, setFileHeaders] = useState<FileHeader[]>([]);
  const [sequenceStep, setSequenceStep] = useState<SequenceStep>(SequenceStep.UPLOAD);
  const [validMimeFileType, setValidMimeFileType] = useState<boolean | null>(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [metaDataFileId, setMetaDataFileId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showStatusToast, setShowStatusToast] = useState<boolean>(false);
  const [s3UploadProgress, setS3UploadProgress] = useState<number>(0);
  const [processingStatus, setProcessingStatus] = useState<ProcessingStatus | null>(null);
  const [processingErrorMessage, setProcessingErrorMessage] = useState<string | null>(null);
  const [headerErrorMessage, setHeaderErrorMessage] = useState<string | null>(null);
  const { selectedPlanningCycle } = useScope();
  const { selectedBattleCardId, selectedQuotaComponentId } = useBattleCard();
  const [isNewAccountRoutingOn] = useTreatment(SplitFeatures.NEW_ACCOUNT_ROUTING_MANAGE);

  const deploymentModelPhase = usePhase();
  const showToast = useShowToast();
  const consecutiveErrorCount = useRef(0);
  // Step 6: Poll getFileList query to ask TQ's back end about Symon processing status:
  // - The TQ back end checks with Symon every two minutes for updates on the processing status
  // of all file uploads
  // - When Symon processes the file successfully, it writes the processed data to the TQ database
  // and we will then see a status update when polling getFileList; we'll know we can then
  // refetch whatever kind of data we're updating
  // - If there's a Symon processing error, we will see a status update when polling getFileList
  // and we can give more detailed error info to the user (esp. if the file headers were incorrect)
  const [pollForProcessingStatus, { error: fileUploadProgressError, stopPolling }] = useLazyQuery(
    GET_FILE_UPLOAD_PROGRESS,
    {
      variables: {
        fileIds: [metaDataFileId]
      },
      fetchPolicy: 'network-only',
      pollInterval: 2000,
      notifyOnNetworkStatusChange: true,
      onError({ graphQLErrors, networkError }) {
        handleError(graphQLErrors, networkError);
        if (networkError && (networkError as ServerError).statusCode === 401) {
          return stopPolling();
        }
        if (
          graphQLErrors?.find((x) => x.message === `FileUploadTrackerServiceError: file ${metaDataFileId} not found`) ||
          consecutiveErrorCount.current > CONSECUTIVE_POLLING_ERROR_LIMIT
        ) {
          showToast(formatMessage('RETRIEVE_PROCESSING_STATUS_ERROR'), 'danger');
          setProcessingStatus(ProcessingStatus.FAILED);
          return stopPolling();
        }
        showToast(formatMessage('RETRIEVE_PROCESSING_STATUS_ERROR'), 'danger');
        consecutiveErrorCount.current += 1;
      },
      onCompleted(data) {
        consecutiveErrorCount.current = 0; // reset the consecutive error count if onCompleted is called
        const { getFileUploadProgress } = data;

        if (getFileUploadProgress[0]) {
          // Check whether processing status has changed yet to completed or failed
          // (all possible Symon statuses are pending, in-progress, pending-validation, validating,
          // completed and failed, but we really only care about checking for the last two)
          if (getFileUploadProgress[0].status === 'completed') {
            stopPolling();
            setProcessingStatus(ProcessingStatus.COMPLETED);
            setDisableDialogComplete(false);
            onComplete();
          }

          if (getFileUploadProgress[0].status === 'failed') {
            stopPolling();
            setProcessingStatus(ProcessingStatus.FAILED);
            setProcessingErrorMessage(getFileUploadProgress[0].message);
            setDisableDialogCancel(false);
            onError();
          }
        }
      }
    }
  );

  useEffect(() => {
    if (fileUploadProgressError) {
      setIsLoading(false);
      setDisableDialogCancel(false);
      onError();
    }
  }, [fileUploadProgressError]);

  const { error: getFileHeadersError, loading: getFileHeadersLoading } = useQuery(GET_FILE_HEADERS, {
    variables: {
      planningCycleId: selectedPlanningCycle?.id,
      fileType: fileUploadType
    },
    onCompleted(data) {
      const { getFileHeaders } = data;
      setFileHeaders(getFileHeaders);
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('GET_FILE_HEADERS_ERROR'), 'danger');
    }
  });

  useEffect(() => {
    if (getFileHeadersError) {
      setIsLoading(false);
      setDisableDialogCancel(false);
      onError();
    }
  }, [getFileHeadersError]);

  // Step 5: Call writeFileToDB mutation to tell our back end that S3 upload is done
  // Then our back end can tell Symon to start processing the file
  const writeDbMutationOptions = {
    onCompleted() {
      setSequenceStep(SequenceStep.PROCESSING);
      setProcessingStatus(ProcessingStatus.PENDING);
      if (metaDataFileId) {
        pollForProcessingStatus();
      }
    },
    onError({ graphQLErrors, networkError }) {
      setIsLoading(false);
      setDisableDialogCancel(false);
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('NOTIFY_SERVER_ERROR'), 'danger');
      onError();
    }
  };
  const [writeFileToDB] = useMutation<WriteFileToDB, WriteFileToDBVariables>(WRITE_FILE_TO_DB, writeDbMutationOptions);
  const [writeLocationFileToDb] = useMutation<WriteLocationFileToDB, WriteLocationFileToDBVariables>(
    WRITE_LOCATION_FILE_TO_DB,
    writeDbMutationOptions
  );

  const reportS3UploadIsComplete = async (fileId, isFileUploaded) => {
    const input = {
      fileId,
      isFileUploaded
    };
    if (fileUploadType === FileType.LOCATION) {
      if (!locationGroupName) throw new Error(`Cannot write location file without group name`);
      await writeLocationFileToDb({ variables: { input: { ...input, locationGroupName } } });
    } else {
      await writeFileToDB({
        variables: { input }
      });
    }
  };

  // Step 4: Use aws-sdk to upload file to Symon's S3 bucket so that Symon can access the file
  const uploadFileToS3 = async (fileMetadata) => {
    const { accessKeyId, secretKey, sessionToken, bucket, key, fileId } = fileMetadata;
    try {
      const upload = new Upload({
        client: new S3Client({
          credentials: {
            accessKeyId,
            secretAccessKey: secretKey,
            sessionToken
          },
          region: config.SYMON_UPLOAD_REGION
        }),
        params: {
          Bucket: bucket,
          Key: key,
          Body: selectedFile
        }
      });

      upload.on('httpUploadProgress', (event) => {
        const progress = Math.round(event.loaded / event.total);
        setS3UploadProgress(progress);
        if (progress === 1) {
          setTimeout(() => {
            setShowStatusToast(false);
            setS3UploadProgress(0);
          }, 2000);
        }
      });
      await upload.done();
      reportS3UploadIsComplete(fileId, true);
    } catch (err) {
      console.error('Error while uploading to s3', err);
      reportS3UploadIsComplete(fileId, false);
      setIsLoading(false);
      setDisableDialogCancel(false);
      onError();
    }
  };

  // Step 3: Call addFileMetadata mutation to retrieve details
  // about which S3 bucket we should upload to
  const [addFileMetadata, { error: addFileMetadataError }] = useMutation(ADD_FILE_METADATA, {
    onCompleted(data) {
      const { addFileMetadata } = data;
      setMetaDataFileId(addFileMetadata.fileId);
      uploadFileToS3(addFileMetadata);
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('BEGIN_UPLOAD_ERROR'), 'danger');
    }
  });

  useEffect(() => {
    if (addFileMetadataError) {
      setIsLoading(false);
      setDisableDialogCancel(false);
      onError();
    }
  }, [addFileMetadataError]);

  const fetchFileMetaData = (fileName) => {
    setIsLoading(true);
    setDisableDialogCancel(true);
    setDisableDialogComplete(true);
    setShowStatusToast(true);

    // NB: Territory rule upload is the only file type that requires quotaComponentId;
    // if that is not passed the upload will fail
    addFileMetadata({
      variables: {
        planningCycleId: selectedPlanningCycle?.id,
        fileName,
        fileType: fileUploadType,
        battlecardId: selectedBattleCardId,
        quotaComponentId: selectedQuotaComponentId,
        hierarchyId
      }
    });
  };

  // Step 2: Parse uploaded file and validate headers
  const validateSelectedFile = (file) => {
    const requiredHeaders: string[] =
      getFileUploadHeaders(fileUploadType) ||
      fileHeaders.filter((fileHeader) => fileHeader.columnRequired).map((fileHeader) => fileHeader.csvHeader);
    return new Promise<void>(async (resolve, reject) => {
      setHeaderErrorMessage(null);
      const result = await validateFileHeader(file, requiredHeaders);
      const { validationError, errors, missingHeaders } = result;
      if (validationError) {
        showToast(validationError, 'danger');
        reject(new Error(validationError));
      } else if (errors?.[0]?.message) {
        showToast(errors[0].message, 'danger');
        reject(new Error(errors[0].message));
      } else if (missingHeaders.length > 0) {
        reject(missingHeaders);
      } else {
        resolve();
      }
    });
  };

  // Step 1: Trigger File Upload API
  // We'll mask the default browser file input for better styling
  // so use refs to trigger hidden input when our TextButton is clicked
  const fileInput = useRef<HTMLInputElement | null>(null);
  const chooseFileButton = useRef<HTMLButtonElement | null>(null);

  const triggerMaskedFileInput = () => {
    if (fileInput.current && chooseFileButton.current) {
      fileInput.current.click();
    }
  };

  const handleSelectedFile = async () => {
    const file = fileInput.current.files[0];
    // Windows doesn't provide the MIME type "text/csv" so extract
    // the file extension to help determine if the file is of the valid format
    const fileExtension = file.name.split('.').pop();
    if (validateFile(file.name, null, null)) {
      showToast(formatMessage('FILE_NAME_VALIDATION_ERROR'), 'danger');
      return;
    }

    setSelectedFile(file);

    if (file?.type === 'text/csv' || fileExtension === 'csv') {
      setValidMimeFileType(true);
      onStart();
      await validateSelectedFile(file)
        .then(() => fetchFileMetaData(file.name))
        .catch((missingHeadersOrError) => {
          const errorMessage = Array.isArray(missingHeadersOrError)
            ? getFileUploadHeadersErrorMessage(missingHeadersOrError)
            : formatMessage('FILE_UPLOAD_HEADER_ERROR_UNKNOWN');
          setHeaderErrorMessage(errorMessage);
          onError();
        });
    } else {
      setValidMimeFileType(false);
    }
  };

  // The Secondary error message renders an canned error message if it contains a certain string. Otherwise, it will display the error message from the service.
  const secondaryErrorMessage = processingErrorMessage?.includes(FILE_UPLOAD_MISSING_INPUT)
    ? formatMessage('CHECK_REQUIRED_FILE_HEADERS')
    : processingErrorMessage;

  const RenderDynamicFileHeaders = (): string => {
    const fileHeadersString: string[] = fileHeaders.map((fileHeader) => {
      return `${fileHeader.csvHeader}${fileHeader.columnRequired ? '*' : ''}`;
    });
    return formatMessage('UPLOAD_COLUMN_HEADERS_MESSAGE_PREFIX', { value: fileHeadersString.join(', ') });
  };

  const columnHeadersInstructionMap = {
    [FileType.CUSTOM_HIERARCHY]: formatMessage('UPLOAD_COLUMN_HEADERS_MESSAGE_PREFIX', {
      value: ColumnHeadersListedMap.GENERIC
    }),
    [FileType.GEOGRAPHIC_TERRITORY_HIERARCHY]: formatMessage('UPLOAD_COLUMN_HEADERS_MESSAGE_PREFIX', {
      value: ColumnHeadersListedMap.GENERIC
    }),
    [FileType.CUSTOMER_ACCOUNT_HIERARCHY]: formatMessage('UPLOAD_COLUMN_HEADERS_MESSAGE_PREFIX', {
      value: ColumnHeadersListedMap.CUSTOMER_ACCOUNT_HIERARCHY
    }),
    [FileType.ACTIVITY]: formatMessage('UPLOAD_COLUMN_HEADERS_MESSAGE_PREFIX', {
      value: ColumnHeadersListedMap.ACTIVITY
    }),
    [FileType.LOCATION]: formatMessage('UPLOAD_COLUMN_HEADERS_MESSAGE_PREFIX', {
      value: ColumnHeadersListedMap.LOCATION
    })
  };

  const RenderFileHeaders = () => {
    const columnHeaders = columnHeadersInstructionMap[fileUploadType];
    if (!columnHeaders) return RenderDynamicFileHeaders();

    return columnHeaders;
  };
  return (
    <div className={b()} data-testid="file-upload-sequence">
      {sequenceStep === SequenceStep.UPLOAD && (
        <div>
          {showImage && (
            <div className={b('imageBackground')}>
              <img
                className={b('processingIllustration')}
                src={beginFileProcessing}
                alt={formatMessage('BEGIN_FILE_UPLOAD')}
              />
            </div>
          )}
          {fileUploadType === FileType.CUSTOMER_ACCOUNT_HIERARCHY && <AccountLocationUploadInstructions />}
          <div className={b('uploadInstructions')}>
            {showFileHeaders && (
              <div className={b('columnHeadersInstruction')} data-testid="column-headers-instruction">
                <span
                  className={getFileHeadersLoading ? `${Classes.SKELETON} ${b('loadingPlaceholder')}` : ''}
                  data-testid="file-headers"
                >
                  {fileUploadType && RenderFileHeaders()}
                </span>
              </div>
            )}
            {headerErrorMessage && (
              <p data-testid="header-error-message" className={b('validationError')}>
                {headerErrorMessage}
              </p>
            )}
            <div className={b('fileStatusInstruction')} data-testid="file-status-instruction">
              {selectedFile ? selectedFile.name : formatMessage('NO_FILE_SELECTED')}
            </div>
            <div className={b('fileTypeInstruction')} data-testid="file-type-instruction">
              {formatMessage('CHOOSE_FILE_FOR_UPLOAD')}
            </div>

            <div className={b('maskedFileInput')}>
              {/* id on input is needed to solve a Selenium issue */}
              <input
                id="fileInput"
                ref={fileInput}
                type="file"
                style={{ display: 'none' }}
                data-testid="masked-input"
                onChange={handleSelectedFile}
              />
              <div>
                <TextButton
                  testId={'select-file-button'}
                  type="button"
                  intent="primary"
                  text={formatMessage('CHOOSE_A_FILE')}
                  refProps={chooseFileButton}
                  onClick={triggerMaskedFileInput}
                  loading={isLoading}
                  disabled={disabled || getFileHeadersLoading}
                />
              </div>
              <div className={b('validationSpacer')}>
                {validMimeFileType === false && (
                  <div className={b('validationError')} data-testid="file-validation-error">
                    {formatMessage('FILE_UPLOAD_VALIDATION_ERROR', { file: selectedFile?.name })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {sequenceStep === SequenceStep.PROCESSING && (
        <div>
          {showImage && (
            <div className={b('imageBackground')}>
              {processingStatus === ProcessingStatus.PENDING && (
                <div>
                  <img
                    className={b('processingIllustration')}
                    src={beginFileProcessing}
                    alt={formatMessage('BEGIN_FILE_UPLOAD')}
                  />
                </div>
              )}
              {processingStatus === ProcessingStatus.COMPLETED && (
                <img
                  className={b('processingIllustration')}
                  src={fileProcessingSuccess}
                  alt={formatMessage('FILE_PROCESSING_SUCCESS')}
                />
              )}
              {processingStatus === ProcessingStatus.FAILED && (
                <img
                  className={b('processingIllustration')}
                  src={fileProcessingError}
                  alt={formatMessage('FILE_PROCESSING_ERROR')}
                />
              )}
            </div>
          )}
          <div className={b('processingStatus')}>
            <div className={b('processingStatusMessages')}>
              <div className={b('processingStatusMainMessage')} data-testid="processing-status-message">
                {processingStatus === ProcessingStatus.PENDING && formatMessage('PROCESSING_MESSAGE_PENDING')}
                {processingStatus === ProcessingStatus.COMPLETED && formatMessage('PROCESSING_MESSAGE_COMPLETED')}
                {processingStatus === ProcessingStatus.FAILED && formatMessage('PROCESSING_MESSAGE_ERROR')}
              </div>
              {secondaryErrorMessage && (
                <div className={b('processingStatusSecondaryMessage')} data-testid="secondary-error-message">
                  {secondaryErrorMessage}
                </div>
              )}
            </div>
            <div className={b('processingStatusDetails')}>
              <span className={b('nameDetails')} data-testid="processing-status-filename">
                <span className={b('fileIcon')}>
                  <Document />
                </span>
                {selectedFile?.name}
              </span>
              <span
                className={b('statusDetails', { error: processingStatus === ProcessingStatus.FAILED })}
                data-testid="processing-status-details"
              >
                {processingStatus === ProcessingStatus.PENDING && (
                  <>
                    {formatMessage('PROCESSING_STATUS_PENDING')}
                    <span className={b('statusIcon')}>
                      <Time />
                    </span>
                  </>
                )}
                {processingStatus === ProcessingStatus.COMPLETED && (
                  <>
                    {formatMessage('COMPLETED')}
                    <span className={b('statusIcon')}>
                      <CheckmarkOutline />
                    </span>
                  </>
                )}
                {processingStatus === ProcessingStatus.FAILED && (
                  <>
                    {formatMessage('PROCESSING_STATUS_ERROR')}
                    <span className={b('statusIcon')}>
                      <WarningAlt />
                    </span>
                  </>
                )}
              </span>
            </div>
            {processingStatus === ProcessingStatus.COMPLETED &&
              fileUploadType === FileType.CUSTOMER_ACCOUNT_HIERARCHY && <AccountInformationCallout />}
            {isNewAccountRoutingOn &&
              processingStatus === ProcessingStatus.COMPLETED &&
              fileUploadType === FileType.CUSTOMER_ACCOUNT_HIERARCHY &&
              deploymentModelPhase === DeploymentModelPhase.manage && (
                <div className={b('uploadAlignmentOption')} data-testid="upload-account-alignment-option">
                  <HTMLLink
                    href="#"
                    icon={<Icon icon={<ArrowRight />} />}
                    iconRight={true}
                    text={formatMessage('UPLOAD_ACCOUNT_ALIGNMENT')}
                    disabled={disabled}
                    onClick={(e) => {
                      e.preventDefault();

                      triggerAlignmentUpload();
                    }}
                  />
                </div>
              )}
          </div>
        </div>
      )}

      {showStatusToast && (
        <StatusToast
          isOpen={showStatusToast}
          value={s3UploadProgress}
          description="Uploading file"
          onClose={() => setShowStatusToast(false)}
          canCancelOperation={false}
          data-testid="status-toast"
        />
      )}
    </div>
  );
};

export default FileUploadSequence;
