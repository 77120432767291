import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import { BasicSelect, BasicMultiSelect } from 'components/BasicSelect/BasicSelects';

import { CollectionFilter, CollectionFilterKind, NamedRootHierarchy } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './CustomHierarchyFilterMenuV2.module.pcss';

const b = block(style);

export const CustomHierarchyFilterMenuV2: FC<{
  hierarchies: NamedRootHierarchy[];
  filter: CollectionFilter<number>;
  rootHierarchyName: string;
  rootHierarchyId: number;
  onUpdateFilter: (filter: CollectionFilter<number>) => void;
}> = ({ hierarchies: rawHierarchies, filter: remoteFilter, onUpdateFilter, rootHierarchyName, rootHierarchyId }) => {
  const [currentFilter, setCurrentFilter] = useState<CollectionFilter<number>>(remoteFilter);

  const hierarchyItems = useMemo(
    () =>
      rawHierarchies.map((hierarchy) => ({ text: hierarchy.customHierarchyName, value: hierarchy.customHierarchyId })),
    [rawHierarchies]
  );

  const handleSelectedValueChanged = (kind: CollectionFilterKind) => {
    let ids = remoteFilter.ids;
    if (kind === CollectionFilterKind.NONE || kind === CollectionFilterKind.MATCH_ANY_CONDITION) {
      ids = [];
    }
    const filter: CollectionFilter<number> = { rootHierarchyId, ids, kind };
    setCurrentFilter(filter);
    onUpdateFilter(filter);
  };

  const handleFilterValuesChange = (ids: number[]) => {
    const filter = {
      rootHierarchyId,
      ids,
      kind: remoteFilter.kind
    };
    setCurrentFilter(filter);
    onUpdateFilter(filter);
  };

  useEffect(() => {
    setCurrentFilter(remoteFilter);
  }, [remoteFilter]);

  const containerRef = useRef();
  return (
    <section data-testid={`custom-hierarchy-filter-menu-${rootHierarchyId}`} className={b('filter')} ref={containerRef}>
      <div>{rootHierarchyName}</div>
      <form className={b('form')} onSubmit={(e) => e.preventDefault()} data-testid="custom-hierarchy-filter-form">
        <BasicSelect<CollectionFilterKind>
          testId="custom-filter-kind"
          items={filterKindItems}
          value={currentFilter.kind}
          minimal
          placement="bottom-start"
          containerElement={containerRef.current}
          label={formatMessage('CUSTOM_HIERARCHY_FILTER_KIND_LABEL')}
          onSelectedValueChange={handleSelectedValueChanged}
        />
        {currentFilter.kind !== CollectionFilterKind.NONE &&
          currentFilter.kind !== CollectionFilterKind.MATCH_ANY_CONDITION && (
            <>
              <hr className={b('fieldSeparator')} />

              <BasicMultiSelect<number>
                testId="custom-filter-ids"
                items={hierarchyItems}
                onValuesChange={handleFilterValuesChange}
                values={currentFilter.ids}
                minimal
                placement="bottom-start"
                className={b('hierarchyMultiSelect')}
                label={formatMessage('CUSTOM_HIERARCHY_FILTER_IDS_LABEL')}
                matchTargetWidth
                containerElement={containerRef.current}
                placeholder={formatMessage(
                  hierarchyItems.length === 0 ? 'CUSTOM_HIERARCHY_FILTER_IDS_EMPTY_PLACEHOLDER' : 'SELECT_A_HIERARCHY'
                )}
              />
            </>
          )}
      </form>
    </section>
  );
};

const filterKindItems = [
  { value: CollectionFilterKind.EQUALS, text: formatMessage('COLLECTION_FILTER_EQUALS_TO') },
  { value: CollectionFilterKind.NOT_EQUALS, text: formatMessage('COLLECTION_FILTER_NOT_EQUALS_TO') },
  { value: CollectionFilterKind.CONTAINS_ANY, text: formatMessage('COLLECTION_FILTER_CONTAINS_ANY') },
  { value: CollectionFilterKind.NOT_CONTAINS_ANY, text: formatMessage('COLLECTION_FILTER_NOT_CONTAINS_ANY') },
  { value: CollectionFilterKind.NONE, text: formatMessage('NONE') },
  { value: CollectionFilterKind.MATCH_ANY_CONDITION, text: formatMessage('COLLECTION_FILTER_MATCH_ANY') }
];
