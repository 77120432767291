import React, { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';

import Dialog from 'components/Dialog/Dialog';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import {
  CreateTerritoryGroup,
  CreateTerritoryGroupVariables,
  UpdateTerritoryGroup,
  UpdateTerritoryGroupVariables,
  UpsertTerritoryGroupOwner,
  UpsertTerritoryGroupOwnerVariables
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { CREATE_TERRITORY_GROUP } from 'app/graphql/mutations/createTerritoryGroup';
import { UPDATE_TERRITORY_GROUP } from 'app/graphql/mutations/updateTerritoryGroup';
import { useUpsertTerritoryGroup } from 'app/graphql/mutations/upsertTerritoryGroup';
import { UPSERT_TERRITORY_GROUP_OWNER } from 'app/graphql/mutations/upsertTerritoryGroupOwner';
import { useGetValidTerritoryGroupDateRange } from 'app/graphql/queries/getValidTerritoryGroupDateRange';

import { useIsTerritoryGroupEffectiveDatesEnabled } from 'app/hooks/useIsTerritoryGroupEffectiveDatesEnabled';
import useShowToast from 'app/hooks/useShowToast';

import { TerritoryGroupDialogModes } from 'app/models';

import { convertToLocalDateString } from 'utils/helpers/dateHelpers';
import { memberToString } from 'utils/helpers/index';
import { formatMessage } from 'utils/messages/utils';

import TerritoryGroupDialogForm from './TerritoryGroupDialogForm';
import buildValidationSchema from './validationsSchema';

interface TerritoryGroupDialogFormValues {
  name: string;
  territoryGroupOwner: { key: string; value: number };
  effectiveDate: string | null;
  endDate: string | null;
}

const dynamicCopy = {
  [TerritoryGroupDialogModes.CREATE_GROUP]: {
    title: formatMessage('CREATE_TERRITORY_GROUP'),
    confirmButtonText: formatMessage('ADD'),
    successToastText: formatMessage('CREATE_NEW_TERRITORY_GROUP_SUCCESS'),
    errorToastText: formatMessage('CREATE_NEW_TERRITORY_GROUP_ERROR')
  },
  [TerritoryGroupDialogModes.EDIT_GROUP]: {
    title: formatMessage('EDIT_TERRITORY_GROUP'),
    confirmButtonText: formatMessage('UPDATE'),
    successToastText: formatMessage('UPDATE_TERRITORY_GROUP_SUCCESS'),
    errorToastText: formatMessage('UPDATE_TERRITORY_GROUP_ERROR')
  }
};

interface TerritoryGroupDialogProps {
  territoryGroupDialogState: {
    territoryGroupId: string;
    mode: TerritoryGroupDialogModes.CREATE_GROUP | TerritoryGroupDialogModes.EDIT_GROUP;
    battleCardId: string;
    effectiveDate?: string;
    endDate?: string;
  };
  closeTerritoryGroupDialog: () => void;
}

const TerritoryGroupDialog: React.FC<TerritoryGroupDialogProps> = ({
  territoryGroupDialogState: { mode, territoryGroupId, battleCardId, effectiveDate, endDate },
  closeTerritoryGroupDialog
}) => {
  const { userProfile } = useUser();
  const { selectedQuotaComponentId } = useBattleCard();

  const { tdrLookupMap, getTDR } = useTerritoryDefineAndRefine();
  const { getPlanTargets } = usePlanTargets();
  const { selectedPlanningCycle, selectedDeploymentModelId } = useScope();

  const showToast = useShowToast();
  const isTerritoryGroupEffectiveDatesEnabled = useIsTerritoryGroupEffectiveDatesEnabled();

  const isCreateMode = mode === TerritoryGroupDialogModes.CREATE_GROUP;
  const { title, confirmButtonText, successToastText, errorToastText } = dynamicCopy[mode];

  const selectedTerritoryGroup = tdrLookupMap[territoryGroupId];
  const tgDateRangeInputForCreate = {
    parentTerritoryGroupId: +selectedTerritoryGroup.territoryGroupId,
    territoryGroupId: null
  };
  const tgDateRangeInputForEdit = {
    parentTerritoryGroupId: +selectedTerritoryGroup.territoryGroupParentId,
    territoryGroupId: +selectedTerritoryGroup.territoryGroupId
  };
  const { data, loading } = useGetValidTerritoryGroupDateRange({
    fetchPolicy: 'network-only',
    skip: !isTerritoryGroupEffectiveDatesEnabled,
    variables: {
      input: isCreateMode ? tgDateRangeInputForCreate : tgDateRangeInputForEdit
    }
  });

  const initialFormValues = useMemo(
    (): TerritoryGroupDialogFormValues =>
      isCreateMode
        ? { name: '', territoryGroupOwner: { key: '', value: 0 }, effectiveDate: null, endDate: null }
        : {
            name: selectedTerritoryGroup.name,
            territoryGroupOwner: {
              key: memberToString(selectedTerritoryGroup.owner),
              value: selectedTerritoryGroup.owner.memberId
            },
            effectiveDate,
            endDate
          },
    [isCreateMode, selectedTerritoryGroup]
  );

  const [upsertTerritoryGroup] = useUpsertTerritoryGroup({
    onCompleted() {
      showToast(successToastText, 'success');
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(errorToastText, 'danger');
    }
  });

  const [createTerritoryGroup] = useMutation<CreateTerritoryGroup, CreateTerritoryGroupVariables>(
    CREATE_TERRITORY_GROUP,
    {
      onCompleted() {
        showToast(successToastText, 'success');
      },
      onError({ graphQLErrors, networkError }) {
        handleError(graphQLErrors, networkError);
        showToast(errorToastText, 'danger');
      }
    }
  );

  const [updateTerritoryGroup] = useMutation<UpdateTerritoryGroup, UpdateTerritoryGroupVariables>(
    UPDATE_TERRITORY_GROUP,
    {
      onCompleted() {
        showToast(successToastText, 'success');
      },
      onError({ graphQLErrors, networkError }) {
        handleError(graphQLErrors, networkError);
        showToast(errorToastText, 'danger');
      }
    }
  );

  const [upsertTerritoryGroupOwner] = useMutation<UpsertTerritoryGroupOwner, UpsertTerritoryGroupOwnerVariables>(
    UPSERT_TERRITORY_GROUP_OWNER,
    {
      onError({ graphQLErrors, networkError }) {
        handleError(graphQLErrors, networkError);
        showToast(formatMessage('UPSERT_TERRITORY_GROUP_OWNER_ERROR'), 'danger');
      }
    }
  );

  const handleSubmit = async (values: TerritoryGroupDialogFormValues) => {
    if (isTerritoryGroupEffectiveDatesEnabled) {
      await upsertTerritoryGroup({
        variables: {
          input: {
            territoryGroupName: values.name,
            territoryGroupParentId: isCreateMode
              ? +selectedTerritoryGroup.territoryGroupId
              : +selectedTerritoryGroup.territoryGroupParentId,
            territoryGroupId: isCreateMode ? null : +selectedTerritoryGroup.territoryGroupId,
            memberId: values.territoryGroupOwner.value ? +values.territoryGroupOwner.value : null,
            effectiveDate: values.effectiveDate ? convertToLocalDateString(values.effectiveDate) : null,
            endDate: values.endDate ? convertToLocalDateString(values.endDate) : null
          }
        }
      });
    } else {
      await handleLegacyUpsertTgAndOwner(values);
    }
    handleCloseDialog();
  };

  const handleLegacyUpsertTgAndOwner = async (values: TerritoryGroupDialogFormValues) => {
    let selectedTerritoryGroupId: number;
    if (isCreateMode) {
      const result = await createTerritoryGroup({
        variables: {
          battlecardId: +battleCardId,
          territoryGroupName: values.name,
          // Use the selection as the parent
          territoryGroupParentId: +selectedTerritoryGroup.territoryGroupId,
          precedence: selectedTerritoryGroup.precedence
        }
      });
      selectedTerritoryGroupId = result?.data?.createTerritoryGroup?.territoryGroupId;
    } else {
      selectedTerritoryGroupId = +selectedTerritoryGroup.territoryGroupId;
      await updateTerritoryGroup({
        variables: {
          territoryGroupName: values.name,
          territoryGroupId: selectedTerritoryGroupId
        }
      });
    }

    const newOwnerId = values.territoryGroupOwner.value;
    const isOwnerChanged = newOwnerId !== initialFormValues.territoryGroupOwner.value;
    const shouldUpsertTerritoryGroupOwner = newOwnerId && (isCreateMode || isOwnerChanged);

    if (selectedTerritoryGroupId && shouldUpsertTerritoryGroupOwner) {
      await upsertTerritoryGroupOwner({
        variables: {
          input: {
            tenantId: userProfile.tenant?.id,
            territoryGroupId: +selectedTerritoryGroupId,
            memberId: +newOwnerId
          }
        }
      });
    }
  };

  const handleCloseDialog = async () => {
    closeTerritoryGroupDialog();
    if (isTerritoryGroupEffectiveDatesEnabled) {
      await getPlanTargets(
        battleCardId,
        selectedQuotaComponentId,
        selectedPlanningCycle?.id,
        selectedDeploymentModelId
      );
    }
    await getTDR(battleCardId, selectedQuotaComponentId);
  };

  const validationsSchema = useMemo(() => {
    return buildValidationSchema({
      isEffectiveDatesEnabled: isTerritoryGroupEffectiveDatesEnabled,
      validDateRange: data?.getValidTerritoryGroupDateRange
        ? {
            minStartDate: data.getValidTerritoryGroupDateRange.minStartDate,
            maxEndDate: data.getValidTerritoryGroupDateRange.maxEndDate
          }
        : null
    });
  }, [isTerritoryGroupEffectiveDatesEnabled, data?.getValidTerritoryGroupDateRange]);

  return (
    <Formik initialValues={initialFormValues} validationSchema={validationsSchema} onSubmit={handleSubmit}>
      {({ handleSubmit, isValid, isSubmitting }) => (
        <Dialog
          isOpen
          onClose={closeTerritoryGroupDialog}
          onSubmit={handleSubmit}
          title={title}
          confirmButtonText={confirmButtonText}
          confirmButtonLoading={isSubmitting}
          disableConfirm={!isValid}
          showOverflow
          isLoading={!selectedTerritoryGroup}
          data-testid="territory-group-dialog"
          size="small"
        >
          <TerritoryGroupDialogForm validDateRange={data} isValidDateRangeLoading={loading} />
        </Dialog>
      )}
    </Formik>
  );
};

export default TerritoryGroupDialog;
