// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".op4WJ {\n    height: 40px;\n  }\n  .EuywY {\n    width: 100%;\n    padding-left: var(--grid-header-padding);\n  }\n  .EuywY .bp3-button {\n      height: var(--select-button-height);\n    }\n  ._3T4Yu {\n      padding-right: var(--grid-header-padding);\n      max-width: 20%;\n    }\n  ._2tJ1b {\n      padding-right: var(--grid-header-padding);\n      max-width: 33%;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UpsertTerritoryRuleViewLeftPanelContent_territoryGroupInput": "op4WJ",
	"UpsertTerritoryRuleViewLeftPanelContent_inputGroup": "EuywY",
	"UpsertTerritoryRuleViewLeftPanelContent_inputGroup__small": "_3T4Yu",
	"UpsertTerritoryRuleViewLeftPanelContent_inputGroup__medium": "_2tJ1b"
};
export default ___CSS_LOADER_EXPORT___;
