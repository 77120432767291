import React, { useState } from 'react';

import { AddAlt, TrashCan } from '@carbon/icons-react';
import { ErrorMessage, Field, FieldArray, useFormikContext } from 'formik';

import IconButton from 'components/Buttons/IconButton/IconButton';
import TextButton from 'components/Buttons/TextButton/TextButton';
import ConfirmDeleteModal from 'components/Dialog/ConfirmDeleteModal/ConfirmDeleteModal';
import SelectMenu from 'components/SelectMenu/SelectMenu';

import FormCheckbox from 'app/components/FormFields/FormCheckbox/FormCheckbox';
import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';
import {
  attributeTypeDropDown,
  CustomerAccountHierarchyFormValues,
  HierarchyFormValues
} from 'app/components/HierarchyEditDialog/HierarchyEditDialog';

import { useScope } from 'app/contexts/scopeProvider';

import { SplitFeatures } from 'app/global/features';

import { useDeleteHierarchyAttribute } from 'app/graphql/mutations/deleteHierarchyAttribute';

import useTreatment from 'app/hooks/useTreatment';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './HierarchyAttributesFormFields.module.pcss';

const b = block(style);

interface HierarchyAttributesFormFieldsProps {
  hierarchyId?: number;
  hierarchyType?: string;
}

const HierarchyAttributesFormFields: React.FC<HierarchyAttributesFormFieldsProps> = ({
  hierarchyId,
  hierarchyType
}) => {
  const { values } = useFormikContext<HierarchyFormValues | CustomerAccountHierarchyFormValues>();
  const { selectedPlanningCycle } = useScope();
  const [showDeleteCustomAttributeDialog, setShowDeleteCustomAttributeDialog] = useState<boolean>(false);
  const [isCustomHierarchyAttributesOn] = useTreatment(SplitFeatures.CUSTOM_HIERARCHY_ATTRIBUTES_V2);
  const [selectedCustomAttribute, setSelectedCustomAttribute] = useState(null);

  const [deleteHierarchyAttribute, { loading: deletingHierarchyAttribute }] = useDeleteHierarchyAttribute();

  const attributeTypes = attributeTypeDropDown.items;

  return (
    <div className={b()} data-testid="hierarchy-attributes-form-fields">
      <div className={b('columns')}>
        <div className={b('columnsHeader')}>
          <span />
          <span>{formatMessage('ATTRIBUTE_NAME')}</span>
          <span>{formatMessage('TYPE')}</span>
          <span />
        </div>
        <FieldArray
          name="attributes"
          render={(arrayHelpers) => (
            <>
              {values.attributes.map((column, index) => {
                const columnKey = `${values.hierarchyName}-${index}`;
                return (
                  <div className={b('columnContainer')} key={columnKey}>
                    <div className={b('column')}>
                      <div className={b('checkBox')} data-testid={`hierarchy-edit-checkbox-${index}`}>
                        <Field name={`attributes[${index}]`} component={FormCheckbox} checked disabled />
                      </div>
                      <div className={b('textInput')} data-testid={`hierarchy-edit-attribute-name-${index}`}>
                        <Field
                          name={`attributes[${index}].name`}
                          component={FormTextInputGroup}
                          disabled={!column.editable}
                          showErrors={false}
                        />
                      </div>
                      <div className={b('dropDownMenu')} data-testid={`hierarchy-edit-attribute-type-${index}`}>
                        <Field
                          name={`attributes[${index}].type`}
                          items={attributeTypes}
                          theme={attributeTypeDropDown.theme}
                          component={SelectMenu}
                          outlineItems
                          // disallow user to edit data type once created to avoid complications
                          disabled={!column.editable || (hierarchyId && !column['unsaved'])}
                          showErrors={false}
                        />
                      </div>
                      <div className={b('deleteButton')}>
                        <IconButton
                          type="button"
                          icon={<TrashCan />}
                          disabled={!column.editable}
                          onClick={() => {
                            if (isCustomHierarchyAttributesOn) {
                              // If it is an empty row, remove it.
                              if (column.name === '') {
                                arrayHelpers.remove(index);
                              } else {
                                // If it is not empty, show a confirm modal.
                                setSelectedCustomAttribute(column);
                                setShowDeleteCustomAttributeDialog(true);
                              }
                            }
                          }}
                          testId={'delete-button'}
                          title={formatMessage('DELETE')}
                        />
                      </div>
                    </div>
                    <ErrorMessage className={b('errorMessage')} name={`attributes[${index}].name`} component="div" />
                  </div>
                );
              })}
              {isCustomHierarchyAttributesOn && (
                <TextButton
                  type="button"
                  icon={<AddAlt />}
                  onClick={() =>
                    arrayHelpers.push({
                      editable: true,
                      name: '',
                      type: attributeTypeDropDown.defaultItem,
                      unsaved: true
                    })
                  }
                  testId={'add-custom-attribute-button'}
                  text={formatMessage('ADD_CUSTOM_ATTRIBUTE')}
                  minimal
                />
              )}
              <ConfirmDeleteModal
                isOpen={showDeleteCustomAttributeDialog}
                onConfirmDelete={(e) => {
                  e.stopPropagation();

                  // If the row is just added and has not been saved, remove it from the list.
                  if (!!selectedCustomAttribute['unsaved']) {
                    values.attributes.splice(
                      values.attributes.findIndex((attribute) => attribute.name === selectedCustomAttribute.name),
                      1
                    );

                    setShowDeleteCustomAttributeDialog(false);
                  } else {
                    // If the row has been saved, call API to delete it.
                    deleteHierarchyAttribute({
                      variables: {
                        hierarchyId,
                        planningCycleId: selectedPlanningCycle?.id,
                        hierarchyType,
                        hierarchyAttributeName: selectedCustomAttribute?.name
                      },
                      onCompleted() {
                        values.attributes.splice(
                          values.attributes.findIndex((attribute) => attribute.name === selectedCustomAttribute.name),
                          1
                        );
                        setShowDeleteCustomAttributeDialog(false);
                      }
                    });
                  }
                }}
                onCancel={(e) => {
                  e.stopPropagation();
                  setShowDeleteCustomAttributeDialog(false);
                }}
                bodyText={`${formatMessage('CONFIRM_DELETE_HIERARCHY', {
                  name: selectedCustomAttribute?.name
                })} ${formatMessage('DELETE_HIERARCHY_CUSTOM_ATTRIBUTE')}`}
                titleText={formatMessage('DELETE')}
                isSubmitting={deletingHierarchyAttribute}
              />
            </>
          )}
        />
      </div>
    </div>
  );
};

export default HierarchyAttributesFormFields;
