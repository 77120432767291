import { MapboxGeoJSONFeature } from 'mapbox-gl';

import { StructuredCloneableFeature, PinSet, PinFeature } from 'app/models';

import { optimizeNativePolygonIdForHashing } from './polygonIdHelpers';

export const asStructuredFeature = (mapboxFeature: MapboxGeoJSONFeature): StructuredCloneableFeature => ({
  id: optimizeNativePolygonIdForHashing(mapboxFeature.id),
  // By default the geometry data does not survive the structured clone
  // By pulling it onto an object literal it works as expected
  geometry: mapboxFeature.geometry as StructuredCloneableFeature['geometry']
});
interface Pin {
  name: string;
  position: { lat: number; lon: number };
}
export const formatPinAsFeature = (pinSet: PinSet, pin: Pin): PinFeature => ({
  type: 'Feature',
  geometry: { type: 'Point', coordinates: [pin.position.lon, pin.position.lat] },
  properties: {
    pinSetId: pinSet.pinSetId,
    name: pin.name,
    icon: pinSet.icon,
    color: pinSet.color
  }
});
