import { useMemo } from 'react';

import { defaultSortModel } from 'app/contexts/gridProvider';
import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';
import { useMapVariant } from 'app/contexts/mapVariantProvider';

import {
  SortableGetTerritoryRulesGridCols,
  SortDirection,
  SourceInput
} from 'app/graphql/generated/graphqlApolloTypes';

import { useSplitPageSize } from 'app/hooks/useSplitPageSize';

export interface MapRulesPagingFetchVariables {
  territoryGroupId: number;
  quotaComponentId: number;
  measureId: number;
  source: SourceInput;
  sorting: {
    sort: SortDirection;
    colId: SortableGetTerritoryRulesGridCols;
  };
  startRow: number;
  endRow: number;
}

export const useMapRulesPagingFetchVariables = (input: { source?: SourceInput }): MapRulesPagingFetchVariables => {
  const { insightsMeasureId } = useMapVariant();
  const { selectedQuotaComponentId, selectedTerritoryGroupId } = useMapContextRedistributor();
  const getTerritoriesForMapPageSize = useSplitPageSize('getTerritoriesForMap');

  const fetchVariables = useMemo(() => {
    if (!selectedTerritoryGroupId || !selectedQuotaComponentId || !insightsMeasureId) return null;

    return {
      territoryGroupId: selectedTerritoryGroupId,
      quotaComponentId: selectedQuotaComponentId,
      measureId: insightsMeasureId,
      source: input.source,
      sorting: defaultSortModel,
      startRow: 1,
      endRow: getTerritoriesForMapPageSize
    };
  }, [
    selectedTerritoryGroupId,
    selectedQuotaComponentId,
    insightsMeasureId,
    input.source,
    getTerritoriesForMapPageSize
  ]);

  return fetchVariables;
};
