import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import {
  BulkReidentifyTerritoriesVariables,
  BulkReidentifyTerritories
} from 'app/graphql/generated/graphqlApolloTypes';

export const BULK_REIDENTIFY_TERRITORIES = gql`
  mutation BulkReidentifyTerritories($input: [BulkReidentifyTerritoriesInput!]!) {
    bulkReidentifyTerritories(input: $input) {
      ruleIds
    }
  }
`;

export const useBulkReidentifyTerritories = createUseMutation<
  BulkReidentifyTerritories,
  BulkReidentifyTerritoriesVariables
>(BULK_REIDENTIFY_TERRITORIES);
