import { KeyValue } from 'components/models';

import {
  CreateBattlecardVariables,
  UpdateBattlecardVariables,
  UpsertBattlecardVariables
} from 'app/graphql/generated/graphqlApolloTypes';

import {
  HierarchyTopWithMemberId,
  HierarchyWithTypeAndFirstLevelMemberCount,
  MapLevelType,
  HierarchyType
} from 'app/models';

export interface BattleCardDesignerDialogFormValues {
  cardName: string;
  currency: KeyValue<string>;
  cardType: KeyValue<string>;
  battleCardOwner: KeyValue<string> | null;
  quotaComponents: KeyValue<string>[];
  activityFiles: KeyValue<string>[];
  reorderableListItems: {
    hierarchies: string;
    hierarchyId: number;
    hierarchyTop: KeyValue<string>;
    hierarchyType: HierarchyType;
    id: number;
    referToAs: string;
    owner: KeyValue<string> | null;
  }[];
  mapLevel: KeyValue<MapLevelType>;
  quotaBreakdowns: HierarchyWithTypeAndFirstLevelMemberCount[];
  hierarchyBasedTarget: HierarchyWithTypeAndFirstLevelMemberCount;
}

export const getCreateUpdateBCVariables = (
  isCreateMode: boolean,
  deploymentModelId: number,
  parentBCId: number,
  hierarchies: Partial<HierarchyTopWithMemberId>[],
  formSubmitValues: BattleCardDesignerDialogFormValues,
  isHierarchyBasedTargetEnabled: boolean,
  editingBattleCardId?: number
): CreateBattlecardVariables | UpdateBattlecardVariables | UpsertBattlecardVariables => {
  let variables;
  const quotaDistributionHierarchyIds = formSubmitValues.quotaBreakdowns.map((quotaBreakdown) => +quotaBreakdown.value);
  if (isCreateMode) {
    variables = {
      deploymentModelId,
      battlecardName: formSubmitValues.cardName,
      battlecardType: formSubmitValues.cardType.value,
      battlecardParentId: parentBCId,
      currencyCode: formSubmitValues?.currency?.value,
      quotaComponent: formSubmitValues.quotaComponents
        ? formSubmitValues.quotaComponents.map((quotaComponent) => {
            return quotaComponent.value;
          })
        : [],
      teams: [],
      hierarchies,
      fileIds:
        formSubmitValues?.activityFiles?.map((file) => {
          return file.value;
        }) ?? [],
      memberId: formSubmitValues.battleCardOwner?.value,
      mapLevel: formSubmitValues.mapLevel.value
    };
  } else {
    variables = {
      battlecardId: editingBattleCardId,
      deploymentModelId,
      battlecardName: formSubmitValues.cardName,
      battlecardType: formSubmitValues.cardType.value,
      currencyCode: formSubmitValues?.currency?.value,
      battlecardParentId: parentBCId,
      mapLevel: formSubmitValues.mapLevel.value
    };
  }

  if (!isHierarchyBasedTargetEnabled) {
    variables = { ...variables, quotaDistributionHierarchyIds };
  } else {
    const quotaDistributionHierarchies = quotaDistributionHierarchyIds.map((hierarchyId) => ({
      hierarchyRootId: hierarchyId,
      isAllocationTarget: false
    }));
    const validHierarchyBasedTarget =
      formSubmitValues.hierarchyBasedTarget &&
      quotaDistributionHierarchies.find(
        (hierarchy) => hierarchy.hierarchyRootId === +formSubmitValues.hierarchyBasedTarget.value
      );
    if (validHierarchyBasedTarget) {
      validHierarchyBasedTarget.isAllocationTarget = true;
    }
    variables = {
      input: {
        ...variables,
        quotaDistributionHierarchies
      }
    };
  }

  return variables;
};
