import gql from 'graphql-tag';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetHierarchyByKey, GetHierarchyByKeyVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const GET_HIERARCHY_BY_KEY = gql`
  query GetHierarchyByKey(
    $hierarchyType: String!
    $hierarchyKey: String!
    $planningCycleId: Int!
    $version: Int!
    $rootId: Int
  ) {
    getHierarchyByKey(
      input: {
        hierarchyType: $hierarchyType
        planningCycleId: $planningCycleId
        version: $version
        hierarchyKey: $hierarchyKey
        rootId: $rootId
      }
    ) {
      children
      cloneId
      customProperties
      fileId
      hierarchyId
      hierarchyType
      key
      name
      parentKey
      path
      planningCycleId
      version
      rootId
    }
  }
`;

export const useGetHierarchyByKeyLazy = createUseLazyQuery<GetHierarchyByKey, GetHierarchyByKeyVariables>(
  GET_HIERARCHY_BY_KEY
);
