import React, { FC, useState } from 'react';

import { HTMLHeading } from '@varicent/components';

import { MenuItem } from 'components/menu/MenuItem';

import { useCommandCenter } from 'app/contexts/commandCenterProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { GetPaginatedRootHierarchies_getPaginatedRootHierarchies_items } from 'app/graphql/generated/graphqlApolloTypes';

import { CommandCenterMenuItem, HierarchySpec } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import { CommandCenterHierarchyItems } from './CommandCenterHierarchyItems';
import { CommandCenterHierarchyToolbar } from './CommandCenterHierarchyToolbar';
import style from './CommandCenterMenu.module.pcss';
import { useDeleteHierarchy } from './hooks/useDeleteHierarchy';

const b = block(style);

export const CommandCenterHierarchy: FC<{
  data?: GetPaginatedRootHierarchies_getPaginatedRootHierarchies_items[];
  activeMenu: CommandCenterMenuItem | unknown;
  changeActiveMenuItem: ({ key, hierarchy }: { key: string; hierarchy: HierarchySpec }) => void;
  isOnFullMenuView: boolean;
  hasError: boolean;
  selectedHierarchy;
  setSelectedHierarchy;
  refetchRootHierarchies: () => void;
  loading: boolean;
}> = ({
  data,
  activeMenu,
  changeActiveMenuItem,
  isOnFullMenuView,
  hasError,
  selectedHierarchy,
  setSelectedHierarchy,
  refetchRootHierarchies,
  loading
}) => {
  const { selectedPlanningCycle } = useScope();
  const { setCommandCenterDrawerState } = useCommandCenter();
  const handleConfirmDeleteModal = async (e: React.FormEvent<HTMLInputElement>) => {
    e.stopPropagation();
    await deleteHierarchy({
      variables: {
        hierarchyIds: [selectedHierarchy?.rootHierarchyId],
        hierarchyType: selectedHierarchy?.hierarchyType,
        planningCycleId: selectedPlanningCycle?.id
      }
    });
    // After the deletion, refetch the entire list of root hierarchies to reflect the changes
    refetchRootHierarchies();
  };
  const [showDeleteHierarchyDialog, setShowDeleteHierarchyDialog] = useState<boolean>(false);
  const [deleteHierarchy, { loading: deletingHierarchy }] = useDeleteHierarchy({
    selectedHierarchy,
    setCommandCenterDrawerState,
    setShowDeleteHierarchyDialog,
    setSelectedHierarchy
  });
  return (
    <>
      <li className={b('menuHeader')} data-testid="menu-header-hierarchies">
        {isOnFullMenuView && <HTMLHeading tagLevel="h5" text={formatMessage('HIERARCHIES')} />}
        <CommandCenterHierarchyToolbar
          setShowDeleteHierarchyDialog={setShowDeleteHierarchyDialog}
          showDeleteHierarchyDialog={showDeleteHierarchyDialog}
          selectedHierarchy={selectedHierarchy}
          deletingHierarchy={deletingHierarchy}
          handleConfirmDeleteModal={handleConfirmDeleteModal}
          loading={loading}
        />
      </li>
      {hasError && (
        <div className={b('errorMessage')} data-testid="command-center-menu-error">
          {formatMessage('UNABLE_TO_RETRIEVE_HIERARCHIES')}
        </div>
      )}
      <CommandCenterHierarchyItems data={data} activeMenu={activeMenu} changeActiveMenuItem={changeActiveMenuItem} />
      {!data?.length && !hasError && (
        <MenuItem
          data-testid="cc-hierarchy-skeleton-item"
          text={
            <div data-testid="menu-item-skeleton">
              <div className={`${b('menuItemText')} bp3-skeleton`}>
                <HTMLHeading tagLevel="h6" />
              </div>
              <div className={`${b('menuItemSubtext')} bp3-skeleton`} />
            </div>
          }
        />
      )}
    </>
  );
};
