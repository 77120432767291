import React, { useRef, useEffect } from 'react';

import ToastMessage from 'components/ToastMessage/ToastMessage';

import ExpandedTerritoryDefineandRefineTree from 'app/components/TerritoriesAndPlanTargets/ExpandedTerritoryDefineandRefineTree/ExpandedTerritoryDefineandRefineTree';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useCoinsort } from 'app/contexts/coinsortProvider';
import { useJobs } from 'app/contexts/jobsProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { SplitFeatures } from 'app/global/features';

import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import { UserRoleType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ExpandedTerritoryDefineAndRefine.module.pcss';

const b = block(style);
interface ExpandedTerritoryDefineAndRefineProps {
  battleCardId: string;
}

const ExpandedTerritoryDefineAndRefine: React.FC<ExpandedTerritoryDefineAndRefineProps> = ({
  battleCardId
}: ExpandedTerritoryDefineAndRefineProps) => {
  const defineAndRefineRef = useRef(null);
  const { tdrTreeLookupMap, tdrErrorLookupMap, getTDR } = useTerritoryDefineAndRefine();
  const {
    coinsortProgressErrorLookupMap,
    coinsortRunningLookupMap,
    coinsortRunErrorLookupMap,
    coinsortCancelErrorLookupMap,
    coinsortCheckErrorLookupMap,
    pollCoinsortProgressDeprecated,
    checkCoinsortProgress
  } = useCoinsort();
  const { selectedQuotaComponentId } = useBattleCard();
  const { userRole } = useUser();
  const { jobItems } = useJobs();
  const [matchingEngineEnabled] = useTreatment(SplitFeatures.MATCHING_ENGINE);
  const showToast = useShowToast();

  const tdrTree = tdrTreeLookupMap && tdrTreeLookupMap[battleCardId];
  const tdrError = tdrErrorLookupMap && tdrErrorLookupMap[battleCardId];

  const coinsortRunError = coinsortRunErrorLookupMap && coinsortRunErrorLookupMap[battleCardId];

  const coinsortCancelError = coinsortCancelErrorLookupMap && coinsortCancelErrorLookupMap[battleCardId];

  const coinsortProgressError = coinsortProgressErrorLookupMap && coinsortProgressErrorLookupMap[battleCardId];

  const coinsortRunning = coinsortRunningLookupMap && coinsortRunningLookupMap[battleCardId];

  const coinsortCheckError = coinsortCheckErrorLookupMap && coinsortCheckErrorLookupMap[battleCardId];

  // on first load, poll coinsort progress to get activity counts and to see if an existing job is running
  useEffect(() => {
    // eslint-disable-next-line no-restricted-syntax
    if (userRole === UserRoleType.ADMIN) {
      if (matchingEngineEnabled) {
        checkCoinsortProgress(battleCardId, jobItems);
      } else {
        pollCoinsortProgressDeprecated(battleCardId, selectedQuotaComponentId);
      }
    }
  }, [battleCardId, selectedQuotaComponentId]);

  // on first load or when coinsort completes, refetch TDR to update unassigned count
  useEffect(() => {
    if (!coinsortRunning) {
      getTDR(battleCardId, selectedQuotaComponentId);
    }
  }, [coinsortRunning, battleCardId, selectedQuotaComponentId]);

  useEffect(() => {
    if (tdrError) {
      showToast(formatMessage('TERRITORY_GROUPS_ERROR'), 'danger');
    }
  }, [tdrError]);

  useEffect(() => {
    if (coinsortRunError) {
      showErrorMessage(coinsortRunError);
    }
  }, [coinsortRunError]);

  useEffect(() => {
    if (coinsortCancelError) {
      showErrorMessage(coinsortCancelError);
    }
  }, [coinsortCancelError]);

  useEffect(() => {
    if (coinsortProgressError) {
      showErrorMessage(coinsortProgressError);
    }
  }, [coinsortProgressError]);

  useEffect(() => {
    if (coinsortCheckError) {
      showErrorMessage(coinsortCheckError);
    }
  }, [coinsortCheckError]);

  const showErrorMessage = (errorMessage: string) => {
    showToast(<ToastMessage title={formatMessage('COINSORT_RUN_ERROR_TITLE')} message={errorMessage} />, 'danger');
  };

  return (
    <div className={b()} ref={defineAndRefineRef} data-testid="expanded-territory-define-and-refine-tree">
      <ExpandedTerritoryDefineandRefineTree battleCardId={battleCardId} tdrTree={tdrTree} />
    </div>
  );
};

export default ExpandedTerritoryDefineAndRefine;
