import {
  // eslint-disable-next-line no-restricted-imports
  useMutation,
  MutationTuple
} from '@apollo/client';

import { GenerateAPIKey, GenerateAPIKeyVariables } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GENERATE_API_KEY } from 'app/graphql/mutations/generateApiKey';

import useShowToast from 'app/hooks/useShowToast';

export const useGenerateApiKey = (): MutationTuple<GenerateAPIKey, GenerateAPIKeyVariables> => {
  const showToast = useShowToast();

  return useMutation<GenerateAPIKey, GenerateAPIKeyVariables>(GENERATE_API_KEY, {
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast('failed to create api key', 'danger');
    }
  });
};
