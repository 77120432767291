import React, { useState } from 'react';

// TQP-16584 change implementation to use useShowToast
// eslint-disable-next-line no-restricted-imports
import { Card, Divider, Position, Toaster } from '@blueprintjs/core';
import { DocumentExport, DocumentImport, OverflowMenuHorizontal } from '@carbon/icons-react';
// eslint-disable-next-line no-restricted-imports
import { Menu, MenuItem } from '@varicent/components';
import { Field, Form, Formik } from 'formik';

import IconButton from 'components/Buttons/IconButton/IconButton';
import FlagIcon from 'components/FlagIcon/FlagIcon';
import JobProgressStatusToast from 'components/JobProgressStatusToast/JobProgressStatusToast';
//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';
import Popover from 'components/Popover/Popover';
import SelectMenu from 'components/SelectMenu/SelectMenu';

import AccountAlignmentUploadDialog from 'app/components/AccountAlignmentUpload/AccountAlignmentUploadDialog';
import QuotaComponentDropdown from 'app/components/BattleCardDiagram/Cards/BattleCardContent/QuotaComponentDropdown/QuotaComponentDropdown';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useLocalization } from 'app/contexts/localizationProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { SplitFeatures } from 'app/global/features';

import { useExportChangeHistory } from 'app/graphql/hooks/useExportChangeHistory';

import usePhase from 'app/hooks/usePhase';
import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import { DeploymentModelPhase } from 'app/models';
import { JobCatalogObjectType } from 'app/models/index';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';
import CanUser from 'utils/permissions/CanUser';
import { UserAction } from 'utils/permissions/userActions';

import style from './BattleCardCanvasControl.module.pcss';

const b = block(style);

const BattleCardCanvasControl: React.FC = () => {
  const { selectedPlanningCycle, selectedDeploymentModelId } = useScope();

  const [showUploadAlignmentDialog, setShowUploadAlignmentDialog] = useState(false);
  const [exportChangeHistoryProgressValue, setExportChangeHistoryProgressValue] = useState(0);
  const [isAccountAlignmentButtonDisabled] = useState(false);
  const [isAsyncAuditTrailExportOn] = useTreatment(SplitFeatures.AUDIT_TRAIL_EXPORT_ASYNC);
  const [isNewAccountRoutingOn] = useTreatment(SplitFeatures.NEW_ACCOUNT_ROUTING_MANAGE);
  const isCHExportButtonDisabled = exportChangeHistoryProgressValue > 0;
  const shouldDisplayExportChangeHistoryToast = isAsyncAuditTrailExportOn && exportChangeHistoryProgressValue > 0;

  const deploymentModelPhase = usePhase();

  const { isLocalCurrencyMode, defaultReportingCurrency, setIsLocalCurrencyMode } = useLocalization();
  const { setShouldRefetchBattleCardDataImmediately, quotaComponentList } = useBattleCard();
  const showToast = useShowToast();
  const { userPlanningCycles } = useUser();

  const selectedPlanningCycleName =
    userPlanningCycles?.find((pc) => pc?.planningCycleId === selectedPlanningCycle?.id)?.planningCycleName ?? '';

  const changeHistoryToastMessage = formatMessage('CHANGE_HISTORY_TOAST', {
    planningCycleName: selectedPlanningCycleName
  });
  const changeHistoryExportSuccessMessage = formatMessage('CHANGE_HISTORY_EXPORT_SUCCESS_DESCRIPTION', {
    planningCycleName: selectedPlanningCycleName
  });

  const exportInitiatedMessage = (
    <div data-testid={'change-history-export-initiated-toast'}>
      <div className={b('toastHeader')}> {formatMessage('CHANGE_HISTORY_EXPORT_STARTED')} </div>
      <div className={b('toastBody')} data-testid={'change-history-export-toast-message'}>
        {changeHistoryToastMessage}
      </div>
    </div>
  );

  const exportChangeHistoryToaster = shouldDisplayExportChangeHistoryToast && (
    <Toaster position={Position.TOP_RIGHT}>
      <JobProgressStatusToast
        jobDetail={{ jobName: JobCatalogObjectType.GET_CHANGE_HISTORY_EXPORT }}
        title={formatMessage('CHANGE_HISTORY_EXPORT_STARTED')}
        progressDescription={changeHistoryToastMessage}
        progress={exportChangeHistoryProgressValue / 100}
        onPollSuccess={() => {
          // TODO use success callback instead of hardcoding logic in component
          return;
        }}
        onPollError={() => {
          // TODO use error callback instead of hardcoding logic in component
          return;
        }}
      />
    </Toaster>
  );

  const exportCompletedMessage = (
    <div data-testid={'change-history-export-success-toast'}>
      <div className={b('toastHeader')}> {formatMessage('CHANGE_HISTORY_EXPORT_SUCCESS')} </div>
      <div className={b('toastBody')} data-testid={'change-history-export-toast-message'}>
        {changeHistoryExportSuccessMessage}
      </div>
    </div>
  );

  const { startExport } = useExportChangeHistory(
    exportCompletedMessage,
    setExportChangeHistoryProgressValue,
    isAsyncAuditTrailExportOn
  );

  const hasMultipleQuotaComponents = quotaComponentList?.length > 1;

  const currencyMenuItems = [
    {
      key: (
        <span>
          <span className={b('menuText')}>
            {formatMessage('REPORTING_CURRENCY_WITH_VALUE', { value: defaultReportingCurrency })}
          </span>
          <FlagIcon countryId={defaultReportingCurrency} />
        </span>
      ),
      value: false
    },
    {
      key: <span>{formatMessage('REPORTING_AND_LOCAL_CURRENCIES')}</span>,
      value: true
    }
  ];

  const handleBattleCardDropDown = () => {
    setExportChangeHistoryProgressValue(1);
    if (!isAsyncAuditTrailExportOn) showToast(exportInitiatedMessage, 'primary');
    startExport(selectedPlanningCycle.id, selectedDeploymentModelId, []);
  };

  const currencyMode = currencyMenuItems.find((currencyMenuItem) => currencyMenuItem.value === isLocalCurrencyMode);
  const CurrencyForm = (
    <Formik
      initialValues={{
        currencyMode
      }}
      className={b('canvasControlsFormBattleCardRefactor')}
      onSubmit={null}
      enableReinitialize
    >
      {({ setFieldValue }) => {
        return (
          <Form>
            <div className={b('canvasSelect')} data-testid={'battle-card-canvas-control-form'}>
              <Field
                name="currencyMode"
                items={currencyMenuItems}
                theme="primary"
                minimal
                showErrors={false}
                component={SelectMenu}
                onChange={(item) => {
                  setFieldValue('currencyMode', item);
                  setIsLocalCurrencyMode(item.value);
                  setShouldRefetchBattleCardDataImmediately(true);
                }}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );

  const BattleCardControlDropDown = (
    <Popover
      data-testid={'battlecard-control-dropdown'}
      content={
        <Menu>
          <MenuItem
            text={formatMessage('EXPORT_CHANGE_HISTORY')}
            disabled={isCHExportButtonDisabled}
            onClick={handleBattleCardDropDown}
            icon={<DocumentExport />}
          />
        </Menu>
      }
      placement={'bottom-end'}
    >
      <MessageTooltip
        content={formatMessage('OPTIONS')}
        target={
          <div data-testid="battlecard-dropdown-menu-cell-renderer-icon">
            <OverflowMenuHorizontal className={b('icon')} />
          </div>
        }
        placement={'top-end'}
      />
    </Popover>
  );

  const AuditTrailExportButton = (
    <div className={b('iconButtonContainer')}>
      <IconButton
        disabled={isCHExportButtonDisabled}
        type="button"
        icon={<DocumentExport size={20} />}
        minimal={true}
        small={false}
        onClick={handleBattleCardDropDown}
        tooltipText={
          isCHExportButtonDisabled
            ? formatMessage('EXPORT_CHANGE_HISTORY_DISABLED')
            : formatMessage('EXPORT_CHANGE_HISTORY')
        }
        tooltipPlacement={isCHExportButtonDisabled ? 'bottom' : 'top'}
        testId="export-audit-trail-button"
      />
      {exportChangeHistoryToaster}
    </div>
  );

  const handleUploadAccountAlignmentClicked = () => {
    setShowUploadAlignmentDialog(true);
  };

  const UploadAccountAlignment = (
    <div>
      <div className={b('iconButtonContainer')}>
        <IconButton
          disabled={isAccountAlignmentButtonDisabled}
          type="button"
          icon={<DocumentImport size={20} />}
          minimal={true}
          small={false}
          onClick={handleUploadAccountAlignmentClicked}
          tooltipText={formatMessage('UPLOAD_ACCOUNT_ALIGNMENT')}
          tooltipPlacement={isAccountAlignmentButtonDisabled ? 'bottom' : 'top'}
          testId="upload-account-alignment-button"
        />
        {showUploadAlignmentDialog && (
          <AccountAlignmentUploadDialog onClose={() => setShowUploadAlignmentDialog(false)} />
        )}
      </div>
    </div>
  );

  return (
    <Card className={b('')} data-testid={'battlecard-canvas-control'}>
      {hasMultipleQuotaComponents && (
        <>
          <QuotaComponentDropdown />
          <Divider className={b('canvasControlsDivider')} />
        </>
      )}
      {CurrencyForm}

      {deploymentModelPhase === DeploymentModelPhase.manage && isNewAccountRoutingOn && UploadAccountAlignment}

      <CanUser
        perform={UserAction.AUDIT_TRAIL_EXPORT}
        yes={
          deploymentModelPhase === DeploymentModelPhase.manage && isNewAccountRoutingOn
            ? BattleCardControlDropDown
            : AuditTrailExportButton
        }
      />

      {isNewAccountRoutingOn && exportChangeHistoryToaster}
    </Card>
  );
};

export default BattleCardCanvasControl;
