import React, { FC } from 'react';

import ReactMapGl from 'react-map-gl';

import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

import { config } from 'utils/config';

type ReactMapGlProps = Parameters<typeof ReactMapGl>[0];

type BasicMapboxMapProps = Partial<ReactMapGlProps>;

const getMapStyleUrl = (splitConfig: string): string => {
  try {
    const styleEnhancementsUrl = JSON.parse(splitConfig)?.mapboxStyleUrl;
    return styleEnhancementsUrl || config.MAPBOX_STYLE_URL;
  } catch {
    return config.MAPBOX_STYLE_URL;
  }
};

export const DefaultMapboxMap: FC<BasicMapboxMapProps> = (props) => {
  const [_isEnabled, { config: splitConfig }] = useTreatment(SplitFeatures.MAP_STYLE_ENHANCEMENTS);

  return (
    <ReactMapGl
      mapboxAccessToken={config.MAPBOX_ACCESS_TOKEN}
      mapStyle={getMapStyleUrl(splitConfig)}
      initialViewState={{ latitude: 40, longitude: -100, zoom: 3 }}
      attributionControl={false}
      boxZoom={false}
      dragRotate={false}
      {...props}
    />
  );
};
