import React from 'react';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './UpsertTerritoryRuleViewLeftPanelHeader.module.pcss';

const b = block(style);

const UpsertTerritoryRuleViewLeftPanelHeader: React.FC = () => {
  const defaultFields = [
    {
      key: 'territoryId',
      value: formatMessage('TERRITORY_ID'),
      testId: 'territoryid-text'
    },
    {
      key: 'territoryName',
      value: formatMessage('TERRITORY_NAME'),
      testId: 'territory-name-text'
    },
    {
      key: 'territoryGroup',
      value: formatMessage('TERRITORY_GROUP'),
      testId: 'territory-group-text'
    }
  ];

  return (
    <>
      {defaultFields.map(({ key, testId, value }) => (
        <div key={key} data-testid={testId} className={b('text', { columnText: true })}>
          {value}
        </div>
      ))}
    </>
  );
};

export default UpsertTerritoryRuleViewLeftPanelHeader;
