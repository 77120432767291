import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import { Dialog, Spinner } from '@blueprintjs/core';
import { Account, InventoryManagement } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';
import { Form, Formik, FormikProps } from 'formik';
import without from 'lodash.without';

import TextButton from 'components/Buttons/TextButton/TextButton';

import {
  getCreateUpdateBCVariables,
  BattleCardDesignerDialogFormValues
} from 'app/components/BattleCardDiagram/Designer/BattleCardDesignerDialog/battleCardDesignerDialogUtils';
import BattleCardDesignerInitialSetupPage from 'app/components/BattleCardDiagram/Designer/BattleCardDesignerInitialSetupPage/BattleCardDesignerInitialSetupPage';
import DirtyFormPrompt from 'app/components/DirtyFormPrompt/DirtyFormPrompt';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useCurrency } from 'app/contexts/currencyProvider';
import { useLocalization } from 'app/contexts/localizationProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { SplitFeatures } from 'app/global/features';

import {
  CreateBattlecardVariables,
  UpdateBattlecardVariables,
  UpsertBattlecardVariables
} from 'app/graphql/generated/graphqlApolloTypes';
import { useAddQuotaComponentToBattlecard } from 'app/graphql/mutations/addQuotaComponentToBattleCard';
import { useCreateBattleCard } from 'app/graphql/mutations/createBattlecard';
import { useRemoveQuotaComponentFromBattleCard } from 'app/graphql/mutations/removeQuotaComponentFromBattleCard';
import { useUpdateActivityFiles } from 'app/graphql/mutations/updateActivityFiles';
import { useUpdateBattleCard } from 'app/graphql/mutations/updateBattlecard';
import { useUpsertBattleCard } from 'app/graphql/mutations/upsertBattlecard';
import { useUpsertBattleCardHierarchyTops } from 'app/graphql/mutations/upsertBattlecardHierarchyTops';
import { useUpsertBattleCardOwner } from 'app/graphql/mutations/upsertBattlecardOwner';
import { GET_BATTLECARD, useGetBattlecard } from 'app/graphql/queries/getBattlecard';
import { GET_BATTLE_CARD_CANVAS } from 'app/graphql/queries/getBattleCardCanvas';
import { useGetPinnedHierarchies } from 'app/graphql/queries/getPinnedHierarchies';

import usePhase from 'app/hooks/usePhase';
import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import { BattleCardDesignerRootHierarchy, BattlecardType, DeploymentModelPhase } from 'app/models';

import block from 'utils/bem-css-modules';
import { getCurrencyItem } from 'utils/helpers/currencyHelpers';
import { memberToString } from 'utils/helpers/index';
import { formatMessage } from 'utils/messages/utils';
import {
  cardTypeSelectionDropDownData,
  cardTypeSelectionDropDownDataMapLevel
} from 'utils/uiCopy/battleCardTypeSelectionDropDownData';

import style from './BattleCardDesignerDialog.module.pcss';
import validationsSchema from './validationsSchema';

const b = block(style);

const qcActions = Object.freeze({
  add: 'add',
  remove: 'remove'
});

interface BattleCardDesignerDialogProps {
  isOpen: boolean;
  setShowDialog: Dispatch<SetStateAction<boolean>>;
  parentBattleCardId: string | null;
  deploymentModelId: number;
  editingBattleCardId: string | null;
}

const BattleCardDesignerDialog: React.FC<BattleCardDesignerDialogProps> = ({
  isOpen,
  setShowDialog,
  parentBattleCardId,
  deploymentModelId,
  editingBattleCardId
}: BattleCardDesignerDialogProps) => {
  const { getTDR } = useTerritoryDefineAndRefine();
  const {
    battleCardLookupMap,
    setShouldRefetchBattleCardDataImmediately,
    selectedQuotaComponentId,
    quotaComponentList
  } = useBattleCard();
  const deploymentModelPhase = usePhase();
  const { currencies } = useCurrency();
  const { defaultReportingCurrency, isLocalCurrencyMode } = useLocalization();
  const { selectedTenant } = useScope();
  const showToast = useShowToast();

  const [isHierarchyBasedTargetEnabled] = useTreatment(SplitFeatures.HIERARCHY_BASED_TOP_DOWN_TARGET_SETTING);

  const [battlecardUpdating, setBattlecardUpdating] = useState<boolean>(false);
  const [quotaComponentUpdating, setQuotaComponentUpdating] = useState<boolean>(false);
  const [rootHierarchies, setRootHierarchies] = useState<Record<number, BattleCardDesignerRootHierarchy>>(null);

  const [addQuotaComponentToBattleCard] = useAddQuotaComponentToBattlecard();
  const [removeQuotaComponentFromBattleCard] = useRemoveQuotaComponentFromBattleCard();

  const bodyRef = useRef<HTMLDivElement>(undefined);
  const formRef = useRef<FormikProps<BattleCardDesignerDialogFormValues>>();

  const isCreateMode = !editingBattleCardId;

  // Get parent battlecard type in order to render battle card type selection menu
  // In edit mode, search for the card based on target card id and get the parent card type of it.
  // In create mode, get parent battlecard id and search for its card type in the look up map.
  const parentBattleCardType = isCreateMode
    ? battleCardLookupMap?.[parentBattleCardId]?.battlecardType
    : battleCardLookupMap?.[editingBattleCardId]?.battleCardParentType;

  // in create mode, the parent battleCard id is passed as a prop,
  // but when editing we need to get the parent battleCard id from the lookup map
  const parentBCId = isCreateMode ? parentBattleCardId : battleCardLookupMap?.[editingBattleCardId]?.battlecardParentId;

  const currBC = editingBattleCardId && battleCardLookupMap?.[editingBattleCardId];
  let bcQuotaComponents;

  if (currBC) {
    // if BC is in edit mode, populate QCs based on BC's current QCs
    bcQuotaComponents = currBC?.quotaComponents;
  } else {
    // if BC is in create mode, populate QCs based on currently selected QC
    const currQC =
      quotaComponentList?.length &&
      quotaComponentList?.find((quotaComponent) => quotaComponent.quotaComponentId === selectedQuotaComponentId);
    bcQuotaComponents = currQC ? [currQC] : [];
  }

  let defaultCardType;
  if (parentBattleCardType === BattlecardType.Rollup) {
    defaultCardType = { key: formatMessage('PRIMARY_TEAM_WITH_CONSOLIDATE'), value: BattlecardType.PrimaryTeam };
  } else if (parentBattleCardType === BattlecardType.PrimaryTeam) {
    defaultCardType = { key: formatMessage('DIRECT_OVERLAY'), value: BattlecardType.DirectOverlay };
  }

  const { data: hierarchyData, loading: hierarchyLoading } = useGetPinnedHierarchies({
    variables: { deploymentModelId, includeFirstLevelMemberCount: isHierarchyBasedTargetEnabled },
    fetchPolicy: 'network-only',
    onError() {
      showToast(formatMessage('UNABLE_TO_RETRIEVE_HIERARCHIES'), 'danger');
    }
  });

  const handleQuotaComponentChange = async (quotas: [], action: string) => {
    try {
      setQuotaComponentUpdating(true);
      switch (action) {
        case qcActions.remove:
          await Promise.all(
            quotas.map((qcId: number) =>
              removeQuotaComponentFromBattleCard({
                variables: { battlecardId: +editingBattleCardId, quotaComponentId: qcId }
              })
            )
          );
          break;
        case qcActions.add:
          await Promise.all(
            quotas.map((qcId: number) =>
              addQuotaComponentToBattleCard({
                variables: { battlecardId: +editingBattleCardId, quotaComponentId: qcId }
              })
            )
          );
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
      switch (action) {
        case qcActions.remove:
          showToast(formatMessage('REMOVE_QUOTA_COMPONENT_ERROR'), 'danger');
          break;
        case qcActions.add:
          showToast(formatMessage('QUOTA_COMPONENTS_UPDATE_ERROR'), 'danger');
          break;
        default:
          break;
      }
    }
    setQuotaComponentUpdating(false);
  };

  const buildRootHierarchiesObject = (rootHierarchyData) => {
    return rootHierarchyData.reduce((acc, curr) => {
      const { hierarchyId, hierarchyType, key, name, hasChildren, firstLevelMemberCount } = curr;

      acc[curr.hierarchyId] = {
        hierarchyTreeData: {
          id: hierarchyId,
          label: name,
          key,
          hierarchyType,
          childNodes: hasChildren ? [] : null,
          hasChildren,
          isExpanded: false,
          firstLevelMemberCount,
          icon: hasChildren ? (
            <InventoryManagement data-testid="collapsable-node-icon" />
          ) : (
            <Account data-testid="leaf-node-icon" />
          )
        }
      };

      return acc;
    }, {});
  };

  const [createBattlecard, { loading: creatingBattlecard, error: createBattlecardError }] = useCreateBattleCard({
    onCompleted() {
      showToast(formatMessage('CREATE_NEW_BATTLECARD_SUCCESS'), 'success');
      setShowDialog(false);
      setShouldRefetchBattleCardDataImmediately(true);
    }
  });

  const [upsertBattlecard, { loading: upsertingBattlecard, error: upsertBattlecardError }] = useUpsertBattleCard({
    onCompleted() {
      setShowDialog(false);
      setShouldRefetchBattleCardDataImmediately(true);
      if (isCreateMode) {
        showToast(formatMessage('CREATE_NEW_BATTLECARD_SUCCESS'), 'success');
      } else {
        showToast(formatMessage('UPDATE_BATTLECARD_SUCCESS'), 'success');
        getTDR(editingBattleCardId, selectedQuotaComponentId);
      }
    },
    onError(error) {
      const { setFieldError } = formRef.current;

      if (error.knownError?.errorCode === 'BC_NAME_ALREADY_EXISTS') {
        setFieldError('cardName', formatMessage('BC_NAME_ALREADY_EXISTS'));
      }
    }
  });

  const [
    upsertBattlecardHierarchyTops,
    { loading: upsertingBattlecardHierarchyTops, error: upsertBattlecardHierarchyTopsError }
  ] = useUpsertBattleCardHierarchyTops();

  const [upsertBattlecardOwner, { loading: upsertingBattlecardOwner, error: upsertBattlecardOwnerError }] =
    useUpsertBattleCardOwner();

  const [updateActivityFiles, { loading: updatingActivityFiles, error: updateActivityFilesError }] =
    useUpdateActivityFiles();

  const [updateBattlecard, { loading: updatingBattlecard, error: updateBattlecardError }] = useUpdateBattleCard({
    onCompleted() {
      showToast(formatMessage('UPDATE_BATTLECARD_SUCCESS'), 'success');
      setShowDialog(false);
      setShouldRefetchBattleCardDataImmediately(true);
      getTDR(editingBattleCardId, selectedQuotaComponentId);
    }
  });

  useEffect(() => {
    if (
      !updatingBattlecard &&
      !upsertingBattlecardHierarchyTops &&
      !upsertingBattlecardOwner &&
      !updatingActivityFiles &&
      !quotaComponentUpdating &&
      !upsertingBattlecard
    ) {
      setBattlecardUpdating(false);
    } else {
      setBattlecardUpdating(true);
    }
  }, [
    upsertingBattlecardHierarchyTops,
    upsertingBattlecardOwner,
    updatingActivityFiles,
    updatingBattlecard,
    quotaComponentUpdating,
    upsertingBattlecard
  ]);

  useEffect(() => {
    if (!hierarchyLoading && hierarchyData?.getDeploymentModelSpec?.pinnedHierarchies?.length > 0) {
      setRootHierarchies(buildRootHierarchiesObject(hierarchyData.getDeploymentModelSpec.pinnedHierarchies));
    }
  }, [hierarchyData, hierarchyLoading]);

  const {
    data: battleCardData,
    loading: battleCardLoading,
    error: battlecardFetchingError
  } = useGetBattlecard({
    skip: editingBattleCardId === null,
    fetchPolicy: 'network-only',
    variables: {
      battlecardId: +editingBattleCardId,
      isTQM: deploymentModelPhase === DeploymentModelPhase.manage,
      deploymentModelId
    }
  });

  let reorderableListItems = [];
  const blankReorderableListItem = {
    id: '1',
    hierarchies: '',
    hierarchyId: '',
    referToAs: '',
    hierarchyTop: null,
    owner: null,
    hierarchyType: ''
  };

  // In create BC mode, set one new blank reorderable list item
  if (isCreateMode) {
    reorderableListItems.push({ ...blankReorderableListItem });
  }

  let battleCard;
  if (!battleCardLoading && battleCardData) {
    battleCard = battleCardData.getDeploymentModelSpec?.battlecards?.[0];
    const territoryGroupTypes = [];
    // In edit BC mode, build reorderable list items from existing territory group types
    if (battleCard?.territoryGroupTypes && battleCard.territoryGroupTypes.length > 0) {
      battleCard.territoryGroupTypes.forEach((territoryGroupType) => {
        const territoryGroup = {
          hierarchies: territoryGroupType.hierarchyRootName,
          hierarchyId: territoryGroupType.territoryGroupTypeHierarchyId,
          hierarchyTop: { key: territoryGroupType.hierarchyTopName, value: territoryGroupType.hierarchyTopId },
          hierarchyType: territoryGroupType.hierarchyType,
          id: territoryGroupType.precedence.toString(),
          referToAs: '',
          owner:
            territoryGroupType.territoryGroupOwnerFirstName &&
            territoryGroupType.territoryGroupOwnerLastName &&
            territoryGroupType.territoryGroupOwnerId
              ? {
                  key: `${
                    territoryGroupType.territoryGroupOwnerEmployeeId
                      ? `(${territoryGroupType.territoryGroupOwnerEmployeeId}) `
                      : ''
                  }${territoryGroupType.territoryGroupOwnerLastName}, ${
                    territoryGroupType.territoryGroupOwnerFirstName
                  }`,
                  value: territoryGroupType.territoryGroupOwnerMemberId
                }
              : null
        };

        // Backend set the territory group type name to hierarchy alias if it is empty, so if they are the same, we don't display it
        if (territoryGroupType.hierarchyAlias !== territoryGroupType.hierarchyTopName) {
          territoryGroup.referToAs = territoryGroupType.hierarchyAlias;
        }

        // Add territory group types that haven't been included to the bottom of the reorderable list
        reorderableListItems = reorderableListItems.filter(
          (item) => item.hierarchyId !== territoryGroupType.territoryGroupTypeHierarchyId
        );
        territoryGroupTypes.push(territoryGroup);
      });
    }
    reorderableListItems.forEach((item) => {
      item.id = (territoryGroupTypes.length + 1).toString();
      territoryGroupTypes.push(item);
    });
    reorderableListItems = territoryGroupTypes;
  }

  const hasFormErrors = (errors, status, values) => {
    return (
      !!errors?.cardName ||
      (values?.cardType?.value !== BattlecardType.Rollup && !!errors?.reorderableListItems) ||
      !!errors?.quotaComponents ||
      !!status?.quotaComponents
    );
  };

  const handleCancel = () => {
    setShowDialog(false);
  };

  const renderSubmitButton = (errors, status, values) => {
    const hasError = hasFormErrors(errors, status, values);

    return (
      <div>
        {isCreateMode && (
          <TextButton
            text={formatMessage('CREATE')}
            type="submit"
            intent="primary"
            disabled={creatingBattlecard || (upsertingBattlecard && isHierarchyBasedTargetEnabled) || hasError}
            loading={creatingBattlecard || (upsertingBattlecard && isHierarchyBasedTargetEnabled)}
            testId={'submit-button'}
          />
        )}
        {!isCreateMode && (
          <TextButton
            text={formatMessage('UPDATE')}
            type="submit"
            intent="primary"
            disabled={battlecardUpdating || hasError}
            loading={battlecardUpdating}
            testId={'submit-button'}
          />
        )}
      </div>
    );
  };

  const refetchQueries = [
    {
      query: GET_BATTLE_CARD_CANVAS,
      variables: { deploymentModelId, reportingCurrency: !isLocalCurrencyMode }
    },
    {
      query: GET_BATTLECARD,
      variables: {
        battlecardId: +editingBattleCardId,
        deploymentModelId,
        isTQM: deploymentModelPhase === DeploymentModelPhase.manage
      }
    }
  ];

  const initialQuotaComponents = bcQuotaComponents?.length
    ? bcQuotaComponents?.map((quotaComponent) => ({
        key: quotaComponent?.quotaComponentName,
        value: quotaComponent?.quotaComponentId
      }))
    : [];

  const getInitialQuotaBreakdowns = () => {
    const bcQuotaDistributionHierarchyIds = battleCardLookupMap[editingBattleCardId]?.quotaDistributionHierarchies.map(
      (hierarchy) => hierarchy.hierarchyRootId
    );
    return bcQuotaDistributionHierarchyIds?.length > 0
      ? bcQuotaDistributionHierarchyIds?.map((hierarchyId) => ({
          key: rootHierarchies[hierarchyId]['hierarchyTreeData'].label,
          value: hierarchyId.toString(),
          type: rootHierarchies[hierarchyId]['hierarchyTreeData'].hierarchyType,
          firstLevelMemberCount: rootHierarchies[hierarchyId]['hierarchyTreeData'].firstLevelMemberCount
        }))
      : [];
  };

  const getInitialHierarchyBasedTarget = () => {
    const bcQuotaDistributionHierarchies = battleCardLookupMap[editingBattleCardId]?.quotaDistributionHierarchies;
    const targetSettingHierarchy = bcQuotaDistributionHierarchies?.find((hierarchy) => hierarchy.isAllocationTarget);
    return targetSettingHierarchy
      ? {
          firstLevelMemberCount:
            rootHierarchies[targetSettingHierarchy.hierarchyRootId]['hierarchyTreeData'].firstLevelMemberCount,
          key: rootHierarchies[targetSettingHierarchy.hierarchyRootId]['hierarchyTreeData'].label,
          type: rootHierarchies[targetSettingHierarchy.hierarchyRootId]['hierarchyTreeData'].hierarchyType,
          value: targetSettingHierarchy.hierarchyRootId.toString()
        }
      : null;
  };

  const handleSubmit = async (values) => {
    const hierarchies = [];
    const hierarchyTops = [];
    let index = 0;

    if (values.cardType.value !== BattlecardType.Rollup) {
      values.reorderableListItems.forEach((item) => {
        const newItem = {};
        newItem['hierarchyAlias'] = item.referToAs;
        newItem['hierarchyTopId'] = +item.hierarchyTop?.value;
        newItem['hierarchyType'] = item.hierarchyType;
        newItem['precedence'] = index + 1;
        newItem['memberId'] = item.owner?.value;
        index += 1;

        if (isCreateMode) {
          hierarchies.push(newItem);
        } else {
          const oldHierarchy = reorderableListItems.find(
            (oldItem) => oldItem.hierarchyTop.value === item.hierarchyTop.value
          );
          hierarchyTops.push({
            currentHierarchyTopId: oldHierarchy ? oldHierarchy.hierarchyTop?.value : null,
            hierarchyInput: newItem
          });
        }
      });
    }

    if (isCreateMode) {
      const createBCVariables = getCreateUpdateBCVariables(
        isCreateMode,
        deploymentModelId,
        +parentBCId,
        hierarchies,
        values,
        isHierarchyBasedTargetEnabled
      );

      if (!isHierarchyBasedTargetEnabled) {
        createBattlecard({
          variables: createBCVariables as CreateBattlecardVariables,
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: GET_BATTLE_CARD_CANVAS,
              variables: { deploymentModelId, reportingCurrency: !isLocalCurrencyMode }
            }
          ]
        });
      } else {
        upsertBattlecard({
          variables: createBCVariables as UpsertBattlecardVariables,
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: GET_BATTLE_CARD_CANVAS,
              variables: { deploymentModelId, reportingCurrency: !isLocalCurrencyMode }
            }
          ]
        });
      }
    } else {
      let upsertBattlecardHierarchyTopsResponse = null;
      if (hierarchyTops.length > 0) {
        // Execute update hierarchy top
        upsertBattlecardHierarchyTopsResponse = await upsertBattlecardHierarchyTops({
          variables: {
            battlecardId: +editingBattleCardId,
            hierarchyUpsertInput: hierarchyTops
          }
        });
      }

      // If upsertBattlecardHierarchyTopsResponse is null, it means upsertBattlecardHierarchyTopsResponse does not get called.
      // If upsertBattlecardHierarchyTopsResponse is undefined, it mean upsertBattlecardHierarchyTopsResponse gets called but failed.
      // We want to make sure that either upsertBattlecardHierarchyTopsResponse does not get called or it gets called and succeed.
      const upsertBattlecardHierarchyTopsComplete =
        upsertBattlecardHierarchyTopsResponse === null ||
        (upsertBattlecardHierarchyTopsResponse !== null && upsertBattlecardHierarchyTopsResponse.data !== undefined);

      // If both updating hierarchy top and adding hierarchy top succeed or battlecard type is roll-up, execute update battlecard owner
      let upsertBattlecardOwnerResponse = null;
      if (
        (values.cardType.value === BattlecardType.Rollup || upsertBattlecardHierarchyTopsComplete) &&
        values.battleCardOwner?.value &&
        battleCard?.owner?.battlecardOwnerId !== values.battleCardOwner?.value
      ) {
        upsertBattlecardOwnerResponse = await upsertBattlecardOwner({
          variables: {
            battlecardId: +editingBattleCardId,
            memberId: values.battleCardOwner.value,
            tenantId: selectedTenant?.id
          }
        });
      }

      // If upsertBattlecardOwnerResponse is null, it means upsertBattlecardOwnerResponse does not get called.
      // If upsertBattlecardOwnerResponse is undefined, it mean upsertBattlecardOwnerResponse gets called but failed.
      // We want to make sure that either upsertBattlecardOwnerResponse does not get called or it gets called and succeed.
      const upsertBattleCardOwnerComplete =
        upsertBattlecardOwnerResponse === null ||
        (upsertBattlecardOwnerResponse !== null && upsertBattlecardOwnerResponse.data !== undefined);

      // If both updating hierarchy top and adding hierarchy top succeed or battlecard type is roll-up, execute update battlecard owner
      let updateActivityFilesResponse = null;
      if (
        (values.cardType.value === BattlecardType.Rollup || upsertBattlecardHierarchyTopsComplete) &&
        upsertBattleCardOwnerComplete
      ) {
        updateActivityFilesResponse = await updateActivityFiles({
          variables: {
            battlecardId: +editingBattleCardId,
            deploymentModelId,
            fileIds:
              values?.activityFiles?.map((file) => {
                return file.value;
              }) ?? []
          }
        });
      }
      const initialQCIds = initialQuotaComponents.map((qcMenuItem) => qcMenuItem.value);
      const currentQCIds = values.quotaComponents.map((qcMenuItem) => qcMenuItem.value);

      const qcsToRemove = without(initialQCIds, ...currentQCIds);
      const qcsToAdd = without(currentQCIds, ...initialQCIds);

      if (qcsToRemove?.length) {
        handleQuotaComponentChange(qcsToRemove, qcActions.remove);
      }

      if (qcsToAdd?.length) {
        handleQuotaComponentChange(qcsToAdd, qcActions.add);
      }

      // If all of updating hierarchy top, adding hierarchy top and upserting battlecard owner complete, execute update battlecard
      if (
        (values.cardType.value === BattlecardType.Rollup || upsertBattlecardHierarchyTopsComplete) &&
        updateActivityFilesResponse?.data !== undefined
      ) {
        const updateBattlecardVariables = getCreateUpdateBCVariables(
          isCreateMode,
          deploymentModelId,
          +parentBCId,
          hierarchies,
          values,
          isHierarchyBasedTargetEnabled,
          +editingBattleCardId
        );

        if (!isHierarchyBasedTargetEnabled) {
          updateBattlecard({
            variables: updateBattlecardVariables as UpdateBattlecardVariables,
            awaitRefetchQueries: true,
            refetchQueries
          });
        } else {
          upsertBattlecard({
            variables: updateBattlecardVariables as UpsertBattlecardVariables,
            awaitRefetchQueries: true,
            refetchQueries
          });
        }
      }
    }
  };

  const shouldRenderDialogContent = rootHierarchies && (isCreateMode || battleCardData);

  const battlecardType = isCreateMode
    ? defaultCardType
    : battleCard?.battlecardType &&
      cardTypeSelectionDropDownData.find((item) => item.value === battleCard?.battlecardType);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapLevel: any = isCreateMode
    ? ''
    : battleCard?.mapLevel && cardTypeSelectionDropDownDataMapLevel.find((item) => item.value === battleCard?.mapLevel);

  const getBattleCardInitialCurrency = () => {
    if (isCreateMode) {
      // when creating a BC underneath a rollup, match the rollup's local currency
      if (parentBattleCardType === BattlecardType.Rollup) {
        return battleCardLookupMap[parentBCId].localCurrencyCode;
      } else {
        return defaultReportingCurrency;
      }
      // in edit mode, use the BC's currency
    } else {
      return battleCard?.localCurrencyCode;
    }
  };

  const renderErrorMessage = () => {
    return (
      <div className={b('errorMessage')}>
        {createBattlecardError && (
          <div data-testid={'create-battle-card-error'}>{formatMessage('CREATE_NEW_BATTLECARD_ERROR')}</div>
        )}
        {upsertBattlecardHierarchyTopsError && (
          <div data-testid={'update-hierarchy-top-error'}>{formatMessage('UPDATE_HIERARCHY_TOP_ERROR')}</div>
        )}
        {upsertBattlecardOwnerError && (
          <div data-testid={'update-battle-card-owner-error'}>{formatMessage('UPDATE_BATTLECARD_OWNER_ERROR')}</div>
        )}
        {updateActivityFilesError && (
          <div data-testid={'update-activity-files-error'}>{formatMessage('UPDATE_ACTIVITY_FILES_ERROR')}</div>
        )}
        {updateBattlecardError && (
          <div data-testid={'update-battle-card-error'}>{formatMessage('UPDATE_BATTLECARD_ERROR')}</div>
        )}
        {isHierarchyBasedTargetEnabled && isCreateMode && upsertBattlecardError && (
          <div data-testid={'create-battle-card-error'}>{formatMessage('CREATE_NEW_BATTLECARD_ERROR')}</div>
        )}
        {isHierarchyBasedTargetEnabled && !isCreateMode && upsertBattlecardError && (
          <div data-testid={'update-battle-card-error'}>{formatMessage('UPDATE_BATTLECARD_ERROR')}</div>
        )}
      </div>
    );
  };

  if (reorderableListItems?.length === 0) {
    reorderableListItems.push({ ...blankReorderableListItem });
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setShowDialog(false)}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      portalClassName={b()}
      data-testid="battle-card-designer-dialog"
    >
      {battlecardFetchingError && (
        <div>
          <div className={b('dialogBody')}>
            <div className={b('errorMessage')}>
              <div className={b('loadBattleCardErrorMessage')} data-testid={'load-battle-card-error'}>
                {formatMessage('BATTLECARD_ERROR')}
              </div>
            </div>
          </div>
          <div className={b('dialogFooter')}>
            <div className={b('buttons')}>
              <div onClick={() => handleCancel()}>
                <TextButton text={formatMessage('CANCEL')} type="button" testId={'cancel-button'} />
              </div>
            </div>
          </div>
        </div>
      )}
      {!battlecardFetchingError && !shouldRenderDialogContent && (
        <div className={b('spinnerContainer')} data-testid="spinner">
          <Spinner intent="primary" size={40} />
        </div>
      )}
      {shouldRenderDialogContent && (
        <Formik
          initialValues={{
            cardName: isCreateMode ? '' : battleCardData && battleCard?.battlecardName,
            currency: getCurrencyItem(currencies, getBattleCardInitialCurrency()),
            cardType: battlecardType,
            battleCardOwner: battleCard?.owner
              ? {
                  key: memberToString(battleCard?.owner),
                  value: battleCard?.owner.memberId
                }
              : null,
            quotaComponents: initialQuotaComponents,
            activityFiles: battleCard?.files
              ? battleCard.files.map((file) => {
                  return { key: file.fileName, value: file.fileId };
                })
              : [],
            reorderableListItems: hierarchyData && reorderableListItems,
            mapLevel,
            quotaBreakdowns: getInitialQuotaBreakdowns(),
            hierarchyBasedTarget: getInitialHierarchyBasedTarget()
          }}
          onSubmit={handleSubmit}
          innerRef={formRef}
          validationSchema={validationsSchema}
          validateOnMount
        >
          {({ errors, values, status, setStatus }) => {
            const isOverlayBattleCard =
              values?.cardType?.value === BattlecardType.DirectOverlay ||
              values?.cardType?.value === BattlecardType.IndirectOverlay;

            // if card is a non-overlay and non-root type,
            // ensure that current QC selection contains at least one of parent BC's QCs,
            // and doesn't contain more than the parent BC's QCs
            const bcParentId = currBC?.battlecardParentId || parentBCId;
            if (!isOverlayBattleCard && bcParentId) {
              const parentQuotaComponents = battleCardLookupMap?.[bcParentId]?.quotaComponents ?? [];
              const parentQuotaComponentIds = parentQuotaComponents.map(
                (quotaComponent) => quotaComponent?.quotaComponentId
              );

              const currentQuotaComponentIds: Array<number | undefined> =
                values?.quotaComponents.map((quotaComponent) =>
                  quotaComponent?.value ? +quotaComponent.value : undefined
                ) ?? [];

              const isQCSelectionInvalid = !currentQuotaComponentIds.every((qcId) =>
                parentQuotaComponentIds.includes(qcId)
              );

              if (isQCSelectionInvalid && !status?.quotaComponents) {
                setStatus({
                  quotaComponents: formatMessage('REMOVE_QUOTA_COMPONENT_ERROR')
                });
              } else if (!isQCSelectionInvalid && status?.quotaComponents) {
                setStatus({ quotaComponents: null });
              }
            }

            const dialogHeader = values.cardName || formatMessage('UNTITLED');

            return (
              <div className={b()}>
                <Form>
                  <DirtyFormPrompt data-testid="dirty-form-prompt" />
                  <div className={b('dialogHeader')}>
                    <div className={b('cardTitle')} data-testid={'battlecard-title'}>
                      <HTMLHeading tagLevel="h3" text={dialogHeader} />
                    </div>
                  </div>
                  <div className={b('dialogBody')} ref={bodyRef}>
                    {battlecardFetchingError && (
                      <div className={b('errorMessage')}>{formatMessage('BATTLECARD_ERROR')}</div>
                    )}
                    {(() => {
                      if (!battlecardFetchingError) {
                        return (
                          <BattleCardDesignerInitialSetupPage
                            parentBattleCardType={parentBattleCardType}
                            editingBattleCardId={editingBattleCardId}
                            rootHierarchies={rootHierarchies}
                            blankReorderableListItem={blankReorderableListItem}
                            mapLevel={battleCard?.mapLevel}
                            deploymentModelId={deploymentModelId}
                            parentRef={bodyRef}
                          />
                        );
                      }
                      return <></>;
                    })()}
                  </div>

                  <div className={b('dialogFooter')}>
                    {/* TODO TQP-1433 Need to update to the new submission error message design */}
                    {renderErrorMessage()}
                    <div className={b('buttons')}>
                      <div className={b('cancelButton')} onClick={() => handleCancel()}>
                        <TextButton text={formatMessage('CANCEL')} type="button" testId={'cancel-button'} />
                      </div>
                      {renderSubmitButton(errors, status, values)}
                    </div>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      )}
    </Dialog>
  );
};

export default BattleCardDesignerDialog;
