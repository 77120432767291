import gql from 'graphql-tag';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetBattleCardCanvas, GetBattleCardCanvasVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const GET_BATTLE_CARD_CANVAS = gql`
  query GetBattleCardCanvas($deploymentModelId: Int!, $reportingCurrency: Boolean, $isTQM: Boolean) {
    getDeploymentModelSpec(
      input: { deploymentModelId: $deploymentModelId, reportingCurrency: $reportingCurrency, isTQM: $isTQM }
    ) {
      battlecards {
        battlecardId
        battlecardInfoLevel
        battlecardName
        battlecardParentId
        battlecardType
        children
        conversionRate
        currencyHierarchyId
        invalidConversionReason
        localCurrencyCode
        localCurrencyName
        mapLevel
        owner {
          battlecardOwnerId
          emailAddress
          employeeId
          firstName
          lastName
          memberId
        }
        position
        reportingCurrencyCode
        rollupConversionRate
        files {
          fileId
          fileName
        }
        sheets {
          sheetId
          sheetName
        }
        territoryGroupsLinked
        territoryGroupTypes {
          hierarchyType
          hierarchyAlias
          territoryGroupId
          territoryGroupOwnerMemberId
        }
        quotaComponents {
          quotaComponentName
          allocatedBusinessTarget
          battlecardQuotaComponentId
          canDelete
          measures {
            activityMeasureColumnName
            editable
            endDate
            fieldId
            fieldName
            measureCategory
            measureConstraints
            measureFieldType
            measureFormatType
            measureFormula
            measureId
            measureName
            measureSource
            measureValue
            startDate
            visible
          }
          newBusinessTarget
          padding
          quotaComponentComment
          quotaComponentId
          quotaComponentSlug
          unallocatedBusinessTarget
        }
        quotaDistributionHierarchies {
          hierarchyRootId
          isAllocationTarget
        }
      }
      deploymentModelId
      deploymentModelName
      planningCycle {
        planningCycleId
        planningCycleName
      }
    }
  }
`;

export const useGetBattleCardCanvasLazy = createUseLazyQuery<GetBattleCardCanvas, GetBattleCardCanvasVariables>(
  GET_BATTLE_CARD_CANVAS
);
