import * as Yup from 'yup';

import { GetAllTerritoryIdentities_getAllTerritoryIdentities_territoryIdentities } from 'app/graphql/generated/graphqlApolloTypes';

import { formatMessage } from 'utils/messages/utils';

const isTerritoryIdExistsInDM = (territoriesIdentities, duplicateTerritoryIds, territoryId: string) => {
  const NonDuplicateTerritoryIds = territoriesIdentities
    .filter((territory) => !duplicateTerritoryIds.includes(territory.territoryId))
    .map((territory) => territory.territoryId);
  return NonDuplicateTerritoryIds.includes(territoryId);
};

const getDuplicateTerritoryIds = (values: { [key: string]: { territoryId: string } }) => {
  const territoryCountMap = {};

  Object.values(values).forEach((territory) => {
    if (territoryCountMap[territory.territoryId]) {
      territoryCountMap[territory.territoryId] += 1;
    } else {
      territoryCountMap[territory.territoryId] = 1;
    }
  });

  return Object.keys(territoryCountMap).filter((territoryId) => territoryCountMap[territoryId] > 1);
};

const validationSchema = (
  territoriesIdentities: GetAllTerritoryIdentities_getAllTerritoryIdentities_territoryIdentities[],
  originalDuplicateTerritoryIds: string[],
  touchedFields: { [key: string]: boolean }
) =>
  Yup.lazy((values) => {
    const shape = {};
    const duplicateTerritoryIds = getDuplicateTerritoryIds(values);
    for (const key in values) {
      shape[key] = Yup.object().shape({
        territoryId: Yup.string()
          .required(formatMessage('TERRITORY_ID_REQUIRED'))
          .test(function (value) {
            // If the field is not touched, we do not want to validate it
            if (!touchedFields[key]) return true;
            // Check if the territoryId already exists in the deployment model
            // to make sure we do not introduce new duplicates
            const territoryIdExists = isTerritoryIdExistsInDM(
              territoriesIdentities,
              originalDuplicateTerritoryIds,
              value
            );
            const { createError } = this;
            if (territoryIdExists) {
              return createError({ message: formatMessage('TERRITORY_ID_ALREADY_EXISTS') });
            }
            // Check if a modified territioryId is unique in the form
            // We only want to validate modified fields because we want to allow partial fixes
            if (duplicateTerritoryIds.includes(value)) {
              return createError({ message: formatMessage('TERRITORY_ID_MUST_BE_UNIQUE') });
            }
            return true;
          }),
        territoryName: Yup.string().required(formatMessage('TERRITORY_NAME_REQUIRED'))
      });
    }
    return Yup.object().shape(shape);
  });

export default validationSchema;
