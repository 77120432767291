import React, { FC } from 'react';

import Dialog from 'components/Dialog/Dialog';

import { useMapVariant } from 'app/contexts/mapVariantProvider';

import { formatMessage } from 'utils/messages/utils';

import { useAccountFirstPinsWarningThreshold } from './hooks/useAccountFirstPinsWarningThreshold';

interface AccountMapWarningDialogProps {
  isErrorLoadingCustomerPins: boolean;
}

const AccountMapWarningDialog: FC<AccountMapWarningDialogProps> = ({ isErrorLoadingCustomerPins }) => {
  const { setIsDataTrayMapOpen } = useMapVariant();

  const accountFirstPinsWarningThreshold = useAccountFirstPinsWarningThreshold();

  return (
    <Dialog
      isOpen
      title={formatMessage('MAP_LOADING_WARNING_TITLE')}
      size="small"
      bodyMinHeight={0}
      showCancel={false}
      confirmButtonText={formatMessage('BACK_TO_GRID_VIEW')}
      onSubmit={() => setIsDataTrayMapOpen(false)}
    >
      {isErrorLoadingCustomerPins
        ? formatMessage('MAP_LOADING_WARNING_BODY_GENERAL')
        : formatMessage('MAP_LOADING_WARNING_BODY', { accountFirstPinsWarningThreshold })}
    </Dialog>
  );
};

export default AccountMapWarningDialog;
