import { ApolloOverrides } from 'app/graphql/apolloTypeOverrides';
import {
  GetSymonPipeConfigurations,
  GetSymonPipeConfigurationsVariables
} from 'app/graphql/generated/graphqlApolloTypes';
import { useGetSymonPipeConfigurationsLazy } from 'app/graphql/queries/getSymonPipeConfigurations';

import useShowToast from 'app/hooks/useShowToast';

import { formatMessage } from 'utils/messages/utils';

export const useSymonPipeConfigurations = (): ApolloOverrides.LazyQueryResultTuple<
  GetSymonPipeConfigurations,
  GetSymonPipeConfigurationsVariables
> => {
  const showToast = useShowToast();

  return useGetSymonPipeConfigurationsLazy({
    fetchPolicy: 'network-only',
    onError() {
      showToast(formatMessage('SYMON_PIPE_CONFIGURATIONS_ERROR'), 'danger');
    }
  });
};
