import { DefinitionFilterOperatorEnum, HierarchyTypeEnum } from 'app/graphql/generated/graphqlApolloTypes';

import {
  CollectionFilter,
  CollectionFilterKind,
  HierarchySpec,
  LegacyCollectionFilterKinds,
  NamedRootHierarchy,
  Segment,
  SegmentFilterClause
} from 'app/models';

import { ReadonlyMapOrSet } from './territoryMapUtils';

type SegmentClauseEvaluator = (
  hierarchiesInClause: ReadonlyArray<{ hierarchyId: number }>,
  idsToTest: ReadonlyMapOrSet<number> | undefined
) => boolean;

const passesContainsAnySegmentClause: SegmentClauseEvaluator = (hierarchiesInClause, idsToTest) => {
  if (!idsToTest) return false;
  for (const hierarchy of hierarchiesInClause) {
    if (idsToTest.has(hierarchy.hierarchyId)) return true;
  }
  return false;
};
const passesNotContainsAnySegmentClause: SegmentClauseEvaluator = (hierarchies, idsToTest) => {
  return !passesContainsAnySegmentClause(hierarchies, idsToTest);
};

const passesEqualsSegmentClause: SegmentClauseEvaluator = (hierarchies, idsToTest) => {
  if (!idsToTest) return false;
  if (hierarchies.length !== idsToTest.size) return false;
  for (const hierarchy of hierarchies) {
    if (!idsToTest.has(hierarchy.hierarchyId)) return false;
  }
  return true;
};
const passesNotEqualsSegmentClause: SegmentClauseEvaluator = (hierarchies, idsToTest) => {
  return !passesEqualsSegmentClause(hierarchies, idsToTest);
};

const passesMatchAnySegmentClause: SegmentClauseEvaluator = (_hierarchies, _idsToTest) => {
  return true;
};

const segmentFilterEvaluators: Record<DefinitionFilterOperatorEnum, SegmentClauseEvaluator> = {
  [DefinitionFilterOperatorEnum.CONTAINS_ANY]: passesContainsAnySegmentClause,
  [DefinitionFilterOperatorEnum.NOT_CONTAINS_ANY]: passesNotContainsAnySegmentClause,
  [DefinitionFilterOperatorEnum.EQUALS]: passesEqualsSegmentClause,
  [DefinitionFilterOperatorEnum.NOT_EQUALS]: passesNotEqualsSegmentClause,
  [DefinitionFilterOperatorEnum.MATCH_ANY_CONDITION]: passesMatchAnySegmentClause,
  [DefinitionFilterOperatorEnum.NONE]: () => false
};

export const passesSegmentFilter = (
  filter: Pick<Segment, 'clauses'>,
  idsToTest: ReadonlyMap<number, NamedRootHierarchy> | undefined
) => {
  for (const [_key, value] of idsToTest) {
    const rootHierarchyId = value.rootHierarchyId;

    const containsRootHierarchy = filter.clauses.some((filter) => filter.rootHierarchyId === rootHierarchyId);

    if (!containsRootHierarchy) return false;
  }

  return filter.clauses.every((clause) => {
    const relevantCustomIds = new Set<number>();
    idsToTest.forEach((custom, key) => {
      if (custom.rootHierarchyId === clause.rootHierarchyId) relevantCustomIds.add(key);
    });

    const evaluator = segmentFilterEvaluators[clause.operator];
    if (!evaluator) throw new Error(`Unsupported segment filter operator: ${clause.operator}`);
    return evaluator(clause.hierarchies, relevantCustomIds);
  });
};

// These enums are identical, but TS requires casting as they have different identities
const operatorAsFilterKind = (operator: DefinitionFilterOperatorEnum) => operator as string as CollectionFilterKind;
const operatorAsDefinitionFilterKind = (operator: CollectionFilterKind) =>
  operator as string as DefinitionFilterOperatorEnum;

export const segmentToCollectionFilter = (clauses: SegmentFilterClause[]): CollectionFilter<number>[] =>
  clauses.map((clause) => {
    return {
      kind: operatorAsFilterKind(clause.operator),
      rootHierarchyId: clause.rootHierarchyId,
      ids: clause.hierarchies.map((h) => h.hierarchyId)
    };
  });

export const isEmptyLegacyFilter = (filter: CollectionFilter<number>[]): boolean => {
  if (filter.length !== 1) return false;
  const [clause] = filter;

  return clause.ids.length === 0 && LegacyCollectionFilterKinds.includes(clause.kind);
};

export const filterToSegmentClauses = (
  customHierarchyFilter: CollectionFilter<number>[],
  customHierarchies: NamedRootHierarchy[],
  rootHierarchies: HierarchySpec[]
) => {
  const customHierarchiesInFilter = new Set();
  customHierarchyFilter.forEach((filter) => {
    filter.ids.forEach((id) => customHierarchiesInFilter.add(id));
  });

  return customHierarchyFilter.map((filter) => {
    const relevantCustomHierarchies = customHierarchies
      .filter(
        (customHierarchy) =>
          customHierarchy.rootHierarchyId === filter.rootHierarchyId &&
          customHierarchiesInFilter.has(customHierarchy.customHierarchyId) &&
          filter.kind !== CollectionFilterKind.NONE &&
          filter.kind !== CollectionFilterKind.MATCH_ANY_CONDITION
      )
      .map((customHierarchy) => ({
        hierarchyId: customHierarchy.customHierarchyId,
        hierarchyName: customHierarchy.customHierarchyName
      }));

    const rootHierarchyName = rootHierarchies.find(
      (hierarchy) => hierarchy.rootHierarchyId === filter.rootHierarchyId
    )?.rootName;

    return {
      operator: operatorAsDefinitionFilterKind(filter.kind),
      rootHierarchyId: filter.rootHierarchyId,
      hierarchyType: HierarchyTypeEnum.CustomHierarchy,
      hierarchies: relevantCustomHierarchies,
      rootHierarchyName
    };
  });
};
