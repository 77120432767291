import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import {
  removeQuotaComponentFromBattlecard,
  removeQuotaComponentFromBattlecardVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const REMOVE_QUOTA_COMPONENT_FROM_BATTLE_CARD = gql`
  mutation removeQuotaComponentFromBattlecard($battlecardId: Int!, $quotaComponentId: Int!) {
    removeQuotaComponentFromBattlecard(input: { battlecardId: $battlecardId, quotaComponentId: $quotaComponentId }) {
      success
    }
  }
`;

export const useRemoveQuotaComponentFromBattleCard = createUseMutation<
  removeQuotaComponentFromBattlecard,
  removeQuotaComponentFromBattlecardVariables
>(REMOVE_QUOTA_COMPONENT_FROM_BATTLE_CARD);
