import React, { useState, useMemo, Dispatch, SetStateAction } from 'react';

import { ApolloError, ApolloQueryResult } from '@apollo/client';

import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import { QUERY_TERRITORY_GROUPS } from 'app/graphql/queries/getAllTerritoryGroups';

import { useContextSafe } from 'app/hooks/useContextSafe';

import {
  BaseContext,
  ExpandedTerritoryGroupDefineAndRefineApiCall,
  ExpandedTerritoryGroupDefineAndRefinePillData
} from 'app/models';

import { sortByPrecedence } from 'utils/helpers/index';

export interface TerritoryDefineAndRefineContextValues extends BaseContext {
  selectedPillIdTDR: string;
  setSelectedPillIdTDR: Dispatch<SetStateAction<string | null>>;

  isActivityCountSelected: boolean;
  setIsActivityCountSelected: Dispatch<SetStateAction<boolean>>;

  tdrLookupMap: Record<string, ExpandedTerritoryGroupDefineAndRefinePillData>;
  tdrBattlecardLookupMap: Record<string, ExpandedTerritoryGroupDefineAndRefinePillData>;
  tdrTreeLookupMap: Record<string, ExpandedTerritoryGroupDefineAndRefinePillData[]>;
  unassignedActivitiesLookupMap: Record<string, number>;

  getTDR: (battleCardId: string, quotaComponentId: number) => Promise<void>;
  tdrLoadingLookupMap: Record<string, boolean>;
  tdrErrorLookupMap: Record<string, readonly ApolloError[]>;

  tgExpandedLookupMap: Record<string, boolean>;
  setTgExpandedCollapsed: (territoryGroupId: string, isExpanded: boolean) => void;

  resetValues: () => void;
}

export const TerritoryDefineAndRefineContext = React.createContext<TerritoryDefineAndRefineContextValues | null>(null);
TerritoryDefineAndRefineContext.displayName = 'TerritoryDefineAndRefineContext';

export const TerritoryDefineAndRefineProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [selectedPillIdTDR, setSelectedPillIdTDR] = useState<string | null>(null);
  const [hoveredPillIdTDR, setHoveredPillIdTDR] = useState<string | null>(null);

  const [isActivityCountSelected, setIsActivityCountSelected] = useState<boolean>(false);

  const [tdrLookupMap, setTDRLookupMap] = useState<Record<string, ExpandedTerritoryGroupDefineAndRefinePillData>>();
  const [tdrBattlecardLookupMap, setTDRBattlecardLookupMap] =
    useState<Record<string, ExpandedTerritoryGroupDefineAndRefinePillData>>();

  const [tdrTreeLookupMap, setTDRTreeLookupMap] =
    useState<Record<string, ExpandedTerritoryGroupDefineAndRefinePillData[]>>();
  const [unassignedActivitiesLookupMap, setUnassignedActivitiesLookupMap] = useState<Record<string, number>>({});

  const [tdrLoadingLookupMap, setTDRLoadingLookupMap] = useState<Record<string, boolean>>({});
  const [tdrErrorLookupMap, setTDRErrorLookupMap] = useState<Record<string, readonly ApolloError[]> | null>({});

  const [tgExpandedLookupMap, setTGExpandedLookupMap] = useState<Record<string, boolean>>({});

  const [showTerritoryGroupConfirmDelete, setShowTerritoryGroupConfirmDelete] = useState<boolean>(false);
  const [dialogSelectedTerritoryGroupId, setDialogSelectedTerritoryGroupId] = useState<string | null>(null);

  const createLookupMap = (flatList) => {
    const map = flatList.reduce((acc, curr) => {
      const reshapedNode = {
        hierarchyId: curr.hierarchyId,
        hierarchyTopId: curr.hierarchyTopId,
        hierarchyType: curr.hierarchyType,
        name: curr.territoryGroupName,
        precedence: curr.precedence,
        territoryGroupId: curr.territoryGroupId,
        // if parentId is null, keep it as null; otherwise must cast parentId to string
        territoryGroupParentId: !curr.territoryGroupParentId
          ? curr.territoryGroupParentId
          : curr.territoryGroupParentId.toString(),
        owner: curr.owner,
        children: [],
        activityCount: curr.activityCount,
        createdInYear: curr.createdInYear,
        effectiveDate: curr.effectiveDate,
        endDate: curr.endDate
      };
      acc[curr.territoryGroupId] = reshapedNode;
      return acc;
    }, {});

    return map;
  };

  const getTDR = async (battleCardId: string, quotaComponentId: number) => {
    setTDRLoadingLookupMap({ ...tdrLoadingLookupMap, [battleCardId]: true });
    setTDRErrorLookupMap({ ...tdrErrorLookupMap, [battleCardId]: null });

    try {
      // get TDR
      const tdr: ApolloQueryResult<{
        getAllTerritoryGroups: {
          allTerritoryGroups: ExpandedTerritoryGroupDefineAndRefineApiCall[];
          unassignedActivities: number;
        };
        // eslint-disable-next-line no-restricted-syntax
      }> = await apolloClient.query({
        query: QUERY_TERRITORY_GROUPS,
        fetchPolicy: 'network-only',
        variables: { battlecardId: battleCardId, quotaComponentId }
      });

      const battleCardTDRTree = [];
      const battleCardTDRLookupMap = createLookupMap(tdr.data?.getAllTerritoryGroups?.allTerritoryGroups);

      for (const key of Object.keys(battleCardTDRLookupMap)) {
        const parentId = battleCardTDRLookupMap[key].territoryGroupParentId;
        if (parentId) {
          battleCardTDRLookupMap[parentId].children.push(battleCardTDRLookupMap[key]);
        } else {
          battleCardTDRTree.push(battleCardTDRLookupMap[key]);
        }
      }
      setTDRLookupMap({ ...tdrLookupMap, ...battleCardTDRLookupMap });
      setTDRBattlecardLookupMap({ ...battleCardTDRLookupMap });

      battleCardTDRTree.sort(sortByPrecedence);
      setTDRTreeLookupMap({ ...tdrTreeLookupMap, [battleCardId]: battleCardTDRTree });

      const unassignedActivities = tdr.data?.getAllTerritoryGroups?.unassignedActivities;
      setUnassignedActivitiesLookupMap({ ...unassignedActivitiesLookupMap, [battleCardId]: unassignedActivities });
    } catch (error) {
      console.log(error);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      setTDRErrorLookupMap({ ...tdrErrorLookupMap, [battleCardId]: error });
    } finally {
      setTDRLoadingLookupMap({ ...tdrLoadingLookupMap, [battleCardId]: false });
    }
  };

  const setTgExpandedCollapsed = (territoryGroupId: string, isExpanded: boolean) => {
    setTGExpandedLookupMap({ ...tgExpandedLookupMap, [territoryGroupId]: isExpanded });
  };

  const resetValues = () => {
    setHoveredPillIdTDR(null);
    setSelectedPillIdTDR(null);
    setShowTerritoryGroupConfirmDelete(false);
    setUnassignedActivitiesLookupMap({});
    setTDRLoadingLookupMap({});
    setTDRErrorLookupMap({});
    setTDRLookupMap({});
    setTDRBattlecardLookupMap({});
    setTDRTreeLookupMap(null);
    setIsActivityCountSelected(false);
    setDialogSelectedTerritoryGroupId(null);
    setTGExpandedLookupMap({});
  };

  // Prevent forced re-render on components that are reading these values,
  // unless certain values have changed.
  const values = useMemo(
    (): TerritoryDefineAndRefineContextValues => ({
      tdrLookupMap,
      tdrBattlecardLookupMap,
      tdrTreeLookupMap,
      unassignedActivitiesLookupMap,
      tdrLoadingLookupMap,
      tdrErrorLookupMap,
      getTDR,
      setSelectedPillIdTDR,
      selectedPillIdTDR,
      isActivityCountSelected,
      setIsActivityCountSelected,
      tgExpandedLookupMap,
      setTgExpandedCollapsed,
      resetValues
    }),
    [
      tdrLookupMap,
      tdrBattlecardLookupMap,
      tdrTreeLookupMap,
      unassignedActivitiesLookupMap,
      tdrLoadingLookupMap,
      tdrErrorLookupMap,
      hoveredPillIdTDR,
      selectedPillIdTDR,
      isActivityCountSelected,
      tgExpandedLookupMap,
      showTerritoryGroupConfirmDelete,
      dialogSelectedTerritoryGroupId
    ]
  );

  // Return the interface that we want to expose to our other components
  return <TerritoryDefineAndRefineContext.Provider value={values}>{children}</TerritoryDefineAndRefineContext.Provider>;
};

// Custom hook to read these values from
export const useTerritoryDefineAndRefine = (): TerritoryDefineAndRefineContextValues =>
  useContextSafe(TerritoryDefineAndRefineContext);
