import React, { useState, useMemo, useCallback } from 'react';

import TerritoryGroupConfirmDelete from 'app/components/TerritoriesAndPlanTargets/TerritoryGroupConfirmDelete/TerritoryGroupConfirmDelete';
import TerritoryGroupDialog from 'app/components/TerritoriesAndPlanTargets/TerritoryGroupDialog/TerritoryGroupDialog';

import { useContextSafe } from 'app/hooks/useContextSafe';

import { BaseContext, TerritoryGroupDialogModes } from 'app/models';

export interface TerritoryGroupDialogContextValues extends BaseContext {
  territoryGroupDialogState: TerritoryGroupDialogState | null;
  openTerritoryGroupDialog: (dialogState: TerritoryGroupDialogState) => void;
  closeTerritoryGroupDialog: () => void;
  resetValues: () => void;
}

export type TerritoryGroupDialogState<M extends TerritoryGroupDialogModes = TerritoryGroupDialogModes> = {
  territoryGroupId: string;
  mode: M;
  battleCardId: string;
  effectiveDate?: string;
  endDate?: string;
};

export const TerritoryGroupDialogContext = React.createContext<TerritoryGroupDialogContextValues | null>(null);
TerritoryGroupDialogContext.displayName = 'TerritoryGroupDialogContext';

export const TerritoryGroupDialogProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [territoryGroupDialogState, setTerritoryGroupDialogState] = useState<TerritoryGroupDialogState | null>(null);

  const openTerritoryGroupDialog = useCallback((dialogState: TerritoryGroupDialogState) => {
    if (!dialogState) throw new Error(`Missing TDR dialog state`);
    setTerritoryGroupDialogState(dialogState);
  }, []);

  const closeTerritoryGroupDialog = useCallback(() => setTerritoryGroupDialogState(null), []);

  const resetValues = () => {
    closeTerritoryGroupDialog();
  };

  const values = useMemo(
    (): TerritoryGroupDialogContextValues => ({
      territoryGroupDialogState,
      openTerritoryGroupDialog,
      closeTerritoryGroupDialog,
      resetValues
    }),
    [territoryGroupDialogState, openTerritoryGroupDialog, closeTerritoryGroupDialog]
  );

  return (
    <>
      {(territoryGroupDialogState?.mode === TerritoryGroupDialogModes.CREATE_GROUP ||
        territoryGroupDialogState?.mode === TerritoryGroupDialogModes.EDIT_GROUP) && (
        <TerritoryGroupDialog
          territoryGroupDialogState={
            territoryGroupDialogState as TerritoryGroupDialogState<
              TerritoryGroupDialogModes.CREATE_GROUP | TerritoryGroupDialogModes.EDIT_GROUP
            >
          }
          closeTerritoryGroupDialog={closeTerritoryGroupDialog}
        />
      )}
      {territoryGroupDialogState?.mode === TerritoryGroupDialogModes.DELETE_GROUP && (
        <TerritoryGroupConfirmDelete
          territoryGroupDialogState={
            territoryGroupDialogState as TerritoryGroupDialogState<TerritoryGroupDialogModes.DELETE_GROUP>
          }
          closeTerritoryGroupDialog={closeTerritoryGroupDialog}
        />
      )}
      <TerritoryGroupDialogContext.Provider value={values}>{children}</TerritoryGroupDialogContext.Provider>
    </>
  );
};

// Custom hook to read these values from context safely
export const useTerritoryGroupDialog = (): TerritoryGroupDialogContextValues =>
  useContextSafe(TerritoryGroupDialogContext);
