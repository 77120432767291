// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9Rv3d .bp3-overlay-content {\n    width: 320px;\n    bottom: 25px;\n    right: 25px;\n    padding: 0;\n  }\n  ._9Rv3d .bp3-progress-bar {\n    background-color: rgb(var(--color-light-gray-4));\n  }\n  ._9Rv3d .bp3-progress-bar .bp3-progress-meter {\n      background-color: rgb(var(--color-cobalt-3));\n    }\n  ._0WcFh {\n    padding-bottom: 10px;\n  }\n  .n-m4D {\n    margin-right: 8px;\n    margin-bottom: 0;\n  }\n  .IiUJr {\n    display: flex;\n    align-items: center;\n  }\n  ._8aRyi {\n    font-size: 0.75rem;\n    white-space: nowrap;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"JobProgressStatusToast": "_9Rv3d",
	"JobProgressStatusToast_descriptionContainer": "_0WcFh",
	"JobProgressStatusToast_progressBar": "n-m4D",
	"JobProgressStatusToast_progressContainer": "IiUJr",
	"JobProgressStatusToast_progressPercentage": "_8aRyi"
};
export default ___CSS_LOADER_EXPORT___;
