import gql from 'graphql-tag';

export const QUERY_TERRITORY_GROUPS = gql`
  query TerritoryGroups($battlecardId: Int, $quotaComponentId: Int) {
    getAllTerritoryGroups(battlecardId: $battlecardId, quotaComponentId: $quotaComponentId) {
      allTerritoryGroups {
        hierarchyId
        hierarchyTopId
        hierarchyType
        precedence
        territoryGroupId
        territoryGroupParentId
        territoryGroupName
        activityCount
        effectiveDate
        endDate
        createdInYear
        owner {
          employeeId
          firstName
          lastName
          memberId
        }
        children {
          hierarchyId
          hierarchyTopId
          precedence
          territoryGroupId
          territoryGroupParentId
          territoryGroupName
        }
      }
      unassignedActivities
    }
  }
`;
