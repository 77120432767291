import React from 'react';

import { InformationFilled } from '@carbon/icons-react';
import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';

import { KeyValue } from 'components/models';

import { getPlanningCycleEndDate } from 'app/components/AdvancedGrid/Sheets/Account/AccountQuotaUtils';
import UpsertTerritoryRuleViewActionButtonContainer from 'app/components/DataTray/TerritoryGrid/UpsertTerritoryRuleView/UpsertTerritoryRuleViewSubComponent/UpsertTerritoryRuleViewActionButtonContainer';
import UpsertTerritoryRuleViewHeader from 'app/components/DataTray/TerritoryGrid/UpsertTerritoryRuleView/UpsertTerritoryRuleViewSubComponent/UpsertTerritoryRuleViewHeader';
import UpsertTerritoryRuleViewLeftPanelContent from 'app/components/DataTray/TerritoryGrid/UpsertTerritoryRuleView/UpsertTerritoryRuleViewSubComponent/UpsertTerritoryRuleViewLeftPanelContent';
import UpsertTerritoryRuleViewLeftPanelHeader from 'app/components/DataTray/TerritoryGrid/UpsertTerritoryRuleView/UpsertTerritoryRuleViewSubComponent/UpsertTerritoryRuleViewLeftPanelHeader';
import UpsertTerritoryRuleViewRightPanelHeader from 'app/components/DataTray/TerritoryGrid/UpsertTerritoryRuleView/UpsertTerritoryRuleViewSubComponent/UpsertTerritoryRuleViewRightPanelHeader';
import FormDateRangePicker from 'app/components/FormFields/FormDateRangePicker/FormDateRangePicker';

import { useGrid } from 'app/contexts/gridProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { useUpsertTerritoryRule } from 'app/graphql/hooks/useUpsertTerritoryRule';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './UpsertTerritoryRuleManageView.module.pcss';

const b = block(style);

const UpsertTerritoryRuleManageView: React.FC = () => {
  const { setShowUpsertTerritoryRuleView } = useGrid();
  const { selectedPlanningCycle } = useScope();
  const { planningCycleStartDate, planningCycleDuration } = selectedPlanningCycle;

  const initialFormValues = {
    territoryGroup: {
      value: '',
      key: ''
    } as KeyValue<string>,
    territoryId: '',
    territoryName: '',
    startDate: planningCycleStartDate,
    endDate: getPlanningCycleEndDate(planningCycleStartDate, planningCycleDuration)
  };

  const onUpsertCompleted = () => {
    onBackButtonClick();
  };

  const successToastMessage = formatMessage('CREATE_NEW_TERRITORY_SUCCESS');
  const errorToastMessage = formatMessage('CREATE_NEW_TERRITORY_ERROR');

  const [upsertTerritoryRule, { loading: upsertTerritoryRuleLoading }] = useUpsertTerritoryRule(
    successToastMessage,
    errorToastMessage,
    onUpsertCompleted
  );

  const handleSubmit = async (values) => {
    if (!values) return;
    await upsertTerritoryRule({
      variables: {
        ruleId: null,
        territoryId: values.territoryId,
        territoryName: values.territoryName,
        territoryGroupId: +values.territoryGroup?.value,
        effectiveDate: values.startDate,
        endDate: values.endDate
      }
    });
  };

  const onBackButtonClick = () => {
    setShowUpsertTerritoryRuleView(false);
  };

  const handleUpdateEffectiveDate = (e: React.ChangeEvent<string>, setFieldValue) => {
    const startDate = e?.[0] ? dayjs(e[0]).format('YYYY-MM-DD') : planningCycleStartDate;
    const endDate = e?.[1]
      ? dayjs(e[1]).format('YYYY-MM-DD')
      : getPlanningCycleEndDate(planningCycleStartDate, planningCycleDuration);
    setFieldValue('startDate', startDate);
    setFieldValue('endDate', endDate);
  };

  return (
    <div className={b('')}>
      <UpsertTerritoryRuleViewHeader
        onBackButtonClick={onBackButtonClick}
        canEdit={false}
        data-testid="upsert-territory-rule-header"
      />
      <div className={b('body')}>
        <Formik initialValues={initialFormValues} onSubmit={handleSubmit} enableReinitialize>
          {({ setFieldValue }) => {
            return (
              <Form className={b('territoryRuleForm')}>
                <div className={b('leftPanel')} data-testid="left-panel">
                  <div className={b('leftPanelHeader')}>
                    <div className={b('leftPanelHeaderItemsContainer')}>
                      <div className={b('inputGroupHeader')}>
                        <UpsertTerritoryRuleViewLeftPanelHeader data-testid="left-panel-header" />
                      </div>

                      <div
                        data-testid="territory-effective-dating-text"
                        className={b('text', { effectiveDatingText: true })}
                      >
                        {formatMessage('TERRITORY_EFFECTIVE_DATES')}
                      </div>
                    </div>
                  </div>
                  <div className={b('leftPanelContentContainer')}>
                    <UpsertTerritoryRuleViewLeftPanelContent
                      initialFormValues={initialFormValues}
                      territoryGroupWidthSize="small"
                      data-testid="left-panel-content"
                    />
                    <div
                      className={b('inputGroup', { effectiveDatingInput: true })}
                      data-testid="effective-dating-field-container"
                    >
                      <Field
                        name="territoryEffectiveDates"
                        component={FormDateRangePicker}
                        defaultStartDate={initialFormValues?.startDate || planningCycleStartDate}
                        defaultEndDate={
                          initialFormValues?.endDate ||
                          getPlanningCycleEndDate(planningCycleStartDate, planningCycleDuration)
                        }
                        position="bottom"
                        onChange={(e) => handleUpdateEffectiveDate(e, setFieldValue)}
                        data-testid="form-date-range-picker"
                      />
                    </div>
                  </div>
                </div>
                <div className={b('rightPanel')}>
                  <UpsertTerritoryRuleViewRightPanelHeader data-testid="right-panel-header" />
                  <div data-testid="right-panel-content" className={b('rightPanelContentContainer')}>
                    <div className={b('actionToolbar')}>
                      <div className={b('infoTextContainer')}>
                        <InformationFilled size={16} className={b('icon')} />
                        <span className={b('infoText')}>{formatMessage('CREATE_NEW_TERRITORY_INFO')}</span>
                      </div>
                      <UpsertTerritoryRuleViewActionButtonContainer
                        onBackButtonClick={onBackButtonClick}
                        loading={upsertTerritoryRuleLoading}
                        data-testid="right-panel-icon-container"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default UpsertTerritoryRuleManageView;
