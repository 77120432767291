import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  IsRowSelectable,
  RowEditingStartedEvent,
  RowNode,
  SelectionChangedEvent,
  ServerSideStoreType
} from '@ag-grid-community/core';
// eslint-disable-next-line no-restricted-imports
import { useMutation } from '@apollo/client';

import Dialog from 'components/Dialog/Dialog';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import GridLoading from 'app/components/AdvancedGrid/GridLoading/GridLoading';
import {
  flashColumnsWithUpdatedValue,
  generateDataSource,
  getFormattedSheetDefinitions,
  getTerritoryFieldIds,
  upsertTerritoryOwnersHelper
} from 'app/components/AdvancedGrid/Sheets/Seller/SellerSheetUtils';
import LookupTableDetail from 'app/components/DataPanel/TablesPanel/LookupTableDetail/LookupTableDetail';

import { CELL_HEIGHT } from 'app/constants/DataTrayConstants';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useData } from 'app/contexts/dataProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { useLocalization } from 'app/contexts/localizationProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { SplitFeatures } from 'app/global/features';
import { BLOCK_SIZE, MEASURES_GRID_COLUMN_WIDTH_SMALL } from 'app/global/variables';

import {
  GetDataSheets_getDeploymentModelSpec_dataSheets,
  GetTerritoryRulesForFieldsVariables,
  LookupTypeEnum
} from 'app/graphql/generated/graphqlApolloTypes';
import { useGetLookupsForPlanningCycle } from 'app/graphql/hooks/useGetLookupsForPlanningCycle';
import { useGetTerritoryRulesForFields } from 'app/graphql/hooks/useGetTerritoryRulesForFields';
import { UPSERT_FIELD_VALUES } from 'app/graphql/mutations/upsertFieldValues';

import useCanUser from 'app/hooks/useCanUser';
import usePhase from 'app/hooks/usePhase';
import useTreatment from 'app/hooks/useTreatment';

import {
  DefaultSheetName,
  DeploymentModelPhase,
  GridFields,
  GridHeaders,
  MeasureType,
  SellerSheetGridColumnName,
  SheetType
} from 'app/models';

import block from 'utils/bem-css-modules';
import { openPreviewDialogHelper } from 'utils/helpers/sheetsPreviewUtils';
import { formatMessage } from 'utils/messages/utils';
import { UserAction } from 'utils/permissions/userActions';

import buildSellerSheetColumnDefsV2 from './SellerSheetGridColumnDefV2';
import style from './SellerSheetGridV2.module.pcss';

const b = block(style);

const SellerSheetGrid: React.FC = () => {
  const [rowEvent, setRowEvent] = useState<null | RowEditingStartedEvent>(null);
  const [isLookupPreviewOpen, setIsLookupPreviewOpen] = useState<boolean>(false);
  const {
    getSheetDefinitions,
    sheetDefinitions,
    sheetDefinitionsLoading,
    allDataSheets,
    selectedTable,
    setSelectedTable,
    setSelectedSheet
  } = useData();
  const { selectedPlanningCycle, selectedDeploymentModelId, selectedTenant } = useScope();
  const { defaultReportingCurrency } = useLocalization();
  const { selectedQuotaComponentId, selectedBattleCardId, battleCardLookupMap } = useBattleCard();
  const { selectedPillIdPlanTargets, getMeasures, measuresData } = usePlanTargets();
  const { selectedPillIdTDR } = useTerritoryDefineAndRefine();
  const { planningCycleDuration, planningCycleStartDate } = selectedPlanningCycle;
  const {
    data: lookups,
    loading: lookupsLoading,
    refetch: refetchLookups
  } = useGetLookupsForPlanningCycle({
    planningCycleId: selectedPlanningCycle.id
  });
  const deploymentModelPhase = usePhase();
  const { sortModel, refreshGrid, setRefreshGrid, setSelectedRowData } = useGrid();

  const containerRef = React.useRef(null);
  const [upsertFieldValues] = useMutation(UPSERT_FIELD_VALUES);
  const isTQM = deploymentModelPhase === DeploymentModelPhase.manage;
  const [isSellerAssignmentTwoDecimalEnabled] = useTreatment(SplitFeatures.SELLER_ASSIGNMENT_TWO_DECIMAL_PLACES);
  const canEditSellerAssignment = useCanUser(UserAction.SELLER_ASSIGNMENT_EDIT);
  const canEditSellerQuota = useCanUser(UserAction.SELLER_QUOTA_EDIT);
  const rampFieldId = measuresData?.find((measure) => {
    return measure.measureName === MeasureType.RAMP;
  })?.measureId;

  useEffect(() => {
    if (selectedPlanningCycle?.id && !isTQM) {
      getMeasures(selectedPlanningCycle.id);
    }
  }, [selectedPlanningCycle]);

  useEffect(() => {
    if (defaultSellerSheetId) {
      setSelectedSheet(
        allDataSheets.filter(
          (sheet) =>
            sheet.sheetType === SheetType.SELLER_SHEET && sheet.sheetName === DefaultSheetName.SELLER_QUOTA_SHEET
        )?.[0] as GetDataSheets_getDeploymentModelSpec_dataSheets
      );
      getSheetDefinitions(selectedDeploymentModelId, defaultSellerSheetId, isTQM);
    }
  }, []);

  const defaultSellerSheetId = allDataSheets.filter(
    (sheet) => sheet.sheetType === SheetType.SELLER_SHEET && sheet.sheetName === DefaultSheetName.SELLER_QUOTA_SHEET
  )?.[0]?.sheetId;

  const formattedSheetDefinitions = getFormattedSheetDefinitions(
    sheetDefinitions,
    planningCycleDuration,
    planningCycleStartDate
  );

  const fieldIdsLookUpTable = formattedSheetDefinitions?.reduce(
    (obj, definition) => ({ ...obj, [definition?.measureId]: definition?.measureName }),
    {}
  );

  const fieldIds = isTQM ? getTerritoryFieldIds(fieldIdsLookUpTable) : [rampFieldId];

  const initialStartRow = 1;
  const initialEndRow = BLOCK_SIZE;
  const openedRowGroups = [];

  const selectedPillId = selectedPillIdPlanTargets || selectedPillIdTDR;

  const getTerritoryRulesVariables: GetTerritoryRulesForFieldsVariables = {
    quotaComponentId: selectedQuotaComponentId,
    battlecardId: +selectedBattleCardId,
    measureId: 0,
    sorting: sortModel,
    fieldIds,
    territoryGroupId: +selectedPillId || null,
    includeRedirectExists: false
  };

  const battleCardLocalCurrencyCode = battleCardLookupMap?.[selectedBattleCardId]?.localCurrencyCode;

  const [getTerritoryRules, { data: territoryRulesData, loading: territoryRulesDataLoading, fetchMore }] =
    useGetTerritoryRulesForFields({
      ...getTerritoryRulesVariables,
      startRow: initialStartRow,
      endRow: initialEndRow
    });

  useEffect(() => {
    getTerritoryRules();
    setSelectedRowData(null);
  }, [selectedBattleCardId, selectedQuotaComponentId, selectedPillId]);

  useEffect(() => {
    if (refreshGrid) {
      getTerritoryRules();
      setRefreshGrid(false);
    }
  }, [refreshGrid]);

  // clean up when grid unmount
  useEffect(() => {
    return () => {
      setSelectedRowData(null);
    };
  }, []);

  const totalCount = territoryRulesData?.getTerritoryRules?.totalCount || 0;

  const handleOnGridReady = (gridEvent) => {
    const fetchMoreVariables = {
      quotaComponentId: +selectedQuotaComponentId,
      battlecardId: +selectedBattleCardId,
      measureId: 0,
      sorting: sortModel,
      totalCount,
      fieldIdsLookUpTable,
      fieldIds,
      includeRedirectExists: false
    };
    const dataSource = generateDataSource(fetchMore, fetchMoreVariables, isTQM, rampFieldId);
    gridEvent.api.setServerSideDatasource(dataSource);
  };

  const gridOptions = {
    headerHeight: CELL_HEIGHT,
    rowHeight: CELL_HEIGHT,
    rowModelType: 'serverSide',
    cacheBlockSize: BLOCK_SIZE,
    infiniteInitialRowCount: totalCount,
    treeData: true,
    animateRows: true,
    serverSideStoreType: 'partial' as ServerSideStoreType,
    onGridReady: handleOnGridReady,
    onRowGroupOpened(event) {
      const nodeData = event?.node?.data;
      if (event?.expanded) {
        if (!openedRowGroups.includes(nodeData.territoryId)) {
          openedRowGroups.push(nodeData.territoryId);
        }
        event?.node?.setData({ ...nodeData, isRowExpanded: true });
      } else {
        openedRowGroups.splice(
          openedRowGroups.findIndex((i) => i === nodeData.territoryId),
          1
        );
        event?.node?.setData({ ...nodeData, isRowExpanded: false });
      }
    },
    isServerSideGroupOpenByDefault(params) {
      return openedRowGroups.includes(params.rowNode.data.territoryId);
    }
  };

  const isLoading = sheetDefinitionsLoading || territoryRulesDataLoading;

  const cellClassNames = [b('rightAlignedCell'), b('highlightOnHoverCell')];
  const headerClassNames = [b('header'), b('rightAlignedHeader')];

  useEffect(() => {
    // only listen for clicks if grid is editing mode
    if (rowEvent) {
      document.addEventListener('click', clickHandler, true);
    } else {
      document.removeEventListener('click', clickHandler, true);
    }

    return () => {
      document.removeEventListener('click', clickHandler, true);
    };
  }, [rowEvent]);

  const clickHandler = useCallback((event: MouseEvent) => {
    // get grid and datatray elements and check if click is in them
    const gridElement = document.getElementsByClassName('ag-center-cols-container')[0];
    const dataTrayElement = document.getElementsByClassName('bp3-drawer')[0];
    const inGrid = event?.composedPath().includes(gridElement);
    const inDataTray = event?.composedPath().includes(dataTrayElement);

    // if user clicks in the grid they may editing fields, so stop editing when click is in datatray but not grid
    if (!inGrid && inDataTray) {
      setRowEvent((prevRowEvent) => {
        prevRowEvent?.api?.stopEditing();
        return prevRowEvent;
      });
    }
  }, []);

  const onRowEditingStarted = useCallback((event) => {
    // save copy of row event to manually call stopEditing if user clicks outside grid
    setRowEvent(event);
  }, []);

  const onRowValueChanged = useCallback(
    async (event) => {
      const gridApi = event.api;
      const data = event.data;
      const rowNode = event.node;
      const singleOwner = data?.territoryId;
      const ownersData = singleOwner ? data?.owners : rowNode?.parent?.data?.owners;
      const isEffectiveDatesValueChanged = data?.isEffectiveDatesValueChanged;
      const isOwnershipValueChanged = data?.isOwnershipValueChanged;
      const isFieldValueChanged = data?.isFieldValueChanged;

      setRowEvent(null);

      if (ownersData && (isEffectiveDatesValueChanged || isOwnershipValueChanged)) {
        const isUpsertTerritoryOwnersSuccessful = await upsertTerritoryOwnersHelper(
          event,
          isTQM ? selectedQuotaComponentId : null,
          selectedTenant?.id
        );

        if (isUpsertTerritoryOwnersSuccessful) {
          const flashColumns = [];

          if (isEffectiveDatesValueChanged) {
            flashColumns.push(isTQM ? SellerSheetGridColumnName.SELLER_EFFECTIVE_DATES : GridHeaders.EFFECTIVE_DATE);
          }

          if (isOwnershipValueChanged) {
            flashColumns.push(isTQM ? SellerSheetGridColumnName.ASSIGNMENT_PERCENTAGE : GridHeaders.OWNERSHIP);
          }

          gridApi.flashCells({
            rowNodes: [rowNode],
            columns: flashColumns
          });
        }
      }

      const territoryVariables = {
        battlecardId: +selectedBattleCardId,
        quotaComponentId: selectedQuotaComponentId,
        territoryGroupId: data?.territoryGroupId || rowNode?.parent?.data?.territoryGroupId,
        territoryId: data?.territoryId || rowNode?.parent?.data?.territoryId,
        ruleId: data?.ruleId,
        territoryOwnerId: data?.owners?.[0]?.ownerId,
        isTQM
      };

      // start upsert for seller sheets values
      const input = [];
      const flashCellFields = [];
      data?.fields?.forEach((field) => {
        const { fieldId, fieldValue, isUpsertValue, fieldName } = field;
        if (isUpsertValue) {
          input.push({ ...territoryVariables, fieldId, fieldValue: fieldValue.toString() });
          flashCellFields.push({ fieldId, fieldName });
        }
      });

      if (isFieldValueChanged && input.length) {
        const result = await upsertFieldValues({
          variables: {
            input: {
              territoryFieldValues: input
            }
          }
        });

        //upsert successfully
        if (result?.data) {
          gridApi.flashCells({
            rowNodes: [rowNode],
            columns: flashCellFields.map((field) => field.fieldName)
          });
          // reset upsert cell value condition
          data?.fields?.forEach((field) => {
            if (field.isUpsertValue) field.isUpsertValue = false;
          });
        }
      }

      flashColumnsWithUpdatedValue(event);
    },
    [selectedBattleCardId, selectedQuotaComponentId]
  );

  const isRowSelectable = useMemo<IsRowSelectable>(() => {
    return (rowNode: RowNode) => {
      return rowNode.data.owners?.length === 1;
    };
  }, []);

  const handleSelectionChanged = (event: SelectionChangedEvent) => {
    const selectedNodes = event.api.getSelectedNodes();
    setSelectedRowData(selectedNodes);
  };

  const handleOpenPreviewDialog = (): void => {
    openPreviewDialogHelper(
      lookups,
      selectedTable,
      setSelectedTable,
      setIsLookupPreviewOpen,
      selectedTenant.id,
      selectedPlanningCycle.id,
      LookupTypeEnum.Ramp
    );
  };

  if (lookupsLoading) {
    return (
      <div data-testid="lookups-loading" ref={containerRef}>
        <GridLoading gridHeight={containerRef?.current?.offsetHeight} gridWidth={containerRef?.current?.offsetWidth} />
      </div>
    );
  }

  const shouldShowRowsTQM = !!(sheetDefinitionsLoading || sheetDefinitions?.length);
  const shouldShowRowsTQP = totalCount > 0 || territoryRulesDataLoading;
  const shouldShowRows = isTQM ? shouldShowRowsTQM : shouldShowRowsTQP;

  const handleLookupRowUpserted = () => {
    refetchLookups();
    setRefreshGrid(true);
  };

  return (
    <div className={b('gridWrapper')} ref={containerRef}>
      {shouldShowRows ? (
        <div className={b('advancedGridWrapper')}>
          <AdvancedGrid
            data-testid="seller-sheet-grid"
            gridOptions={gridOptions}
            columnDefs={buildSellerSheetColumnDefsV2({
              sheetDefinitions: formattedSheetDefinitions,
              currency: battleCardLocalCurrencyCode || defaultReportingCurrency,
              headerClassNames,
              cellClassNames,
              lookups,
              fieldIdsLookUpTable,
              selectedQuotaComponentId,
              previewDialogOpener: handleOpenPreviewDialog,
              isTQM,
              canEditSellerAssignment,
              canEditSellerQuota,
              fieldId: rampFieldId,
              isSellerAssignmentTwoDecimalEnabled
            })}
            gridWidth={containerRef?.current?.offsetWidth}
            gridHeight={containerRef?.current?.offsetHeight}
            showGridLoading={isLoading}
            editType={'fullRow'}
            onRowValueChanged={onRowValueChanged}
            onRowEditingStarted={onRowEditingStarted}
            suppressRowClickSelection={true}
            suppressCellSelection
            rowSelection={'multiple'}
            isRowSelectable={isRowSelectable}
            onSelectionChanged={handleSelectionChanged}
            autoGroupColumnDef={{
              headerName: GridHeaders.TERRITORY_ID,
              headerClass: headerClassNames[0],
              field: GridFields.TERRITORY_ID,
              minWidth: MEASURES_GRID_COLUMN_WIDTH_SMALL,
              flex: 1,
              resizable: true,
              pinned: 'left',
              cellRendererParams: {
                checkbox: true,
                suppressPadding: true
              }
            }}
            isServerSideGroup={(dataItem) => {
              return dataItem.owners?.length > 1;
            }}
            getServerSideGroupKey={(dataItem) => {
              return dataItem.ruleId + dataItem.owners[0]?.employeeId;
            }}
            suppressRowTransform
          />
        </div>
      ) : (
        <div className={b('gridOverlayContainer')} data-testid="no-territories-overlay">
          <div className={b('gridOverlayText')}>{formatMessage('EMPTY_GRID')}</div>
        </div>
      )}
      <Dialog
        title={formatMessage('RAMP_SCHEDULES')}
        isOpen={isLookupPreviewOpen}
        confirmButtonText={formatMessage('CLOSE')}
        showCancel={false}
        style={{ minWidth: '1200px', width: '60%' }}
        onSubmit={() => setIsLookupPreviewOpen(false)}
      >
        <span className={b('lookupPreviewContents')}>
          <LookupTableDetail showHeader={false} onRowUpserted={handleLookupRowUpserted} />
        </span>
      </Dialog>
    </div>
  );
};
export default SellerSheetGrid;
