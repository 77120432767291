import React from 'react';

// GENERATE: PROVIDER IMPORTS
import { AssistantProvider } from 'app/contexts/assistantProvider';
import { BattleCardProvider } from 'app/contexts/battleCardProvider';
import { CoinsortProvider } from 'app/contexts/coinsortProvider';
import { CommandCenterProvider } from 'app/contexts/commandCenterProvider';
import { CommentProvider } from 'app/contexts/commentProvider';
import { ContributorProvider } from 'app/contexts/contributorProvider';
import { DataProvider } from 'app/contexts/dataProvider';
import { DataTrayProvider } from 'app/contexts/dataTrayProvider';
import { DedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { GridProvider } from 'app/contexts/gridProvider';
import { JobsProvider } from 'app/contexts/jobsProvider';
import { NativeMcrLoader } from 'app/contexts/MapContextRedistributor/NativeMcrLoader';
import { MapVariantProvider } from 'app/contexts/mapVariantProvider';
import { BCCanvasPermissionsLoader } from 'app/contexts/PermissionsContextRedistributor/BCCanvasPermissionsLoader';
import { PlanTargetsProvider } from 'app/contexts/planTargetsProvider';
import { QuotaApprovalWorkflowProvider } from 'app/contexts/quotaApprovalWorkflowProvider';
import { RebalancingProvider } from 'app/contexts/rebalancingProvider';
import { TerritoryDefineAndRefineProvider } from 'app/contexts/territoryDefineAndRefineProvider';
import { TerritoryOptimizationProvider } from 'app/contexts/territoryOptimizationProvider';

import { FileUploadProvider } from 'app/core/fileUpload/fileUploadProvider';

const NativeProviders: React.FC = ({ children }) => {
  return (
    <BattleCardProvider>
      <JobsProvider>
        <CoinsortProvider>
          <ContributorProvider>
            <PlanTargetsProvider>
              <DataTrayProvider>
                <GridProvider>
                  <TerritoryDefineAndRefineProvider>
                    <FileUploadProvider>
                      <QuotaApprovalWorkflowProvider>
                        <BCCanvasPermissionsLoader>
                          <CommandCenterProvider>
                            <AssistantProvider>
                              <TerritoryOptimizationProvider>
                                <MapVariantProvider isEmbedded={false}>
                                  <NativeMcrLoader>
                                    <DedicatedMapProvider>
                                      <DataProvider>
                                        <RebalancingProvider>
                                          <CommentProvider>{children}</CommentProvider>
                                        </RebalancingProvider>
                                      </DataProvider>
                                    </DedicatedMapProvider>
                                  </NativeMcrLoader>
                                </MapVariantProvider>
                              </TerritoryOptimizationProvider>
                            </AssistantProvider>
                          </CommandCenterProvider>
                        </BCCanvasPermissionsLoader>
                      </QuotaApprovalWorkflowProvider>
                    </FileUploadProvider>
                  </TerritoryDefineAndRefineProvider>
                </GridProvider>
              </DataTrayProvider>
            </PlanTargetsProvider>
          </ContributorProvider>
        </CoinsortProvider>
      </JobsProvider>
    </BattleCardProvider>
  );
};

export default NativeProviders;
