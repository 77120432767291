import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { UpsertFieldValues, UpsertFieldValuesVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const UPSERT_FIELD_VALUES = gql`
  mutation UpsertFieldValues($input: UpsertFieldValuesInput!) {
    upsertFieldValues(input: $input) {
      territoryFieldValues {
        battlecardId
        fieldId
        fieldValue
        quotaComponentId
        territoryGroupId
        territoryId
        adate
        ruleId
        customHierarchyId_1
        customHierarchyId_2
        customerAccountHierarchyId
      }
      asyncTaskJobId
    }
  }
`;

export const useUpsertFieldValues = createUseMutation<UpsertFieldValues, UpsertFieldValuesVariables>(
  UPSERT_FIELD_VALUES
);
