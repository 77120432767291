import gql from 'graphql-tag';

import { createUseLazyQuery, createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import {
  GetAllTerritoryIdentities,
  GetAllTerritoryIdentitiesVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const GET_ALL_TERRITORY_IDENTITIES = gql`
  query GetAllTerritoryIdentities($deploymentModelId: Int!) {
    getAllTerritoryIdentities(input: { deploymentModelId: $deploymentModelId }) {
      totalCount
      territoryIdentities {
        battlecardName
        ruleId
        territoryId
        territoryName
        territoryGroupName
      }
    }
  }
`;

export const useGetAllTerritoryIdentities = createUseQuery<
  GetAllTerritoryIdentities,
  GetAllTerritoryIdentitiesVariables
>(GET_ALL_TERRITORY_IDENTITIES);
export const useGetAllTerritoryIdentitiesLazy = createUseLazyQuery<
  GetAllTerritoryIdentities,
  GetAllTerritoryIdentitiesVariables
>(GET_ALL_TERRITORY_IDENTITIES);
