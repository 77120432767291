import { useCallback, useEffect, useRef, useState } from 'react';

import { useGrid } from 'app/contexts/gridProvider';
import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { RequestDeleteTerritoryRulesInput } from 'app/graphql/generated/graphqlApolloTypes';
import { useRequestDeleteTerritoryRules } from 'app/graphql/mutations/requestDeleteTerritoryRules';

import useShowToast from 'app/hooks/useShowToast';

import { getRuleDeleteErrorToastText } from 'utils/helpers/territoryToastHelpers';

interface BulkDeleteRulesForMapInput {
  onCompleted: () => void;
}

interface BulkDeleteRulesForMapOutput {
  isLoading: boolean;
  startRuleDelete: (ruleIds: number[]) => void;
}

export const useBulkDeleteRulesForMap = ({ onCompleted }: BulkDeleteRulesForMapInput): BulkDeleteRulesForMapOutput => {
  const { bulkDeleteTerritoryJobKey, setBulkDeleteTerritoryJobKey } = useGrid();
  const showToast = useShowToast();
  const [isLoading, setIsLoading] = useState(false);

  const [requestDeleteTerritoryRules] = useRequestDeleteTerritoryRules({
    onCompleted: (data) => {
      setBulkDeleteTerritoryJobKey(data.requestDeleteTerritoryRules.jobKey);
    },
    onError: (error, options) => {
      setIsLoading(false);
      const input: RequestDeleteTerritoryRulesInput = options.variables?.input;
      const errorToastText = getRuleDeleteErrorToastText(error.knownError, input?.ruleIds.inclusions.length);
      showToast(errorToastText, 'danger');
    }
  });

  const { selectedDeploymentModelId } = useMapContextRedistributor();
  const startRuleDelete = useCallback(
    (ruleIds: number[]) => {
      setIsLoading(true);
      requestDeleteTerritoryRules({
        variables: {
          input: {
            deploymentModelId: selectedDeploymentModelId,
            ruleIds: { inclusions: ruleIds, exclusions: [] }
          }
        }
      });
    },
    [selectedDeploymentModelId, requestDeleteTerritoryRules]
  );

  useOnJobKeyCompleted(bulkDeleteTerritoryJobKey, () => {
    setIsLoading(false);
    onCompleted();
  });

  return {
    isLoading,
    startRuleDelete
  };
};

const useOnJobKeyCompleted = (jobKey: string | null, onCompleted: () => void) => {
  const subscribedJobKey = useRef(jobKey);
  useEffect(() => {
    if (subscribedJobKey.current === jobKey) return;
    subscribedJobKey.current = jobKey;
    if (!subscribedJobKey.current) onCompleted();
  }, [jobKey]);
};
