// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Pm4aK .bp3-dialog {\n      border-radius: 12px;\n    }\n    .Pm4aK .vds-callout-v2 {\n      margin: var(--l) 0;\n    }\n  ._2pQKm {\n    height: 200px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  .mMQnf {\n    display: flex;\n    flex-direction: column;\n  }\n  .D0voj {\n    margin-top: var(--m);\n  }\n  .twFLP {\n    margin-top: var(--l);\n  }\n  .jvsPD {\n    display: flex;\n  }\n  .Z2EQM {\n      margin-bottom: var(--l);\n    }\n  .Vfpnj {\n    display: flex;\n    margin-top: var(--s);\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    align-items: center;\n  }\n  .AFTqu {\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    margin-bottom: var(--xs);\n    line-height: var(--m);\n    display: inline-flex;\n    align-items: center;\n  }\n  .TP0BJ {\n      margin-bottom: var(--m);\n    }\n  .p9wSl {\n    padding-left: 12px;\n  }\n  .rn9Et {\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 20px;\n    margin: var(--m) 0;\n  }\n  .WkpEm {\n    width: 100%;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    margin-bottom: var(--xs);\n    line-height: var(--m);\n    margin-top: var(--l);\n  }\n  .LPFIl {\n    font-weight: 400;\n    font-size: 12px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MapVisualizationSettingsDialog_dialog": "Pm4aK",
	"MapVisualizationSettingsDialog_spinnerContainer": "_2pQKm",
	"MapVisualizationSettingsDialog_dialogContainer": "mMQnf",
	"MapVisualizationSettingsDialog_formContainer": "D0voj",
	"MapVisualizationSettingsDialog_radioGroupContainer": "twFLP",
	"MapVisualizationSettingsDialog_radio": "jvsPD",
	"MapVisualizationSettingsDialog_radio__isAccount": "Z2EQM",
	"MapVisualizationSettingsDialog_radioDescription": "Vfpnj",
	"MapVisualizationSettingsDialog_formLabel": "AFTqu",
	"MapVisualizationSettingsDialog_formLabel__isRadioLabel": "TP0BJ",
	"MapVisualizationSettingsDialog_radioGroup": "p9wSl",
	"MapVisualizationSettingsDialog_dialogBodyHeader": "rn9Et",
	"MapVisualizationSettingsDialog_label": "WkpEm",
	"MapVisualizationSettingsDialog_complexRulesRadioLabel": "LPFIl"
};
export default ___CSS_LOADER_EXPORT___;
