import React from 'react';

import { ChevronLeft } from '@carbon/icons-react';

import TextButton from 'components/Buttons/TextButton/TextButton';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './UpsertTerritoryRuleViewHeader.module.pcss';

const b = block(style);

interface UpsertTerritoryRuleViewHeaderProps {
  onBackButtonClick: () => void;
  territoryId?: string;
  canEdit?: boolean; // if isEditMode is true, the modal's fields will be initially populated using the provided data
}

const UpsertTerritoryRuleViewHeader: React.FC<UpsertTerritoryRuleViewHeaderProps> = ({
  onBackButtonClick,
  territoryId,
  canEdit = false
}) => {
  return (
    <nav data-testid="navigation-header" className={b('navigationHeader')}>
      <div className={b('navigationHeaderItemsContainer')}>
        <TextButton
          intent="primary"
          icon={<ChevronLeft size={20} className={b('backButtonIcon')} />}
          text={formatMessage('BACK')}
          onClick={onBackButtonClick}
          type="button"
          testId="back-button"
          minimal
        />
        <span data-testid="new-territory-text" className={b('text', { newTerritoryText: true })}>
          {canEdit ? territoryId : formatMessage('NEW_TERRITORY')}
        </span>
        <span data-testid="forward-slash-text" className={b('text', { slashText: true })}>
          {formatMessage('FORWARD_SLASH')}
        </span>
        <span data-testid="is-editing-text" className={b('text')}>
          {canEdit ? formatMessage('EDIT') : formatMessage('CREATE')}
        </span>
      </div>
    </nav>
  );
};

export default UpsertTerritoryRuleViewHeader;
