import React, { useState } from 'react';

import { SettingsAdjust } from '@carbon/icons-react';
// eslint-disable-next-line no-restricted-imports
import { Classes } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';

import { useDynamicMapHierarchyCopy } from 'app/components/TerritoryMap/hooks/useDynamicMapHierarchyCopy';
import { useIsMapCapable } from 'app/components/TerritoryMap/hooks/useMapCapability';
import MapVisualizationSettingsDialog from 'app/components/TerritoryMap/MapVisualizationSettingsDialog/MapVisualizationSettingsDialog';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import block from 'utils/bem-css-modules';
import { MapCapability } from 'utils/maps/mapCapabilityRegistry';

import style from './MapGridTitleV2.module.pcss';

const b = block(style);

const MapGridTitleV2: React.FC = () => {
  const { isMapVisualizationSettingsLoading } = useMapContextRedistributor();
  const canViewVisualizationSettings = useIsMapCapable(MapCapability.VIEW_VISUALIZATION_SETTINGS);

  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);

  const { mapGridTitleText } = useDynamicMapHierarchyCopy();

  return (
    <>
      {isMapVisualizationSettingsLoading ? (
        <div className={b('loadingSkeleton')} data-testid="loading-skeleton">
          <div className={Classes.SKELETON} />
        </div>
      ) : (
        <div className={b('titleWrapper')}>
          <div className={b('titleTextWrapper')}>
            <span className={b('title')} data-testid="map-grid-title">
              {mapGridTitleText}
            </span>
          </div>
          {canViewVisualizationSettings && (
            <div>
              <IconButton
                type="button"
                icon={<SettingsAdjust />}
                testId="map-settings-button"
                onClick={() => setIsSettingsDialogOpen(true)}
              />
            </div>
          )}
          {isSettingsDialogOpen && (
            <MapVisualizationSettingsDialog
              onClose={() => setIsSettingsDialogOpen(false)}
              onComplete={() => setIsSettingsDialogOpen(false)}
              isLastStep={true}
            />
          )}
        </div>
      )}
    </>
  );
};

export default MapGridTitleV2;
