import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Classes, Menu, Text } from '@blueprintjs/core';
import { ChevronDown } from '@carbon/icons-react';
import { datadogRum } from '@datadog/browser-rum';
import Avatar from 'react-avatar';
import { Link, useHistory } from 'react-router-dom';

import { MenuItem } from 'components/menu/MenuItem';
import Popover from 'components/Popover/Popover';

import { RoutePaths } from 'app/containers/App/Router/routePaths';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useComments } from 'app/contexts/commentProvider';
import { useContributor } from 'app/contexts/contributorProvider';
import { useDataTray } from 'app/contexts/dataTrayProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useQuotaApprovalWorkflow } from 'app/contexts/quotaApprovalWorkflowProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';
import { useUserMenu } from 'app/contexts/userMenuProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import block from 'utils/bem-css-modules';
import { clearAuthValuesFromStorage } from 'utils/helpers/routingHelpers';
import { getFullName } from 'utils/helpers/userProfileHelper';
import { formatMessage } from 'utils/messages/utils';

import style from './UserMenu.module.pcss';

const b = block(style);

const modifiers = {
  arrow: {
    enabled: false
  }
};

const UserMenu: React.FC = () => {
  const { logout: auth0Logout } = useAuth0();
  const { resetValues: resetBattleCardProviderValues } = useBattleCard();
  const { resetValues: resetContributorProviderValues } = useContributor();
  const { resetValues: resetDataTrayProviderValues } = useDataTray();
  const { resetValues: resetGridProviderValues } = useGrid();
  const { resetValues: resetPlanTargetsProviderValues } = usePlanTargets();
  const { resetValues: resetQuotaApprovalWorkflowProviderValues } = useQuotaApprovalWorkflow();
  const { resetValues: resetScopeProviderValues, selectedTenant } = useScope();
  const { resetValues: resetTerritoryDefineAndRefineProviderValues } = useTerritoryDefineAndRefine();
  const { resetValues: resetCommentProviderValues } = useComments();
  const { userProfile, resetValues: resetUserProviderValues } = useUser();
  const history = useHistory();
  const {
    displayExitImpersonateMenuItem,
    displayImpersonateMenuItem,
    onExitImpersonationMenuItemClick,
    onImpersonationMenuItemClick
  } = useUserMenu();

  const handleLogout = () => {
    auth0Logout({ returnTo: window.location.origin });
    datadogRum.clearUser();
    clearAuthValuesFromStorage();
    history.push(RoutePaths.LOGIN);
    resetBattleCardProviderValues();
    resetContributorProviderValues();
    resetDataTrayProviderValues();
    resetGridProviderValues();
    resetPlanTargetsProviderValues();
    resetQuotaApprovalWorkflowProviderValues();
    resetScopeProviderValues();
    resetTerritoryDefineAndRefineProviderValues();
    resetUserProviderValues();
    resetCommentProviderValues();
  };

  const userMenuContent = (
    <Menu data-testid="user-menu-items">
      {selectedTenant && userProfile?.tenant && (
        <Link
          className={Classes.MENU_ITEM}
          to={`/${userProfile.tenant.slug}${RoutePaths.USER_PROFILE}/${userProfile.subjectId}`}
          data-testid="user-profile-item"
        >
          <Text ellipsize={true}>{formatMessage('USER_PROFILE')}</Text>
        </Link>
      )}

      {displayImpersonateMenuItem && (
        <MenuItem
          text={formatMessage('IMPERSONATE_USER')}
          onClick={onImpersonationMenuItemClick}
          data-testid="impersonation-menu-item"
        />
      )}

      {displayExitImpersonateMenuItem && (
        <MenuItem
          text={formatMessage('EXIT_IMPERSONATION')}
          onClick={onExitImpersonationMenuItemClick}
          data-testid="exit-impersonate-menu-item"
        />
      )}
      <MenuItem text={formatMessage('LOGOUT')} onClick={() => handleLogout()} data-testid="log-out-item" />
    </Menu>
  );

  return (
    <Popover content={userMenuContent} placement={'bottom-end'} modifiers={modifiers}>
      <div className={b()} data-testid="user-menu">
        <div className={b('profilePictureContainer')}>
          <Avatar size={'30'} name={getFullName(userProfile)} round={true} className={b('profilePicture')} />
        </div>
        <span className={b('userName')}>{getFullName(userProfile)}</span>
        <ChevronDown size={24} />
      </div>
    </Popover>
  );
};

export default UserMenu;
