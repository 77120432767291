// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jBMk5 {\n    width: 100%;\n    height: var(--grid-header-height);\n    display: flex;\n    flex-shrink: 0;\n    justify-content: space-between;\n    cursor: auto;\n    background-color: rgb(var(--color-light-gray-5));\n    border-bottom: var(--grey-border);\n  }\n  ._9QDEq {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n  }\n  ._61r6K {\n    font-weight: 400;\n  }\n  .R9iRo {\n      margin-left: 5px;\n    }\n  .XBZSR {\n      margin: 0 10px;\n    }\n  .BiO9Y {\n    margin-right: 5px !important;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UpsertTerritoryRuleViewHeader_navigationHeader": "jBMk5",
	"UpsertTerritoryRuleViewHeader_navigationHeaderItemsContainer": "_9QDEq",
	"UpsertTerritoryRuleViewHeader_text": "_61r6K",
	"UpsertTerritoryRuleViewHeader_text__newTerritoryText": "R9iRo",
	"UpsertTerritoryRuleViewHeader_text__slashText": "XBZSR",
	"UpsertTerritoryRuleViewHeader_backButtonIcon": "BiO9Y"
};
export default ___CSS_LOADER_EXPORT___;
