// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YxqPV {\n    margin: var(--s) 0;\n  }\n  .sClFq {\n    overflow-y: auto;\n  }\n  .hKQGh {\n    margin-top: 10px;\n    background-color: rgb(var(--color-midnight-blue-7));\n    border-radius: var(--xs);\n    padding: var(--s);\n  }\n  .qYO13 {\n    display: flex;\n    flex-direction: column;\n  }\n  .UUxkU {\n    display: flex;\n    font-size: 12px;\n    margin-top: var(--s);\n  }\n  .OisUQ {\n    width: 150px;\n    flex: 1;\n    margin: 0 var(--s);\n  }\n  ._9h3XI {\n    display: flex;\n    background-color: white;\n    border: 1px solid rgb(var(--color-midnight-blue-6));\n    border-radius: var(--border-rad);\n    align-items: baseline;\n    width: 100%;\n    margin-top: var(--xs);\n    font-size: 14px;\n  }\n  ._4xPWh {\n    display: flex;\n    width: 100%;\n    margin: 0 0 var(--s) var(--s);\n  }\n  .\\+TGaj {\n    flex: 1;\n    margin: 0 var(--s);\n  }\n  .rclBV {\n    min-height: 44px;\n    flex: 1;\n    margin: 0 var(--s);\n    padding: 6px 0 2px 0;\n  }\n  ._85bRv {\n    min-height: 0;\n    color: rgb(var(--color-red-3));\n    margin-top: 6px;\n  }\n  .Asp72 {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 600px;\n  }\n  .sP1DI {\n    color: rgb(var(--color-field-green-3));\n  }\n  .SYK8P {\n    margin-top: var(--s);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ResolveDuplicateTerritoriesDialog_dialogMessage": "YxqPV",
	"ResolveDuplicateTerritoriesDialog_formSection": "sClFq",
	"ResolveDuplicateTerritoriesDialog_duplicateTerritoryEditGroup": "hKQGh",
	"ResolveDuplicateTerritoriesDialog_territoryIdField": "qYO13",
	"ResolveDuplicateTerritoriesDialog_header": "UUxkU",
	"ResolveDuplicateTerritoriesDialog_headerItem": "OisUQ",
	"ResolveDuplicateTerritoriesDialog_territoryIdentity": "_9h3XI",
	"ResolveDuplicateTerritoriesDialog_validation": "_4xPWh",
	"ResolveDuplicateTerritoriesDialog_textDisplay": "+TGaj",
	"ResolveDuplicateTerritoriesDialog_textInput": "rclBV",
	"ResolveDuplicateTerritoriesDialog_validationMessage": "_85bRv",
	"ResolveDuplicateTerritoriesDialog_completePage": "Asp72",
	"ResolveDuplicateTerritoriesDialog_checkmark": "sP1DI",
	"ResolveDuplicateTerritoriesDialog_errorCallout": "SYK8P"
};
export default ___CSS_LOADER_EXPORT___;
