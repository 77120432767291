import { useCallback } from 'react';

import { useOnMapWorkerMessage } from 'app/contexts/mapWorkerContext';

import { formatMessage } from 'utils/messages/utils';

import useShowToast from './useShowToast';

export const useSelectionNotificationToasts = (): void => {
  const showToast = useShowToast();

  const onSelectionNotification = useCallback((message) => {
    switch (message.notificationKind) {
      case 'empty-rule':
        showToast(
          formatMessage('NO_TERRITORY_RULES', { territories: message.details.territoryNames.join(', ') }),
          'primary',
          'top-right'
        );
        break;
    }
  }, []);
  useOnMapWorkerMessage(onSelectionNotification);
};
