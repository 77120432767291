import {
  MutationTuple,
  // eslint-disable-next-line no-restricted-imports
  useMutation
} from '@apollo/client';

import {
  DeleteSymonPipeConfigurations,
  DeleteSymonPipeConfigurationsVariables
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { DELETE_SYMON_PIPE_CONFIGURATIONS } from 'app/graphql/mutations/deleteSymonPipeConfigurations';

import useShowToast from 'app/hooks/useShowToast';

import { formatMessage } from 'utils/messages/utils';

export const useDeleteSymonPipeConfigurations = (): MutationTuple<
  DeleteSymonPipeConfigurations,
  DeleteSymonPipeConfigurationsVariables
> => {
  const showToast = useShowToast();
  return useMutation<DeleteSymonPipeConfigurations, DeleteSymonPipeConfigurationsVariables>(
    DELETE_SYMON_PIPE_CONFIGURATIONS,
    {
      onCompleted(data) {
        if (data?.deleteSymonPipeConfigurations) {
          showToast(formatMessage('DELETE_SYMON_PIPE_CONFIGURATION_SUCCESS'), 'success');
        }
      },
      onError({ graphQLErrors, networkError }) {
        handleError(graphQLErrors, networkError);
        showToast(formatMessage('DELETE_SYMON_PIPE_CONFIGURATION_ERROR'), 'danger');
      }
    }
  );
};
