import React from 'react';

import TerritoryQuotaDrillIn from 'app/components/AdvancedGrid/Sheets/Territory/TerritoryQuotaGrid/TerritoryQuotaDrillInGrid/TerritoryQuotaDrillInGrid';
import TerritoryQuotaGrid from 'app/components/AdvancedGrid/Sheets/Territory/TerritoryQuotaGrid/TerritoryQuotaGrid';
import UpsertTerritoryRuleManageView from 'app/components/AdvancedGrid/Sheets/Territory/TerritoryQuotaGrid/UpsertTerritoryRuleManageView/UpsertTerritoryRuleManageView';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

import { HierarchyType } from 'app/models';

const TerritoryQuotaGridContainer: React.FC = () => {
  const { selectedBattleCardId, quotaBreakdownHierarchies } = useBattleCard();
  const { selectedPillIdPlanTargets } = usePlanTargets();
  const { selectedPillIdTDR } = useTerritoryDefineAndRefine();
  const { showManageTerritoryQuotaDrillIn, showUpsertTerritoryRuleView } = useGrid();

  const [isAccountMoveInTerritoryQuotaGridEnabled] = useTreatment(
    SplitFeatures.AMWQ_ACCOUNT_QUOTA_IN_TERRITORY_QUOTA_GRID
  );

  const [isAccountMoveWithQuotaTwoHierarchiesEnabled] = useTreatment(SplitFeatures.AMWQ_TWO_HIERARCHIES);
  const isAccountMoveWithQuotaMoveSettingEnabled =
    quotaBreakdownHierarchies?.length >= 1 &&
    quotaBreakdownHierarchies.some((hierarchy) => hierarchy.hierarchyType === HierarchyType.CustomerAccountHierarchy);

  const selectedPillId = selectedPillIdPlanTargets || selectedPillIdTDR;

  if (!selectedBattleCardId && !selectedPillId) {
    return null;
  } else if (showUpsertTerritoryRuleView) {
    return <UpsertTerritoryRuleManageView data-testid="upsert-territory-rule-manage-view" />;
  } else if (
    showManageTerritoryQuotaDrillIn &&
    (isAccountMoveInTerritoryQuotaGridEnabled || isAccountMoveWithQuotaTwoHierarchiesEnabled) &&
    isAccountMoveWithQuotaMoveSettingEnabled
  ) {
    return <TerritoryQuotaDrillIn data-testid="territory-quota-drill-in-grid" />;
  }

  return <TerritoryQuotaGrid data-testid="territory-quota-grid" />;
};

export default TerritoryQuotaGridContainer;
