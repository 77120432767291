import React from 'react';

import dayjs from 'dayjs';

import { MenuItem } from 'components/menu/MenuItem';

import DataTrayAddButton from 'app/components/DataTray/TerritoryGrid/DataTrayAddButton/DataTrayAddButton';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useGrid } from 'app/contexts/gridProvider';

import { useFileUpload } from 'app/core/fileUpload/fileUploadProvider';

import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

import { BattlecardType, FileType } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

const TerritoryQuotaGridActionButtons: React.FC = () => {
  const { setShowUpsertTerritoryRuleView, showManageTerritoryQuotaDrillIn, selectedQuotaDrillInTerritory } = useGrid();
  const { showUploadSequenceByFileType, setShowUploadSequenceByFileType } = useFileUpload();
  const { selectedBattleCardId, battleCardLookupMap } = useBattleCard();
  const isRootBattleCard = !battleCardLookupMap?.[selectedBattleCardId]?.battlecardParentId;
  const isRollUpBattleCard = battleCardLookupMap?.[selectedBattleCardId]?.battlecardType === BattlecardType.Rollup;
  const [isAccountMoveInTerritoryQuotaGridEnabled] = useTreatment(
    SplitFeatures.AMWQ_ACCOUNT_QUOTA_IN_TERRITORY_QUOTA_GRID
  );

  const [isCreationOfTerritoriesInYearEnabled] = useTreatment(SplitFeatures.CREATION_OF_TERRITORIES_IN_YEAR);
  return (
    <>
      {showManageTerritoryQuotaDrillIn && isAccountMoveInTerritoryQuotaGridEnabled && (
        <div data-testid="territory-quota-drill-in-header">
          {formatMessage('TERRITORY_EFFECTIVE_DATE_FOR_TERRITORY_QUOTA_DRILL_IN', {
            effectiveDate: dayjs(selectedQuotaDrillInTerritory?.effectiveDate).format('MM/DD/YYYY'),
            endDate: dayjs(selectedQuotaDrillInTerritory?.endDate).format('MM/DD/YYYY')
          })}
        </div>
      )}
      {!showManageTerritoryQuotaDrillIn && isCreationOfTerritoriesInYearEnabled && (
        <DataTrayAddButton
          data-testid="data-tray-add-button"
          menuItems={[
            <MenuItem
              text={formatMessage('ADD_TERRITORY_ROW')}
              key="addTerritoryRow"
              onClick={() => setShowUpsertTerritoryRuleView(true)}
              data-testid="add-territory-row-menu-item"
            />,
            <MenuItem
              text={formatMessage('LOAD_TERRITORIES')}
              key="loadTerritories"
              onClick={() =>
                setShowUploadSequenceByFileType({
                  ...showUploadSequenceByFileType,
                  [FileType.TERRITORY_RULE_MANAGE]: true
                })
              }
              data-testid="load-territories-menu-item"
            />
          ]}
          disabled={!selectedBattleCardId || isRootBattleCard || isRollUpBattleCard}
        />
      )}
    </>
  );
};

export default TerritoryQuotaGridActionButtons;
