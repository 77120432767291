import { GetAllTerritoryIdentities_getAllTerritoryIdentities_territoryIdentities as TerritoryIdentity } from 'app/graphql/generated/graphqlApolloTypes';
import { useGetAllTerritoryIdentitiesLazy } from 'app/graphql/queries/getAllTerritoryIdentities';

const checkDuplicatedTerritory = (territories: TerritoryIdentity[]) => {
  // Create a map to count occurrences of each territoryId
  const territoryCountMap = {};

  territories.forEach((territory) => {
    if (territoryCountMap[territory.territoryId]) {
      territoryCountMap[territory.territoryId] += 1;
    } else {
      territoryCountMap[territory.territoryId] = 1;
    }
  });

  return Object.keys(territoryCountMap).filter((territoryId) => territoryCountMap[territoryId] > 1);
};

export const useGetDuplicateTerritories = (): {
  getTerritoryIdentities: (deploymentModelId: number) => void;
  territoriesIdentities: TerritoryIdentity[];
  duplicateTerritoryIds: string[];
} => {
  const [getAllTerritoryIdentities, { data }] = useGetAllTerritoryIdentitiesLazy({
    fetchPolicy: 'network-only'
  });

  const getTerritoryIdentities = (deploymentModelId: number) => {
    getAllTerritoryIdentities({
      variables: {
        deploymentModelId
      }
    });
  };

  if (!data?.getAllTerritoryIdentities?.territoryIdentities) {
    return {
      getTerritoryIdentities,
      territoriesIdentities: [],
      duplicateTerritoryIds: []
    };
  }

  return {
    getTerritoryIdentities,
    territoriesIdentities: data.getAllTerritoryIdentities.territoryIdentities,
    duplicateTerritoryIds: checkDuplicatedTerritory(data.getAllTerritoryIdentities.territoryIdentities)
  };
};
