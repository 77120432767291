import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { GridApi } from '@ag-grid-community/core';
import debounce from 'lodash.debounce';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';

import { debounceDelay } from 'app/constants/DebounceConstants';

import { MENU_INFINITE_SCROLL_ITEM_HEIGHT } from 'app/global/variables';

import {
  GetTerritoryRulesForSearch_getTerritoryRules_territoryRules,
  GetTerritoryRulesForSearchVariables,
  SortableGetTerritoryRulesGridCols,
  SortDirection
} from 'app/graphql/generated/graphqlApolloTypes';
import { useGetTerritoryRulesForSearchLazy } from 'app/graphql/queries/getTerritoryRulesForSearch';

import useShowToast from 'app/hooks/useShowToast';

import { HierarchyItem } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './TerritorySearch.module.pcss';
import { TerritorySearchColumnDefs } from './TerritorySearchColumnDefs';

const b = block(style);

interface TerritorySearchProps {
  battlecardId: number;
  quotaComponentId: number;
  searchString: string;
  selectedNodes: HierarchyItem[];
  onSelect: (event) => void;
}

const TerritorySearch: React.FC<TerritorySearchProps> = ({
  quotaComponentId,
  battlecardId,
  searchString,
  selectedNodes,
  onSelect
}: TerritorySearchProps) => {
  const [gridApi, setGridApi] = useState<GridApi>(null);
  const [offset, setOffset] = useState(0);

  const showToast = useShowToast();

  const [getTerritoryRulesForSearch] = useGetTerritoryRulesForSearchLazy({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      showToast(formatMessage('TERRITORY_GRID_ERROR'), 'danger');
    }
  });

  const searchTerritoriesGridContainerRef = React.useRef(null);

  const loadTerritoryRulesData = useCallback(
    async (completeDefinition: boolean, startRow: number, endRow: number, searchString: string) => {
      if (searchString?.length > 0) {
        const variables: GetTerritoryRulesForSearchVariables = {
          startRow,
          endRow,
          quotaComponentId,
          battlecardId: +battlecardId,
          measureId: 0,
          completeDefinition,
          sorting: {
            colId: SortableGetTerritoryRulesGridCols.territoryId,
            sort: SortDirection.asc
          },
          searchInput: { searchString }
        };
        const result = await getTerritoryRulesForSearch({ variables });

        const territoryRulesData: {
          items: GetTerritoryRulesForSearch_getTerritoryRules_territoryRules[];
          totalCount: number;
        } = {
          items: result?.data?.getTerritoryRules.territoryRules,
          totalCount: result?.data?.getTerritoryRules.totalCount
        };

        return territoryRulesData;
      }
      return [];
    },
    []
  );

  const handleTerritorySearch = async (searchString) => {
    if (debounceHandler === '') {
      return;
    }

    gridApi?.setRowData([]);

    if (gridApi && searchString !== '') {
      const { items: newRows } = (await loadTerritoryRulesData(false, 0, 10, searchString)) as {
        items: GetTerritoryRulesForSearch_getTerritoryRules_territoryRules[];
        totalCount: number;
      };
      gridApi.setRowData(newRows);
      setOffset(offset + newRows?.length + 1);
    }
  };

  const debounceHandler = useMemo(() => debounce(handleTerritorySearch, debounceDelay), []);

  useEffect(() => {
    handleTerritorySearch(searchString);
  }, [searchString]);

  const onGridReady = (gridEvent) => {
    setGridApi(gridEvent?.api);
  };

  const gridOptions = {
    onGridReady,
    selectedNodes: { selectedNodes },
    rowMultiSelectWithClick: true,
    suppressCellSelection: true,
    suppressRowClickSelection: false,
    rowSelection: 'multiple',
    headerHeight: 0,
    rowHeight: MENU_INFINITE_SCROLL_ITEM_HEIGHT,
    suppressRowDrag: false
  };

  const isNodePreselected = (node) => {
    return !!selectedNodes.filter((selectedNode) => selectedNode.hierarchyId === node.data.hierarchyId).length;
  };

  const checkBoxSelection = ({ node }) => {
    if (isNodePreselected(node)) {
      node.setSelected(true);
    }

    return true;
  };

  return (
    <div className={b()} data-testid="territory-search">
      <div className={b('fullWidthGrid')} ref={searchTerritoriesGridContainerRef}>
        <AdvancedGrid
          className={'searchTerritoriesGrid'}
          animateRows={true}
          gridOptions={gridOptions}
          columnDefs={TerritorySearchColumnDefs(checkBoxSelection)}
          noDataMessage={formatMessage('NO_RESULTS_FOUND')}
          onRowSelected={(e) => {
            onSelect(e);
          }}
        />
      </div>
    </div>
  );
};

export default TerritorySearch;
