// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cG9J\\+ {\n    margin-right: 8px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UpsertTerritoryRuleViewActionButtonContainer_cancelBtn": "cG9J+"
};
export default ___CSS_LOADER_EXPORT___;
