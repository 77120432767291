import { useEffect } from 'react';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';
import { useMapWorkerPostMessage } from 'app/contexts/mapWorkerContext';

import { useGetSegments } from 'app/graphql/queries/getSegments';

import { Segment } from 'app/models';

export const useSegments = () => {
  const { selectedBattleCardId, selectedDeploymentModelId } = useMapContextRedistributor();

  const postMessage = useMapWorkerPostMessage();

  const { data } = useGetSegments({
    fetchPolicy: 'network-only',
    variables: {
      battlecardId: parseInt(selectedBattleCardId),
      deploymentModelId: selectedDeploymentModelId
    }
  });

  useEffect(() => {
    const allSegments: Segment[] = data?.getDeploymentModelSpec?.battlecards?.[0]?.segments ?? [];

    postMessage({
      type: 'segments-change',
      segments: allSegments
    });
  }, [data]);
};
