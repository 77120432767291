import React, { useState, useMemo, useCallback, Dispatch, SetStateAction } from 'react';

import { datadogRum } from '@datadog/browser-rum';

import { useContextSafe } from 'app/hooks/useContextSafe';
import useQueryParamState from 'app/hooks/useQueryParamState';

import {
  BaseContext,
  SelectedPlanningType,
  SelectedTenant,
  CustomHeader,
  SelectedPlanningCycle,
  HierarchySpec
} from 'app/models';

import { deepEqual } from 'utils/helpers/index';

import { useApplyCustomHeader } from './ApolloHeaderProvider';

export interface ScopeValues extends BaseContext {
  selectedPlanningCycle: SelectedPlanningCycle;
  setSelectedPlanningCycle: (planningCycle: SelectedPlanningCycle | null) => void;

  selectedTenant: SelectedTenant;
  setSelectedTenant: (newTenant: SelectedTenant | null) => void;

  selectedDeploymentModelId: number;
  setSelectedDeploymentModelId: (selectedDeploymentModelId: number | null) => void;

  selectedPlanningType: SelectedPlanningType;

  showUnsaveChangesWarningDialog: boolean;
  setShowUnsaveChangesWarningDialog: Dispatch<SetStateAction<boolean>>;

  rootHierarchies: HierarchySpec[];
  setRootHierarchies: Dispatch<SetStateAction<HierarchySpec[]>>;

  resetValues: () => void;
}

export const ScopeContext = React.createContext<ScopeValues | null>(null);
ScopeContext.displayName = 'ScopeContext';

export const ScopeProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [selectedPlanningCycle, setSelectedPlanningCycleRaw] = useState<SelectedPlanningCycle | null>(null);
  const [selectedTenant, setSelectedTenantRaw] = useState<SelectedTenant | null>(null);
  const [selectedDeploymentModelId, setSelectedDeploymentModelId] = useState<number | null>(null);
  const [showUnsaveChangesWarningDialog, setShowUnsaveChangesWarningDialog] = useState<boolean>(false);
  const [rootHierarchies, setRootHierarchies] = useState<HierarchySpec[]>([]);
  const selectedPlanningType = usePlanView();

  const resetValues = useCallback(() => {
    setSelectedTenant(null);
    setSelectedPlanningCycle(null);
    setSelectedDeploymentModelId(null);
    setShowUnsaveChangesWarningDialog(false);
  }, []);

  const setSelectedPlanningCycle = useCallback(
    (newPc: SelectedPlanningCycle) => setSelectedPlanningCycleRaw((oldPc) => (deepEqual(newPc, oldPc) ? oldPc : newPc)),
    []
  );

  // update tenant in context and DataDog RUM
  const setSelectedTenant = useCallback((newTenant: SelectedTenant) => {
    setSelectedTenantRaw((oldTenant) => (deepEqual(newTenant, oldTenant) ? oldTenant : newTenant));
    datadogRum.setGlobalContextProperty('tenant', newTenant);
  }, []);

  // Prevent forced re-render on components that are reading these values,
  // unless certain values have changed.
  const values = useMemo(
    () => ({
      selectedPlanningCycle,
      setSelectedPlanningCycle,
      selectedTenant,
      setSelectedTenant,
      selectedDeploymentModelId,
      setSelectedDeploymentModelId,
      resetValues,
      selectedPlanningType,
      showUnsaveChangesWarningDialog,
      setShowUnsaveChangesWarningDialog,
      rootHierarchies,
      setRootHierarchies
    }),
    [
      selectedPlanningCycle,
      selectedTenant,
      selectedDeploymentModelId,
      selectedPlanningType,
      showUnsaveChangesWarningDialog,
      rootHierarchies
    ]
  );

  // These 2 legacy usages are needed until all mutations move to apollo factory functions
  // eslint-disable-next-line deprecation/deprecation
  useApplyCustomHeader(CustomHeader.PLANNING_CYCLE_ID, selectedPlanningCycle?.id);
  // eslint-disable-next-line deprecation/deprecation
  useApplyCustomHeader(CustomHeader.DEPLOYMENT_MODEL_ID, selectedDeploymentModelId);

  // Return the interface that we want to expose to our other components
  return <ScopeContext.Provider value={values}>{children}</ScopeContext.Provider>;
};

// Custom hook to read these values from
export const useScope = (): ScopeValues => useContextSafe(ScopeContext);

const usePlanView = (): SelectedPlanningType => {
  const [viewParamValue] = useQueryParamState<string>('view');
  if (
    viewParamValue === SelectedPlanningType.TERRITORY_PLANNING ||
    viewParamValue === SelectedPlanningType.QUOTA_PLANNING
  ) {
    return viewParamValue;
  }

  return SelectedPlanningType.NONE;
};
