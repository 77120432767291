import { useEffect } from 'react';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';
import { useMapWorkerPostMessage } from 'app/contexts/mapWorkerContext';

import { defaultCustomHierarchyFilter } from 'app/models';

export const useResetFilterBetweenTgs = () => {
  const postMessage = useMapWorkerPostMessage();
  const { selectedTerritoryGroupId } = useMapContextRedistributor();
  useEffect(() => {
    postMessage({
      type: 'custom-hierarchy-filter-change',
      filter: defaultCustomHierarchyFilter
    });
  }, [selectedTerritoryGroupId]);
};
