import React from 'react';

import { CalloutV2, Intent, Text } from '@varicent/components';
import { Form, useFormikContext } from 'formik';

import { BasicSelectField } from 'components/BasicSelect/BasicSelectField';

import AccountInformationCallout from 'app/components/TerritoryMap/AccountInformationCallout';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { AccountSourceFilterEnum } from 'app/graphql/generated/graphqlApolloTypes';

import { HierarchyType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import { FormValues } from './MapVisualizationSettingsDialog';
import style from './MapVisualizationSettingsDialog.module.pcss';
import RuleVisualizationSettings from './RuleVisualizationSettings';

const b = block(style);

export const ACCOUNT_SOURCE_FILTER_ITEMS = [
  {
    value: AccountSourceFilterEnum.CustomerAccountHierarchy,
    text: formatMessage('ACCOUNT_HIERARCHY')
  },
  {
    value: AccountSourceFilterEnum.Activity,
    text: formatMessage('SALES_ACTIVITY')
  }
];

interface MapVisualizationSettingsFormProps {
  customHierarchyItems: {
    text: string;
    value: number;
  }[];
  territoryGroupTypeItems: {
    text: string;
    value: HierarchyType;
  }[];
}

const MapVisualizationSettingsForm: React.FC<MapVisualizationSettingsFormProps> = ({
  customHierarchyItems,
  territoryGroupTypeItems
}) => {
  const { values } = useFormikContext<FormValues>();
  const { selectedBattleCard } = useMapContextRedistributor();

  const isAccountMapSelected = values.territoryGroupType === HierarchyType.CustomerAccountHierarchy;

  const showTgtSelectionField = territoryGroupTypeItems.length > 1;

  const showActivityFileWarning =
    (values.accountSourceFilter === AccountSourceFilterEnum.Activity ||
      values.territoryGroupType === HierarchyType.GeographicTerritoryHierarchy) &&
    !selectedBattleCard?.files?.length;

  return (
    <Form>
      <div className={b('formContainer')} data-testid="map-visualization-settings-form">
        {showTgtSelectionField && (
          <>
            <Text className={b('dialogBodyHeader')}>{formatMessage('RULE_VIZ_SETTINGS_DIALOG_BODY_HEADER')}</Text>
            <Text className={b('formLabel')}>{formatMessage('TERRITORY_GROUP_TYPE')}</Text>
            <BasicSelectField
              name="territoryGroupType"
              label={formatMessage('TERRITORY_GROUP_TYPE')}
              items={territoryGroupTypeItems}
              placement="bottom-start"
              fill
              showIconOnButton={false}
            />
          </>
        )}
        <div className={b('radioGroupContainer')}>
          <RuleVisualizationSettings customHierarchyItems={customHierarchyItems} />
          {isAccountMapSelected && (
            <>
              <label className={b('label')}>{formatMessage('SHOW_ACCOUNTS_BASED_ON')}</label>
              <BasicSelectField
                name="accountSourceFilter"
                label={formatMessage('SHOW_ACCOUNTS_BASED_ON')}
                items={ACCOUNT_SOURCE_FILTER_ITEMS}
                placement="bottom-start"
                fill
                showIconOnButton={false}
              />
            </>
          )}
        </div>
      </div>
      {showActivityFileWarning && (
        <CalloutV2
          intent={Intent.WARNING}
          title={formatMessage('NO_ACTIVITY_FILE_CALLOUT_TITLE')}
          data-testid="activity-file-warning-callout"
        >
          <p>{formatMessage('NO_ACTIVITY_FILE_CALLOUT_DESCRIPTION')}</p>
        </CalloutV2>
      )}
      {isAccountMapSelected && <AccountInformationCallout />}
    </Form>
  );
};

export default MapVisualizationSettingsForm;
