import React from 'react';

import { RowNode } from '@ag-grid-community/core';

import Dialog from 'components/Dialog/Dialog';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { RequestDeleteTerritoryRulesInput } from 'app/graphql/generated/graphqlApolloTypes';
import { useRequestDeleteTerritoryRules } from 'app/graphql/mutations/requestDeleteTerritoryRules';

import useShowToast from 'app/hooks/useShowToast';

import { getRuleDeleteErrorToastText } from 'utils/helpers/territoryToastHelpers';
import { formatMessage } from 'utils/messages/utils';

interface BulkDeleteTerritoryDialogProps {
  onClose: () => void;
  selectedNodes: Array<RowNode>;
}

export const generateDialogMessage = (
  numberToDelete: number,
  isTGSelected: boolean,
  isBulkDeleteChecked = false,
  numberToLeaveOut = 0
): string => {
  if (!isBulkDeleteChecked) {
    if (isTGSelected) {
      return numberToDelete <= 1
        ? formatMessage('DELETE_TG_TERRITORY_CONFIRM', { count: numberToDelete })
        : formatMessage('DELETE_TG_TERRITORIES_CONFIRM', { count: numberToDelete });
    }
    return numberToDelete <= 1
      ? formatMessage('DELETE_BC_TERRITORY_CONFIRM', { count: numberToDelete })
      : formatMessage('DELETE_BC_TERRITORIES_CONFIRM', { count: numberToDelete });
  }

  if (isBulkDeleteChecked && numberToLeaveOut === 0) {
    return isTGSelected
      ? formatMessage('DELETE_ALL_TERRITORY_GROUP_TERRITORIES_CONFIRMATION_MESSAGE')
      : formatMessage('DELETE_ALL_BATTLECARD_TERRITORIES_CONFIRMATION_MESSAGE');
  } else {
    if (isTGSelected) {
      return numberToLeaveOut <= 1
        ? formatMessage('DELETE_TG_TERRITORY_WITH_EXCEPTION', { count: numberToLeaveOut })
        : formatMessage('DELETE_TG_TERRITORIES_WITH_EXCEPTION', { count: numberToLeaveOut });
    }
    return numberToLeaveOut <= 1
      ? formatMessage('DELETE_BC_TERRITORY_WITH_EXCEPTION', { count: numberToLeaveOut })
      : formatMessage('DELETE_BC_TERRITORIES_WITH_EXCEPTION', { count: numberToLeaveOut });
  }
};

const BulkDeleteTerritoryDialog: React.FC<BulkDeleteTerritoryDialogProps> = ({
  onClose,
  selectedNodes
}: BulkDeleteTerritoryDialogProps) => {
  const {
    isBulkDeleteChecked,
    setIsBulkDeleteChecked,
    bulkDeleteExclusionIds,
    setBulkDeleteExclusionIds,
    setBulkDeleteInclusionIds,
    setBulkDeleteTerritoryJobKey
  } = useGrid();
  const { selectedDeploymentModelId } = useScope();
  const showToast = useShowToast();

  const [requestDeleteTerritoryRules] = useRequestDeleteTerritoryRules({
    onCompleted(data) {
      setBulkDeleteTerritoryJobKey(data.requestDeleteTerritoryRules.jobKey);
    },
    onError: (error, options) => {
      const input: RequestDeleteTerritoryRulesInput = options.variables?.input;
      const errorToastText = getRuleDeleteErrorToastText(error.knownError, input?.ruleIds.inclusions.length);
      showToast(
        <ToastMessage title={formatMessage('FAIL_BULK_DELETE_TERRITORY_TITLE')} message={errorToastText} />,
        'danger',
        'top-right'
      );
    }
  });

  const { selectedBattleCardId } = useBattleCard();
  const { selectedPillIdPlanTargets } = usePlanTargets();
  const { selectedPillIdTDR } = useTerritoryDefineAndRefine();

  const selectedPillId = selectedPillIdPlanTargets || selectedPillIdTDR;

  const handleClose = () => {
    onClose();
    setIsBulkDeleteChecked(false);
    setBulkDeleteExclusionIds([]);
    setBulkDeleteInclusionIds([]);
  };

  const handleRequestDeleteTerritoryRule = async () => {
    if (isBulkDeleteChecked) {
      if (selectedPillId != null || selectedPillId?.length > 0) {
        await requestDeleteTerritoryRules({
          variables: {
            input: {
              deploymentModelId: selectedDeploymentModelId,
              territoryGroupId: Number(selectedPillId),
              ruleIds: {
                inclusions: [],
                exclusions: bulkDeleteExclusionIds
              }
            }
          }
        });
      } else if (selectedBattleCardId?.length > 0) {
        await requestDeleteTerritoryRules({
          variables: {
            input: {
              deploymentModelId: selectedDeploymentModelId,
              battlecardId: Number(selectedBattleCardId),
              ruleIds: {
                inclusions: [],
                exclusions: bulkDeleteExclusionIds
              }
            }
          }
        });
      }
    } else {
      await requestDeleteTerritoryRules({
        variables: {
          input: {
            deploymentModelId: selectedDeploymentModelId,
            ruleIds: {
              inclusions: selectedNodes.map((node) => node.data.ruleId),
              exclusions: []
            }
          }
        }
      });
    }

    onClose();
    setIsBulkDeleteChecked(false);
    setBulkDeleteExclusionIds([]);
    setBulkDeleteInclusionIds([]);
  };
  return (
    <Dialog
      isOpen
      title={formatMessage('DELETE_MULTIPLE_TERRITORIES_DIALOG_TITLE')}
      cancelButtonText={formatMessage('KEEP')}
      confirmButtonText={formatMessage('DELETE')}
      confirmButtonIntent="danger"
      onSubmit={handleRequestDeleteTerritoryRule}
      onClose={handleClose}
      size="small"
      bodyMinHeight={0}
      data-testid="bulk-delete-territory-dialog"
    >
      <div>
        {generateDialogMessage(
          selectedNodes.length,
          !!selectedPillId,
          isBulkDeleteChecked,
          bulkDeleteExclusionIds?.length
        )}
      </div>
    </Dialog>
  );
};

export default BulkDeleteTerritoryDialog;
