import React, { useEffect, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useApolloClient } from '@apollo/client';
import { ProgressBar } from '@blueprintjs/core';
// eslint-disable-next-line no-restricted-imports
import { Toast } from '@varicent/components';

import ToastMessage from 'components/ToastMessage/ToastMessage';

import { PcJobInProgress, useUser } from 'app/core/userManagement/userProvider';

import { GET_PLANNING_CYCLES } from 'app/graphql/queries/getPlanningCycles';
import { useGetPostCoinsortProgressLazy } from 'app/graphql/queries/getPostCoinsortProgress';
import { GET_TENANT_WIDE_INFO } from 'app/graphql/queries/getTenantWideInfo';

import useShowToast from 'app/hooks/useShowToast';

import { JobStatus } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './PostCoinsortProgressToast.module.pcss';

const b = block(style);

interface PostCoinsortProgressToastProps {
  job: PcJobInProgress;
  title: string;
  progressDescription: string;
}

const PostCoinsortProgressToast: React.FC<PostCoinsortProgressToastProps> = ({ job, title, progressDescription }) => {
  const { pcJobsInProgress, setPcJobsInProgress } = useUser();
  const showToast = useShowToast();

  const { planningCycleId, planningCycleName, jobType } = job;

  const generateSuccessText = formatMessage('UPDATE_PC_SUCCESS', {
    planningCycleName
  });
  const apolloClient = useApolloClient();

  const handlePcJobComplete = (stopPolling, isError = false) => {
    if (isError) {
      setIsOpen(false);
      showToast(
        <ToastMessage
          title={formatMessage('PC_START_DATE_POST_COINSORT_FAILURE')}
          message={formatMessage('PC_START_DATE_POST_COINSORT_FAILURE_MESSAGE', {
            planningCycleName
          })}
        />,
        'danger'
      );
    } else {
      showToast(
        <ToastMessage title={formatMessage('EDIT_PLANNING_CYCLE_SUCCESS_TITLE')} message={generateSuccessText} />,
        'success'
      );
    }
    stopPolling();
    apolloClient.refetchQueries({ include: [GET_TENANT_WIDE_INFO, GET_PLANNING_CYCLES] });

    setPcJobsInProgress(
      pcJobsInProgress.filter((pc) => pc.planningCycleId !== planningCycleId || pc.jobType !== jobType)
    );
  };

  const [getPostCoinsortProgress, { data: postCoinsortProgress, stopPolling }] = useGetPostCoinsortProgressLazy({
    fetchPolicy: 'network-only',
    variables: {
      planningCycleId: job.planningCycleId
    },
    pollInterval: 1000,
    onCompleted(response) {
      if (!isOpen) handlePcJobComplete(stopPolling);
      const { totalJobs, status, jobNumber } = response.getPostCoinsortProgress
        ? response.getPostCoinsortProgress
        : { totalJobs: 1, status: null, jobNumber: 0 };
      if (status === JobStatus.FAILED) {
        handlePcJobComplete(stopPolling, true);
      } else if ((status === JobStatus.COMPLETED || status === JobStatus.SKIPPED) && totalJobs === jobNumber) {
        // close the toast for planningCycleJob when job is complete
        handlePcJobComplete(stopPolling);
      }
    },
    onError() {
      handlePcJobComplete(stopPolling, true);
    }
  });
  const [isOpen, setIsOpen] = useState(true);
  const totalJobs = postCoinsortProgress?.getPostCoinsortProgress?.totalJobs;
  const jobNumber = postCoinsortProgress?.getPostCoinsortProgress?.jobNumber;
  const statusValue = totalJobs ? jobNumber / totalJobs : 0;

  const message = (
    <ToastMessage
      title={title}
      message={
        <>
          <div className={b('descriptionContainer')}>
            <span>{progressDescription}</span>
          </div>
          <div className={b('progressContainer')}>
            <ProgressBar className={b('progressBar')} stripes={false} value={statusValue} />
            <span data-testid={'progressPercentage'} className={b('progressPercentage')}>{`${Math.floor(
              statusValue * 100
            )}%`}</span>
          </div>
        </>
      }
    />
  );

  useEffect(() => {
    getPostCoinsortProgress();
  }, []);

  return (
    isOpen && (
      <Toast
        message={message}
        timeout={0}
        intent="primary"
        icon="info-sign"
        onDismiss={(isOpen) => setIsOpen(isOpen)}
        data-testid="pc-date-change-progress-status-toast"
      />
    )
  );
};

export default PostCoinsortProgressToast;
