import { SearchableSelectMenuItem } from 'components/models';

import {
  GetOptionsForCompareForm_getDeploymentModelSpec_battlecards,
  VisualizationTerritoryGroupTypeEnum
} from 'app/graphql/generated/graphqlApolloTypes';

import { BattlecardType, HierarchyType, MapCompareState } from 'app/models';

export const getCompareMapStateAsSearchParams = (mapCompareState: MapCompareState, search: string): string => {
  const newSearchParams = new URLSearchParams(search);

  newSearchParams.set('primaryBc', mapCompareState.primary.battlecardId.toString());
  newSearchParams.set('primaryQc', mapCompareState.primary.quotaComponentId.toString());
  newSearchParams.set('primaryDm', mapCompareState.primary.deploymentModelId.toString());
  newSearchParams.set('primaryGeoTgt', mapCompareState.primary.geoTgtId.toString());
  newSearchParams.set('secondaryBc', mapCompareState.secondary.battlecardId.toString());
  newSearchParams.set('secondaryQc', mapCompareState.secondary.quotaComponentId.toString());
  newSearchParams.set('secondaryDm', mapCompareState.secondary.deploymentModelId.toString());
  newSearchParams.set('secondaryGeoTgt', mapCompareState.secondary.geoTgtId.toString());

  return newSearchParams.toString();
};

export const calculateDividerIndex = (
  selectedValue: SearchableSelectMenuItem,
  mappableItems: SearchableSelectMenuItem[]
): number => {
  if (selectedValue) {
    return mappableItems.some((item) => item.value === selectedValue.value)
      ? mappableItems.length - 1
      : mappableItems.length;
  }

  return mappableItems.length;
};

type BcTgtCombo = {
  battleCard: Omit<GetOptionsForCompareForm_getDeploymentModelSpec_battlecards, '__typename'>;
  geoTgtId: number;
};
export const findMappableBcTgtCombos = (
  inputBcs: Omit<GetOptionsForCompareForm_getDeploymentModelSpec_battlecards, '__typename'>[],
  filterToQcId: number
): BcTgtCombo[] => {
  const output = new Array<BcTgtCombo>();
  if (!filterToQcId) return output;

  for (const battleCard of inputBcs) {
    if (battleCard.battlecardType !== BattlecardType.PrimaryTeam) continue;
    const isConnectedToQc = battleCard.quotaComponents.some((qc) => qc.quotaComponentId === filterToQcId);
    if (!isConnectedToQc) continue;
    const geoTgt = battleCard.territoryGroupTypes.find(isGeoTgt);
    if (!geoTgt) continue;

    if (
      battleCard.mapVisualizationSettings?.territoryGroupType ===
      VisualizationTerritoryGroupTypeEnum.CustomerAccountHierarchy
    )
      continue;
    output.push({ battleCard, geoTgtId: geoTgt.territoryGroupId });
  }
  return output;
};

const isGeoTgt = (tgt: { hierarchyType: HierarchyType | string }) =>
  tgt.hierarchyType === HierarchyType.GeographicTerritoryHierarchy;
