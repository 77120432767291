import * as yup from 'yup';

import { formatMessage } from 'utils/messages/utils';

interface ValidDateRange {
  minStartDate?: string | null;
  maxEndDate?: string | null;
}

interface BuildValidationSchemaOptions {
  isEffectiveDatesEnabled: boolean;
  validDateRange?: ValidDateRange | null;
}

const buildValidationSchema = ({ isEffectiveDatesEnabled, validDateRange }: BuildValidationSchemaOptions) => {
  const schemaShape: Record<string, yup.AnySchema> = {
    name: yup.string().max(256).required(formatMessage('ENTER_TERRITORY_GROUP_NAME')).label(formatMessage('ADD')),

    territoryGroupOwner: yup.object({
      key: yup.string().nullable(),
      value: yup.number().nullable()
    }),

    effectiveDate: yup.string().nullable(),
    endDate: yup.string().nullable()
  };

  if (isEffectiveDatesEnabled && validDateRange) {
    const { minStartDate, maxEndDate } = validDateRange;

    if (minStartDate) {
      schemaShape.effectiveDate = yup.string().required(formatMessage('REQUIRED_EFFECTIVE_START_DATE')).nullable();
    }

    if (maxEndDate) {
      schemaShape.endDate = yup.string().required(formatMessage('REQUIRED_EFFECTIVE_END_DATE')).nullable();
    }
  }

  return yup.object().shape(schemaShape);
};

export default buildValidationSchema;
