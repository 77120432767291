// Currently, some APIs return a ISO formatted string date without timezone kept in mind.
// This function just returns a date string in the current user's locale with the same

import dayjs from 'dayjs';

// year, month and full year provided in `isoDateStr` to avoid issues with timezones.
export const getLocaleDateStringFromLocalISOStr = (
  isoDateStr: string,
  options?: Intl.DateTimeFormatOptions
): string => {
  const [fullYear, month, date] = isoDateStr.split('-').map((str) => parseInt(str));
  // Set full year, month and date based on local time
  const event = new Date(fullYear, month - 1, date);
  // Returns a date string in local timezone and the user's default locale
  return event.toLocaleDateString(undefined, options ?? {});
};

export const getLocalDateString = (): string => {
  return dayjs().format('YYYY-MM-DD');
};

export const convertToLocalDateString = (date: string): string => {
  return dayjs(date).format('YYYY-MM-DD');
};

export const roundToDateOnlyString = (date: string): Date => {
  return dayjs(date).toDate();
};
