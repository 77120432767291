import { ICellRendererParams } from '@ag-grid-community/core';
import {
  MutationTuple,
  // eslint-disable-next-line no-restricted-imports
  useMutation
} from '@apollo/client';

import {
  UpsertCustomHierarchy,
  UpsertCustomHierarchyVariables,
  UpsertCustomerAccountHierarchy,
  UpsertCustomerAccountHierarchyVariables,
  UpsertGeographicTerritoryHierarchy,
  UpsertGeographicTerritoryHierarchyVariables
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import {
  UPSERT_CUSTOMER_ACCOUNT_HIERARCHY,
  UPSERT_CUSTOM_HIERARCHY,
  UPSERT_GEOGRAPHIC_HIERARCHY
} from 'app/graphql/mutations/upsertHierarchy';

import useShowToast from 'app/hooks/useShowToast';

import { HierarchyType } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

const upsertHierarchyLookupMap = {
  [HierarchyType.CustomerAccountHierarchy]: {
    mutationConstant: UPSERT_CUSTOMER_ACCOUNT_HIERARCHY,
    responseName: 'upsertCustomerAccountHierarchy'
  },
  [HierarchyType.GeographicTerritoryHierarchy]: {
    mutationConstant: UPSERT_GEOGRAPHIC_HIERARCHY,
    responseName: 'upsertGeographicTerritoryHierarchy'
  },
  [HierarchyType.CustomHierarchy]: {
    mutationConstant: UPSERT_CUSTOM_HIERARCHY,
    responseName: 'upsertCustomHierarchy'
  }
};

/** Find the full path from root to the target node to only refresh the server side store of the affected node.
 * If the node's parent has children before, return the path from root to the node's parent - this will refresh all the children of the parent.
 * Otherwise, return the path from root to the node's parent's parent - this will refresh the parent itself so that it will render the correct icons.
 */
const findFullPath = (node: ICellRendererParams['node'], parentHasNoChildren: boolean): string[] => {
  const nodePath = node?.id;
  const path = nodePath?.split('-');
  path?.pop();

  // If the new node's parent has no children before,
  if (!parentHasNoChildren) {
    path?.push(node?.data?.name);
  }
  return path;
};

const handleUpsertInFilteredTree = (searchString, setSearchString, setSelectedNode, setInitialBlock) => {
  // Reset search string and search for the term again to get the latest result
  const tempSearchString = searchString;
  setSearchString('');
  setSearchString(tempSearchString);
  setSelectedNode(null);
  // Re-fetch the original tree to make sure it shows the latest data
  setInitialBlock(null);
};

interface UseUpsertHierarchyProps {
  hierarchyType: HierarchyType;
  isCreateMode: boolean;
  rootHierarchyName: string;
  nodeData;
  selectedNode: ICellRendererParams;
  setSelectedNode;
  setNodeData;
  setInitialBlock;
  searchString?: string;
  setSearchString: (value) => void;
  refetchRootHierarchies?: () => void;
}

export const useUpsertHierarchy = ({
  hierarchyType,
  isCreateMode,
  rootHierarchyName,
  nodeData,
  selectedNode,
  setSelectedNode,
  setNodeData,
  setInitialBlock,
  searchString,
  setSearchString,
  refetchRootHierarchies
}: UseUpsertHierarchyProps): MutationTuple<
  UpsertCustomHierarchy | UpsertCustomerAccountHierarchy | UpsertGeographicTerritoryHierarchy,
  UpsertCustomHierarchyVariables | UpsertCustomerAccountHierarchyVariables | UpsertGeographicTerritoryHierarchyVariables
> => {
  const showToast = useShowToast();
  return useMutation<
    UpsertCustomHierarchy | UpsertCustomerAccountHierarchy | UpsertGeographicTerritoryHierarchy,
    | UpsertCustomHierarchyVariables
    | UpsertCustomerAccountHierarchyVariables
    | UpsertGeographicTerritoryHierarchyVariables
  >(upsertHierarchyLookupMap?.[hierarchyType]?.mutationConstant, {
    onCompleted(response) {
      const responseNameConstant = upsertHierarchyLookupMap?.[hierarchyType]?.responseName;
      const newName = response[responseNameConstant]?.name;
      showToast(
        isCreateMode
          ? formatMessage('ADDED_HIERARCHY', { rootName: rootHierarchyName.toLowerCase(), name: newName })
          : formatMessage('UPDATED_HIERARCHY', { rootName: rootHierarchyName.toLowerCase(), name: nodeData.name }),
        'success'
      );
      refetchRootHierarchies?.();

      if (searchString !== '') {
        return handleUpsertInFilteredTree(searchString, setSearchString, setSelectedNode, setInitialBlock);
      }

      if (isCreateMode) {
        const newNode = selectedNode?.data;
        const newNodeParent = newNode?.parent;

        // if adding child to root, refresh the entire tree
        if (!newNodeParent) {
          setInitialBlock(null);
          setSelectedNode(null);
          return;
        }
        // if the current parent has no children, we want to calculate the route till the parent of the parent, otherwise, calculate till the parent
        // For more information please refer to the refresh tree date section on https://www.ag-grid.com/javascript-data-grid/server-side-model-tree-data/
        const parentHasNoChildren = !newNodeParent.data?.hasChildren;

        const route = findFullPath(newNodeParent.node, parentHasNoChildren);

        newNodeParent.api?.refreshServerSideStore({ route });
        newNodeParent.node?.setSelected(false);
        setSelectedNode(null);

        // Update children count to handle special cases when the parentNode has no children originally
        newNodeParent['data']['hasChildren'] = true;
      } else {
        selectedNode.setValue(newName);
        selectedNode.data = response[responseNameConstant];
        selectedNode?.api?.refreshServerSideStore({ route: [newName] });
      }

      setNodeData(selectedNode?.data);
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      if (isCreateMode) {
        showToast(formatMessage('CREATE_NEW_HIERARCHY_ERROR'), 'danger');
      } else {
        showToast(
          formatMessage('UPDATE_HIERARCHY_ERROR', { rootName: rootHierarchyName.toLowerCase(), name: nodeData.name }),
          'danger'
        );
      }
    }
  });
};
