import React from 'react';

import { CheckmarkFilled, Save } from '@carbon/icons-react';
import { CalloutV2, HTMLHeading, Intent } from '@varicent/components';
import { Field, Form, Formik } from 'formik';

import Dialog from 'components/Dialog/Dialog';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';

import { useScope } from 'app/contexts/scopeProvider';

import { GetAllTerritoryIdentities_getAllTerritoryIdentities_territoryIdentities } from 'app/graphql/generated/graphqlApolloTypes';
import { useBulkReidentifyTerritories } from 'app/graphql/mutations/bulkReidentifyTerritories';
import { GET_ALL_TERRITORY_IDENTITIES } from 'app/graphql/queries/getAllTerritoryIdentities';

import useShowToast from 'app/hooks/useShowToast';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ResolveDuplicateTerritoriesDialog.module.pcss';
import validationSchema from './validationsSchema';

const b = block(style);

const FormHeader: React.FC = () => (
  <div className={b('header')}>
    <span className={b('headerItem')} data-testid="resolve-duplicate-territories-battlecard-name-header">
      {formatMessage('BATTLE_CARD')}
    </span>
    <span className={b('headerItem')} data-testid="resolve-duplicate-territories-territory-id-header">
      {formatMessage('TERRITORY_ID')}
    </span>
    <span className={b('headerItem')} data-testid="resolve-duplicate-territories-territory-name-header">
      {formatMessage('TERRITORY_NAME')}
    </span>
    <span className={b('headerItem')} data-testid="resolve-duplicate-territories-territory-group-name-header">
      {formatMessage('TERRITORY_GROUP')}
    </span>
  </div>
);

interface ResolveDuplicateTerritoriesDialogProps {
  onClose: () => void;
  territoriesIdentities: GetAllTerritoryIdentities_getAllTerritoryIdentities_territoryIdentities[];
  duplicateTerritoryIds: string[];
}

const ResolveDuplicateTerritoriesDialog: React.FC<ResolveDuplicateTerritoriesDialogProps> = ({
  onClose,
  territoriesIdentities,
  duplicateTerritoryIds
}) => {
  const [bulkReidentifyTerritories, { loading }] = useBulkReidentifyTerritories();
  const { selectedDeploymentModelId } = useScope();
  const showToast = useShowToast();
  const duplicateTerritoryIdsMap: {
    [key: number]: GetAllTerritoryIdentities_getAllTerritoryIdentities_territoryIdentities[];
  } = {};

  duplicateTerritoryIds.forEach((id) => {
    const territories = territoriesIdentities.filter((t) => t.territoryId === id);
    if (territories.length > 0) {
      duplicateTerritoryIdsMap[id] = territories;
    }
  });

  const initialValues: { [key: number]: { territoryId: string; territoryName: string } } = {};

  Object.entries(duplicateTerritoryIdsMap).forEach(
    ([_id, territories]: [string, GetAllTerritoryIdentities_getAllTerritoryIdentities_territoryIdentities[]]) => {
      territories.forEach((territory) => {
        initialValues[territory.ruleId] = {
          territoryId: territory.territoryId,
          territoryName: territory.territoryName
        };
      });
    }
  );

  // Keep track of touched fields for validation
  const touchedFields = {};

  const handleBlur = (ruleId: number) => {
    if (!touchedFields[ruleId]) {
      touchedFields[ruleId] = true;
    }
  };

  const handleSubmit = (values) => {
    if (Object.keys(touchedFields).length === 0) return;
    const input = Object.keys(touchedFields).map((ruleId) => ({
      ruleId: +ruleId,
      territoryId: values[ruleId].territoryId,
      territoryName: values[ruleId].territoryName
    }));
    bulkReidentifyTerritories({
      variables: {
        input
      },
      refetchQueries: [
        { query: GET_ALL_TERRITORY_IDENTITIES, variables: { deploymentModelId: selectedDeploymentModelId } }
      ],
      onCompleted(data) {
        showToast(
          <ToastMessage
            message={formatMessage('REIDENTIFY_TERRITORIES_SUCCESS', {
              count: data.bulkReidentifyTerritories.ruleIds.length
            })}
          />,
          'success'
        );
      },
      onError() {
        showToast(formatMessage('REIDENTIFY_TERRITORIES_ERROR'), 'danger');
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema(territoriesIdentities, duplicateTerritoryIds, touchedFields)}
      validateOnChange={false}
      validateOnBlur={true}
    >
      {({ handleSubmit, errors, submitCount }) => {
        return (
          <Dialog
            isOpen
            title={formatMessage('RESOLVE_DUPLICATE_TERRITORY_IDS')}
            data-testid="resolve-duplicate-territories-dialog"
            showConfirm={duplicateTerritoryIds.length > 0}
            confirmButtonText={formatMessage('SAVE')}
            confirmButtonIcon={<Save size={24} />}
            onSubmit={handleSubmit}
            onClose={onClose}
            cancelButtonText={formatMessage('CLOSE')}
            confirmButtonLoading={loading}
            disableCancel={loading}
            bodyMaxHeight={600}
            bodyWidth={960}
            size="auto"
          >
            <>
              {duplicateTerritoryIds.length === 0 ? (
                <div className={b('completePage')} data-testid="complete-page">
                  <CheckmarkFilled size={144} data-testid="success-input-icon" className={b('checkmark')} />
                  <HTMLHeading
                    bold
                    tagLevel={'h5'}
                    styleLevel={'h5'}
                    text={formatMessage('NO_DUPLICATE_TERRITORY_IDS')}
                  />
                  <p>{formatMessage('NO_DUPLICATE_TERRITORY_IDS_MESSAGE')}</p>
                </div>
              ) : (
                <>
                  <div className={b('dialogMessage')} data-testid="resolve-duplicate-territories-dialog-message">
                    {formatMessage('RESOLVE_DUPLICATE_TERRITORY_IDS_DIALOG_MESSAGE')}
                  </div>
                  <Form className={b('formSection')}>
                    {Object.entries(duplicateTerritoryIdsMap).map(([id, territories]) => {
                      return (
                        <div className={b('duplicateTerritoryEditGroup')} key={id}>
                          <HTMLHeading text={id} styleLevel="h5" tagLevel="h5" bold />
                          <FormHeader />
                          <div className={b('territoryIdField')}>
                            {territories.map((territory) => {
                              const territoryIdError = errors[territory.ruleId]?.territoryId;
                              const territoryNameError = errors[territory.ruleId]?.territoryName;
                              const shouldShowErrors = !!territoryIdError || !!territoryNameError;
                              return (
                                <div className={b('territoryIdentity')} key={territory.ruleId}>
                                  <div className={b('textDisplay')}>{territory.battlecardName}</div>
                                  <div className={b('textInput')}>
                                    <Field
                                      name={`${territory.ruleId}[territoryId]`}
                                      type="text"
                                      component={FormTextInputGroup}
                                      showErrors={shouldShowErrors}
                                      customError={territoryIdError}
                                      onBlur={() => handleBlur(territory.ruleId)}
                                    />
                                  </div>
                                  <div className={b('textInput')}>
                                    <Field
                                      name={`${territory.ruleId}[territoryName]`}
                                      type="text"
                                      component={FormTextInputGroup}
                                      showErrors={shouldShowErrors}
                                      customError={territoryNameError}
                                      onBlur={() => handleBlur(territory.ruleId)}
                                    />
                                  </div>
                                  <div className={b('textDisplay')}>{territory.territoryGroupName}</div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </Form>
                  {submitCount > 0 && Object.keys(errors).length > 0 && (
                    <div className={b('errorCallout')} data-testid="resolve-duplicate-territories-dialog-error-callout">
                      <CalloutV2 intent={Intent.DANGER}>
                        {formatMessage('RESOLVE_DUPLICATE_TERRITORY_IDS_ERROR_CALLOUT_MESSAGE')}
                      </CalloutV2>
                    </div>
                  )}
                </>
              )}
            </>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default ResolveDuplicateTerritoriesDialog;
