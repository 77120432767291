import React, { useEffect, useMemo, useState } from 'react';

import { Field } from 'formik';

import { KeyValue } from 'components/models';
import SearchableSelectMenu from 'components/SearchableSelectMenu/SearchableSelectMenu';

import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import block from 'utils/bem-css-modules';
import { getLeafNodes } from 'utils/helpers/index';
import { formatMessage } from 'utils/messages/utils';

import style from './UpsertTerritoryRuleViewLeftPanelContent.module.pcss';

const b = block(style);

interface UpsertTerritoryRuleDialogFormValues {
  territoryGroup: KeyValue<string>;
  territoryId: string;
  territoryName: string;
  startDate?: string;
  endDate?: string;
}

interface UpsertTerritoryRuleViewLeftPanelProps {
  initialFormValues: UpsertTerritoryRuleDialogFormValues;
  territoryGroupWidthSize?: 'small' | 'medium';
}

const UpsertTerritoryRuleViewLeftPanelContent: React.FC<UpsertTerritoryRuleViewLeftPanelProps> = ({
  initialFormValues,
  territoryGroupWidthSize = 'medium'
}: UpsertTerritoryRuleViewLeftPanelProps) => {
  const { tdrTreeLookupMap } = useTerritoryDefineAndRefine();
  const { selectedBattleCardId } = useBattleCard();
  const [searchText, setSearchText] = useState('');
  const [territoryGroupMenuItems, setTerritoryGroupMenuItems] = useState<KeyValue<string>[]>(null);

  const filteredMenuItems = useMemo(() => {
    return territoryGroupMenuItems?.filter(({ key }) => {
      return key.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [searchText, territoryGroupMenuItems]);

  useEffect(() => {
    const tdrTree = tdrTreeLookupMap && tdrTreeLookupMap[selectedBattleCardId];

    if (tdrTree?.length) {
      // get all leaf territory groups
      const territoryGroupLeafNodes = [];
      tdrTree.forEach((treeNode) => getLeafNodes(treeNode, territoryGroupLeafNodes));

      // convert every territory group leaf node into a menu item
      const newTerritoryGroupMenuItems = [];
      territoryGroupLeafNodes.forEach((territoryGroup) => {
        newTerritoryGroupMenuItems.push({
          value: String(territoryGroup?.territoryGroupId),
          key: territoryGroup?.name
        });
      });

      setTerritoryGroupMenuItems(newTerritoryGroupMenuItems);
    }
  }, [tdrTreeLookupMap]);

  return (
    <>
      <Field
        component={FormTextInputGroup}
        name="territoryId"
        value={initialFormValues.territoryId}
        placeholder={formatMessage('INPUT_TEXT')}
        className={b('inputGroup')}
        data-testid="territory-id-input"
      />
      <Field
        component={FormTextInputGroup}
        name="territoryName"
        value={initialFormValues.territoryName}
        placeholder={formatMessage('INPUT_TEXT')}
        className={b('inputGroup')}
        data-testid="territory-name-input"
      />
      <div
        className={b('inputGroup', {
          small: territoryGroupWidthSize === 'small',
          medium: territoryGroupWidthSize === 'medium'
        })}
      >
        <Field
          component={SearchableSelectMenu}
          name="territoryGroup"
          placeHolderText={formatMessage('SELECT')}
          showErrors={false}
          onSearch={(searchString) => setSearchText(searchString)}
          onSearchReset={() => setSearchText('')}
          showIconInField={false}
          initialLoadingComplete={true}
          items={filteredMenuItems}
          theme="default"
          data-testid="territory-group-input"
          usePortal
        />
      </div>
    </>
  );
};

export default UpsertTerritoryRuleViewLeftPanelContent;
