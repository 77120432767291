import React, { useState } from 'react';

import { WatsonHealthStackedMove, Filter, Search, ChevronDown } from '@carbon/icons-react';

import TextButton from 'components/Buttons/TextButton/TextButton';
import Popover from 'components/Popover/Popover';

import AccountQuotaTGTFilter from 'app/components/AdvancedGrid/Sheets/Account/AccountQuotaTGTFilter';

import { SplitFeatures } from 'app/global/features';

import { GetAccountRuleGrid_getAccountRuleGrid_accountRuleHeaders } from 'app/graphql/generated/graphqlApolloTypes';

import useCanUser from 'app/hooks/useCanUser';
import useTreatment from 'app/hooks/useTreatment';

import { AccountRuleRow, FilterChangeInput, FilterInput, NamedTgt } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';
import { UserAction } from 'utils/permissions/userActions';

import AccountReassignDialog from './AccountReassignDialog';
import AccountRuleFilter from './AccountRuleFilter';
import style from './AccountRuleHeader.module.pcss';

const b = block(style);

export interface AccountRuleHeaderProps {
  territoryGroupTypes: NamedTgt[];
  currentTerritoryGroupTypeId: number;
  onTerritoryGroupTypeChange: (territoryGroupType: number) => void;
  selectedAccountRules: AccountRuleRow[];
  onDialogSubmit: () => void;
  onFilterChange: (updatedFilter: FilterChangeInput) => void;
  onFilterApply: () => void;
  dynamicColumns: GetAccountRuleGrid_getAccountRuleGrid_accountRuleHeaders[];
  accountFilters: FilterInput;
  activeFiltersCount: number;
  onClearAll: () => void;
}

const AccountRuleHeader: React.FC<AccountRuleHeaderProps> = ({
  territoryGroupTypes,
  onTerritoryGroupTypeChange,
  currentTerritoryGroupTypeId,
  selectedAccountRules,
  onDialogSubmit,
  onFilterChange,
  onFilterApply,
  dynamicColumns,
  accountFilters,
  activeFiltersCount,
  onClearAll
}) => {
  const [isReassignDialogOpen, setIsReassignDialogOpen] = useState(false);

  const [isAddedConfigurationsForUserPermissionsOn] = useTreatment(
    SplitFeatures.ADDED_CONFIGURATIONS_FOR_USER_PERMISSIONS
  );

  const canViewUnassigned = useCanUser(UserAction.ACCOUNT_RULE_GRID_VIEW_UNASSIGNED);
  const canViewAccountList = useCanUser(UserAction.ACCOUNT_LIST_VIEW);
  const canReassignAccountRules = useCanUser(UserAction.ACCOUNT_RULE_GRID_REASSIGN);

  const shouldShowMoveButton = isAddedConfigurationsForUserPermissionsOn ? canViewAccountList : canReassignAccountRules;

  const handleDialogClose = () => setIsReassignDialogOpen(false);
  const handleDialogSubmit = () => {
    setIsReassignDialogOpen(false);
    onDialogSubmit();
  };

  const filterButtonText = (
    <div>
      {formatMessage('FILTERS')}
      {!!activeFiltersCount && <span className={b('filterCount')}>{activeFiltersCount}</span>}
    </div>
  );

  return (
    <>
      <header className={b()}>
        <div className={b('container')}>
          <div className={b('tgtWrapper')}>
            <AccountQuotaTGTFilter
              territoryGroupTypes={territoryGroupTypes}
              onTerritoryGroupTypeChange={onTerritoryGroupTypeChange}
              currentTerritoryGroupType={currentTerritoryGroupTypeId}
              shouldIncludeUnassignedItem={canViewUnassigned}
              icon={<Search />}
            />
            <Popover
              content={
                <AccountRuleFilter
                  dynamicColumns={dynamicColumns}
                  onFilterChange={onFilterChange}
                  accountFilters={accountFilters}
                  onFilterApply={onFilterApply}
                  shouldShowStaticColumns={true}
                />
              }
              placement={'bottom-start'}
              onClosed={onFilterApply}
            >
              <TextButton
                text={filterButtonText}
                icon={<Filter size={20} />}
                type="button"
                testId="filter-button"
                minimal
                large={false}
                className={b('filterButton')}
                rightIcon={<ChevronDown size={20} />}
              />
            </Popover>
            {!!activeFiltersCount && (
              <TextButton
                text={formatMessage('CLEAR_ALL')}
                type="button"
                testId="clear-all-button"
                minimal
                large={false}
                className={b('filterButton')}
                onClick={onClearAll}
              />
            )}
          </div>
          {!!currentTerritoryGroupTypeId && shouldShowMoveButton && (
            <TextButton
              text={formatMessage('MOVE')}
              icon={<WatsonHealthStackedMove />}
              type="button"
              testId="move-button"
              large={false}
              intent="primary"
              disabled={selectedAccountRules.length === 0}
              onClick={() => setIsReassignDialogOpen(true)}
            />
          )}
        </div>
      </header>
      {isReassignDialogOpen && (
        <AccountReassignDialog
          currentTerritoryGroupTypeId={currentTerritoryGroupTypeId}
          onClose={handleDialogClose}
          onSubmit={handleDialogSubmit}
          selectedAccountRules={selectedAccountRules}
          data-testid="account-reassign-dialog"
        />
      )}
    </>
  );
};

export default AccountRuleHeader;
