import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import {
  UpdateReviewStatusDeploymentModel,
  UpdateReviewStatusDeploymentModelVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const UPDATE_REVIEW_STATUS_DEPLOYMENT_MODEL = gql`
  mutation UpdateReviewStatusDeploymentModel($deploymentModelId: Int!, $isReadyForReview: Boolean!) {
    updateDeploymentModel(input: { deploymentModelId: $deploymentModelId, isReadyForReview: $isReadyForReview }) {
      deploymentModelId
      isReadyForReview
    }
  }
`;

export const useUpdateReviewStatusDeploymentModel = createUseMutation<
  UpdateReviewStatusDeploymentModel,
  UpdateReviewStatusDeploymentModelVariables
>(UPDATE_REVIEW_STATUS_DEPLOYMENT_MODEL);
