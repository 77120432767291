import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import {
  addQuotaComponentToBattlecard,
  addQuotaComponentToBattlecardVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const ADD_QUOTA_COMPONENT_TO_BATTLE_CARD = gql`
  mutation addQuotaComponentToBattlecard($battlecardId: Int!, $quotaComponentId: Int!) {
    addQuotaComponentToBattlecard(input: { battlecardId: $battlecardId, quotaComponentId: $quotaComponentId }) {
      battlecardId
      quotaComponentId
    }
  }
`;

export const useAddQuotaComponentToBattlecard = createUseMutation<
  addQuotaComponentToBattlecard,
  addQuotaComponentToBattlecardVariables
>(ADD_QUOTA_COMPONENT_TO_BATTLE_CARD);
