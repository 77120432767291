import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { Position, Toaster } from '@blueprintjs/core';

import JobProgressStatusToast from 'components/JobProgressStatusToast/JobProgressStatusToast';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import { useScope } from 'app/contexts/scopeProvider';

import { GetJobProgress, PCJobType } from 'app/graphql/generated/graphqlApolloTypes';

import useShowToast from 'app/hooks/useShowToast';

import { JobStatus } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

export interface DeploymentModelToasterProps {
  planningCycleName: string;
  deploymentModelName: string;
  onActivate: () => void;
}

const getToasterText = (deploymentModelName: string) => {
  return {
    statusMessage: formatMessage('SCENARIO_ACTIVATION_STARTED_MESSAGE', { name: deploymentModelName }),
    activationFailedMessage: formatMessage('SCENARIO_ACTIVATION_FAILED_MESSAGE', { name: deploymentModelName }),
    activationCompletedMessage: formatMessage('SCENARIO_ACTIVATION_COMPLETED_MESSAGE', {
      name: deploymentModelName
    })
  };
};

const DeploymentModelToaster: React.FC<DeploymentModelToasterProps> = ({
  planningCycleName,
  deploymentModelName,
  onActivate
}: DeploymentModelToasterProps) => {
  const toasterText = getToasterText(deploymentModelName);
  const { statusMessage, activationFailedMessage, activationCompletedMessage } = toasterText;

  const { selectedPlanningCycle } = useScope();
  const showToast = useShowToast();

  const showErrorToast = () =>
    showToast(<ToastMessage title={formatMessage('ACTIVATION_FAILED')} message={activationFailedMessage} />, 'danger');

  const onDmCloneSuccess = (response: GetJobProgress, stopPolling: () => void) => {
    if (response?.getJobProgress?.pcCloneJobOutput?.pcCloneStatus === JobStatus.COMPLETED) {
      stopPolling();
      showToast(
        <ToastMessage
          title={formatMessage('ACTIVATE_PLANNING_CYCLE_COMPLETED')}
          message={activationCompletedMessage}
        />,
        'success'
      );
      onActivate();
    } else if (response?.getJobProgress?.pcCloneJobOutput?.pcCloneStatus === JobStatus.FAILED) {
      stopPolling();
      showErrorToast();
    }
  };

  return (
    <Toaster position={Position.TOP_RIGHT}>
      <JobProgressStatusToast
        data-testid="planning-cycle-toast"
        key={selectedPlanningCycle.id}
        jobDetail={{
          planningCycleId: selectedPlanningCycle.id,
          planningCycleName,
          jobType: PCJobType.DEPLOYMENT_MODEL_CLONE
        }}
        title={formatMessage('ACTIVATION_STARTED')}
        progressDescription={statusMessage}
        onPollSuccess={onDmCloneSuccess}
        onPollError={showErrorToast}
      />
    </Toaster>
  );
};

export default DeploymentModelToaster;
