// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cVGDg {\n  margin-top: var(--m);\n}\n\n  .saYcd {\n    margin-top: var(--m);\n  }\n\n  .SQnO9 {\n    margin-top: var(--xs);\n  }\n\n  .InjzU {\n    display: flex;\n    justify-content: flex-end;\n    padding: var(--m) 0;\n    grid-gap: var(--m);\n    gap: var(--m);\n  }\n\n  .PdUhS {\n    margin-right: auto;\n  }\n\n  .u31Cs {\n    max-height: 400px;\n    overflow-y: auto;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SegmentEditorDialog": "cVGDg",
	"SegmentEditorDialog_segmentNameField": "saYcd",
	"SegmentEditorDialog_customHierarchyFilterMenuContainer": "SQnO9",
	"SegmentEditorDialog_footer": "InjzU",
	"SegmentEditorDialog_secondaryActions": "PdUhS",
	"SegmentEditorDialog_filterContainer": "u31Cs"
};
export default ___CSS_LOADER_EXPORT___;
