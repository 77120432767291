import React, { useEffect } from 'react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import { useAssistant } from 'app/contexts/assistantProvider';
import { useCommandCenter } from 'app/contexts/commandCenterProvider';

import { AssistantDialogState, CommandCenterDrawerState } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './AssistantMenu.module.pcss';
import AssistantMenuIcon from './AssistantMenuIcon';

const b = block(style);

const AssistantMenu = () => {
  const { assistantDialogState, setAssistantDialogState } = useAssistant();
  const { commandCenterDrawerState, resetValues: resetCommandCenterValues } = useCommandCenter();

  useEffect(() => {
    // Close the assistant dialog if the command center is open
    if (
      commandCenterDrawerState !== CommandCenterDrawerState.CLOSE &&
      assistantDialogState === AssistantDialogState.OPEN
    ) {
      setAssistantDialogState(AssistantDialogState.CLOSE);
    }
  }, [commandCenterDrawerState]);

  const handleClick = () => {
    // We either close the dialog, or open the dialog and potentially also close the command center
    if (assistantDialogState === AssistantDialogState.OPEN) {
      setAssistantDialogState(AssistantDialogState.CLOSE);
    } else {
      if (commandCenterDrawerState !== CommandCenterDrawerState.CLOSE) {
        resetCommandCenterValues();
      }
      setAssistantDialogState(AssistantDialogState.OPEN);
    }
  };

  return (
    <div className={b()}>
      <IconButton
        icon={<AssistantMenuIcon />}
        active={assistantDialogState === AssistantDialogState.OPEN}
        onClick={handleClick}
        type="button"
        testId="assistant-menu-button"
        tooltipText={formatMessage('COPILOT')}
      />
    </div>
  );
};

export default AssistantMenu;
