import { EditableCallbackParams, ICellRendererParams, IServerSideDatasource } from '@ag-grid-community/core';
import { ApolloQueryResult } from '@apollo/client';
import dayjs from 'dayjs';
import capitalize from 'lodash.capitalize';
import clonedeep from 'lodash.clonedeep';

import DateRangeCellEditor from 'app/components/AdvancedGrid/CellEditors/DateRangeCellEditor/DateRangeCellEditor';
import DropdownMenuCellEditor from 'app/components/AdvancedGrid/CellEditors/DropdownMenuCellEditor/DropdownMenuCellEditor';
import FormatNumbersCellEditor from 'app/components/AdvancedGrid/CellEditors/FormatNumbersCellEditor/FormatNumbersCellEditor';
import ExpandableHeaderCellRenderer from 'app/components/AdvancedGrid/CellRenderers/ExpandableHeaderCellRenderer/ExpandableHeaderCellRenderer';
import LookupInfoHeaderRenderer from 'app/components/AdvancedGrid/CellRenderers/LookupInfoHeaderRenderer/LookupInfoHeaderRenderer';
import MessageTooltipHeaderRenderer from 'app/components/AdvancedGrid/CellRenderers/MessageTooltipHeaderRenderer/MessageTooltipHeaderRenderer';
import { getPaginationCheck } from 'app/components/AdvancedGrid/GridHelper';
import { formatFilterForRequest } from 'app/components/AdvancedGrid/Sheets/AccountRule/AccountRuleHelpers';

import { MONTH_PRESET_ARRAY } from 'app/constants/DataTrayConstants';

import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import { BLOCK_SIZE } from 'app/global/variables';

import {
  GetTerritoryRulesForFields,
  GetTerritoryRulesForFields_getTerritoryRules_territoryRules,
  GetTerritoryRulesForFields_getTerritoryRules_territoryRules_fields,
  GetTerritoryRulesForFields_getTerritoryRules_territoryRules_owners,
  GetTerritoryRulesForFields_getTerritoryRules_territoryRules_territoryRangeFields,
  ISheetDefinitionDef
} from 'app/graphql/generated/graphqlApolloTypes';
import { UPSERT_TQM_TERRITORY_RULE_EFFECTIVE_DATES } from 'app/graphql/mutations/upsertTerritoryRuleForTQM';

import {
  FieldIdsLookUpTable,
  GridFields,
  MeasureFormatType,
  MonthMap,
  QuotaGridColumnName,
  TerritorySheetDefinitionsOrderLookUpMap,
  TerritorySheetGridColumnName,
  UpsertTerritoryDateStatusType,
  UpsertTerritoryFields,
  Lookup,
  NumberInputFormatStyle,
  MeasureFieldType,
  MeasureSource,
  GridHeaders,
  INVALID_DATE,
  QuotaComponentEditableType
} from 'app/models';

import { formatMessage } from 'utils/messages/utils';

interface Fields extends GetTerritoryRulesForFields_getTerritoryRules_territoryRules_fields {
  fieldName: string;
}

interface TerritorySheetGridRow {
  __typename?: 'TerritoryRules';
  fields?: Fields[] | null;
  owners?: GetTerritoryRulesForFields_getTerritoryRules_territoryRules_owners[] | null;
  territoryRangeFields?:
    | (GetTerritoryRulesForFields_getTerritoryRules_territoryRules_territoryRangeFields | null)[]
    | null;
  territoryGroupId?: number;
  territoryGroupName?: string;
  ruleId: number | null;
  territoryId: string;
  territoryName: string;
  effectiveDate: string | null;
  endDate: string | null;
}

export const getMonthlyMeasureName = (measureName: string, planDurationMonths: Record<string, number>): string => {
  //Split monthly quota measureName into an array of strings and use the last string in the array to look for month name (e.g: 'Seasonality1 Month 1', so 1 will be January in MonthMap)
  const splitName = measureName.split(' ')[2];
  const planningCycleMonth = planDurationMonths[`Month ${splitName}`];
  return MonthMap[planningCycleMonth] ? MonthMap[planningCycleMonth] : measureName;
};

//Sort sheetDefinitions data to match with order of measures in design
export const getSortedSheetDefinitions = (sheetDefinitions: ISheetDefinitionDef[]): ISheetDefinitionDef[] => {
  return sheetDefinitions.sort((firstElem, secondElem) => {
    const firstElemName = firstElem.measureName;
    const secondElemName = secondElem.measureName;
    return (
      TerritorySheetDefinitionsOrderLookUpMap[firstElemName] - TerritorySheetDefinitionsOrderLookUpMap[secondElemName]
    );
  });
};

export const getFormattedSheetDefinitions = (
  sheetDefinitions: ISheetDefinitionDef[],
  planningCycleDuration: number,
  planningCycleStartDate: string,
  selectedQuotaComponentId?: number
): ISheetDefinitionDef[] => {
  //Separate between measure and monthly measures to reorder data from BE to match the order of measures in design
  const actualTerritoryQuotaDefinition = sheetDefinitions.filter((definition) => {
    return definition.measureName === TerritorySheetGridColumnName.ACTUAL_TERRITORY_QUOTA;
  });

  const seasonalityDefinitions = sheetDefinitions.filter((definition) => definition.measureName.includes('Month'));
  const sortedSeasonalityDefinitions = [...seasonalityDefinitions].sort((a, b) => {
    // We need to use split function here because BE returns seasonality month as 'Seasonality Month 1'
    const monthA = parseInt(a.measureName.split(' ')[2]);
    const monthB = parseInt(b.measureName.split(' ')[2]);
    return monthA - monthB;
  });

  const nonSeasonalityDefinitions = sheetDefinitions.filter((definition) => {
    return (
      !definition.measureName.includes('Month') &&
      definition.measureName !== TerritorySheetGridColumnName.ACTUAL_TERRITORY_QUOTA &&
      definition.measureName in TerritorySheetDefinitionsOrderLookUpMap
    );
  });

  //Filter out custom measure for ordering purpose
  const customDefinitions = sheetDefinitions.filter((definition) => {
    return (
      !definition.measureName.includes('Month') && !(definition.measureName in TerritorySheetDefinitionsOrderLookUpMap)
    );
  });

  //Format monthly measureName to match designer header for rendering
  const formattedSeasonalityDefinitions = sortedSeasonalityDefinitions.map((definition) => {
    const planDurationMonths = getPlanDurationMonths(planningCycleStartDate, planningCycleDuration);
    const formattedMeasureName = getMonthlyMeasureName(definition?.measureName, planDurationMonths);
    return { ...definition, measureName: formattedMeasureName };
  });

  const effectiveDateSheetDefinitions = {
    measureName: TerritorySheetGridColumnName.ADJUSTMENT_EFFECTIVE_DATE,
    quotaComponents: [
      {
        quotaComponentId: selectedQuotaComponentId,
        roles: [{ editable: QuotaComponentEditableType.TRUE, visible: QuotaComponentEditableType.TRUE }]
      }
    ]
  };

  const renameTerritoryQuota = actualTerritoryQuotaDefinition.map((quota) => {
    return { ...quota, measureName: capitalize(TerritorySheetGridColumnName.REVISED_TERRITORY_QUOTA) };
  });

  const sortedNonSeasonalityDefinitions = getSortedSheetDefinitions([
    ...nonSeasonalityDefinitions,
    effectiveDateSheetDefinitions
  ]);

  const combinedDefinitions = [
    ...sortedNonSeasonalityDefinitions,
    ...customDefinitions,
    ...renameTerritoryQuota,
    ...formattedSeasonalityDefinitions
  ];
  return combinedDefinitions;
};

export const getPlanDurationMonths = (
  planningCycleStartDate: string,
  planningCycleDuration: number
): Record<string, number> => {
  const startMonth = new Date(planningCycleStartDate).getUTCMonth();
  const monthValues = {};
  for (let i = 1; i <= planningCycleDuration; i++) {
    monthValues[`Month ${i}`] = startMonth + i === 12 ? 12 : (startMonth + i) % 12;
  }

  return monthValues;
};

// header style for Monthly quota breakdown and actual territory quota are handled in ExpandableHeaderCellRenderer
// column header normally left aligned --> headerClassNames[0]
// column with $Value and %Value, header are right aligned --> headerClassNames[1]
export const getHeaderClass = (column: ISheetDefinitionDef, headerClassNames: string[]): string => {
  const isMonthlyQuotaColumn = isMonthlyBreakdownColumn(column.measureName);
  if (isMonthlyQuotaColumn || column.measureName === TerritorySheetGridColumnName.REVISED_TERRITORY_QUOTA) {
    return null;
  }
  const measureFormatType = column.measureFormatType;
  if (
    measureFormatType === MeasureFormatType.CURRENCY ||
    measureFormatType === MeasureFormatType.NUMERIC ||
    measureFormatType === MeasureFormatType.PERCENTAGE
  ) {
    return headerClassNames[1];
  } else {
    return headerClassNames[0];
  }
};

// only $Value and %Value are right aligned  --> cellClassNames[0]
// only editable field have hover background --> cellClassNames[1]
// only parent row of expanded monlthy breakdown fields --> cellClassNames[2]
export const getCellClass = (
  measureFormatType: string,
  isEditableColumn: boolean,
  cellClassNames: string[],
  params: ICellRendererParams,
  measureName?: string
): string[] => {
  const isAMonthlyBreakdownColumn = isMonthlyBreakdownColumn(measureName);
  const classNames = [];
  if (checkAreAdjEffectiveDatesOutOfInTerritoryDateRanges(params)) {
    classNames.push(cellClassNames[3]);
  }

  const isMeasureFormatTypeValid = [
    MeasureFormatType.CURRENCY,
    MeasureFormatType.NUMERIC,
    MeasureFormatType.PERCENTAGE
  ].includes(measureFormatType as MeasureFormatType);

  const isNotAdjustment = params.colDef.field !== TerritorySheetGridColumnName.TERRITORY_QUOTA_ADJUSTMENT;

  if (isMeasureFormatTypeValid && isNotAdjustment) {
    classNames.push(cellClassNames[0]);
  }

  if (isEditableColumn) {
    classNames.push(cellClassNames[1]);
  }

  if (isAMonthlyBreakdownColumn && params.node.expanded && params.node.level === 0) {
    classNames.push(cellClassNames[2]);
  }

  return classNames;
};

export const getRowEffectiveDate = (startDate, endDate) => {
  const effectiveStartDate = startDate ? dayjs(startDate).format('M/D/YYYY') : formatMessage('NO_START_DATE');
  const effectiveEndDate = endDate ? dayjs(endDate).format('M/D/YYYY') : formatMessage('NO_END_DATE');

  if ((!startDate && !endDate) || (startDate === INVALID_DATE && endDate === INVALID_DATE)) {
    return null;
  }
  return `${effectiveStartDate} - ${effectiveEndDate}`;
};

export const getAdjustmentEffectiveDate = (startDate, endDate) => {
  if (!startDate && !endDate) {
    return null;
  }

  if (startDate === 'multiple' && endDate === 'multiple') {
    return 'Multiple';
  }

  const effectiveStartDate = startDate ? dayjs(startDate).format('M/D/YYYY') : formatMessage('NO_START_DATE');
  const effectiveEndDate = endDate ? dayjs(endDate).format('M/D/YYYY') : formatMessage('NO_END_DATE');

  return `${effectiveStartDate} - ${effectiveEndDate}`;
};

export const getFlattenRowData = (rowData, fieldIdsLookUpTable) => {
  const flattenRowData = {};
  rowData.fields?.forEach((field) => {
    const fieldName = fieldIdsLookUpTable[field?.fieldId];
    const isTerritoryQuotaAdjustment = fieldName === TerritorySheetGridColumnName.TERRITORY_QUOTA_ADJUSTMENT;
    flattenRowData[fieldName] = field.fieldValue;
    flattenRowData[TerritorySheetGridColumnName.ADJUSTMENT_EFFECTIVE_DATE] = getAdjustmentEffectiveDate(
      field.effectiveStartDate,
      field.effectiveEndDate
    );
    flattenRowData[GridHeaders.PARENT_ADJ_EFFECTIVE_DATE] = getAdjustmentEffectiveDate(
      field.effectiveStartDate,
      field.effectiveEndDate
    );
    if (isTerritoryQuotaAdjustment) flattenRowData[GridHeaders.PARENT_QUOTA_ADJUSTMENTS] = field.fieldValue;
  });
  return { ...flattenRowData };
};

export const formatRowData = (
  data: GetTerritoryRulesForFields_getTerritoryRules_territoryRules[],
  fieldIdsLookUpTable: FieldIdsLookUpTable,
  isAccountMoveInTerritoryQuotaGridEnabled = false
): TerritorySheetGridRow[] => {
  const cloneData = clonedeep(data);
  return cloneData?.map((row) => {
    const flattenRowData = getFlattenRowData(row, fieldIdsLookUpTable);
    flattenRowData['ruleId'] = row.ruleId;
    const rowData = {
      [GridFields.TERRITORY_ID]: row.territoryId,
      [GridFields.TERRITORY_NAME]: row.territoryName,
      [TerritorySheetGridColumnName.TERRITORY_EFFECTIVE_DATES]: getRowEffectiveDate(row.effectiveDate, row.endDate),
      ...flattenRowData,
      effectiveDate: row.effectiveDate,
      endDate: row.endDate,
      territoryGroupId: row.territoryGroupId
    };

    return isAccountMoveInTerritoryQuotaGridEnabled ? { ...rowData, redirectExists: row.redirectExists } : rowData;
  });
};

export const getHeaderComponent = (column: ISheetDefinitionDef): unknown => {
  const monthMap = {};
  MONTH_PRESET_ARRAY.forEach((month) => {
    monthMap[month] = ExpandableHeaderCellRenderer;
  });

  const headerMap = {
    [TerritorySheetGridColumnName.SEASONALITY]: LookupInfoHeaderRenderer,
    [TerritorySheetGridColumnName.PLANNED_QUOTA]: MessageTooltipHeaderRenderer,
    [TerritorySheetGridColumnName.REVISED_TERRITORY_QUOTA]: ExpandableHeaderCellRenderer,
    ...monthMap
  };

  return headerMap[column.measureName];
};

export const getHeaderParams = (column: ISheetDefinitionDef, previewDialogOpener: () => void): unknown => {
  const isAMonthlyBreakdownColumn = isMonthlyBreakdownColumn(column.measureName);
  const isRevisedTerritoryQuotaColumn = column.measureName === TerritorySheetGridColumnName.REVISED_TERRITORY_QUOTA;
  const isSeasonalityColumn = column.measureName === TerritorySheetGridColumnName.SEASONALITY;
  const isPlannedQuotaColumn = column.measureName === TerritorySheetGridColumnName.PLANNED_QUOTA;

  if (isSeasonalityColumn) {
    return {
      displayName: QuotaGridColumnName.SEASONALITY,
      hover: formatMessage('VIEW_SEASONALITY_DATA'),
      tooltipMessage: formatMessage('SEASONALITY_TOOLTIP_MESSAGE'),
      onButtonClicked: previewDialogOpener
    };
  } else if (isRevisedTerritoryQuotaColumn || isAMonthlyBreakdownColumn) {
    return { areColumnsHidden: !isRevisedTerritoryQuotaColumn };
  } else if (isPlannedQuotaColumn) {
    return {
      displayName: capitalize(TerritorySheetGridColumnName.PLANNED_QUOTA),
      tooltipMessage: formatMessage('PLANNED_QUOTA_TOOLTIP_MESSAGE')
    };
  } else {
    return null;
  }
};

const handleEditableColumnForEffectiveAdj = (params, isAdjustmentEffectiveDateColumn) => {
  //given grid cells belong to territory quota adjustment and adjustment effective date and the grid cell is the parent row or single row and the param?.data?.isEditingQuotaAdjustment is true then the cell is editable
  if (params?.data?.isEditingQuotaAdjustment) {
    if (isAdjustmentEffectiveDateColumn) {
      return true;
    }
    //should return false if the row is not single row or child row in row group
    return false;
  }

  // given chervon is expanded for effective dating, should enable editing on double click
  // for territory quota adjustment column and adjustment effective date column
  if (params.node.level > 0) {
    if (isAdjustmentEffectiveDateColumn) {
      return true;
    }
    return false;
  }

  return !isAdjustmentEffectiveDateColumn;
};

export const enableEditableColumns = (
  params: EditableCallbackParams,
  isOnlyCustomerHierarchySelected?: boolean,
  isAccountMoveInTerritoryQuotaGridEnabled?: boolean
): boolean => {
  const field = params.colDef.field;
  const isAdjustmentEffectiveDateColumn =
    field === TerritorySheetGridColumnName.TERRITORY_QUOTA_ADJUSTMENT ||
    field === TerritorySheetGridColumnName.ADJUSTMENT_EFFECTIVE_DATE;

  const isTerEffectiveDateOrSeasonalityColumn =
    field === TerritorySheetGridColumnName.TERRITORY_EFFECTIVE_DATES ||
    field === TerritorySheetGridColumnName.SEASONALITY;

  // when account move with quota is enabled (FF + QBT setting on BC is only customer account hierarchy)
  // territory effective date and seasonality is only editable when there is no redirect exists for that territory
  // quota adjustment is not editable cause adjustment will be made through redirect
  if (isAccountMoveInTerritoryQuotaGridEnabled && isOnlyCustomerHierarchySelected) {
    if (isTerEffectiveDateOrSeasonalityColumn) return !params.data?.redirectExists;
    if (field === TerritorySheetGridColumnName.TERRITORY_QUOTA_ADJUSTMENT) {
      return false;
    }
  }

  return handleEditableColumnForEffectiveAdj(params, isAdjustmentEffectiveDateColumn);
};

export const upsertTerritoryDate = async (params: ICellRendererParams, quotaComponentId: number): Promise<number> => {
  const data = params?.data;

  try {
    // eslint-disable-next-line no-restricted-syntax
    await apolloClient.mutate({
      mutation: UPSERT_TQM_TERRITORY_RULE_EFFECTIVE_DATES,
      variables: {
        ruleId: data.ruleId,
        territoryId: data.territoryId,
        territoryName: data.territoryName,
        territoryGroupId: data.territoryGroupId,
        effectiveDate: data.effectiveDate ? dayjs(data.effectiveDate).format('YYYY-MM-DD') : null,
        endDate: data.endDate ? dayjs(data.endDate).format('YYYY-MM-DD') : null,
        quotaComponentId
      }
    });
    return UpsertTerritoryDateStatusType.SUCCESS;
  } catch (_error) {
    return UpsertTerritoryDateStatusType.FAIL;
  }
};

export const isMonthlyBreakdownColumn = (columnName: string): boolean => {
  return MONTH_PRESET_ARRAY.includes(columnName);
};

export const getTerritoryFieldIds = (fieldIdsLookUpTable: FieldIdsLookUpTable): number[] => {
  return Object.keys(fieldIdsLookUpTable)
    .map((str) => {
      return Number(str);
    })
    .filter((number) => !isNaN(number));
};

export const getQuotaAdjustmentRowWithBreakdown = (
  rangeFields: GetTerritoryRulesForFields_getTerritoryRules_territoryRules_territoryRangeFields[],
  planDurationMonths: Record<string, number>,
  parentData: GetTerritoryRulesForFields_getTerritoryRules_territoryRules,
  fieldIdsLookUpTable: FieldIdsLookUpTable
): TerritorySheetGridRow[] => {
  const flattenParentData = getFlattenRowData(parentData, fieldIdsLookUpTable);

  const plannedQuotaIndex = rangeFields?.findIndex(
    (field) => field.fieldName === TerritorySheetGridColumnName.PLANNED_QUOTA
  );

  const plannedQuota = rangeFields.splice(plannedQuotaIndex, 1);
  rangeFields.sort((a, b) => a.rangeId - b.rangeId);
  rangeFields.unshift(...plannedQuota);
  const quotaAdjustmentRowWithBreakdown = [];
  rangeFields.forEach((field) => {
    const breakdownMap = {};
    field.breakdownByPeriod.forEach((breakdown) => {
      const seasonalityName = TerritorySheetGridColumnName.SEASONALITY.concat(' ', breakdown.period);
      const monthName = getMonthlyMeasureName(seasonalityName, planDurationMonths);
      breakdownMap[monthName] = breakdown.value.toString();
    });
    if (field.fieldName === TerritorySheetGridColumnName.PLANNED_QUOTA) {
      quotaAdjustmentRowWithBreakdown.push({
        [field.fieldName]: field.fieldValue,
        ...breakdownMap,
        ruleId: parentData.ruleId,
        [TerritorySheetGridColumnName.SEASONALITY]: flattenParentData[TerritorySheetGridColumnName.SEASONALITY]
      });
    } else {
      quotaAdjustmentRowWithBreakdown.push({
        [field.fieldName]: field.fieldValue,
        ...breakdownMap,
        [TerritorySheetGridColumnName.ADJUSTMENT_EFFECTIVE_DATE]: getAdjustmentEffectiveDate(
          field.effectiveStartDate,
          field.effectiveEndDate
        ),
        effectiveStartDate: field.effectiveStartDate,
        effectiveEndDate: field.effectiveEndDate,
        rangeId: field.rangeId,
        effectiveDate: parentData.effectiveDate,
        endDate: parentData.endDate,
        territoryGroupId: parentData.territoryGroupId,
        ruleId: parentData.ruleId,
        territoryParentId: parentData.territoryId,
        territoryParentName: parentData.territoryName
      });
    }
  });

  return quotaAdjustmentRowWithBreakdown;
};

export const generateDataSource = (
  fetchMore: (variables) => Promise<ApolloQueryResult<GetTerritoryRulesForFields>>,
  fetchMoreVariables: UpsertTerritoryFields,
  planningCycleDuration?: number,
  planningCycleStartDate?: string,
  selectedDeploymentModelId?: number,
  isAccountMoveInTerritoryQuotaGridEnabled?: boolean
): IServerSideDatasource => {
  const { quotaComponentId, battlecardId, measureId, sorting, totalCount, fieldIdsLookUpTable, fieldIds } =
    fetchMoreVariables;
  const dataSource: IServerSideDatasource = {
    getRows: async (params) => {
      const fetchMoreVariables = {
        quotaComponentId,
        battlecardId,
        measureId,
        sorting,
        fieldIds
      };
      if (params.request.groupKeys?.length > 0) {
        const parentData = params?.parentNode?.data;
        if (!parentData) return;
        const variables = {
          ...fetchMoreVariables,
          searchInput: {
            filters: handleFilterInput(parentData.territoryId, parentData.territoryName)
          },
          rangeFieldInput: {
            deploymentModelId: selectedDeploymentModelId,
            ruleId: parentData.ruleId,
            rangeFieldName: TerritorySheetGridColumnName.TERRITORY_QUOTA_ADJUSTMENT
          }
        };

        const refetchRangeFields = await fetchMore({ variables });
        const refetchQuotaBreakdown = refetchRangeFields?.data?.getTerritoryRules?.territoryRules[0];
        if (!refetchQuotaBreakdown) return;
        const quotaAdjustmentRowWithBreakdown = formatQuotaBreakdownSubGroup(
          refetchQuotaBreakdown,
          planningCycleStartDate,
          planningCycleDuration,
          fieldIdsLookUpTable
        );

        params.success({
          rowData: quotaAdjustmentRowWithBreakdown.slice(params.request.startRow, params.request.endRow),
          rowCount: quotaAdjustmentRowWithBreakdown.length
        });
      } else if (getPaginationCheck(params.request.startRow, totalCount) || params.request.endRow === BLOCK_SIZE) {
        const fetchMoreTerritoryRules = await fetchMore({
          variables: {
            startRow: params.request.startRow + 1,
            endRow: params.request.endRow,
            ...fetchMoreVariables
          }
        });

        const moreTerritoryRules = fetchMoreTerritoryRules?.data?.getTerritoryRules;

        const territoryRulesData = moreTerritoryRules?.territoryRules || [];
        const updatedTerritoryRuleTotalCount = moreTerritoryRules?.totalCount || 0;

        const rowData = formatRowData(
          territoryRulesData,
          fieldIdsLookUpTable,
          isAccountMoveInTerritoryQuotaGridEnabled
        );
        params?.success({ rowData, rowCount: updatedTerritoryRuleTotalCount });
      }
    }
  };
  return dataSource;
};

export const getHiddenColumns = (
  measureName: string,
  isAccountMoveInTerritoryQuotaGridEnabled: boolean,
  isOnlyCustomerHierarchySelected: boolean
): boolean => {
  const isAMonthlyBreakdownColumn = isMonthlyBreakdownColumn(measureName);
  const isActualTerritoryQuotaColumn = measureName === TerritorySheetGridColumnName.ACTUAL_TERRITORY_QUOTA;
  const isAdjustmentEffectiveDateColumn = measureName === TerritorySheetGridColumnName.ADJUSTMENT_EFFECTIVE_DATE;

  const shouldHideAdjEffectiveDateColumnWithAccountQuotaFF =
    isAccountMoveInTerritoryQuotaGridEnabled && isOnlyCustomerHierarchySelected && isAdjustmentEffectiveDateColumn;

  if (
    (isAMonthlyBreakdownColumn && !isActualTerritoryQuotaColumn) ||
    shouldHideAdjEffectiveDateColumnWithAccountQuotaFF
  ) {
    return true;
  }

  return false;
};

const getTooltipMessage = (canEditTerritoryQuota: boolean, redirectExists: boolean, measureName: string): string => {
  if (!canEditTerritoryQuota) {
    return formatMessage('TERRITORY_SHEET_GRID_EDIT_UNALLOWED_TOOLTIP');
  }

  if (redirectExists) {
    return measureName === TerritorySheetGridColumnName.SEASONALITY
      ? formatMessage('SEASONALITY_DISABLE_TOOLTIP_TEXT')
      : formatMessage('TERRITORY_EFFECTIVE_DATE_DISABLE_TOOLTIP_TEXT');
  }
  return null;
};

export const getCellRendererParams = (
  value: string | number,
  params: ICellRendererParams,
  canEditTerritoryQuota: boolean,
  isAccountMoveInTerritoryQuotaGridEnabled: boolean,
  isOnlyCustomerHierarchySelected: boolean
): unknown => {
  // this is to disable add button when planned quota is null, undefined or NaN
  const shouldDisableAddButton =
    params.data[TerritorySheetGridColumnName.PLANNED_QUOTA] === null ||
    params.data[TerritorySheetGridColumnName.PLANNED_QUOTA] === undefined ||
    isNaN(+params.data[TerritorySheetGridColumnName.PLANNED_QUOTA]);

  const measureName = params.colDef.field;
  const shouldShowAddButtonForEffectiveDating =
    measureName === TerritorySheetGridColumnName.TERRITORY_QUOTA_ADJUSTMENT && params.node.level === 0;

  const shouldShowAddButton = isAccountMoveInTerritoryQuotaGridEnabled
    ? !isOnlyCustomerHierarchySelected && shouldShowAddButtonForEffectiveDating
    : shouldShowAddButtonForEffectiveDating;

  const redirectExists =
    isAccountMoveInTerritoryQuotaGridEnabled && isOnlyCustomerHierarchySelected && params.data?.redirectExists;
  const isTooltipDisplayed = !canEditTerritoryQuota || redirectExists;

  const isTerEffectiveDateOrSeasonalityColumn =
    measureName === TerritorySheetGridColumnName.TERRITORY_EFFECTIVE_DATES ||
    measureName === TerritorySheetGridColumnName.SEASONALITY;

  return {
    value,
    shouldShowAddButton,
    shouldDisableAddButton,
    isTooltipDisplayed: isTooltipDisplayed && isTerEffectiveDateOrSeasonalityColumn,
    measureName,
    isEffectiveDateOutOfRange: checkAreAdjEffectiveDatesOutOfInTerritoryDateRanges(params),
    tooltipMessage: getTooltipMessage(canEditTerritoryQuota, redirectExists, measureName)
  };
};

export const getCellEditorFramework = (measureName): unknown => {
  const cellEditorLookupMap = {
    [TerritorySheetGridColumnName.SEASONALITY]: DropdownMenuCellEditor,
    [TerritorySheetGridColumnName.TERRITORY_EFFECTIVE_DATES]: DateRangeCellEditor,
    [TerritorySheetGridColumnName.ADJUSTMENT_EFFECTIVE_DATE]: DateRangeCellEditor,
    [TerritorySheetGridColumnName.PLANNED_QUOTA]: FormatNumbersCellEditor,
    [TerritorySheetGridColumnName.QUOTA_ADJUSTMENT]: FormatNumbersCellEditor,
    [TerritorySheetGridColumnName.TERRITORY_QUOTA_ADJUSTMENT]: FormatNumbersCellEditor
  };
  return cellEditorLookupMap[measureName];
};

export const getCellEditorValue = (params) => {
  const field = params.colDef.field;
  return field === TerritorySheetGridColumnName.TERRITORY_QUOTA_ADJUSTMENT && params.data?.isEditingQuotaAdjustment
    ? null
    : params.value;
};

export const getCellEditorParams = (
  column: ISheetDefinitionDef,
  lookups: Lookup[],
  params: ICellRendererParams,
  currency: string
): unknown => {
  const { measureFieldType, measureSource, measureName } = column;

  const isALookupInputColumn = measureFieldType === MeasureFieldType.INPUT && measureSource === MeasureSource.LOOKUP;

  if (isALookupInputColumn) {
    const lookup = lookups.find((lookup) => lookup.lookupId === column?.fieldId);
    const selectMenuItems = (lookup?.metadata?.tableData ? Object.keys(lookup.metadata.tableData) : []).map(
      (lookupVal) => ({
        key: lookupVal,
        value: lookupVal
      })
    );

    return {
      items: selectMenuItems,
      value: params.value
    };
  }

  if (measureName === TerritorySheetGridColumnName.ADJUSTMENT_EFFECTIVE_DATE) {
    const maxDate = params.data.endDate;
    const minDate = params.data.effectiveDate;
    return {
      name: 'adjustmentEffectiveDates',
      maxDate: maxDate ? dayjs(maxDate).toDate() : dayjs().toDate(),
      minDate: minDate ? dayjs(minDate).toDate() : dayjs().toDate(),
      effectiveStartDate: params.node.level === 0 ? null : params.data['effectiveStartDate'],
      effectiveEndDate: params.node.level === 0 ? null : params.data['effectiveEndDate']
    };
  }

  return {
    placeHolder: formatMessage('ENTER_AMOUNT'),
    value: getCellEditorValue(params),
    formatStyle: NumberInputFormatStyle.CURRENCY,
    currency
  };
};

export const valueSetterForAdjEffectiveDate = (params) => {
  const oldEndDate = params.data['effectiveEndDate'];
  const oldStartDate = params.data['effectiveStartDate'];
  const newStartDate = dayjs(params.newValue.effectiveStartDate).format('M/D/YYYY');
  const newEndDate = dayjs(params.newValue.effectiveEndDate).format('M/D/YYYY');
  const newAdjEffectiveDate = getRowEffectiveDate(newStartDate, newEndDate);
  if ((oldStartDate === newStartDate && oldEndDate === newEndDate) || !newAdjEffectiveDate) return false;
  params.data['effectiveEndDate'] = newEndDate;
  params.data['effectiveStartDate'] = newStartDate;
  params.data[TerritorySheetGridColumnName.ADJUSTMENT_EFFECTIVE_DATE] = newAdjEffectiveDate;
  return true;
};

export const valueSetter = (params) => {
  const oldValue = params.oldValue;
  const newValue = params.newValue;
  const name = params.colDef.field;

  if (newValue === oldValue) return false;
  params.data[name] = params.newValue;
  return true;
};

export const valueSetterForTerritoryEffectiveDate = (params) => {
  const oldEndDate = params.data['endDate'];
  const oldStartDate = params.data['effectiveDate'];
  const newStartDate = dayjs(params.newValue.effectiveStartDate).format('M/D/YYYY');
  const newEndDate = dayjs(params.newValue.effectiveEndDate).format('M/D/YYYY');
  const newEffectiveDate = getRowEffectiveDate(newStartDate, newEndDate);

  if ((oldStartDate === newStartDate && oldEndDate === newEndDate) || !newEffectiveDate) return false;
  params.data[TerritorySheetGridColumnName.TERRITORY_EFFECTIVE_DATES] = newEffectiveDate;
  params.data['endDate'] = newEndDate;
  params.data['effectiveDate'] = newStartDate;
  return true;
};

export const handleFilterInput = (territoryId, territoryName) => {
  const filters = {};
  filters['territoryId'] = {
    filter: territoryId,
    filterTo: null,
    filterType: 'text',
    type: 'contains'
  };
  filters['territoryName'] = {
    filter: territoryName,
    filterTo: null,
    filterType: 'text',
    type: 'contains'
  };
  return formatFilterForRequest(filters);
};

export const formatQuotaBreakdownSubGroup = (
  territoryRulesWithQuotaBreakdown,
  planningCycleStartDate,
  planningCycleDuration,
  fieldIdsLookUpTable
): TerritorySheetGridRow[] => {
  const territoryRangeFields = territoryRulesWithQuotaBreakdown?.territoryRangeFields;
  const planDurationMonths = getPlanDurationMonths(planningCycleStartDate, planningCycleDuration);
  const quotaAdjustmentRowWithBreakdown = getQuotaAdjustmentRowWithBreakdown(
    territoryRangeFields,
    planDurationMonths,
    territoryRulesWithQuotaBreakdown,
    fieldIdsLookUpTable
  );
  return quotaAdjustmentRowWithBreakdown;
};

export const handleFlashCells = (params, rowNodes) => {
  const valueListForUpdates = params.node.changedValues;
  const gridApi = params.api;
  const columnsWithUpdatedvalue = valueListForUpdates.map((item) => item.fieldName);
  const flashingColumns = [
    ...MONTH_PRESET_ARRAY,
    TerritorySheetGridColumnName.REVISED_TERRITORY_QUOTA,
    ...columnsWithUpdatedvalue
  ];

  gridApi.flashCells({
    rowNodes,
    columns: flashingColumns
  });
};

export const handleAddingNewAdjEffectiveDates = async (params) => {
  const gridApi = params.api;
  const rowNodes = params.node;
  gridApi?.refreshServerSideStore({ purge: true });
  handleFlashCells(params, [rowNodes]);
  params.node.changedValues = [];
  return;
};

export const handleRefetchEditingRows = async (
  variables,
  params,
  fetchMore,
  formatInput,
  isAccountMoveInTerritoryQuotaGridEnabled
) => {
  const gridApi = params.api;
  const { refetchTerritoryRuleVariables, refetchQuotaBreakdownVariables } = variables;
  const [refetchTerritoryRules, refetchRangeFields] = await Promise.all([
    fetchMore({ variables: refetchTerritoryRuleVariables }),
    fetchMore({ variables: refetchQuotaBreakdownVariables })
  ]);
  const { planningCycleStartDate, planningCycleDuration, fieldIdsLookUpTable } = formatInput;
  const refetchData = refetchTerritoryRules?.data?.getTerritoryRules?.territoryRules;

  const refetchQuotaBreakdown = refetchRangeFields?.data?.getTerritoryRules?.territoryRules[0];
  if (!refetchData && !refetchQuotaBreakdown) return;
  const newRowData = formatRowData(refetchData, fieldIdsLookUpTable, isAccountMoveInTerritoryQuotaGridEnabled);
  const newQuotaBreakdown = formatQuotaBreakdownSubGroup(
    refetchQuotaBreakdown,
    planningCycleStartDate,
    planningCycleDuration,
    fieldIdsLookUpTable
  );

  const rowNodes = [];
  gridApi?.forEachNode((node) => {
    const currentRowRuleId = params.data.ruleId;
    const nodeRuleId = node.data.ruleId;
    if (currentRowRuleId !== nodeRuleId) return;
    if (node.level === 0) {
      node.updateData(...newRowData);
      rowNodes.push(node);
    } else {
      const currentBreakdownValue = newQuotaBreakdown.find((breakdown) => {
        return breakdown['rangeId'] === node.data['rangeId'];
      });
      const updatedBreakdownvalue = params.node.changedValues.find((value) => value.rangeId === node.data['rangeId']);
      const isTerritoryEffectiveDateUpdated = params.node.changedValues.find(
        (value) => value.fieldName === TerritorySheetGridColumnName.TERRITORY_EFFECTIVE_DATES
      );
      node.updateData(currentBreakdownValue);
      if (updatedBreakdownvalue && !isTerritoryEffectiveDateUpdated) rowNodes.push(node);
    }
  });
  handleFlashCells(params, rowNodes);
  gridApi.refreshCells({ force: true });
  params.node.changedValues = [];
};

const getMonthAndYearForProvidedDate = (date) => {
  if (!date) return undefined;
  return Date.parse(dayjs(date).format('YYYY/MM'));
};

export const checkAreAdjEffectiveDatesOutOfInTerritoryDateRanges = (params: ICellRendererParams): boolean => {
  if (!params) return false;
  const data = params.data;
  const isEffectiveDatingColumn = params.colDef.field === TerritorySheetGridColumnName.ADJUSTMENT_EFFECTIVE_DATE;
  if (!isEffectiveDatingColumn) return false;
  const territoryEffectiveStartDate = getMonthAndYearForProvidedDate(data?.effectiveDate);
  const territoryEffectiveEndDate = getMonthAndYearForProvidedDate(data?.endDate);
  const adjEffectiveStartDate = getMonthAndYearForProvidedDate(data?.effectiveStartDate);
  const adjEffectiveEndDate = getMonthAndYearForProvidedDate(data?.effectiveEndDate);

  if (adjEffectiveStartDate < territoryEffectiveStartDate) {
    return true;
  }

  if (adjEffectiveEndDate > territoryEffectiveEndDate) {
    return true;
  }

  return false;
};
