import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Menu } from '@blueprintjs/core';
import { ChevronLeft, ChevronRight, Close } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';

import CommandCenterContent from 'app/components/CommandCenter/CommandCenterContent/CommandCenterContent';
import CommandCenterHierarchyPanel from 'app/components/CommandCenterHierarchyPanel/CommandCenterHierarchyPanel';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useCommandCenter } from 'app/contexts/commandCenterProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { useFileUpload } from 'app/core/fileUpload/fileUploadProvider';

import { GetPaginatedRootHierarchies_getPaginatedRootHierarchies_items } from 'app/graphql/generated/graphqlApolloTypes';
import { useGetMemberCountLazy } from 'app/graphql/queries/getMemberCount';

import { CommandCenterDrawerState, CommandCenterMenuItem, CommandCenterMenuState, HierarchySpec } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import { CommandCenterHierarchy } from './CommandCenterHierarchy';
import style from './CommandCenterMenu.module.pcss';
import { CommandCenterMenuAdmin } from './CommandCenterMenuAdmin';
import { CommandCenterMenuPlanningCycle } from './CommandCenterMenuPlanningCycle';
import { useDefaultMenuItems } from './hooks/useDefaultMenuItems';
import { useGetConversionRates } from './hooks/useGetConversionRates';
import { useGetPaginatedRootHierarchiesInfo } from './hooks/useGetPaginatedRootHierarchies';

const b = block(style);
interface CommandCenterMenuProps {
  setHierarchyToEdit: Dispatch<SetStateAction<GetPaginatedRootHierarchies_getPaginatedRootHierarchies_items | null>>;
}

const CommandCenterMenu: React.FC<CommandCenterMenuProps> = ({ setHierarchyToEdit }: CommandCenterMenuProps) => {
  const [contentTitle, setContentTitle] = useState<string>('');
  const [selectedHierarchy, setSelectedHierarchy] =
    useState<GetPaginatedRootHierarchies_getPaginatedRootHierarchies_items>(null);

  const { quotaComponentList } = useBattleCard();
  const { selectedPlanningCycle, selectedTenant } = useScope();
  const { measuresData, getMeasures } = usePlanTargets();
  const { showAddHierarchyDialog, showEditHierarchyDialog, showUploadHierarchyDialog } = useFileUpload();
  const {
    commandCenterDrawerState,
    setCommandCenterDrawerState,
    commandCenterMenuState,
    setCommandCenterMenuState,
    activeMenu,
    setActiveMenu
  } = useCommandCenter();

  const { getRootHierarchies, rootHierarchies, loading, hasError } = useGetPaginatedRootHierarchiesInfo();

  const [getMemberCount, { data: memberCount }] = useGetMemberCountLazy({
    variables: {
      endRow: 1,
      startRow: 1,
      tenantId: selectedTenant?.id
    }
  });

  const [getConversionRates, { data: conversionRates }] = useGetConversionRates(selectedPlanningCycle?.id);

  const defaultMenuItems = useDefaultMenuItems(getMemberCount, memberCount?.getMemberList?.totalCount ?? 0);

  // Refetch the entire list of root hierarchies
  const refetchRootHierarchies = (dialogClosed: boolean = true) => {
    if (dialogClosed && !!rootHierarchies) {
      getRootHierarchies?.();
    }
  };

  // Make sure hierarchy count updates after file uploaded
  useEffect(() => {
    refetchRootHierarchies(!showAddHierarchyDialog);
  }, [showAddHierarchyDialog]); // Add

  useEffect(() => {
    refetchRootHierarchies(!showEditHierarchyDialog);
  }, [showEditHierarchyDialog]); // Edit

  useEffect(() => {
    refetchRootHierarchies(!showUploadHierarchyDialog);
  }, [showUploadHierarchyDialog]); // Upload

  useEffect(() => {
    if (commandCenterDrawerState === CommandCenterDrawerState.OPEN) {
      // make sure that menu is in full view every time command center opens
      setCommandCenterMenuState(CommandCenterMenuState.FULL);
      getRootHierarchies?.();
      getMemberCount();
      getConversionRates();
    }
  }, [commandCenterDrawerState]);

  useEffect(() => {
    if (selectedPlanningCycle?.id) {
      getMeasures(selectedPlanningCycle.id);
      getRootHierarchies?.();
    }
  }, [selectedPlanningCycle]);

  // This make sure the command center content has the latest title and selected hierarchy after renaming a root hierarchy
  useEffect(() => {
    if (rootHierarchies) {
      const activeHierarchy = rootHierarchies.find((hierarchy) => hierarchy.rootKey === activeMenu);
      if (activeHierarchy) {
        setContentTitle(activeHierarchy.rootName);
        setHierarchyToEdit({ ...activeHierarchy });
        setSelectedHierarchy(activeHierarchy);
      }
    }
  }, [rootHierarchies]);

  // deal with a menu item being opened from another component
  useEffect(() => {
    // note: only "default" menu items can be opened in this manner,
    // since custom hierarchies must be fetched every time the command center is opened
    if (Object.values(CommandCenterMenuItem).includes(activeMenu as CommandCenterMenuItem)) {
      changeActiveMenuItem({ key: activeMenu, hierarchy: null });
    }
  }, [activeMenu]);

  const setCommandCenterMenuContentProps = (itemKey, hierarchy) => {
    const selectedMenuItem = defaultMenuItems.find((item) => item.key === itemKey);

    // render a default menu item's content
    if (selectedMenuItem) {
      setSelectedHierarchy(null);
      setContentTitle(selectedMenuItem.title);
      setCommandCenterDrawerState(selectedMenuItem.drawerState);
      setCommandCenterMenuState(selectedMenuItem.menuState);
    }
    // render a hierarchy's content
    else {
      setContentTitle(hierarchy?.rootName);
      setSelectedHierarchy(hierarchy);
      setCommandCenterDrawerState(CommandCenterDrawerState.HALF);
      setCommandCenterMenuState(CommandCenterMenuState.FULL);
    }
  };

  const changeActiveMenuItem = ({ key, hierarchy }) => {
    setActiveMenu(key);
    setHierarchyToEdit(hierarchy);
    setCommandCenterMenuContentProps(key, hierarchy);
  };

  const CommandCenterMenuFullViewHeader = (
    // In full menu view, render header text and
    // the close icon when only menu opens
    // or the collapse icon when the panel is expanded
    <div className={b('headerTextWithIcon')}>
      <div className={b('headerText')}>
        <HTMLHeading tagLevel="h4" text={formatMessage('COMMAND_CENTER')} />
      </div>
      {commandCenterDrawerState === CommandCenterDrawerState.OPEN && (
        <div data-testid={'close-btn-container'}>
          <IconButton
            type="button"
            icon={<Close size={24} />}
            onClick={() => setCommandCenterDrawerState(CommandCenterDrawerState.CLOSE)}
            testId={'close-btn'}
            title={formatMessage('CLOSE')}
          />
        </div>
      )}
      {commandCenterDrawerState === CommandCenterDrawerState.EXPAND && (
        <div data-testid={'collapse-btn-container'}>
          <IconButton
            type="button"
            icon={<ChevronLeft />}
            onClick={() => setCommandCenterMenuState(CommandCenterMenuState.ICON_ONLY)}
            testId={'collapse-btn'}
            title={formatMessage('CLOSE')}
          />
        </div>
      )}
    </div>
  );

  const CommandCenterMenuIconOnlyViewHeader = (
    <div data-testid={'expand-btn-container'}>
      <IconButton
        type="button"
        icon={<ChevronRight />}
        onClick={() => setCommandCenterMenuState(CommandCenterMenuState.FULL)}
        testId={'expand-btn'}
        title={formatMessage('EXPAND')}
      />
    </div>
  );

  const getExpandedPanelContent = () => {
    const selectedItem = defaultMenuItems.find((i) => i.key === activeMenu);
    if (!!selectedItem) {
      // active menu is not a hierarchy
      return selectedItem.component;
    } else if (!!selectedHierarchy) {
      // active menu is a hierarchy
      return (
        <CommandCenterHierarchyPanel
          // Casting because API currently does not return an enum
          selectedHierarchy={selectedHierarchy as HierarchySpec}
          refetchRootHierarchies={refetchRootHierarchies}
        />
      );
    }
    return <></>;
  };

  const handleHierarchyItemClicked = () => {
    setCommandCenterMenuState(CommandCenterMenuState.FULL);
    changeActiveMenuItem({ key: CommandCenterMenuItem.MEASURES, hierarchy: null });
  };

  const isOnFullMenuView = commandCenterMenuState === CommandCenterMenuState.FULL;
  return (
    <div className={b()}>
      <div className={b('menuContainer')}>
        <div className={b('header', { iconOnly: !isOnFullMenuView })} data-testid={'command-center-header'}>
          {isOnFullMenuView ? CommandCenterMenuFullViewHeader : CommandCenterMenuIconOnlyViewHeader}
        </div>
        <Menu className={b('menu', { iconOnly: !isOnFullMenuView })}>
          <CommandCenterMenuAdmin defaultMenuItems={defaultMenuItems} changeActiveMenuItem={changeActiveMenuItem} />
          <CommandCenterMenuPlanningCycle
            changeActiveMenuItem={changeActiveMenuItem}
            quotaComponentList={quotaComponentList}
            conversionRates={conversionRates?.getConversionRates}
            isOnFullMenuView={isOnFullMenuView}
            activeMenu={activeMenu}
            handleHierarchyItemClicked={handleHierarchyItemClicked}
            measuresData={measuresData}
          />
          <CommandCenterHierarchy
            data={rootHierarchies}
            activeMenu={activeMenu}
            changeActiveMenuItem={changeActiveMenuItem}
            isOnFullMenuView={isOnFullMenuView}
            hasError={hasError}
            selectedHierarchy={selectedHierarchy}
            setSelectedHierarchy={setSelectedHierarchy}
            refetchRootHierarchies={refetchRootHierarchies}
            loading={loading}
          />
        </Menu>
        <CommandCenterContent content={getExpandedPanelContent()} title={contentTitle} />
      </div>
    </div>
  );
};

export default CommandCenterMenu;
