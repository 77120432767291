import { Dispatch, SetStateAction } from 'react';

import { GridApi, ICellRendererParams } from '@ag-grid-community/core';
import {
  MutationTuple,
  // eslint-disable-next-line no-restricted-imports
  useMutation
} from '@apollo/client';

import { DeleteHierarchyById, DeleteHierarchyByIdVariables } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { DELETE_HIERARCHY_BY_ID } from 'app/graphql/mutations/deleteHierarchyById';

import useShowToast from 'app/hooks/useShowToast';

import { formatMessage } from 'utils/messages/utils';

interface UseDeleteHierarchyProps {
  isMultiDelete?: boolean;
  setShowDeleteHierarchyDialog: Dispatch<SetStateAction<boolean>>;
  rootHierarchyName: string;
  setSelectedNode: Dispatch<SetStateAction<ICellRendererParams>>;
  selectedNode: ICellRendererParams;
  setInitialBlock;
  gridApi?: GridApi;
  multiSelectedNodes?: ICellRendererParams['node'][];
  setMultiSelectedNodes?: Dispatch<SetStateAction<ICellRendererParams['node'][]>>;
  searchString?: string;
  setSearchString?: (value) => void;
}

const findFullPath = (currentNode, path) => {
  const parentName = currentNode?.parent?.data?.name;
  if (!parentName) {
    return path;
  }
  path.unshift(parentName);
  return findFullPath(currentNode.parent, path);
};

const handleMultiDelete = (multiSelectedNodes, setInitialBlock, gridApi, setMultiSelectedNodes) => {
  multiSelectedNodes.forEach((node) => {
    // if the node is on the first level, refresh the entire tree
    if (!node.parent?.key) {
      return setInitialBlock(null);
    }
    const parentChildrenList = node.parent?.data?.children;

    // node.id is provided by ag grid which shows the path.
    // eg. 'node1-node2-0' means the node is the first child (index 0) of node2, and node2 is a child of node1.
    // if the parent of the node only have 1 child, after deleting the node we need to make sure the parent is refreshed so that
    // the expand icon and folder icon updates. In this case, route should be ['node1'], otherwise it should be ['node1', 'node2']
    const path = parentChildrenList?.length === 1 ? node.id.split('-').slice(0, -2) : node.id.split('-').slice(0, -1);

    gridApi?.refreshServerSideStore({ route: path });
    // pop 1 item from the parent's children list so that it has the latest children count
    parentChildrenList?.pop();
  });

  gridApi['selectionService'].selectedNodes = {};

  setMultiSelectedNodes([]);
};

const handleSingleDelete = (selectedNode, setInitialBlock, setSelectedNode) => {
  const parentNode = selectedNode?.node?.parent;
  const route = findFullPath(parentNode?.data?.children?.length > 1 ? selectedNode.node : parentNode, []);

  selectedNode?.api?.refreshServerSideStore({ route });
  // pop 1 item from the parent's children list so that it has the latest children count
  parentNode?.data?.children?.pop();
  selectedNode.node?.setSelected(false);
  // If the node is on the first level, referesh the entire tree
  if (!selectedNode.node?.parent?.key) {
    setInitialBlock(null);
  }

  setSelectedNode(null);
};

const handleDeleteInFilteredTree = (searchString, setSearchString, setMultiSelectedNodes, setInitialBlock) => {
  // Reset search string and search for the term again to get the latest result
  const tempSearchString = searchString;
  setSearchString('');
  setSearchString(tempSearchString);
  setMultiSelectedNodes?.([]);
  // Re-fetch the original tree to make sure it shows the latest data
  setInitialBlock(null);
};

export const useDeleteHierarchy = ({
  isMultiDelete = false,
  setShowDeleteHierarchyDialog,
  rootHierarchyName,
  setSelectedNode,
  selectedNode,
  setInitialBlock,
  gridApi,
  multiSelectedNodes,
  setMultiSelectedNodes,
  searchString,
  setSearchString
}: UseDeleteHierarchyProps): MutationTuple<DeleteHierarchyById, DeleteHierarchyByIdVariables> => {
  const showToast = useShowToast();

  return useMutation<DeleteHierarchyById, DeleteHierarchyByIdVariables>(DELETE_HIERARCHY_BY_ID, {
    onCompleted() {
      setShowDeleteHierarchyDialog(false);
      if (searchString !== '') {
        const toastMessage = multiSelectedNodes
          ? formatMessage('MEMBERS_DELETE_SUCCESS_WITH_COUNT', { count: multiSelectedNodes?.length })
          : formatMessage('HIERARCHY_MEMBER_DELETE_SUCCESS');
        showToast(toastMessage, 'success');
        return handleDeleteInFilteredTree(searchString, setSearchString, setMultiSelectedNodes, setInitialBlock);
      }
      if (isMultiDelete) {
        handleMultiDelete(multiSelectedNodes, setInitialBlock, gridApi, setMultiSelectedNodes);
        showToast(formatMessage('MEMBERS_DELETE_SUCCESS_WITH_COUNT', { count: multiSelectedNodes?.length }), 'success');
      } else {
        handleSingleDelete(selectedNode, setInitialBlock, setSelectedNode);
        const deleteSuccessToastMessage = formatMessage('DELETE_SUCCESS_WITH_NAMES_QUOTED', {
          nameOne: rootHierarchyName.toLowerCase().charAt(0).toUpperCase() + rootHierarchyName.toLowerCase().slice(1),
          nameTwo: selectedNode?.data?.name
        });
        showToast(deleteSuccessToastMessage, 'success');
      }
    },
    onError({ graphQLErrors, networkError }) {
      const singleDeleteToastMessage = formatMessage('HIERARCHY_DELETE_ERROR_WITH_NAMES', {
        rootName: rootHierarchyName.toLowerCase(),
        name: selectedNode?.data?.name || ''
      });
      setShowDeleteHierarchyDialog(false);
      handleError(graphQLErrors, networkError);
      if (isMultiDelete) {
        showToast(formatMessage('DELETE_HIERARCHIES_ERROR'), 'danger');
      } else {
        showToast(singleDeleteToastMessage, 'danger');
      }
    }
  });
};
