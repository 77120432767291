import React from 'react';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './UpsertTerritoryRuleViewRightPanelHeader.module.pcss';
const b = block(style);

const UpsertTerritoryRuleViewRightPanelHeader = () => {
  return (
    <div data-testid="right-panel-header" className={b('rightPanelHeader')}>
      <span data-testid="territory-rule-text" className={b('text', { columnText: true })}>
        {formatMessage('TERRITORY_RULE')}
      </span>
    </div>
  );
};

export default UpsertTerritoryRuleViewRightPanelHeader;
