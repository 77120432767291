import React, { useMemo } from 'react';

import { Search, CollapseAll, ExpandAll } from '@carbon/icons-react';
import { DividerV2 } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';

import { useMapVisualizationSettings } from 'app/components/TerritoryMap/hooks/useMapVisualizationSettings';
import SortMenu from 'app/components/TerritoryMap/SortMenu/SortMenu';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { SplitFeatures } from 'app/global/features';

import { SortDirection } from 'app/graphql/generated/graphqlApolloTypes';

import useTreatment from 'app/hooks/useTreatment';

import {
  CollectionFilter,
  CollectionFilterKind,
  HierarchyType,
  MchQuantity,
  NamedRootHierarchy,
  PrimaryTerritoryField,
  Segment
} from 'app/models';

import block from 'utils/bem-css-modules';
import { filterToSegmentClauses } from 'utils/helpers/segmentFilterUtils';
import {
  buildFilterWithExplicitNones,
  convertCollectionFilterToLegacy,
  isDefaultFilter
} from 'utils/helpers/territoryMapUtils';
import { formatMessage } from 'utils/messages/utils';

import ExpandableCustomHierarchyTags from './ExpandableCustomHierarchyTags';
import MapGridFilter from './MapGridFilter';
import MapGridFilterV2 from './MapGridFilterV2';
import style from './MapGridHeader.module.pcss';

const b = block(style);

interface MapGridHeaderProps {
  onSearchIconClick: () => void;
  customHierarchies: NamedRootHierarchy[];
  customHierarchyFilter: CollectionFilter<number>[];
  onCollapseRows?: () => void;
  onExpandRows?: () => void;
  showCollapseAndExpandButtons?: boolean;
  primaryTerritoryField?: PrimaryTerritoryField;
  onPrimaryTerritoryFieldChange?: (field: PrimaryTerritoryField) => void;
  sortOrder: SortDirection;
  onSortOrderChange: (sortOrder: SortDirection) => void;
  segments: Segment[];
}

const MapGridHeader: React.FC<MapGridHeaderProps> = ({
  onSearchIconClick,
  customHierarchies,
  customHierarchyFilter,
  onCollapseRows,
  onExpandRows,
  primaryTerritoryField,
  onPrimaryTerritoryFieldChange,
  showCollapseAndExpandButtons = false,
  sortOrder,
  onSortOrderChange,
  segments
}) => {
  const { chosenCustomHierarchy } = useDedicatedMapProvider();
  const [isMapSegmentationEnabled] = useTreatment(SplitFeatures.MAP_SEGMENTATION);
  const { rootHierarchies } = useMapContextRedistributor();
  const { primaryHierarchy } = useMapVisualizationSettings();

  const isCustomHierarchiesEnabled = chosenCustomHierarchy.quantity !== MchQuantity.NONE;

  const expandAndCollapseButtons = () => {
    if (!showCollapseAndExpandButtons) return null;

    return (
      <>
        <DividerV2 vertical className={b('divider')} />
        <IconButton
          type="button"
          icon={<CollapseAll />}
          testId="collapse-all-button"
          minimal
          onClick={onCollapseRows}
          tooltipText={formatMessage('COLLAPSE_ALL')}
        />
        <IconButton
          type="button"
          icon={<ExpandAll />}
          testId="expand-all-button"
          minimal
          onClick={onExpandRows}
          tooltipText={formatMessage('EXPAND_ALL')}
        />
      </>
    );
  };

  const isFilterDefault = isDefaultFilter(customHierarchyFilter);

  const convertedCustomHierarchyFilter = useMemo(
    () => convertCollectionFilterToLegacy(customHierarchyFilter),
    [customHierarchyFilter]
  );

  const filterAsSegmentClause = useMemo(() => {
    const fullMapFilter = buildFilterWithExplicitNones(customHierarchyFilter, rootHierarchies).filter(
      (currentFilter) => currentFilter.kind !== CollectionFilterKind.NONE
    );
    return filterToSegmentClauses(fullMapFilter, customHierarchies, rootHierarchies);
  }, [customHierarchyFilter, customHierarchies, rootHierarchies]);

  return (
    <div className={b()}>
      <div className={b('visualizationContainer')}>
        <div className={b('iconsContainer')}>
          <IconButton type="button" icon={<Search />} testId="search-icon-button" minimal onClick={onSearchIconClick} />
          {isMapSegmentationEnabled && (
            <SortMenu
              onPrimaryTerritoryFieldChange={onPrimaryTerritoryFieldChange}
              primaryTerritoryField={primaryTerritoryField}
              sortOrder={sortOrder}
              onSortOrderChange={onSortOrderChange}
            />
          )}
          {expandAndCollapseButtons()}
        </div>

        {isMapSegmentationEnabled && chosenCustomHierarchy.quantity !== MchQuantity.NONE && (
          <MapGridFilterV2
            customHierarchyFilter={customHierarchyFilter}
            segments={segments}
            showCollapseAndExpandButtons={showCollapseAndExpandButtons}
            customHierarchies={customHierarchies}
          />
        )}
        {!isMapSegmentationEnabled && (
          <MapGridFilter
            customHierarchyFilter={convertedCustomHierarchyFilter}
            customHierarchies={customHierarchies}
            shouldShowCustomHierarchyFilter={isCustomHierarchiesEnabled}
            primaryTerritoryField={primaryTerritoryField}
            onPrimaryTerritoryFieldChange={onPrimaryTerritoryFieldChange}
          />
        )}
      </div>
      {isMapSegmentationEnabled && (!isFilterDefault || customHierarchyFilter.length === 0) && (
        <div className={b('tagsContainer')}>
          <ExpandableCustomHierarchyTags
            isAccountFirstMap={primaryHierarchy === HierarchyType.CustomerAccountHierarchy}
            customHierarchies={customHierarchies}
            customHierarchyFilters={filterAsSegmentClause}
          />
        </div>
      )}
    </div>
  );
};

export default MapGridHeader;
