// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kAmIp {\n    width: 100%;\n    height: var(--grid-header-height);\n    display: flex;\n    align-items: center;\n    cursor: auto;\n    background-color: rgb(var(--color-light-gray-5));\n    box-shadow: 10px 0 10px -6px rgb(var(--color-light-gray-4)) inset;\n    border-bottom: var(--grey-border);\n  }\n  .waBZ2 {\n    font-weight: 400;\n  }\n  .bra-9 {\n      display: flex;\n      justify-content: flex-start;\n      width: 100%;\n      padding-left: var(--grid-header-padding);\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UpsertTerritoryRuleViewRightPanelHeader_rightPanelHeader": "kAmIp",
	"UpsertTerritoryRuleViewRightPanelHeader_text": "waBZ2",
	"UpsertTerritoryRuleViewRightPanelHeader_text__columnText": "bra-9"
};
export default ___CSS_LOADER_EXPORT___;
