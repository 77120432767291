// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KfyVT {\n  margin: 0 20px 0 5px;\n}\n\n  .KfyVT .bp3-button .bp3-icon {\n    height: 25px;\n    width: 25px;\n  }\n\n  .KfyVT .bp3-button .bp3-icon > svg {\n      height: 100%;\n      width: 100%;\n    }\n\n  .KfyVT .bp3-button .bp3-icon:hover > svg > path {\n    fill: url('#paint0_linear_2074_44857');\n  }\n\n  .KfyVT .bp3-button.bp3-active.bp3-minimal .bp3-icon > svg > path {\n    fill: url('#paint0_linear_2074_44859');\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AssistantMenu": "KfyVT"
};
export default ___CSS_LOADER_EXPORT___;
