import React from 'react';

const AssistantMenuIcon: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
        fill="url(#paint0_linear_2074_44855)"
      />
      <g clipPath="url(#clip0_2074_44855)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2948 9.71043C14.3072 9.68292 14.3197 9.65516 14.3324 9.62717L14.5726 9.09527C14.6444 8.9363 14.844 8.9363 14.9158 9.09527L15.156 9.62717C15.1687 9.65516 15.1812 9.68292 15.1936 9.71043C15.2073 9.74055 15.2207 9.77039 15.2341 9.79994C15.9067 11.2882 16.264 12.0534 16.8495 12.6389C17.4351 13.2244 18.2002 13.5817 19.6885 14.2543C19.718 14.2677 19.7479 14.2812 19.778 14.2948C19.8055 14.3072 19.8333 14.3197 19.8612 14.3324L20.3932 14.5726C20.5521 14.6444 20.5521 14.844 20.3932 14.9158L19.8613 15.1561C19.8333 15.1687 19.8055 15.1812 19.778 15.1937C19.7479 15.2073 19.718 15.2208 19.6885 15.2341C18.2002 15.9067 17.4351 16.2641 16.8495 16.8496C16.264 17.4351 15.9067 18.2003 15.2341 19.6885C15.2207 19.7181 15.2073 19.7479 15.1936 19.778C15.1812 19.8055 15.1687 19.8333 15.156 19.8613L14.9158 20.3932C14.844 20.5522 14.6444 20.5522 14.5726 20.3932L14.3324 19.8613C14.3197 19.8333 14.3072 19.8055 14.2948 19.778C14.2811 19.7479 14.2677 19.7181 14.2543 19.6885C13.5817 18.2003 13.2244 17.4351 12.6389 16.8496C12.0533 16.2641 11.2882 15.9067 9.79991 15.2341C9.77036 15.2208 9.74052 15.2073 9.7104 15.1937C9.68289 15.1812 9.65514 15.1687 9.62714 15.1561L9.09524 14.9158C8.93627 14.844 8.93627 14.6444 9.09524 14.5726L9.62714 14.3324C9.65513 14.3197 9.68289 14.3072 9.7104 14.2948C9.74052 14.2812 9.77036 14.2677 9.79991 14.2543C11.2882 13.5817 12.0533 13.2244 12.6389 12.6389C13.2244 12.0534 13.5817 11.2882 14.2543 9.79994C14.2677 9.77039 14.2811 9.74055 14.2948 9.71043Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.914 16.6795C19.8741 16.5912 19.7631 16.5912 19.7232 16.6795L19.5898 16.9751C19.5486 17.0663 19.5095 17.1527 19.4722 17.2349C19.4482 17.2879 19.4248 17.3391 19.4021 17.3885C19.3764 17.4446 19.3516 17.4984 19.3273 17.5503C19.0817 18.0757 18.9044 18.393 18.6487 18.6487C18.393 18.9044 18.0757 19.0817 17.5503 19.3273C17.4984 19.3516 17.4446 19.3764 17.3886 19.4021C17.3391 19.4248 17.2879 19.4482 17.2349 19.4722C17.1527 19.5095 17.0663 19.5486 16.9751 19.5897L16.6795 19.7232C16.5912 19.7631 16.5912 19.8741 16.6795 19.914L16.9751 20.0475C17.0663 20.0886 17.1527 20.1277 17.2349 20.165C17.2879 20.1891 17.3391 20.2124 17.3885 20.2351C17.4446 20.2608 17.4984 20.2857 17.5503 20.3099C18.0757 20.5555 18.393 20.7329 18.6487 20.9885C18.9044 21.2442 19.0817 21.5615 19.3273 22.087C19.3516 22.1388 19.3764 22.1926 19.4021 22.2487C19.4248 22.2982 19.4482 22.3493 19.4722 22.4023C19.5095 22.4845 19.5486 22.571 19.5898 22.6621L19.7232 22.9577C19.7631 23.046 19.8741 23.046 19.914 22.9577L20.0475 22.6621C20.0886 22.571 20.1277 22.4845 20.165 22.4023C20.1891 22.3493 20.2124 22.2982 20.2351 22.2487C20.2608 22.1926 20.2857 22.1388 20.3099 22.087C20.5555 21.5615 20.7329 21.2442 20.9885 20.9885C21.2442 20.7329 21.5615 20.5555 22.087 20.3099C22.1388 20.2857 22.1926 20.2608 22.2487 20.2351C22.2982 20.2124 22.3493 20.1891 22.4023 20.165C22.4845 20.1277 22.571 20.0886 22.6621 20.0475L22.9577 19.914C23.046 19.8741 23.046 19.7631 22.9577 19.7232L22.6621 19.5898C22.571 19.5486 22.4845 19.5095 22.4023 19.4722C22.3493 19.4482 22.2982 19.4248 22.2487 19.4021C22.1926 19.3764 22.1388 19.3516 22.087 19.3273C21.5615 19.0817 21.2442 18.9044 20.9885 18.6487C20.7329 18.393 20.5555 18.0757 20.3099 17.5503C20.2857 17.4984 20.2608 17.4446 20.2351 17.3886C20.2124 17.3391 20.1891 17.2879 20.165 17.2349C20.1277 17.1527 20.0886 17.0663 20.0475 16.9751L19.914 16.6795Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2074_44855"
          x1="32"
          y1="1.05312e-06"
          x2="-1.50352"
          y2="30.3397"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25" stopColor="#9F2BD6" />
          <stop offset="1" stopColor="#2B4FF4" />
        </linearGradient>
        <linearGradient
          id="paint0_linear_2074_44857"
          x1="34.8718"
          y1="-8.5"
          x2="-6.59881"
          y2="-4.85569"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6F1D96" />
          <stop offset="1" stopColor="#0A2CC7" />
        </linearGradient>
        <linearGradient
          id="paint0_linear_2074_44859"
          x1="34.8718"
          y1="-8.5"
          x2="-6.59881"
          y2="-4.85569"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6F1D96" />
          <stop offset="1" stopColor="#071C7E" />
        </linearGradient>
        <clipPath id="clip0_2074_44855">
          <rect width="16" height="16" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AssistantMenuIcon;
