import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import {
  GetValidTerritoryGroupDateRange,
  GetValidTerritoryGroupDateRangeVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const GET_VALID_TERRITORY_GROUP_DATE_RANGE = gql`
  query GetValidTerritoryGroupDateRange($input: GetValidTerritoryGroupDateRangeInput!) {
    getValidTerritoryGroupDateRange(input: $input) {
      maxEndDate
      maxStartDate
      minEndDate
      minStartDate
    }
  }
`;

export const useGetValidTerritoryGroupDateRange = createUseQuery<
  GetValidTerritoryGroupDateRange,
  GetValidTerritoryGroupDateRangeVariables
>(GET_VALID_TERRITORY_GROUP_DATE_RANGE);
