import React, { useRef } from 'react';

import { Field, Form, useFormikContext } from 'formik';

import DirtyFormPrompt from 'app/components/DirtyFormPrompt/DirtyFormPrompt';
import FormDatePicker from 'app/components/FormFields/FormDatePicker/FormDatePicker';
import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';
import SellerSelect from 'app/components/SellerSelect/SellerSelect';

import { GetValidTerritoryGroupDateRange } from 'app/graphql/generated/graphqlApolloTypes';

import { useIsTerritoryGroupEffectiveDatesEnabled } from 'app/hooks/useIsTerritoryGroupEffectiveDatesEnabled';

import block from 'utils/bem-css-modules';
import { roundToDateOnlyString } from 'utils/helpers/dateHelpers';
import { formatMessage } from 'utils/messages/utils';

import style from './TerritoryGroupDialogForm.module.pcss';

const b = block(style);

interface TerritoryGroupDialogFormProps {
  validDateRange: GetValidTerritoryGroupDateRange | null;
  isValidDateRangeLoading: boolean;
}
const TerritoryGroupDialogForm: React.FC<TerritoryGroupDialogFormProps> = ({
  validDateRange,
  isValidDateRangeLoading
}) => {
  const portalRef = useRef<HTMLDivElement>(null);
  const isTerritoryGroupEffectiveDatesEnabled = useIsTerritoryGroupEffectiveDatesEnabled();
  const { handleBlur, handleChange } = useFormikContext();

  const maxStartDate =
    validDateRange?.getValidTerritoryGroupDateRange.maxStartDate ||
    validDateRange?.getValidTerritoryGroupDateRange.maxEndDate;
  const minEndDate =
    validDateRange?.getValidTerritoryGroupDateRange.minEndDate ||
    validDateRange?.getValidTerritoryGroupDateRange.minStartDate;

  return (
    <Form>
      <div className={b('form')} ref={portalRef}>
        <DirtyFormPrompt data-testid="dirty-form-prompt" />
        <label>
          {formatMessage('NAME_REQUIRED')}
          <Field
            name="name"
            data-testid="name-field"
            type="text"
            component={FormTextInputGroup}
            onChange={(event: React.FocusEvent<HTMLInputElement>) => {
              handleBlur(event);
              handleChange(event);
            }}
          />
        </label>

        <label>
          {formatMessage('TERRITORY_OWNER_LABEL')}
          <Field
            name="territoryGroupOwner"
            component={SellerSelect}
            data-testid="owner-select-field"
            usePortal
            portalRef={portalRef}
          />
        </label>

        {isTerritoryGroupEffectiveDatesEnabled && (
          <>
            <div className={b('startDateField')}>
              <label>
                {formatMessage('EFFECTIVE_START_DATE')}
                <Field
                  name="effectiveDate"
                  component={FormDatePicker}
                  minDate={roundToDateOnlyString(validDateRange?.getValidTerritoryGroupDateRange.minStartDate)}
                  maxDate={roundToDateOnlyString(maxStartDate)}
                  placeholder={formatMessage('DATE_FORMAT_PLACEHOLDER')}
                  disabled={isValidDateRangeLoading}
                  showCalendarIcon
                  data-testid="effective-date-field"
                  fullWidth
                />
              </label>
            </div>
            <div>
              <label>
                {formatMessage('EFFECTIVE_END_DATE')}
                <Field
                  name="endDate"
                  component={FormDatePicker}
                  minDate={roundToDateOnlyString(minEndDate)}
                  maxDate={roundToDateOnlyString(validDateRange?.getValidTerritoryGroupDateRange.maxEndDate)}
                  placeholder={formatMessage('DATE_FORMAT_PLACEHOLDER')}
                  disabled={isValidDateRangeLoading}
                  showCalendarIcon
                  data-testid="end-date-field"
                  fullWidth
                />
              </label>
            </div>
          </>
        )}
      </div>
    </Form>
  );
};

export default TerritoryGroupDialogForm;
