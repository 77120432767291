import React from 'react';

const AssistantIcon = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.86864 2.1377C7.88418 2.10332 7.89984 2.06863 7.91565 2.03363L8.21593 1.36876C8.30568 1.17005 8.55522 1.17005 8.64497 1.36876L8.94525 2.03363C8.96106 2.06863 8.97672 2.10332 8.99226 2.1377C9.00927 2.17536 9.02612 2.21265 9.04281 2.24959C9.88356 4.10993 10.3302 5.06639 11.0621 5.79828C11.794 6.53018 12.7505 6.97685 14.6108 7.8176C14.6478 7.83429 14.6851 7.85114 14.7227 7.86815C14.7571 7.88368 14.7918 7.89934 14.8267 7.91514L15.4916 8.21544C15.6904 8.30519 15.6904 8.55472 15.4916 8.64447L14.8268 8.94476C14.7918 8.96057 14.7571 8.97623 14.7227 8.99177C14.6851 9.00878 14.6478 9.02562 14.6108 9.04232C12.7505 9.88307 11.794 10.3297 11.0621 11.0616C10.3302 11.7935 9.88356 12.75 9.04281 14.6103C9.02612 14.6473 9.00927 14.6846 8.99226 14.7222C8.97672 14.7566 8.96106 14.7913 8.94525 14.8263L8.64497 15.4912C8.55522 15.6899 8.30568 15.6899 8.21593 15.4912L7.91565 14.8263C7.89984 14.7913 7.88418 14.7566 7.86864 14.7222C7.85163 14.6846 7.83478 14.6473 7.81809 14.6103C6.97734 12.75 6.53067 11.7935 5.79877 11.0616C5.06688 10.3297 4.11042 9.88307 2.25008 9.04232C2.21314 9.02562 2.17585 9.00878 2.13819 8.99177C2.10381 8.97623 2.06912 8.96057 2.03412 8.94476L1.36925 8.64447C1.17054 8.55472 1.17054 8.30519 1.36925 8.21544L2.03412 7.91516C2.06911 7.89936 2.10381 7.88368 2.13819 7.86815C2.17585 7.85114 2.21314 7.83429 2.25008 7.8176C4.11042 6.97685 5.06688 6.53018 5.79877 5.79828C6.53067 5.06639 6.97734 4.10993 7.81809 2.24959C7.83478 2.21265 7.85163 2.17536 7.86864 2.1377Z"
        fill="url(#paint0_linear_2308_5779)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8925 10.8494C14.8426 10.739 14.7039 10.739 14.6541 10.8494L14.4872 11.2189C14.4357 11.3328 14.3869 11.4409 14.3403 11.5436C14.3102 11.6099 14.281 11.6738 14.2527 11.7357C14.2206 11.8057 14.1894 11.873 14.1592 11.9378C13.8522 12.5946 13.6304 12.9913 13.3109 13.3109C12.9913 13.6304 12.5946 13.8522 11.9378 14.1592C11.873 14.1894 11.8057 14.2206 11.7357 14.2527C11.6738 14.281 11.6099 14.3102 11.5436 14.3403C11.4409 14.3869 11.3328 14.4357 11.2189 14.4872L10.8494 14.6541C10.739 14.7039 10.739 14.8426 10.8494 14.8925L11.2189 15.0593C11.3328 15.1108 11.4409 15.1596 11.5436 15.2063C11.6099 15.2363 11.6738 15.2655 11.7357 15.2938C11.8057 15.326 11.873 15.3571 11.9378 15.3874C12.5946 15.6944 12.9913 15.9161 13.3109 16.2357C13.6304 16.5552 13.8522 16.9519 14.1592 17.6087C14.1894 17.6735 14.2206 17.7408 14.2527 17.8108C14.281 17.8727 14.3102 17.9367 14.3403 18.0029C14.3869 18.1056 14.4357 18.2137 14.4872 18.3276L14.6541 18.6971C14.7039 18.8075 14.8426 18.8075 14.8925 18.6971L15.0593 18.3276C15.1108 18.2137 15.1596 18.1056 15.2063 18.0029C15.2363 17.9367 15.2655 17.8727 15.2938 17.8108C15.326 17.7408 15.3571 17.6735 15.3874 17.6087C15.6944 16.9519 15.9161 16.5552 16.2357 16.2357C16.5552 15.9161 16.9519 15.6944 17.6087 15.3874C17.6735 15.3571 17.7408 15.326 17.8108 15.2938C17.8727 15.2655 17.9367 15.2363 18.0029 15.2063C18.1056 15.1596 18.2137 15.1108 18.3276 15.0593L18.6971 14.8925C18.8075 14.8426 18.8075 14.7039 18.6971 14.6541L18.3276 14.4872C18.2137 14.4357 18.1056 14.3869 18.0029 14.3403C17.9367 14.3102 17.8727 14.281 17.8108 14.2527C17.7408 14.2206 17.6735 14.1894 17.6087 14.1592C16.9519 13.8522 16.5552 13.6304 16.2357 13.3109C15.9161 12.9913 15.6944 12.5946 15.3874 11.9378C15.3571 11.873 15.326 11.8057 15.2938 11.7357C15.2655 11.6738 15.2363 11.6099 15.2063 11.5436C15.1596 11.4409 15.1108 11.3328 15.0593 11.2189L14.8925 10.8494Z"
        fill="url(#paint1_linear_2308_5779)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2308_5779"
          x1="15.6407"
          y1="1.21973"
          x2="0.542668"
          y2="14.892"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25" stopColor="#9F2BD6" />
          <stop offset="1" stopColor="#2B4FF4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2308_5779"
          x1="18.7799"
          y1="10.7666"
          x2="10.3901"
          y2="18.3642"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25" stopColor="#9F2BD6" />
          <stop offset="1" stopColor="#2B4FF4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AssistantIcon;
