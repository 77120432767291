import React, { useMemo, useState } from 'react';

import { AddAlt, TrashCan } from '@carbon/icons-react';
import { Intent, Text } from '@varicent/components';
import { Field, Formik } from 'formik';
import * as yup from 'yup';

import TextButton from 'components/Buttons/TextButton/TextButton';
import Dialog from 'components/Dialog/Dialog';

import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';
import { CustomHierarchyFilterMenuV2 } from 'app/components/TerritoryMap/CustomHierarchyFilterMenuV2';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';
import { useMapWorkerPostMessage } from 'app/contexts/mapWorkerContext';

import { DefinitionFilterOperatorEnum, HierarchyTypeEnum } from 'app/graphql/generated/graphqlApolloTypes';
import { useUpsertSegment } from 'app/graphql/mutations/upsertSegment';
import { GET_SEGMENTS_NAME } from 'app/graphql/queries/getSegments';

import useShowToast from 'app/hooks/useShowToast';

import {
  CollectionFilter,
  CollectionFilterKind,
  SegmentEditorDialogModes,
  Segment,
  NamedRootHierarchy
} from 'app/models';

import block from 'utils/bem-css-modules';
import { buildFilterWithExplicitNones, cleanEmptyMapFilterClauses } from 'utils/helpers/territoryMapUtils';
import { formatMessage } from 'utils/messages/utils';

import style from './SegmentEditorDialog.module.pcss';

const b = block(style);

type EditProps = {
  mode: SegmentEditorDialogModes.EDIT;
  segment: Pick<Segment, 'segmentId' | 'segmentName'>;
  filter: CollectionFilter<number>[];
};

type CreateProps = {
  mode: SegmentEditorDialogModes.CREATE;
  filter: CollectionFilter<number>[];
  segment?: undefined;
};

export type CreateOrEditSegmentProps = EditProps | CreateProps;

export type SegmentEditorDialogProps = CreateOrEditSegmentProps & {
  hierarchies: NamedRootHierarchy[];
  onClose: () => void;
};

interface SegmentEditorFormValues {
  segmentName: string;
  filter: CollectionFilter<number>[];
}

const SegmentEditorDialog: React.FC<SegmentEditorDialogProps> = ({ mode, filter, segment, hierarchies, onClose }) => {
  const { setSelectedSegmentId, setSegmentEditorDialogOptions } = useDedicatedMapProvider();
  const { selectedBattleCardId, rootHierarchies } = useMapContextRedistributor();
  const [filterDraft, setFilterDraft] = useState(buildFilterWithExplicitNones(filter, rootHierarchies));

  const customRootHierarchies = rootHierarchies.filter(
    (rootHierarchy) => rootHierarchy.hierarchyType === HierarchyTypeEnum.CustomHierarchy
  );

  const showToast = useShowToast();

  const postMessage = useMapWorkerPostMessage();

  const [upsertSegment] = useUpsertSegment({
    refetchQueries: [GET_SEGMENTS_NAME],
    awaitRefetchQueries: true
  });

  const initialValues = useMemo((): SegmentEditorFormValues => {
    switch (mode) {
      case SegmentEditorDialogModes.EDIT:
        return { segmentName: segment.segmentName, filter };
      case SegmentEditorDialogModes.CREATE:
        return { segmentName: '', filter };
    }
    throw new Error(`Unsupported mode: ${mode}`);
  }, [filter, segment]);

  const dynamicCopy = {
    [SegmentEditorDialogModes.CREATE]: {
      title: formatMessage('CREATE_SEGMENT'),
      errorToast: formatMessage('SEGMENT_CREATE_ERROR')
    },
    [SegmentEditorDialogModes.EDIT]: {
      title: formatMessage('EDIT_SEGMENT'),
      errorToast: formatMessage('SEGMENT_EDIT_ERROR')
    }
  }[mode];

  const updateFilterDraft = (rootHierarchyFilter: CollectionFilter<number>) => {
    setFilterDraft((prevFilter) =>
      prevFilter.map((prevRootHierarchyfilter) =>
        prevRootHierarchyfilter.rootHierarchyId === rootHierarchyFilter.rootHierarchyId
          ? rootHierarchyFilter
          : prevRootHierarchyfilter
      )
    );
  };

  return (
    <Dialog isOpen={true} size="small" title={dynamicCopy.title} showDialogFooter={false}>
      <div className={b()}>
        <Text>{formatMessage('CREATE_SEGMENT_DIALOG_DESCRIPTION')}</Text>
        <Formik<SegmentEditorFormValues>
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            const cleanedFilter = cleanEmptyMapFilterClauses(filterDraft).filter(
              (clause) => clause.kind !== CollectionFilterKind.NONE
            );

            const clauses = cleanedFilter.map((filter) => {
              return {
                rootHierarchyId: filter.rootHierarchyId,
                hierarchyType: HierarchyTypeEnum.CustomHierarchy,
                ids: filter.ids as number[],
                operator: collectionFilterKindAsDefinitionFilter(filter.kind)
              };
            });

            await upsertSegment({
              variables: {
                input: {
                  segmentId: segment?.segmentId,
                  segmentName: values.segmentName,
                  battlecardId: parseInt(selectedBattleCardId),
                  clauses
                }
              },
              onCompleted: (result) => {
                setSelectedSegmentId(result.upsertSegment?.segmentId);
                postMessage({ type: 'custom-hierarchy-filter-change', filter: cleanedFilter });
                onClose();
              },
              onError: () => {
                showToast(dynamicCopy.errorToast, 'danger');
              }
            });
          }}
        >
          {({ isSubmitting, handleSubmit }) => (
            <>
              <Field
                className={b('segmentNameField')}
                name="segmentName"
                component={FormTextInputGroup}
                label={formatMessage('SEGMENT_NAME')}
              />
              <div className={b('filterContainer')}>
                {customRootHierarchies.map((customRootHierarchy) => (
                  <div key={customRootHierarchy.rootHierarchyId} className={b('customHierarchyFilterMenuContainer')}>
                    <Field
                      name={`filter-${customRootHierarchy.rootHierarchyId}`}
                      component={CustomHierarchyFilterMenuV2}
                      showFooter={false}
                      hierarchies={hierarchies.filter(
                        (hierarchy) => hierarchy.rootHierarchyId === customRootHierarchy.rootHierarchyId
                      )}
                      rootHierarchyName={customRootHierarchy.rootName}
                      rootHierarchyId={customRootHierarchy.rootHierarchyId}
                      filter={filterDraft.find(
                        (filter) => filter.rootHierarchyId === customRootHierarchy.rootHierarchyId
                      )}
                      onUpdateFilter={(filter) => updateFilterDraft(filter)}
                    />
                  </div>
                ))}
              </div>
              <div className={b('footer')}>
                <div className={b('secondaryActions')}>
                  {mode === SegmentEditorDialogModes.EDIT && (
                    <TextButton
                      minimal
                      intent={Intent.DANGER}
                      type="button"
                      testId="delete-button"
                      text={formatMessage('DELETE')}
                      icon={<TrashCan />}
                      onClick={() => {
                        setSegmentEditorDialogOptions({
                          mode: SegmentEditorDialogModes.DELETE,
                          segmentId: segment.segmentId
                        });
                      }}
                    />
                  )}
                </div>
                <TextButton
                  type="button"
                  testId="cancel-button"
                  text={formatMessage('CANCEL')}
                  onClick={() => onClose()}
                  minimal
                />
                {mode === SegmentEditorDialogModes.CREATE && (
                  <TextButton
                    testId="create-segment-button"
                    text={formatMessage('CREATE')}
                    icon={<AddAlt />}
                    type="button"
                    intent={Intent.PRIMARY}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  />
                )}
                {mode === SegmentEditorDialogModes.EDIT && (
                  <TextButton
                    testId="edit-segment-button"
                    text={formatMessage('CONFIRM')}
                    type="button"
                    intent={Intent.PRIMARY}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  />
                )}
              </div>
            </>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};

export default SegmentEditorDialog;

const collectionFilterKindAsDefinitionFilter = (kind: CollectionFilterKind) => {
  // Two enums are identical, TS jut complains since they have different identities
  return kind as string as DefinitionFilterOperatorEnum;
};

const validationSchema = yup.object().shape({
  segmentName: yup.string().required(formatMessage('REQUIRED_FIELD'))
});
