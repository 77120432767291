import gql from 'graphql-tag';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetLookupTable, GetLookupTableVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const GET_LOOKUP_TABLE = gql`
  query GetLookupTable($planningCycleId: Int!, $lookupInput: LookupInput) {
    getPlanningCycleSpec(input: { planningCycleId: $planningCycleId, lookupInput: $lookupInput }) {
      planningCycleId
      lookups {
        fileId
        lookupId
        lookupType
        metadata {
          tableData
          tableSchema
        }
      }
    }
  }
`;

export const useGetLookupTableLazy = createUseLazyQuery<GetLookupTable, GetLookupTableVariables>(GET_LOOKUP_TABLE);
