import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { GridApi, GridOptions, ServerSideStoreType } from '@ag-grid-community/core';
import dayjs from 'dayjs';
import isequal from 'lodash.isequal';

import ToastMessage from 'components/ToastMessage/ToastMessage';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import GridLoading from 'app/components/AdvancedGrid/GridLoading/GridLoading';
import AccountQuotaHeader from 'app/components/AdvancedGrid/Sheets/Account/AccountQuotaHeader';
import { formatFilterForRequest } from 'app/components/AdvancedGrid/Sheets/AccountRule/AccountRuleHelpers';

import { CELL_HEIGHT } from 'app/constants/DataTrayConstants';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useData } from 'app/contexts/dataProvider';
import { useLocalization } from 'app/contexts/localizationProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryGroupDialog } from 'app/contexts/territoryGroupDialogProvider';

import { SplitFeatures } from 'app/global/features';
import { ACCOUNT_QUOTA_BLOCK_SIZE } from 'app/global/variables';

import {
  AccountRedirectInput,
  GetAccountRuleBindings_getAccountRuleBindings_bindings,
  GetAccountRuleBindings_getAccountRuleBindings_bindings_redirects,
  GetAccountRuleBindings_getAccountRuleBindings_bindings_sourceRule,
  GetAccountRuleBindingsInput,
  GetBattleCard_getDeploymentModelSpec_battlecards_territoryGroupTypes
} from 'app/graphql/generated/graphqlApolloTypes';
import { useGetSheetDefinitions } from 'app/graphql/hooks/useGetSheetDefinitions';

import useCanUser from 'app/hooks/useCanUser';
import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import {
  AccountMoveVariables,
  AqgTerritoryKind,
  DeleteRedirectVariables,
  GridFields,
  GridHeaders,
  HierarchyType,
  Toast,
  MeasureType,
  SheetType,
  DefaultSheetName,
  TerritorySheetGridColumnName,
  FilterInput,
  FilterChangeInput
} from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';
import { UserAction } from 'utils/permissions/userActions';

import AccountMoveDialog from './AccountMoveDialog';
import AccountMoveWithQuotaDialog from './AccountMoveWithQuotaDialog';
import style from './AccountQuotaGrid.module.pcss';
import buildAccountQuotaGridColumnDef from './AccountQuotaGridColumnDef';
import {
  getDayAfterDate,
  getDynamicColumns,
  getFormattedBindings,
  getPlanningCycleEndDate,
  handleRedirectRowRefresh
} from './AccountQuotaUtils';
import { redirectAccountUnit } from './AccountSheetUtils';
import DeleteRedirectDialog from './DeleteRedirectDialog';
import { useGetAccountRuleBindings } from './useGetAccountRuleBindings';

const b = block(style);

interface AccountQuotaGridProps {
  territoryGroupTypes: GetBattleCard_getDeploymentModelSpec_battlecards_territoryGroupTypes[];
}

export interface AqgRow {
  territoryName: string;
  territoryId: string;
  sourceRuleId: number;
  kind: AqgTerritoryKind;
  effectiveStartDate?: string;
  effectiveEndDate?: string;
  redirectStartDate?: string;
  redirectEndDate?: string;
  redirectId?: number;
  firstRedirectStartDate?: string;
}

export interface AqgBindingRow extends AqgRow {
  accountId: number;
  accountKey: string;
  accountName: string;
  redirects: GetAccountRuleBindings_getAccountRuleBindings_bindings_redirects[];
  sourceRule: GetAccountRuleBindings_getAccountRuleBindings_bindings_sourceRule;
  accountQuotaMeasureValue: number;
  accountQuotaMeasureId: number;
}

const AccountQuotaGrid: React.FC<AccountQuotaGridProps> = ({ territoryGroupTypes }) => {
  const { selectedQuotaComponentId, battleCardLookupMap, selectedBattleCardId, quotaBreakdownHierarchies } =
    useBattleCard();
  const { selectedPlanningCycle, selectedDeploymentModelId } = useScope();
  const canEditAccountQuota = useCanUser(UserAction.ACCOUNT_QUOTA_EDIT);
  const { allDataSheets } = useData();

  const [currentTerritoryGroupTypeId, setCurrentTerritoryGroupTypeId] = useState<number>(null);
  const [isMoveDialogOpen, setIsMoveDialogOpen] = useState<boolean>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(null);
  const [accountMoveVariables, setAccountMoveVariables] = useState<AccountMoveVariables>(null);
  const [deleteRedirectVariables, setDeleteRedirectVariables] = useState<DeleteRedirectVariables>(null);
  const [isAccountMoveWithQuotaEnabled] = useTreatment(SplitFeatures.AMWQ_ACCOUNT_QUOTA_IN_GRID);
  const [isAccountMoveWithQuotaTwoHierarchiesEnabled] = useTreatment(SplitFeatures.AMWQ_TWO_HIERARCHIES);
  const [isAccountMoveWithQuotaTwoHierarchySortFilterEnabled] = useTreatment(
    SplitFeatures.AMWQ_TWO_HIERARCHIES_SORT_FILTER
  );
  const draftAccountFilters = useRef<FilterInput>({});
  const { territoryGroupDialogState } = useTerritoryGroupDialog();

  const [savedFilterModel, setSavedFilterModel] = useState<FilterInput>({});
  const { defaultReportingCurrency } = useLocalization();
  const showToast = useShowToast();
  const gridApi = useRef<GridApi>(null);

  const containerRef = React.useRef(null);

  const territoryGroupTypeId = useMemo(() => {
    return currentTerritoryGroupTypeId ?? territoryGroupTypes?.[0]?.territoryGroupId;
  }, [territoryGroupTypes, currentTerritoryGroupTypeId]);

  const battleCardLocalCurrencyCode = battleCardLookupMap?.[selectedBattleCardId]?.localCurrencyCode;

  const accountRuleGridInput = useMemo(
    (): GetAccountRuleBindingsInput => ({
      territoryGroupTypeId,
      quotaComponentId: selectedQuotaComponentId,
      startRow: 1,
      endRow: ACCOUNT_QUOTA_BLOCK_SIZE,
      filters: formatFilterForRequest(savedFilterModel)
    }),
    [territoryGroupTypeId, selectedQuotaComponentId, savedFilterModel]
  );
  const accountRuleGridInputRef = useAsLiveRef(accountRuleGridInput);

  const [getRuleBindings, { data: initialRuleBindings, loading, fetchMore }] = useGetAccountRuleBindings({
    input: accountRuleGridInput
  });

  const isOnlyCustomerHierarchySelected =
    quotaBreakdownHierarchies?.length === 1 &&
    quotaBreakdownHierarchies[0]?.hierarchyType === HierarchyType.CustomerAccountHierarchy;

  const shouldShowDialog =
    isAccountMoveWithQuotaEnabled && (isAccountMoveWithQuotaTwoHierarchiesEnabled || isOnlyCustomerHierarchySelected);

  // get territory quota adjustment measureId from the territory sheet
  const defaultTerritorySheetId = allDataSheets.filter(
    (sheet) =>
      sheet.sheetType === SheetType.TERRITORY_SHEET && sheet.sheetName === DefaultSheetName.TERRITORY_QUOTA_SHEET
  )?.[0]?.sheetId;
  const { sheetDefinitions: territoryQuotaSheetDefinitions } = useGetSheetDefinitions({
    deploymentModelId: selectedDeploymentModelId,
    sheetId: defaultTerritorySheetId,
    isTQM: true
  });

  const territoryQuotaSheetDefinitionLists = territoryQuotaSheetDefinitions?.[0]?.sheetDefinitions;
  const territoryQuotaAdjustmentMeasureId = territoryQuotaSheetDefinitionLists?.find(
    (measure) => measure?.measureName === TerritorySheetGridColumnName.TERRITORY_QUOTA_ADJUSTMENT
  )?.measureId;
  const accountQuotaMeasureId = initialRuleBindings?.getAccountRuleBindings?.bindings?.[0]?.measures?.find(
    (measure) => measure.measureName === MeasureType.ACCOUNT_QUOTA
  )?.measureId;

  const getIsServerSideGroup = useCallback((dataItem: AqgBindingRow) => dataItem?.redirects?.length > 0, []);

  const getServerSideGroupKey = useCallback(
    (binding: AqgBindingRow) => `${binding.sourceRuleId}::${binding.accountId}`,
    []
  );

  useEffect(() => {
    if (territoryGroupTypeId && !territoryGroupDialogState) {
      getRuleBindings();
    }
  }, [territoryGroupTypeId, territoryGroupDialogState]);

  const formatBindings = (bindings: GetAccountRuleBindings_getAccountRuleBindings_bindings[]): AqgBindingRow[] => {
    return getFormattedBindings(
      bindings,
      getPlanningCycleEndDate(selectedPlanningCycle.planningCycleStartDate, selectedPlanningCycle.planningCycleDuration)
    );
  };

  const updateCurrentTerritoryGroupType = useCallback((territoryGroupTypeId: number): void => {
    setCurrentTerritoryGroupTypeId(territoryGroupTypeId);
  }, []);

  const getExpandedTerritoryRows = (expandedRow: AqgBindingRow): AqgRow[] => {
    const sortedTerritories = expandedRow?.redirects
      ? [...expandedRow.redirects].sort((a, b) => b.startDate.localeCompare(a.startDate))
      : [];

    const redirectedTerritories = sortedTerritories.map((redirect, index) => {
      const fallBackMinDate =
        expandedRow.sourceRule?.effectiveDate &&
        dayjs(expandedRow.sourceRule?.effectiveDate).isAfter(selectedPlanningCycle.planningCycleStartDate)
          ? expandedRow.sourceRule?.effectiveDate
          : selectedPlanningCycle.planningCycleStartDate;

      const minDateForUpsert =
        index < sortedTerritories.length - 1 ? sortedTerritories[index + 1].startDate : fallBackMinDate;
      const accountQuotaMeasureValue = redirect.fields.find(
        (field) => field.primaryFieldId === expandedRow.accountQuotaMeasureId
      )?.fieldValue;
      const territoryName = redirect.targetRule?.territoryName ?? formatMessage('UNASSIGNED_TERRITORY');
      const territoryId = redirect.targetRule?.territoryId ?? null;
      let kind;
      if (!redirect.targetRule?.ruleId) {
        kind = AqgTerritoryKind.UNASSIGNED;
      } else if (redirect.targetRule.ruleId === expandedRow.sourceRule.ruleId) {
        kind = AqgTerritoryKind.MOVE_BACK;
      } else {
        kind = AqgTerritoryKind.MOVE_AWAY;
      }
      return {
        territoryName,
        territoryId,
        kind,
        redirectStartDate: redirect.startDate,
        redirectEndDate: redirect.endDate,
        redirectId: redirect.redirectId,
        sourceRuleId: expandedRow.sourceRule.ruleId,
        minDateForUpsert: getDayAfterDate(minDateForUpsert),
        accountQuotaMeasureValue
      };
    });

    const originalTerritory = {
      territoryName: expandedRow.sourceRule.territoryName,
      territoryId: expandedRow.sourceRule.territoryId,
      sourceRuleId: expandedRow.sourceRule.ruleId,
      kind: AqgTerritoryKind.EXPANDED_SOURCE,
      effectiveStartDate: expandedRow.sourceRule.effectiveDate,
      effectiveEndDate: expandedRow.sourceRule.endDate,
      firstRedirectStartDate: redirectedTerritories[redirectedTerritories.length - 1].redirectStartDate
    };
    return [...redirectedTerritories, originalTerritory];
  };

  const accountQuotaGridProps = useMemo(
    (): GridOptions => ({
      rowModelType: 'serverSide',
      serverSideStoreType: 'partial' as ServerSideStoreType,
      cacheBlockSize: ACCOUNT_QUOTA_BLOCK_SIZE,
      suppressMenuHide: true,
      onRowGroupOpened(event) {
        const nodeData = event?.node?.data;
        event?.node?.setData({ ...nodeData, isRowExpanded: !!event?.expanded });
      },
      rowHeight: CELL_HEIGHT,
      serverSideDatasource: {
        getRows: async (params) => {
          const { startRow, endRow } = params?.request;
          // ParentNode key exists when a row is expanded
          if (params.parentNode?.key) {
            const expandedTerritoryRows = getExpandedTerritoryRows(params.parentNode.data);
            params.success({ rowData: expandedTerritoryRows, rowCount: expandedTerritoryRows.length });
            return;
          }

          let accountRuleBindingData;
          // Avoid refetching the initial data since it's already fetched once on component mount
          if (endRow === ACCOUNT_QUOTA_BLOCK_SIZE && params.api.getRenderedNodes().length === 1) {
            accountRuleBindingData = initialRuleBindings?.getAccountRuleBindings;
          } else {
            const response = await fetchMore({
              variables: {
                input: {
                  ...accountRuleGridInputRef.current,
                  startRow: startRow + 1,
                  endRow
                }
              }
            });
            accountRuleBindingData = response?.data?.getAccountRuleBindings;
          }

          const totalCount = accountRuleBindingData?.totalCount || 0;
          const bindings = accountRuleBindingData?.bindings || [];
          if (totalCount === 0) {
            params.api.showNoRowsOverlay();
          } else {
            params.api.hideOverlay();
          }

          params.success({
            rowData: formatBindings(bindings),
            rowCount: totalCount
          });
        }
      }
    }),

    [accountRuleGridInput, initialRuleBindings]
  );

  const handleAccountMoveDialogOpen = (moveVariables: AccountMoveVariables) => {
    setIsMoveDialogOpen(true);
    setAccountMoveVariables({
      ...moveVariables,
      accountQuotaMeasureId,
      territoryQuotaAdjustmentMeasureId
    });
  };

  const handleAccountMoveDialogClose = () => {
    setIsMoveDialogOpen(false);
    setAccountMoveVariables(null);
  };

  const handleDeleteDialogOpen = (deleteVariables: DeleteRedirectVariables) => {
    setIsDeleteDialogOpen(true);
    setDeleteRedirectVariables(deleteVariables);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
    setDeleteRedirectVariables(null);
  };

  const handleRedirectAccountUnit = async (accountRedirectInput: AccountRedirectInput, toast: Toast) => {
    try {
      await redirectAccountUnit(accountRedirectInput);
      showToast(<ToastMessage title={toast.title} message={toast.message} />, 'success');
      const refetchPromises: Promise<void>[] = [];
      gridApi?.current.forEachNode((rowNode) => {
        refetchPromises.push(
          handleRedirectRowRefresh({
            rowNode,
            accountRedirectInput,
            fetchMore,
            territoryGroupTypeId,
            selectedQuotaComponentId,
            selectedPlanningCycle,
            getExpandedTerritoryRows,
            isAccountMoveWithQuotaTwoHierarchiesEnabled
          })
        );
      });
      await Promise.all(refetchPromises);
      gridApi?.current.refreshCells({ force: true });
    } catch (_error) {
      showToast(formatMessage('UPDATE_REDIRECT_ERROR'), 'danger');
    }
  };
  const currency = battleCardLocalCurrencyCode || defaultReportingCurrency;
  const selectedPlanningCycleDuration = {
    planningCycleStartDate: selectedPlanningCycle.planningCycleStartDate,
    planningCycleDuration: selectedPlanningCycle.planningCycleDuration
  };

  const columnDefs = useMemo(() => {
    if (!loading) {
      return buildAccountQuotaGridColumnDef(
        handleAccountMoveDialogOpen,
        handleDeleteDialogOpen,
        selectedPlanningCycleDuration,
        quotaBreakdownHierarchies,
        handleRedirectAccountUnit,
        canEditAccountQuota,
        currency,
        currentTerritoryGroupTypeId,
        isAccountMoveWithQuotaEnabled,
        isAccountMoveWithQuotaTwoHierarchiesEnabled
      );
    }
    return null;
  }, [loading]);

  const onGridReady = (params) => {
    gridApi.current = params.api;
  };

  const namedTgts = useMemo(
    () => territoryGroupTypes?.map((tgt) => ({ name: tgt.hierarchyAlias, id: tgt.territoryGroupId })) ?? [],
    [territoryGroupTypes]
  );

  const handleClearAll = () => {
    draftAccountFilters.current = {};
    handleFilterApply();
  };

  const activeFiltersCount = useMemo(
    () => Object.values(savedFilterModel).filter(({ filter }) => !!filter || filter === 0).length,
    [savedFilterModel]
  );

  const handleFilterApply = () => {
    if (!isequal(draftAccountFilters.current, savedFilterModel)) {
      setSavedFilterModel(draftAccountFilters.current);
      gridApi.current?.onFilterChanged();
      gridApi.current?.deselectAll();
    }
  };

  const dynamicColumns = getDynamicColumns(
    quotaBreakdownHierarchies,
    isAccountMoveWithQuotaTwoHierarchySortFilterEnabled
  );

  const handleFilterChange = (updatedFilter: FilterChangeInput) => {
    const { filterType, type, filter, colId } = updatedFilter;
    draftAccountFilters.current = {
      ...draftAccountFilters.current,
      [colId]: {
        filterType,
        type,
        filter,
        filterTo: null
      }
    };
  };

  return (
    <div className={b('gridWrapper')} ref={containerRef}>
      <AccountQuotaHeader
        territoryGroupTypes={namedTgts}
        onTerritoryGroupTypeChange={updateCurrentTerritoryGroupType}
        currentTerritoryGroupTypeId={currentTerritoryGroupTypeId}
        onFilterChange={handleFilterChange}
        onFilterApply={handleFilterApply}
        dynamicColumns={dynamicColumns}
        accountFilters={draftAccountFilters.current}
        activeFiltersCount={activeFiltersCount}
        onClearAll={handleClearAll}
      />
      <div className={b('advancedGridWrapper')}>
        {!loading && initialRuleBindings?.getAccountRuleBindings?.bindings?.length ? (
          <AdvancedGrid
            data-testid="account-quota-grid"
            gridOptions={accountQuotaGridProps}
            columnDefs={columnDefs}
            treeData
            autoGroupColumnDef={{
              headerName: GridHeaders.ACCOUNT_NAME,
              field: GridFields.ACCOUNT_NAME,
              flex: 2
            }}
            suppressRowHoverHighlight
            isServerSideGroup={getIsServerSideGroup}
            getServerSideGroupKey={getServerSideGroupKey}
            gridWidth={containerRef?.current?.offsetWidth}
            gridHeight={containerRef?.current?.offsetHeight}
            suppressRowTransform
            onGridReady={onGridReady}
          />
        ) : (
          <>
            {loading ? (
              <div data-testid="account-quota-grid-loading">
                <GridLoading
                  gridHeight={containerRef?.current?.offsetHeight}
                  gridWidth={containerRef?.current?.offsetWidth}
                />
              </div>
            ) : (
              <div className={b('gridOverlayContainer')} data-testid="no-data-overlay">
                <div className={b('gridOverlayText')}>{formatMessage('EMPTY_GRID')}</div>
              </div>
            )}
          </>
        )}
      </div>
      {isMoveDialogOpen &&
        accountMoveVariables &&
        (shouldShowDialog ? (
          <AccountMoveWithQuotaDialog
            accountMoveVariables={accountMoveVariables}
            onClose={handleAccountMoveDialogClose}
            onMoveSuccess={getRuleBindings}
            currency={currency}
            isAccountMoveWithQuotaTwoHierarchiesEnabled={isAccountMoveWithQuotaTwoHierarchiesEnabled}
            data-testid="account-move-with-quota-dialog"
          />
        ) : (
          <AccountMoveDialog
            accountMoveVariables={accountMoveVariables}
            onClose={handleAccountMoveDialogClose}
            territoryGroupTypeId={territoryGroupTypeId}
            onMoveSuccess={getRuleBindings}
          />
        ))}
      {isDeleteDialogOpen && deleteRedirectVariables && (
        <DeleteRedirectDialog
          deleteRedirectVariables={deleteRedirectVariables}
          onClose={handleDeleteDialogClose}
          onDeleteSuccess={getRuleBindings}
        />
      )}
    </div>
  );
};

export default AccountQuotaGrid;

function useAsLiveRef<T>(value: T) {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref;
}
