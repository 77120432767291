import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { AccountSourceFilterEnum } from 'app/graphql/generated/graphqlApolloTypes';

import { HierarchyType } from 'app/models';

export const useIsFilteringAccountsByActivities = (): boolean => {
  const { primaryHierarchy, accountSourceFilter } = useMapContextRedistributor();

  return (
    primaryHierarchy === HierarchyType.CustomerAccountHierarchy &&
    accountSourceFilter === AccountSourceFilterEnum.Activity
  );
};
