import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { UpsertTerritoryGroup, UpsertTerritoryGroupVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const UPSERT_TERRITORY_GROUP = gql`
  mutation UpsertTerritoryGroup($input: UpsertTerritoryGroupInput!) {
    upsertTerritoryGroup(input: $input) {
      territoryGroupId
    }
  }
`;

export const useUpsertTerritoryGroup = createUseMutation<UpsertTerritoryGroup, UpsertTerritoryGroupVariables>(
  UPSERT_TERRITORY_GROUP
);
