import { GridApi, ICellRendererParams } from '@ag-grid-community/core';
import {
  MutationTuple,
  // eslint-disable-next-line no-restricted-imports
  useMutation
} from '@apollo/client';

import { updateHierarchyParent, updateHierarchyParentVariables } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { UPDATE_HIERARCHY_PARENT } from 'app/graphql/mutations/updateHierarchyParent';

import useShowToast from 'app/hooks/useShowToast';

import { formatMessage } from 'utils/messages/utils';

const handleSourceNodes = (sourceNodes, setInitialBlock, gridApi) => {
  sourceNodes.forEach((node) => {
    // if the node is on the first level, refresh the entire tree
    if (!node.parent?.key) {
      return setInitialBlock?.(null);
    }
    const parentChildrenList = node.parent?.data?.children;

    // node.id is provided by ag grid which shows the path.
    // eg. 'node1-node2-0' means the node is the first child (index 0) of node2, and node2 is a child of node1.
    // if the parent of the node only have 1 child, after deleting the node we need to make sure the parent is refreshed so that
    // the expand icon and folder icon updates. In this case, route should be ['node1'], otherwise it should be ['node1', 'node2']
    const path = parentChildrenList?.length === 1 ? node.id.split('-').slice(0, -2) : node.id.split('-').slice(0, -1);

    gridApi?.refreshServerSideStore({ route: path });
    // pop 1 item from the parent's children list so that it has the latest children count
    parentChildrenList?.pop();
  });
};

const handleDestNode = (destNode, sourceNodes, gridApi) => {
  const destNodeChildrenList = destNode.data?.children;

  // If the dest node has children before, refresh itself, otherwise, refresh it's parent
  const destPath =
    destNodeChildrenList?.length > 0 ? destNode.id.split('-').slice(0, -1) : destNode.id.split('-').slice(0, -2);
  gridApi?.refreshServerSideStore({ route: destPath });

  // Add temp hierarchy id '1' to the destination node's children list
  const tempChildren = sourceNodes.map(() => '1');
  destNodeChildrenList?.concat(tempChildren);
};

export const useUpdateHierarchyParent = (
  sourceNodes: ICellRendererParams['node'][],
  destNode: ICellRendererParams['node'],
  gridApi?: GridApi,
  setInitialBlock?: (value) => void,
  setMultiSelectedNodes?: (value) => void
): MutationTuple<updateHierarchyParent, updateHierarchyParentVariables> => {
  const showToast = useShowToast();
  return useMutation<updateHierarchyParent, updateHierarchyParentVariables>(UPDATE_HIERARCHY_PARENT, {
    onCompleted() {
      // If reparent to root or the destination node is on the first level, refresh teh entire tree
      if (!sourceNodes || !destNode?.parent?.key) {
        showToast(
          sourceNodes?.length > 1
            ? formatMessage('MEMBERS_REPARENTED_SUCCESS', { count: sourceNodes.length })
            : formatMessage('HIERARCHY_MOVE_SUCCESS'),
          'success'
        );
        return setInitialBlock?.(null);
      }

      // Calculate the paths and refresh affacted source nodes
      handleSourceNodes(sourceNodes, setInitialBlock, gridApi);

      // Calculate the path and refresh the destination node
      handleDestNode(destNode, sourceNodes, gridApi);

      gridApi['selectionService'].selectedNodes = {};

      showToast(
        sourceNodes?.length > 1
          ? formatMessage('MEMBERS_REPARENTED_SUCCESS', { count: sourceNodes.length })
          : formatMessage('HIERARCHY_MOVE_SUCCESS'),
        'success'
      );
      setMultiSelectedNodes?.([]);
      gridApi?.redrawRows();
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('HIERARCHY_MOVE_ERROR'), 'danger');
    }
  });
};
