// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3W0jk {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 10px;\n  word-break: break-word;\n}\n\n  .gjQ0I {\n    color: rgb(var(--color-midnight-blue-2));\n    font-size: 12px;\n    margin-left: auto;\n    margin-bottom: 2px;\n  }\n\n  .TARJI {\n      margin-left: 0;\n      margin-right: auto;\n    }\n\n  .u-X1D {\n    border-radius: 12px 12px 0 12px;\n    background: rgb(var(--color-midnight-blue-7));\n    color: rgb(${colorMidnightBlue1});\n    font-size: 14px;\n    line-height: 20px;\n    margin-left: auto;\n    max-width: 95%;\n    padding: 10px;\n    overflow: hidden;\n    /* stylelint-enable */\n  }\n\n  .T6Ufm {\n      border-radius: 12px;\n      background: var(--assistant-ai-light);\n      color: rgb(${colorMidnightBlue1});\n      margin-left: 0;\n      margin-right: auto;\n    }\n\n  /* stylelint-disable */\n\n  .u-X1D ol, .u-X1D ul {\n      margin-left: 15px;\n      margin-bottom: 0;\n    }\n\n  /* This portion is used for generic styling around LLM markdown responses */\n\n  .u-X1D table {\n      margin: 10px 0;\n      white-space: nowrap;\n      font-size: 12px;\n      border-radius: 4px;\n      border: 1px solid var(--border);\n      border-bottom: none;\n      background: var(--assistant-background-default);\n    }\n\n  .u-X1D thead {\n      background: #f6f6f9;\n      background: var(--colour-background-midtone-background-midtone, #f6f6f9);\n    }\n\n  .u-X1D th {\n      padding: 8px 12px;\n      border-bottom: 1px solid #aab1d5;\n      border-bottom: 1px solid var(--border-input-default-border-input, #aab1d5);\n    }\n\n  .u-X1D td {\n      padding: 4px 12px;\n      border-right: 1px solid #dddfee;\n      border-right: 1px solid var(--border-table-border-table, #dddfee);\n      border-bottom: 1px solid #dddfee;\n      border-bottom: 1px solid var(--border-table-border-table, #dddfee);\n    }\n\n  .u7v7z {\n    overflow-x: auto;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AssistantMessage": "_3W0jk",
	"AssistantMessage_chatDetails": "gjQ0I",
	"AssistantMessage_chatDetails__ai": "TARJI",
	"AssistantMessage_chatBubble": "u-X1D",
	"AssistantMessage_chatBubble__ai": "T6Ufm",
	"AssistantMessage_chatBubbleInner": "u7v7z"
};
export default ___CSS_LOADER_EXPORT___;
