import React, { useEffect } from 'react';

import { Divider } from '@blueprintjs/core';
import { Map, TableSplit } from '@carbon/icons-react';

import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';

import { useGrid } from 'app/contexts/gridProvider';
import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';
import { useMapVariant } from 'app/contexts/mapVariantProvider';

import useCanUser from 'app/hooks/useCanUser';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';
import { UserAction } from 'utils/permissions/userActions';

import style from './TerritoryGridActionButtons.module.pcss';

const b = block(style);

interface MapGridToggleProps {
  onOpenMap: () => void;
  onCloseMap: () => void;
}

const MapGridToggle: React.FC<MapGridToggleProps> = ({ onOpenMap, onCloseMap }: MapGridToggleProps) => {
  const canViewMapSettings = useCanUser(UserAction.MAPS_SETTINGS_VIEW);
  const { bulkDeleteTerritoryJobKey } = useGrid();
  const { isDataTrayMapOpen } = useMapVariant();
  const { selectedTerritoryGroupTypeId: mapTgtId, mapVisualizationSettings } = useMapContextRedistributor();

  const isContributorRestricted = !canViewMapSettings && mapVisualizationSettings === null;
  const isMapPossible = !!mapTgtId && !isContributorRestricted;

  useEffect(() => {
    if (isDataTrayMapOpen && !isMapPossible) {
      onCloseMap();
    }
  }, [isDataTrayMapOpen, isMapPossible]);

  const getMapDisabledTooltipText = () => {
    if (isContributorRestricted) {
      return formatMessage('CONTRIBUTOR_MAP_DISABLED_TOOLTIP');
    }
    return formatMessage('GEO_OR_ACCOUNT_MAP_DISABLED_TOOLTIP');
  };

  return (
    <>
      <Divider className={b('mapToggleDivider')} />
      <div data-testid="map-toggle-switch-wrapper" className={b('mapToggle')}>
        <ToggleSwitch
          onLeftToggle={onCloseMap}
          onRightToggle={() => {
            onOpenMap();
          }}
          leftToggleLabel={formatMessage('GRID')}
          rightToggleLabel={formatMessage('MAP')}
          currentSelection={isDataTrayMapOpen}
          leftToggleIcon={TableSplit}
          rightToggleIcon={Map}
          isRightToggleDisabled={!isMapPossible || !!bulkDeleteTerritoryJobKey}
          rightTooltipContent={getMapDisabledTooltipText()}
        />
      </div>
    </>
  );
};

export default MapGridToggle;
