// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RZmCD {\n    font-weight: 400;\n  }\n\n    .FzOoy {\n      display: flex;\n      width: 100%;\n      justify-content: flex-start;\n      padding-left: var(--grid-header-padding);\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UpsertTerritoryRuleViewLeftPanelHeader_text": "RZmCD",
	"UpsertTerritoryRuleViewLeftPanelHeader_text__columnText": "FzOoy"
};
export default ___CSS_LOADER_EXPORT___;
