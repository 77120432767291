import React, { FC } from 'react';

import AuthSpinner from 'components/AuthSpinner/AuthSpinner';

import { EmbeddedMapNoAccessView } from 'app/components/TerritoryMap/EmbeddedMap/EmbeddedMapFallbackViews';

import { useAuthHeader } from 'app/contexts/AuthHeaderProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { useGetUserSpec } from 'app/graphql/queries/getUserSpec';
import { useGetUserTenantExactlyOnce } from 'app/graphql/queries/getUserTenant';

import { useBreakdownUserSpec } from 'app/hooks/EmbeddedMap/useBreakdownUserSpec';
import { useEmbeddedMapSlugs } from 'app/hooks/EmbeddedMap/useEmbeddedMapSlugs';
import { useSetupScopeForEmbeddedMap } from 'app/hooks/EmbeddedMap/useSetupScopeForEmbeddedMap';
import { useSetupUserForEmbeddedMap } from 'app/hooks/EmbeddedMap/useSetupUserForEmbeddedMap';

export const EmbeddedProviders: FC = ({ children }) => {
  return (
    <SpinUntilAuthenticated>
      <AuthDependantProviders>{children}</AuthDependantProviders>
    </SpinUntilAuthenticated>
  );
};

const SpinUntilAuthenticated: FC = ({ children }) => {
  const { isLoading, isAuthenticated } = useAuthHeader();
  if (isLoading) return <AuthSpinner />;
  if (!isAuthenticated) return <EmbeddedMapNoAccessView />;
  return <>{children}</>;
};

const AuthDependantProviders: FC = ({ children }) => {
  const { selectedTenant } = useScope();
  const slugs = useEmbeddedMapSlugs();

  const { userTenant, loading: userTenantLoading } = useGetUserTenantExactlyOnce();

  const { data: tenantUserSpec, loading: tenantUserSpecLoading } = useGetUserSpec({
    skip: !selectedTenant?.globalId
  });

  const mostSpecificUserSpec = tenantUserSpec ?? userTenant;
  const specBreakdown = useBreakdownUserSpec(mostSpecificUserSpec, slugs);

  useSetupScopeForEmbeddedMap(mostSpecificUserSpec, slugs);
  useSetupUserForEmbeddedMap(mostSpecificUserSpec, slugs);

  if (userTenantLoading || tenantUserSpecLoading) return <AuthSpinner />;

  const canAccessDeploymentModel =
    specBreakdown.tenantSpec && specBreakdown.planningCycleSpec && specBreakdown.deploymentModelSpec;
  if (tenantUserSpec && !canAccessDeploymentModel) {
    return <EmbeddedMapNoAccessView />;
  }
  return <>{children}</>;
};
