import React, { useEffect } from 'react';

import { Play } from '@carbon/icons-react';
import { Intent } from '@varicent/components';

import TextButton from 'components/Buttons/TextButton/TextButton';
//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import { useGetDuplicateTerritories } from 'app/components/PlanningCyclePageHeader/useGetDuplicateTerritories';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { SplitFeatures } from 'app/global/features';

import useCanUser from 'app/hooks/useCanUser';
import { useCoinsortButton } from 'app/hooks/useCoinsortButton';
import useTreatment from 'app/hooks/useTreatment';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';
import { UserAction } from 'utils/permissions/userActions';

import style from './CoinsortButtons.module.pcss';

const b = block(style);

interface CoinsortButtonsProps {
  onClick?: () => void;
}

const CoinsortButton: React.FC<CoinsortButtonsProps> = ({ onClick }: CoinsortButtonsProps) => {
  const { selectedDeploymentModelId } = useScope();
  const { selectedBattleCardId, selectedQuotaComponentId, battleCardLookupMap } = useBattleCard();
  const {
    coinsortEnabled,
    isCoinsortLoading,
    isCoinsortRunning,
    isButtonLoading,
    onClickCancelCoinsort,
    onClickRunCoinsort
  } = useCoinsortButton(selectedBattleCardId, selectedQuotaComponentId, selectedDeploymentModelId);
  const { getTerritoryIdentities, duplicateTerritoryIds } = useGetDuplicateTerritories();

  const [isResolveDuplicateTerritoryEnabled] = useTreatment(SplitFeatures.RESOLVE_DUPLICATE_TERRITORY);
  const [matchingEngineEnabled] = useTreatment(SplitFeatures.MATCHING_ENGINE);
  const [isAddedConfigurationsForUserPermissionsEnabled] = useTreatment(
    SplitFeatures.ADDED_CONFIGURATIONS_FOR_USER_PERMISSIONS
  );
  const canUserEditCoinsort = useCanUser(UserAction.COINSORT_EDIT);
  const shouldDisableCoinsortButton = isAddedConfigurationsForUserPermissionsEnabled ? !canUserEditCoinsort : false;

  useEffect(() => {
    if (isResolveDuplicateTerritoryEnabled) {
      getTerritoryIdentities(selectedDeploymentModelId);
    }
  }, []);

  const handleClick = async () => {
    if (isCoinsortRunning) {
      await onClickCancelCoinsort();
    } else {
      await onClickRunCoinsort();
    }
    onClick?.();
  };

  const quotaComponentName = battleCardLookupMap[selectedBattleCardId]?.quotaComponents?.find(
    (qc) => qc.quotaComponentId === selectedQuotaComponentId
  )?.quotaComponentName;
  const battleCardName = battleCardLookupMap[selectedBattleCardId]?.battlecardName;

  const shouldShowMECoinsortRunningMessage = isCoinsortRunning && matchingEngineEnabled;
  const hasDuplicateTerritoryIds = duplicateTerritoryIds?.length > 0;

  let tooltipContent = '';
  if (shouldShowMECoinsortRunningMessage) {
    tooltipContent = formatMessage('RUN_COIN_SORT_DISABLED_MESSAGE', {
      quotaComponentName,
      battleCardName
    });
  } else if (hasDuplicateTerritoryIds) {
    tooltipContent = formatMessage('COIN_SORT_BUTTON_DISABLED_TOOLTIP_TEXT_DUPLICATE_TERRITORY_IDS', {
      battleCardName
    });
  }

  return (
    <span className={b()}>
      <MessageTooltip
        disabled={!tooltipContent}
        data-testid="coinsort-action-button-tooltip"
        content={tooltipContent}
        target={
          <TextButton
            testId={'coinsort-action-button'}
            type="button"
            text={
              matchingEngineEnabled || !isCoinsortRunning ? formatMessage('RUN_COIN_SORT') : formatMessage('CANCEL')
            }
            intent={Intent.PRIMARY}
            onClick={handleClick}
            icon={<Play />}
            isActiveWhileLoading
            loading={isButtonLoading}
            disabled={
              isCoinsortLoading ||
              !coinsortEnabled ||
              (isCoinsortRunning && matchingEngineEnabled) ||
              hasDuplicateTerritoryIds ||
              shouldDisableCoinsortButton
            }
          />
        }
        placement={'top'}
      />
    </span>
  );
};

export default CoinsortButton;
