import React, { useEffect, useRef, useState } from 'react';

import { ChevronUp } from '@carbon/icons-react';
import { Button, Tag } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';
import EllipsisText from 'components/EllipsisText/EllipsisText';

import { DefinitionFilterOperatorEnum } from 'app/graphql/generated/graphqlApolloTypes';

import { CollectionFilterKind, NamedRootHierarchy, SegmentFilterClause } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ExpandableCustomHierarchyTags.module.pcss';

const b = block(style);

interface ExpandableCustomHierarchyTagsProps {
  customHierarchyFilters: SegmentFilterClause[];
  customHierarchies: NamedRootHierarchy[];
  isAccountFirstMap: boolean;
}

const filterKindText = {
  [CollectionFilterKind.EQUALS]: formatMessage('COLLECTION_FILTER_EQUALS_TO'),
  [CollectionFilterKind.NOT_EQUALS]: formatMessage('COLLECTION_FILTER_NOT_EQUALS_TO'),
  [CollectionFilterKind.CONTAINS_ANY]: formatMessage('COLLECTION_FILTER_CONTAINS_ANY'),
  [CollectionFilterKind.NOT_CONTAINS_ANY]: formatMessage('COLLECTION_FILTER_NOT_CONTAINS_ANY'),
  [CollectionFilterKind.MATCH_ANY_CONDITION]: formatMessage('COLLECTION_FILTER_MATCH_ANY'),
  [CollectionFilterKind.NONE]: formatMessage('NONE')
};

const HIERARCHY_TAG_DATA_TYPE = 'hierarchyTagDataType';

const ExpandableCustomHierarchyTags: React.FC<ExpandableCustomHierarchyTagsProps> = ({
  customHierarchyFilters,
  customHierarchies,
  isAccountFirstMap
}) => {
  const tagContainerRef = useRef<HTMLDivElement>(null);
  const [hiddenTagsCount, setHiddenTagsCount] = useState<number>(0);
  const [hideOverflowTags, setHideOverflowTags] = useState<boolean>(true);

  useEffect(() => {
    if (customHierarchies.length === 0) {
      return;
    }

    let totalTagWidth = 0;
    let hiddenHierarchyTagsCount = 0;
    const tags = tagContainerRef.current.children;

    for (let i = 0; i < tags.length; i++) {
      tags[i].classList.remove(b('hidden'));
    }

    if (hideOverflowTags) {
      for (let i = 0; i < tags.length; i++) {
        const tag = tags[i] as HTMLElement;
        totalTagWidth += tag.clientWidth;
        const isOverflowing = totalTagWidth > tagContainerRef.current.clientWidth;
        const isHierarchyTag = tag.dataset.type === HIERARCHY_TAG_DATA_TYPE;

        if (isOverflowing) {
          tag.classList.add(b('hidden'));
          if (isHierarchyTag) {
            hiddenHierarchyTagsCount++;
          }
        }
      }
    }

    setHiddenTagsCount(hiddenHierarchyTagsCount);
  }, [customHierarchyFilters, hideOverflowTags, customHierarchies]);

  const isAllNones = customHierarchyFilters.length === 0;

  const matchAnyFilters = new Array<SegmentFilterClause>();
  const filtersWithoutMatchAny = new Array<SegmentFilterClause>();

  customHierarchyFilters.forEach((filter) => {
    if (filter.operator === DefinitionFilterOperatorEnum.MATCH_ANY_CONDITION) {
      matchAnyFilters.push(filter);
    } else {
      filtersWithoutMatchAny.push(filter);
    }
  });

  const sortedFilter = [...filtersWithoutMatchAny, ...matchAnyFilters];

  return (
    <div className={b()}>
      <div ref={tagContainerRef} className={b('tagsContainer', { hideOverflowTags })}>
        {isAllNones ? (
          <Tag className={b('tag')} round data-type={HIERARCHY_TAG_DATA_TYPE}>
            <EllipsisText
              className={b('baseRulesOnlyEllipsisText')}
              text={isAccountFirstMap ? formatMessage('ACCOUNT_RULES_ONLY') : formatMessage('GEOGRAPHY_RULES_ONLY')}
            />
          </Tag>
        ) : (
          sortedFilter.map((customHierarchyFilter, index) => (
            <React.Fragment key={`${customHierarchyFilter.rootHierarchyId}-${index}`}>
              {index !== 0 && (
                <Tag className={`${b('tag')} ${b('connectorTag')}`} round>
                  <EllipsisText className={b('tagEllipsisText')} text={formatMessage('AND')} />
                </Tag>
              )}
              <Tag className={b('tag')} round intent="primary">
                <EllipsisText className={b('tagEllipsisText')} text={customHierarchyFilter.rootHierarchyName} />
              </Tag>
              <Tag
                className={`${b('tag')} ${b('filterKindTag')}`}
                data-type={
                  customHierarchyFilter.operator === DefinitionFilterOperatorEnum.MATCH_ANY_CONDITION
                    ? HIERARCHY_TAG_DATA_TYPE
                    : null
                }
                round
              >
                <EllipsisText className={b('tagEllipsisText')} text={filterKindText[customHierarchyFilter.operator]} />
              </Tag>
              {customHierarchyFilter.hierarchies.map(({ hierarchyId, hierarchyName }) => (
                <Tag className={b('tag')} key={hierarchyId} round data-type={HIERARCHY_TAG_DATA_TYPE}>
                  <EllipsisText className={b('tagEllipsisText')} text={hierarchyName} />
                </Tag>
              ))}
            </React.Fragment>
          ))
        )}
      </div>
      <div className={b('controlButtonsContainer')}>
        {hiddenTagsCount > 0 && hideOverflowTags && (
          <Button
            onClick={() => setHideOverflowTags(false)}
            minimal
            small
            text={formatMessage('PLUS', { count: hiddenTagsCount })}
            type="button"
          />
        )}
        {!hideOverflowTags && (
          <div className={b('hideOverflowTagsButton')}>
            <IconButton
              icon={<ChevronUp />}
              onClick={() => setHideOverflowTags(true)}
              type="button"
              testId="hide-overflow-tags"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpandableCustomHierarchyTags;
