// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lrhhP {\n}\n\n  ._6GKQw {\n    width: 100%;\n    box-shadow: var(--box-shadow-2);\n    position: sticky;\n    top: 0;\n    padding: 0;\n    z-index: 20; /* Set to 20 so the Territory builder does not hide behind the Planning Cycle header. */\n  }\n\n  .t\\+kqb {\n    justify-content: space-between;\n    background-color: white;\n    min-height: var(--header-height);\n    height: var(--header-height);\n  }\n\n  ._95eVs {\n      background-color: rgb(var(--color-light-gray-5));\n      padding-left: 32px;\n      justify-content: flex-start;\n      width: 100%;\n      z-index: 1;\n      min-height: 40px;\n      height: 40px;\n    }\n\n  .GzANp {\n    margin-left: var(--l);\n  }\n\n  .h01Av {\n    margin: 0 var(--l) !important;\n    height: var(--xl);\n  }\n\n  .IS8sI {\n    display: flex;\n    margin-right: var(--m);\n    align-items: center;\n    height: 100%;\n  }\n\n  .RJa54 {\n    padding-left: var(--l);\n    color: rgb(var(--color-dark-gray-3));\n    flex-shrink: 0;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n  }\n\n  .jhNgc {\n    color: var(--text-heading);\n    font-weight: bold;\n    font-size: 16px;\n    max-width: 400px;\n  }\n\n  .FvFqe {\n  }\n\n  .FvFqe .bp3-popover2-target {\n      flex-shrink: 0;\n    }\n\n  .iLcMD, .XPyTS {\n    padding-right: var(--m);\n  }\n\n  .fHQz\\+ {\n    border-right: 1px solid rgb(var(--color-light-gray-3)) !important;\n  }\n\n  ._2AWp5 {\n    cursor: pointer;\n  }\n\n  .A6SWr {\n    border-left: 1px solid rgb(var(--color-light-gray-3)) !important;\n  }\n\n  .i1NlP {\n    width: 100%;\n    height: 2px; /* ensure the container matches the LoadingBar's height to prevent thrashing */\n  }\n\n  ._853Yg {\n    margin: var(--s) 0 !important;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PlanningCyclePageHeader": "lrhhP " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["flex-column"] + "",
	"PlanningCyclePageHeader_headerWrapper": "_6GKQw",
	"PlanningCyclePageHeader_header": "t+kqb " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["flex-row"] + " " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["bottom-border"] + "",
	"PlanningCyclePageHeader_header__territoryQuotaTabs": "_95eVs",
	"PlanningCyclePageHeader_phaseTabs": "GzANp",
	"PlanningCyclePageHeader_divider": "h01Av",
	"PlanningCyclePageHeader_logoArea": "IS8sI",
	"PlanningCyclePageHeader_planningCycleName": "RJa54",
	"PlanningCyclePageHeader_planningCycleNameText": "jhNgc",
	"PlanningCyclePageHeader_actionArea": "FvFqe " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["flex-row"] + "",
	"PlanningCyclePageHeader_tenantMenu": "iLcMD",
	"PlanningCyclePageHeader_userMenu": "XPyTS",
	"PlanningCyclePageHeader_homeBtn": "fHQz+ " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["header-menu-btn"] + "",
	"PlanningCyclePageHeader_commandCenterBtnContainer": "_2AWp5",
	"PlanningCyclePageHeader_commandCenterBtn": "A6SWr " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["header-menu-btn"] + "",
	"PlanningCyclePageHeader_loadingBarContainer": "i1NlP",
	"PlanningCyclePageHeader_duplicateTerritoryMessage": "_853Yg"
};
export default ___CSS_LOADER_EXPORT___;
