import React, { useEffect } from 'react';

import { Text } from '@varicent/components';

import { useMapQuestion } from 'app/components/TerritoryMap/hooks/useMapQuestion';

import { CollectionFilter, NamedRootHierarchy, Segment } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import SegmentCard from './SegmentCard';
import style from './SegmentCards.module.pcss';

const b = block(style);

interface SegmentCardsProps {
  segments: Segment[];
  customHierarchies: NamedRootHierarchy[];
  territoryRules: Array<unknown>; // We don't need any details, just the identity of the object
  customHierarchyFilter: CollectionFilter<number>[];
}

const SegmentCards: React.FC<SegmentCardsProps> = ({
  segments,
  customHierarchies,
  territoryRules,
  customHierarchyFilter
}) => {
  const terCountForSegments = useMapQuestion('get-territory-counts-for-segments');

  useEffect(() => {
    terCountForSegments.ask();
  }, [segments, territoryRules]);

  return (
    <div className={b()}>
      <Text className={b('title')}>{formatMessage('SELECT_A_SEGMENT')}</Text>
      {segments.map((segment) => (
        <SegmentCard
          customHierarchyFilter={customHierarchyFilter}
          territoriesCount={terCountForSegments.answer?.get(segment.segmentId) ?? null}
          isLoadingTerritoriesCount={terCountForSegments.isLoading}
          name={segment.segmentName}
          key={segment.segmentId}
          id={segment.segmentId}
          filter={segment.clauses}
          customHierarchies={customHierarchies}
          orphanCount={segment.orphanCount}
        />
      ))}
    </div>
  );
};

export default SegmentCards;
