import gql from 'graphql-tag';

import { createUseLazyQuery, createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import {
  GetPaginatedRootHierarchies,
  GetPaginatedRootHierarchiesVariables
} from 'app/graphql/generated/graphqlApolloTypes';

export const GET_PAGINATED_ROOT_HIERARCHIES = gql`
  query GetPaginatedRootHierarchies($planningCycleId: Int!, $startRow: Int!, $endRow: Int!) {
    getPaginatedRootHierarchies(
      input: { planningCycleId: $planningCycleId, startRow: $startRow, endRow: $endRow, version: 1 }
    ) {
      totalCount
      items {
        hierarchyType
        rootHierarchyId
        rootKey
        rootName
        numOfMembers
        attributes {
          name
          type
          editable
        }
      }
    }
  }
`;

export const useGetPaginatedRootHierarchies = createUseQuery<
  GetPaginatedRootHierarchies,
  GetPaginatedRootHierarchiesVariables
>(GET_PAGINATED_ROOT_HIERARCHIES);
export const useGetPaginatedRootHierarchiesLazy = createUseLazyQuery<
  GetPaginatedRootHierarchies,
  GetPaginatedRootHierarchiesVariables
>(GET_PAGINATED_ROOT_HIERARCHIES);
