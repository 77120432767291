import { useEffect, useMemo, useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import {
  GetCustomerAggregatePins,
  GetCustomerAggregatePinsVariables,
  TGTAggregationInput
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { useGetCustomerAggregatePins } from 'app/graphql/queries/getCustomerAggregatePins';

import useShowToast from 'app/hooks/useShowToast';
import { useSplitPageSize } from 'app/hooks/useSplitPageSize';

import { AggregatePin } from 'app/models';

import { startPerformanceTimer } from 'utils/helpers/analyticsReporter';
import { CompressionUtil } from 'utils/helpers/CompressionUtil';
import { formatMessage } from 'utils/messages/utils';

export const useCustomerAggregatesFeatures = (
  measureId: number
): {
  customerPins: AggregatePin[];
  loading: boolean;
  refetchAllPins: () => void;
  totalPins: number;
  isErrorLoadingCustomerPins: boolean;
} => {
  const [loading, setLoading] = useState(false);
  const [isErrorLoadingCustomerPins, setIsErrorLoadingCustomerPins] = useState(false);
  const { selectedQuotaComponentId, selectedBattleCardId, selectedTerritoryGroupTypeId, accountSourceFilter } =
    useMapContextRedistributor();
  const [customerPins, setCustomerPins] = useState<AggregatePin[]>([]);
  const [totalPins, setTotalPins] = useState(0);

  const showToast = useShowToast();

  const getCustomerAggregatePinsPageSize = useSplitPageSize('getCustomerAggregatePins');

  const refetchAllPins = () => {
    setCustomerPins([]);
    setTotalPins(0);
    fetchAllPinsAndSaveToState();
  };

  const input: TGTAggregationInput = useMemo(() => {
    if (!selectedBattleCardId || !selectedQuotaComponentId || !selectedTerritoryGroupTypeId || !measureId) return null;
    return {
      battlecardId: +selectedBattleCardId,
      quotaComponentId: selectedQuotaComponentId,
      territoryGroupId: selectedTerritoryGroupTypeId,
      measureId
    };
  }, [selectedBattleCardId, selectedQuotaComponentId, selectedTerritoryGroupTypeId, measureId]);

  const { fetchMore, refetch } = useGetCustomerAggregatePins({
    fetchPolicy: 'network-only',
    skip: true,
    onError() {
      showToast(formatMessage('CUSTOMER_PINS_ERROR'), 'danger');
    }
  });

  useEffect(() => {
    setCustomerPins([]);
    setTotalPins(0);
  }, [input]);

  const canonicalRequestId = useRef(0);

  const fetchAllPinsAndSaveToState = () => {
    canonicalRequestId.current += 1;
    const localRequestId = canonicalRequestId.current;
    setLoading(true);
    setIsErrorLoadingCustomerPins(false);

    const updateTotalPins = (total: number) => {
      if (localRequestId !== canonicalRequestId.current) return;
      setTotalPins(total);
    };

    getAllPinPages(input, refetch, fetchMore, getCustomerAggregatePinsPageSize, updateTotalPins)
      .then(({ allCustomerPins }) => {
        if (localRequestId !== canonicalRequestId.current) return;
        setCustomerPins(allCustomerPins);
        setLoading(false);
      })
      .catch((error: ApolloError) => {
        if (localRequestId !== canonicalRequestId.current) return;
        handleError(null, error);

        if (error.networkError) {
          setIsErrorLoadingCustomerPins(true);
        }
        showToast(formatMessage('CUSTOMER_PINS_ERROR'), 'danger');
        setCustomerPins([]);
        setTotalPins(0);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!input) {
      return () => null;
    }
    fetchAllPinsAndSaveToState();
    return () => {
      canonicalRequestId.current += 1;
    };
  }, [input, accountSourceFilter]);

  return {
    customerPins,
    loading,
    refetchAllPins,
    totalPins,
    isErrorLoadingCustomerPins
  };
};

const getAllPinPages = async (
  input: TGTAggregationInput,
  getFirstPage: (variables?: Partial<GetCustomerAggregatePinsVariables>) => Promise<{ data: GetCustomerAggregatePins }>,
  getAdditionalPage: (input: {
    variables?: Partial<GetCustomerAggregatePinsVariables>;
  }) => Promise<{ data: GetCustomerAggregatePins }>,
  pageSize: number,
  updateTotalPins: (total: number) => void
): Promise<{ allCustomerPins: AggregatePin[]; totalRowCount: number }> => {
  const timer = startPerformanceTimer('getAllPinPages');
  const paginationInput = { startRow: 1, endRow: pageSize };
  const firstPage = (await getFirstPage({ input: { ...input, ...paginationInput }, includeCount: true })).data;

  const compressedFirstPage = firstPage.getTGTAggregate.pinsCompressed;
  const decompressedFirstPage = CompressionUtil.decompress(compressedFirstPage);

  const totalRowCount = firstPage.getTGTAggregate.numberOfAccounts ?? 0;
  updateTotalPins(totalRowCount);

  const pagePromises: Promise<AggregatePin[]>[] = [Promise.resolve(decompressedFirstPage)];
  const pageCount = Math.ceil(totalRowCount / pageSize);

  for (let pageIndex = 1; pageIndex < pageCount; pageIndex++) {
    pagePromises.push(
      getAdditionalPage({
        variables: {
          input: {
            ...input,
            startRow: pageSize * pageIndex + 1,
            endRow: pageSize * (pageIndex + 1)
          },
          includeCount: false
        }
      }).then(({ data }) => CompressionUtil.decompress(data.getTGTAggregate.pinsCompressed))
    );
  }

  const customerPinPages = await Promise.all(pagePromises);
  const allCustomerPins = customerPinPages.flat();
  timer.stop({
    count: allCustomerPins.length,
    pageCount: customerPinPages.length
  });
  return { allCustomerPins, totalRowCount };
};
