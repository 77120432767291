export interface ScheduleData {
  scheduleName: string | null;
  month_1: number | null;
  month_2: number | null;
  month_3: number | null;
  month_4: number | null;
  month_5: number | null;
  month_6: number | null;
  month_7: number | null;
  month_8: number | null;
  month_9: number | null;
  month_10: number | null;
  month_11: number | null;
  month_12: number | null;
}

export const getScheduleList = (data): ScheduleData[] => {
  if (!data) {
    return [];
  }
  const parsedData = JSON.parse(data);

  const scheduleList = [];
  Object.entries(parsedData).forEach((schedule) => {
    const data = {};
    data['scheduleName'] = schedule[0];
    Object.entries(schedule[1]).forEach((monthData) => (data[monthData[0]] = monthData[1]));
    scheduleList.push(data);
  });
  return scheduleList;
};
