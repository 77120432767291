import React, { useMemo } from 'react';

import { Radio, RadioGroup, Text } from '@varicent/components';
import { useFormikContext } from 'formik';

import { BasicSelectField } from 'components/BasicSelect/BasicSelectField';

import Combinator from 'app/components/TerritoryRuleBuilder/Combinator';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { SplitFeatures } from 'app/global/features';

import { CustomHierarchyQuantifierEnum } from 'app/graphql/generated/graphqlApolloTypes';

import useTreatment from 'app/hooks/useTreatment';

import { CombinatorType, HierarchyType, MchQuantityType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import { FormValues } from './MapVisualizationSettingsDialog';
import style from './MapVisualizationSettingsDialog.module.pcss';

const b = block(style);

interface RuleVisualizationSettingsProps {
  customHierarchyItems: {
    text: string;
    value: number;
  }[];
}

const RuleVisualizationSettings: React.FC<RuleVisualizationSettingsProps> = ({ customHierarchyItems }) => {
  const [isSingleCustomHierarchyMapOn] = useTreatment(SplitFeatures.MAP_SINGLE_CUSTOM_HIERARCHY);
  const { setFieldValue, values } = useFormikContext<FormValues>();
  const { rootHierarchies } = useMapContextRedistributor();

  const [isMapSegmentationEnabled] = useTreatment(SplitFeatures.MAP_SEGMENTATION);

  const isAccountFirstMap = values.territoryGroupType === HierarchyType.CustomerAccountHierarchy;

  const getCustomHierarchyName = (customHierarchyRootId: number, quantity: MchQuantityType): string => {
    if (!isSingleCustomHierarchyMapOn) return formatMessage('CUSTOM_HIERARCHY_RULES');

    const customHierarchyRootName = customHierarchyItems.find((item) => item.value === customHierarchyRootId)?.text;

    const customHierarchyNameText =
      quantity === CustomHierarchyQuantifierEnum.NONE || !customHierarchyRootId
        ? formatMessage('DYNAMIC_HIERARCHY_PLACEHOLDER')
        : customHierarchyRootName;

    return customHierarchyNameText;
  };

  const getComplexRadioValue = () => {
    if (isMapSegmentationEnabled) {
      return CustomHierarchyQuantifierEnum.ANY;
    } else if (isSingleCustomHierarchyMapOn) {
      return CustomHierarchyQuantifierEnum.SINGULAR;
    } else {
      return CustomHierarchyQuantifierEnum.ALL;
    }
  };

  const customHierarchyCount = useMemo(() => {
    return rootHierarchies.filter(
      (rootCustomHierarchy) => rootCustomHierarchy.hierarchyType === HierarchyType.CustomHierarchy
    ).length;
  }, [rootHierarchies]);

  return (
    <>
      <Text className={b('formLabel', { isRadioLabel: true })}>
        {formatMessage('RULE_VIZ_SETTINGS_DIALOG_BODY_DESCRIPTION')}
      </Text>
      <RadioGroup
        name="quantity"
        className={b('radioGroup')}
        selectedValue={values.quantity}
        onChange={(event) => setFieldValue('quantity', event.currentTarget.value as MchQuantityType)}
      >
        <Radio
          className={b('radio', {
            isAccount: isAccountFirstMap
          })}
          inline
          value={CustomHierarchyQuantifierEnum.NONE}
          labelElement={
            <div>
              <strong>
                {formatMessage('RULE_VIZ_SETTINGS_DIALOG_RULE_TYPE_LABEL', {
                  ruleType: isAccountFirstMap ? formatMessage('ACCOUNT') : formatMessage('GEOGRAPHY')
                })}
              </strong>
              {!isAccountFirstMap && (
                <div className={b('radioDescription')}>
                  <Text className={b('formLabel')}>{formatMessage('GEOGRAPHY')}</Text>
                  <Combinator data-testid="geography-rules-combinator-or" combinatorType={CombinatorType.OR} />
                  <Text className={b('formLabel')}>{formatMessage('ACCOUNT_OVERRIDES')}</Text>
                </div>
              )}
            </div>
          }
        />
        <Radio
          data-testid="complex-visual-radio"
          value={getComplexRadioValue()}
          disabled={isSingleCustomHierarchyMapOn && customHierarchyItems.length === 0}
          inline
          className={b('radio')}
          labelElement={
            <div>
              <strong>{formatMessage('COMPLEX_RULES')}</strong>
              {isMapSegmentationEnabled ? (
                <Text className={b('complexRulesRadioLabel')}>
                  {formatMessage('COMPLEX_RULES_RADIO_LABEL', { count: customHierarchyCount })}
                </Text>
              ) : (
                <div className={b('radioDescription')}>
                  {isAccountFirstMap ? formatMessage('ACCOUNT') : formatMessage('GEOGRAPHY')}
                  <Combinator data-testid="complex-rules-combinator-and" combinatorType={CombinatorType.AND} />
                  <span>{getCustomHierarchyName(values.customHierarchyRoot, values.quantity)}</span>
                  {!isAccountFirstMap && (
                    <>
                      <Combinator data-testid="complex-rules-combinator-or" combinatorType={CombinatorType.OR} />
                      {formatMessage('ACCOUNT_OVERRIDES')}
                    </>
                  )}
                </div>
              )}
            </div>
          }
        />
      </RadioGroup>
      {isSingleCustomHierarchyMapOn &&
        !isMapSegmentationEnabled &&
        values.quantity !== CustomHierarchyQuantifierEnum.NONE && (
          <>
            <label className={b('label')}>{formatMessage('HIERARCHY')}</label>
            <BasicSelectField
              name="customHierarchyRoot"
              label="customHierarchyRoot"
              items={customHierarchyItems}
              placement="bottom-start"
              fill
              showIconOnButton={false}
              disabled={values.quantity !== CustomHierarchyQuantifierEnum.SINGULAR}
            />
          </>
        )}
    </>
  );
};

export default RuleVisualizationSettings;
