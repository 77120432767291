import { FromMapWorkerMessage, ToMapWorkerMessageWithQueueTime } from './MapWorkerProtocol';
export class MapWorkerProxy {
  private readonly worker: Worker;

  constructor() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.worker = new Worker(new URL('./MapWorker.ts', import.meta.url));
  }

  public postMessage(message: ToMapWorkerMessageWithQueueTime): void {
    this.worker.postMessage(message);
    this.addOnError((event) => {
      console.error(`Error from worker`, event);
    });
    this.addOnMessageError((event) => {
      console.error(`MessageError from worker`, event);
    });
  }

  public addOnMessage(onMessage: (message: FromMapWorkerMessage) => void): () => void {
    const handler = (event) => onMessage(event.data);
    this.worker.addEventListener('message', handler);

    return () => {
      this.worker.removeEventListener('message', handler);
    };
  }

  public addOnError(onError: (event: ErrorEvent) => void): () => void {
    this.worker.addEventListener('error', onError);
    return () => {
      this.worker.removeEventListener('error', onError);
    };
  }

  public addOnMessageError(onMessageError: (event: MessageEvent) => void): () => void {
    this.worker.addEventListener('messageerror', onMessageError);
    return () => {
      this.worker.removeEventListener('messageerror', onMessageError);
    };
  }
}
