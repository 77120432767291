import React, { useEffect, useRef, useState } from 'react';

import { SpinnerSize } from '@blueprintjs/core';
import { Send, Maximize, Minimize, Close, TrashCan } from '@carbon/icons-react';
import { Spinner } from '@varicent/components';
import { ChatRoleEnum, SendChatVariables } from 'app/api/ApiTypes';
import { Endpoint } from 'app/api/endpoints';
import { CSSTransition } from 'react-transition-group';
import { v4 as uuidv4 } from 'uuid';

import { UUID } from 'crypto';

import IconButton from 'components/Buttons/IconButton/IconButton';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import {
  parseStreamData,
  updateMessages,
  getTypingEffectChunks
} from 'app/components/Assistant/AssistantStreamResponseHelper';

import { useAssistant } from 'app/contexts/assistantProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { useStreamData, StreamServices } from 'app/graphql/hooks/useStreamData';

import { useApi } from 'app/hooks/useApi';
import useShowToast from 'app/hooks/useShowToast';

import { AssistantDialogState, AssistantMessageDetails, ChatHistory } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './Assistant.module.pcss';
import AssistantEmptyState from './AssistantEmptyState';
import AssistantIcon from './AssistantIcon';
import AssistantLoading from './AssistantLoading';
import AssistantMessage from './AssistantMessage';

const b = block(style);

const Assistant = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [messages, setMessages] = useState<AssistantMessageDetails[]>([]);
  const [isMaximized, setIsMaximized] = useState<boolean>(false);
  const [inputMessageKey, setInputMessageKey] = useState<UUID | null>(null);
  const [activeConversation, setActiveConversation] = useState<string | null>(null);
  const [isNewConv, setIsNewConv] = useState<boolean>(false);
  const showToast = useShowToast();
  const { selectedPlanningCycle } = useScope();

  const {
    userProfile: { subjectId }
  } = useUser();

  const { assistantDialogState, setAssistantDialogState } = useAssistant();

  const {
    get: getChatHistory,
    buildEndpoint,
    data: chatHistoryData,
    isLoading: chatHistoryLoading
  } = useApi<ChatHistory, { subjectId: string }>();

  const {
    data: streamData,
    loading: streamLoading,
    handleStartStream: handleAssistantStream,
    error: streamError
  } = useStreamData<SendChatVariables>(StreamServices.ASSISTANT);

  useEffect(() => {
    const shouldSkip = isNewConv || activeConversation || assistantDialogState === AssistantDialogState.CLOSE;
    if (selectedPlanningCycle?.id && !shouldSkip) {
      getChatHistory(buildEndpoint(Endpoint.GET_CHAT_HISTORY, selectedPlanningCycle.id.toString()), { subjectId });
    }
  }, [selectedPlanningCycle?.id, isNewConv, assistantDialogState, activeConversation]);

  useEffect(() => {
    if (chatHistoryData?.conversationId) {
      setActiveConversation(chatHistoryData.conversationId);
    }
  }, [chatHistoryData]);

  const isLastMessageAi: boolean =
    messages.length > 0 && messages[messages.length - 1].content.role === ChatRoleEnum.ai;

  const classNames = {
    enter: b('wrapperAnimation__enter'),
    enterActive: b('wrapperAnimation__enterActive'),
    exit: b('wrapperAnimation__exit'),
    exitActive: b('wrapperAnimation__exitActive')
  };

  // Used to handle assistant dialog state changes (triggered by menu, command center, etc)
  useEffect(() => {
    // Grab focus on input when opened
    // Clear input on close
    if (assistantDialogState === AssistantDialogState.OPEN) {
      inputRef.current?.focus();
      scrollToBottom();
    } else if (assistantDialogState === AssistantDialogState.CLOSE) {
      setInputValue('');
      setIsMaximized(false);
    }
  }, [assistantDialogState]);

  // Used to handle chat history data
  useEffect(() => {
    if (chatHistoryData?.messages.length) {
      setMessages([
        ...chatHistoryData.messages.map((chat) => ({
          content: { message: chat.message, role: chat.role },
          conversationId: chatHistoryData.conversationId,
          inputMessageKey
        }))
      ]);
    }
  }, [chatHistoryData]);

  // Used to handle chat data
  useEffect(() => {
    if (!streamData) return;

    handleStreamUpdate(streamData);
  }, [streamData]);

  useEffect(() => {
    if (streamError) {
      showToast(
        <ToastMessage title={formatMessage('ASSISTANT_ERROR_TITLE')} message={formatMessage('ASSISTANT_BASE_ERROR')} />,
        'danger'
      );
    }
  }, [streamError]);

  // Used to manage text area height
  useEffect(() => {
    if (inputRef.current) {
      // Always reset it in case content is removed, we want it to shrink back to 1 row
      inputRef.current.style.height = '34px';
      // Add an addition 2px to account for padding
      inputRef.current.style.height = `${inputRef.current.scrollHeight + 2}px`;
    }
  }, [inputRef, inputValue]);

  // Used to ensure the chat always scrolls to the bottom
  useEffect(() => {
    scrollToBottom();
  }, [messages, messagesEndRef]);

  // Used to reset required state when unmounted
  useEffect(() => {
    return () => {
      setAssistantDialogState(AssistantDialogState.CLOSE);
    };
  }, []);

  const handleClearChat = () => {
    setMessages([]);
    removeConversation();
  };

  const removeConversation = () => {
    setIsNewConv(true);
    setActiveConversation(null);
  };

  const handleClose = () => {
    setAssistantDialogState(AssistantDialogState.CLOSE);
  };

  const handleToggleMaximize = () => {
    setIsMaximized(!isMaximized);
  };

  const handleSendChat = (e) => {
    e.preventDefault();
    const inputMessageKey = uuidv4();
    setInputMessageKey(inputMessageKey);
    setMessages([
      ...messages,
      {
        content: { message: inputValue, role: ChatRoleEnum.human },
        ...(activeConversation ? { conversationId: activeConversation } : {}),
        inputMessageKey
      }
    ]);
    handleAssistantStream({ query: inputValue, ...(activeConversation && { conversationId: activeConversation }) });
    setInputValue('');
  };

  const handleStreamUpdate = (streamData: string[]): void => {
    if (!streamData.length) return;

    try {
      const { content, conversationId } = parseStreamData(streamData);
      const conversationToAssignMessages = conversationId || activeConversation;

      const typingChunks: string[] = content && getTypingEffectChunks(content);

      // Update messages if there's new content
      typingChunks?.forEach((chunk) => {
        setMessages((prevMessages) =>
          updateMessages(prevMessages, chunk, conversationToAssignMessages, inputMessageKey)
        );
      });

      // Update conversation ID if one has been retrieved and there isn't an active conversation
      if (conversationId && !activeConversation) {
        setActiveConversation(conversationId);
        setIsNewConv(true);
      }
    } catch {
      console.error(`Failed to handle stream update}`);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className={b()} data-testid="assistant">
      <CSSTransition
        nodeRef={wrapperRef}
        in={assistantDialogState === AssistantDialogState.OPEN}
        classNames={classNames}
        timeout={200}
        appear
        unmountOnExit
      >
        <div ref={wrapperRef} className={b('wrapper', { maximized: isMaximized })} data-testid="assistant-wrapper">
          <div className={b('header')}>
            <div className={b('title')} data-testid="assistant-header-title">
              <div className={b('titleIcon')}>
                <AssistantIcon />
              </div>
              {formatMessage('COPILOT')}
            </div>
            <div className={b('titleActions')}>
              <IconButton
                type="button"
                icon={<TrashCan />}
                title="Clear chat"
                tooltipText={formatMessage('ASSISTANT_HEADER_CLEAR')}
                testId="assistant-action-delete"
                onClick={handleClearChat}
              />
              <IconButton
                type="button"
                icon={isMaximized ? <Minimize /> : <Maximize />}
                title={isMaximized ? 'Minimize' : 'Maximize'}
                tooltipText={isMaximized ? formatMessage('MINIMIZE') : formatMessage('MAXIMIZE')}
                testId="assistant-action-maximize"
                onClick={handleToggleMaximize}
              />
              <IconButton
                type="button"
                icon={<Close />}
                title={formatMessage('CLOSE')}
                tooltipText={formatMessage('CLOSE')}
                testId="assistant-action-close"
                onClick={handleClose}
              />
            </div>
          </div>
          <div className={b('body')}>
            {chatHistoryLoading ? (
              <Spinner size={SpinnerSize.SMALL} />
            ) : (
              <>
                {messages.length ? (
                  <>
                    {messages.map((message, idx) => (
                      <AssistantMessage message={message} key={idx} data-testid="assistant-message" />
                    ))}
                    {streamLoading && !isLastMessageAi && <AssistantLoading />}
                    <div ref={messagesEndRef} data-testid="assistant-message-end" />
                  </>
                ) : (
                  <div className={b('emptyBodyContainer')}>
                    <AssistantEmptyState setInputValue={setInputValue} data-testid="assistant-empty-state" />
                  </div>
                )}
              </>
            )}
          </div>
          <div className={b('footer')}>
            <div className={b('sendChat')}>
              <form onSubmit={handleSendChat}>
                <textarea
                  ref={inputRef}
                  className={b('sendChatInput')}
                  placeholder={formatMessage('ASSISTANT_INPUT_PLACEHOLDER')}
                  value={inputValue}
                  rows={1}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      handleSendChat(e);
                    }
                  }}
                  data-testid="assistant-send-chat-input"
                />
                <IconButton
                  type="submit"
                  className={b('sendChatButton')}
                  icon={streamLoading ? <Spinner size={SpinnerSize.SMALL} /> : <Send />}
                  title="Send message"
                  testId="assistant-send-chat-button"
                  onClick={handleSendChat}
                  disabled={!inputValue?.length}
                />
              </form>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};
export default Assistant;
