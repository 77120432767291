import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

const fallbackPageSizes = {
  getTerritoriesForMap: 600,
  getCustomerAggregatePins: 30_000
} as const;

export const useSplitPageSize = (requestKey: keyof typeof fallbackPageSizes): number => {
  const [_isPagingEnabled, { config }] = useTreatment(SplitFeatures.PAGE_SIZES_CONFIG);

  let pageSize: number;
  try {
    pageSize = config
      ? parseInt(JSON.parse(config)?.[requestKey]) || fallbackPageSizes[requestKey]
      : fallbackPageSizes[requestKey];
  } catch {
    pageSize = fallbackPageSizes[requestKey];
  }

  return pageSize;
};
