import React from 'react';

import { Close, Checkmark } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './UpsertTerritoryRuleViewActionButtonContainer.module.pcss';
const b = block(style);

const UpsertTerritoryRuleViewActionButtonContainer = ({
  isSaveDisabled = false,
  tooltipText,
  loading = false,
  onBackButtonClick
}: {
  isSaveDisabled?: boolean;
  tooltipText?: string;
  loading?: boolean;
  onBackButtonClick: () => void;
}) => {
  return (
    <div>
      <IconButton
        type="button"
        icon={<Close size={20} />}
        title={formatMessage('CANCEL')}
        onClick={onBackButtonClick}
        outlined
        className={b('cancelBtn')}
        testId="cancel-rule-btn"
      />
      <IconButton
        type="submit"
        icon={<Checkmark size={20} />}
        title={formatMessage('SAVE')}
        disabled={isSaveDisabled}
        tooltipText={tooltipText}
        minimal={false}
        loading={loading}
        intent="primary"
        testId="save-rule-btn"
      />
    </div>
  );
};

export default UpsertTerritoryRuleViewActionButtonContainer;
