import React from 'react';

import {
  MutationTuple,
  // eslint-disable-next-line no-restricted-imports
  useMutation
} from '@apollo/client';
import { Position } from '@blueprintjs/core';
import { Intent } from '@varicent/components';

import ToastMessage from 'components/ToastMessage/ToastMessage';

import {
  upsertSymonPipeConfiguration,
  upsertSymonPipeConfigurationVariables
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { UPSERT_SYMON_PIPE_CONFIGURATION } from 'app/graphql/mutations/upsertSymonPipeConfiguration';

import useShowToast from 'app/hooks/useShowToast';

import { formatMessage } from 'utils/messages/utils';

export const useUpsertSymonPipeConfiguration = (
  isUpdate?: boolean
): MutationTuple<upsertSymonPipeConfiguration, upsertSymonPipeConfigurationVariables> => {
  const showToast = useShowToast();
  return useMutation<upsertSymonPipeConfiguration, upsertSymonPipeConfigurationVariables>(
    UPSERT_SYMON_PIPE_CONFIGURATION,
    {
      onCompleted() {
        showToast(
          <ToastMessage
            message={
              isUpdate
                ? formatMessage('UPDATE_SYMON_PIPE_CONFIGURATION_SUCCESS')
                : formatMessage('CONFIGURATION_CREATION_SUCCESS')
            }
          />,
          Intent.SUCCESS,
          Position.TOP_RIGHT
        );
      },
      onError({ graphQLErrors, networkError }) {
        handleError(graphQLErrors, networkError);
        showToast(
          <ToastMessage
            message={
              isUpdate
                ? formatMessage('UPDATE_SYMON_PIPE_CONFIGURATION_ERROR')
                : formatMessage('CONFIGURATION_CREATION_FAIL')
            }
          />,
          Intent.DANGER,
          Position.TOP_RIGHT
        );
      }
    }
  );
};
