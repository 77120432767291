import { useState } from 'react';

import { useScope } from 'app/contexts/scopeProvider';

import { LOAD_ROOT_HIERARCHIES_OPT_BLOCK_SIZE } from 'app/global/variables';

import { GetPaginatedRootHierarchies_getPaginatedRootHierarchies_items } from 'app/graphql/generated/graphqlApolloTypes';
import { useGetPaginatedRootHierarchiesLazy } from 'app/graphql/queries/getPaginatedRootHierarchies';

export const useGetPaginatedRootHierarchiesInfo = () => {
  const { selectedPlanningCycle } = useScope();
  const [rootHierarchies, setRootHierarchies] = useState<
    GetPaginatedRootHierarchies_getPaginatedRootHierarchies_items[] | null
  >(null);

  const [loadRootHierarchies, { loading, fetchMore, error }] = useGetPaginatedRootHierarchiesLazy({
    fetchPolicy: 'network-only'
  });

  if (!selectedPlanningCycle) {
    return { rootHierarchies: [], loading: false, hasError: true };
  }

  const getRootHierarchies = async () => {
    const firstPage = await loadRootHierarchies({
      variables: {
        planningCycleId: selectedPlanningCycle.id,
        startRow: 0,
        endRow: LOAD_ROOT_HIERARCHIES_OPT_BLOCK_SIZE
      }
    });

    if (!firstPage.data?.getPaginatedRootHierarchies) {
      return setRootHierarchies([]);
    }

    const totalCount = firstPage.data.getPaginatedRootHierarchies.totalCount ?? 0;
    const pageCount = Math.ceil(totalCount / LOAD_ROOT_HIERARCHIES_OPT_BLOCK_SIZE);
    const pagePromises = [Promise.resolve(firstPage.data.getPaginatedRootHierarchies.items)];

    for (let pageIndex = 1; pageIndex < pageCount; pageIndex++) {
      pagePromises.push(
        fetchMore({
          variables: {
            planningCycleId: selectedPlanningCycle.id,
            startRow: LOAD_ROOT_HIERARCHIES_OPT_BLOCK_SIZE * pageIndex + 1,
            endRow: LOAD_ROOT_HIERARCHIES_OPT_BLOCK_SIZE * (pageIndex + 1)
          }
        }).then(({ data }) => data.getPaginatedRootHierarchies.items)
      );
    }

    setRootHierarchies((await Promise.all(pagePromises)).flat());
  };

  return { getRootHierarchies, rootHierarchies, loading, hasError: !!error };
};
