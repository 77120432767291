import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';
import { useMapVariant } from 'app/contexts/mapVariantProvider';

import { HierarchyType } from 'app/models';

import { useAccountFirstPinsWarningThreshold } from './useAccountFirstPinsWarningThreshold';

export const useShouldShowAccountMapWarning = (totalCustomers: number, isErrorLoadingCustomerPins: boolean) => {
  const { primaryHierarchy } = useMapContextRedistributor();
  const { isDataTrayMapOpen } = useMapVariant();

  const accountFirstPinsWarningThreshold = useAccountFirstPinsWarningThreshold();
  return (
    isDataTrayMapOpen &&
    accountFirstPinsWarningThreshold &&
    (totalCustomers > accountFirstPinsWarningThreshold || isErrorLoadingCustomerPins) &&
    primaryHierarchy === HierarchyType.CustomerAccountHierarchy
  );
};
