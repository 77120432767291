import { useEffect, useState } from 'react';

import { useGetDataPreviewForSymonPipe } from 'app/graphql/queries/getDataPreviewFromSymonPipe';

import useShowToast from 'app/hooks/useShowToast';

import { formatMessage } from 'utils/messages/utils';

interface UseSymonPipeDataPreviewReturn {
  symonPipeFileRows: string[];
  symonPipeFileHeadersData: string[];
  symonPipeFileLoading: boolean;
}

export const useSymonPipeDataPreview = (
  planningCycleId: number,
  pollingTokenId: string,
  skip: boolean
): UseSymonPipeDataPreviewReturn => {
  const [fileRows, setFileRows] = useState([]);
  const [headersData, setHeadersData] = useState([]);
  const [pollingToken, setPollingToken] = useState(pollingTokenId);
  const showToast = useShowToast();

  const { data, loading, stopPolling } = useGetDataPreviewForSymonPipe({
    skip,
    variables: { planningCycleId, pollingTokenId: pollingToken },
    fetchPolicy: 'network-only',
    pollInterval: 2000,
    onError() {
      showToast(formatMessage('GET_S3_FILE_ERROR'), 'danger');
    }
  });

  const numOfRowsToReturnForUserToViewData = 200;

  useEffect(() => {
    if (!loading && data) {
      if (data.getDataPreviewFromSymonPipe?.rows && fileRows.length < numOfRowsToReturnForUserToViewData) {
        const rowsToAdd = numOfRowsToReturnForUserToViewData - fileRows.length;
        setFileRows([...fileRows, ...data.getDataPreviewFromSymonPipe?.rows.slice(0, rowsToAdd)]);
      }
      if (data.getDataPreviewFromSymonPipe?.colHeaders) {
        setHeadersData(data.getDataPreviewFromSymonPipe?.colHeaders);
      }
    }
  }, [data, loading]);

  useEffect(() => {
    if (data?.getDataPreviewFromSymonPipe?.pollingTokenId) {
      setPollingToken(data?.getDataPreviewFromSymonPipe?.pollingTokenId);
    }
    if (data?.getDataPreviewFromSymonPipe?.status === 'completed') {
      stopPolling();
    }
  }, [data]);

  return { symonPipeFileRows: fileRows, symonPipeFileHeadersData: headersData, symonPipeFileLoading: loading };
};
